import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _directive_preventReClick = _resolveDirective("preventReClick")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    open: _ctx.props.visible,
    "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.props.visible) = $event)),
    title: _ctx.$t('myTask.saveImage'),
    centered: "",
    footer: null,
    maskClosable: false,
    class: "st-modal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        labelCol: { span: 7 },
        ref: "formRef",
        model: _ctx.form,
        rules: _ctx.props.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('myTask.imageName'),
                    name: "imageName"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.form.imageName,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.imageName) = $event)),
                        valueModifiers: { trim: true },
                        allowClear: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('myTask.description'),
                    name: "description"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.form.description,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.description) = $event)),
                        valueModifiers: { trim: true },
                        allowClear: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "btn-m-cancel",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
        }, " 取消 "),
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: "btn-m-primary",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleConfirmPackage && _ctx.handleConfirmPackage(...args)))
        }, [
          _createTextVNode(" 确认 ")
        ])), [
          [_directive_preventReClick, 3000]
        ])
      ])
    ]),
    _: 1
  }, 8, ["open", "title"]))
}