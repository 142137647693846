
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['commit'],
  props: {
    accountInfo: {
      type: Object,
      required: true
    }
  },
  setup (_, { emit }) {
    const handleCommit = (value: any):void => {
      emit('commit', value)
    }
    return {
      handleCommit
    }
  }
})
