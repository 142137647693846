import { computed, ref } from 'vue'
export const useSubUser = () => {
  const activeKey = ref<number>(1)
  const currentComponent = computed<string>(() => {
    if (activeKey.value === 1) {
      return 'UserManagement'
    } else {
      return 'DomainName'
    }
  })
  return {
    activeKey,
    currentComponent
  }
}
