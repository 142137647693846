
import { defineComponent } from 'vue'
import DynamicConditionSearch from '@/components/condition/Condition'
import { useWorkOrder } from './work-order'
import { DeleteOutlined } from '@ant-design/icons-vue'
import StPagination from '@/components/st-pagination/StPagination.vue'

export default defineComponent({
  components: {
    DynamicConditionSearch,
    DeleteOutlined,
    StPagination
  },
  setup () {
    const {
      visible,
      handleAddWorkOrder,
      addForm,
      form,
      fileList,
      allowUploadList,
      customRequest,
      handleConfirm,
      total,
      dataSource,
      columns,
      hanleChangePage,
      handleLinkDetail,
      handleRevoke,
      revokeVisible,
      handleConfirmReVoke,
      recallForm,
      handleModifyWorkOrder,
      title,
      currentCondition,
      conditionList,
      handleChangeCondition,
      handleSelectChange,
      filterOptions,
      handleQuery,
      handleClear,
      handleDelOption,
      showDetail,
      filterList,
      handleChangeStatus,
      handleconfirmFilter,
      formRe
    } = useWorkOrder()

    return {
      currentCondition,
      handleChangeCondition,
      conditionList,
      handleSelectChange,
      handleQuery,
      filterOptions,
      handleAddWorkOrder,
      visible,
      addForm,
      form,
      fileList,
      allowUploadList,
      customRequest,
      handleConfirm,
      total,
      dataSource,
      columns,
      hanleChangePage,
      handleLinkDetail,
      handleRevoke,
      revokeVisible,
      handleConfirmReVoke,
      recallForm,
      handleModifyWorkOrder,
      title,
      handleClear,
      handleDelOption,
      showDetail,
      filterList,
      handleChangeStatus,
      handleconfirmFilter,
      formRe
    }
  }
})
