
import { defineComponent, PropType, computed, ref, watch } from 'vue'
import { userStore } from '@/store/user'
import { useRoute, useRouter } from 'vue-router'
import SubMenu from './SubMenu.vue'

interface ChildrenMenuFace {
  path: string
  children: ChildrenMenuFace[]
  meta: {
    icon: string;
    menuTitle: string;
    englishTitle: string;
  },
  hidden: boolean
}

interface menuFace {
  meta: {
    menuTitle: string;
    icon: string;
    englishTitle: string;
  };
  path: string;
  children: ChildrenMenuFace[];
}

interface PropsFace {
  menu: menuFace
  collapsed: boolean
}

export default defineComponent({
  components: {
    SubMenu
  },
  props: {
    menu: {
      type: Object as PropType<menuFace>,
      required: true
    },
    collapsed: {
      type: Boolean,
      required: true
    }
  },
  setup (props: PropsFace) {
    const user = userStore()
    const route = useRoute()
    const router = useRouter()

    const firstTitleIcon = computed<string>(() => {
      const a = user.$state.themeColor
      if (a === 0) {
        return '_blue'
      } else if (a === 1) {
        return '_red'
      } else if (a === 2) {
        return '_green'
      } else {
        return '_yellow'
      }
    })

    const selectedKeys = ref<string[]>(route.path.substring(route.path.indexOf('/') + 1).split('/').splice(1))
    watch(() => route.path, () => {
      selectedKeys.value = route.path.substring(route.path.indexOf('/') + 1).split('/').splice(1)
    })

    const handleMenuClick = (e: { keyPath: string[] }):void => {
      router.push({
        path: props.menu.path + '/' + e.keyPath.join('/')
      })
    }

    const logoPadding = computed(() => {
      return props.collapsed ? { paddingLeft: '30px' } : { paddingLeft: '54px' }
    })

    return {
      firstTitleIcon,
      selectedKeys,
      handleMenuClick,
      logoPadding
    }
  }
})
