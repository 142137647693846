import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f006c74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox-div" }
const _hoisted_2 = { class: "btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createBlock(_component_a_popover, {
    trigger: "click",
    open: _ctx.visible,
    "onUpdate:open": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
    placement: "bottomRight"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_checkbox, {
          checked: _ctx.checkedAll,
          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedAll) = $event)),
          indeterminate: _ctx.computedColums.length !== 0,
          onChange: _ctx.handleChangeAll
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('del.all')), 1)
          ]),
          _: 1
        }, 8, ["checked", "indeterminate", "onChange"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (item) => {
          return (_openBlock(), _createBlock(_component_a_checkbox, {
            key: item,
            checked: item.isShow,
            "onUpdate:checked": ($event: any) => ((item.isShow) = $event),
            disabled: item.disabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1032, ["checked", "onUpdate:checked", "disabled"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          onClick: _cache[1] || (_cache[1] = () => _ctx.visible = false)
        }, _toDisplayString(_ctx.$t('del.cancel')), 1),
        _createElementVNode("span", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)))
        }, _toDisplayString(_ctx.$t('del.ok')), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_svg_icon, {
        "icon-class": _ctx.visible ? 'ic_shezhi_pressed' : 'ic_shezhi'
      }, null, 8, ["icon-class"])
    ]),
    _: 1
  }, 8, ["open"]))
}