<template>
  <div class="alert-container">
    <div class="alert-message">
      <svg-icon icon-class="ic_alerts" class="alert-icon"></svg-icon>
      <div class="alert-content" v-if="locale !== 'en-US'">
        {{ $route.meta.menuTitle }}：{{ $route.meta.chineseIntroduction }}
      </div>
      <div class="alert-content" v-else-if="locale === 'en-US'">
        {{ $route.meta.englishTitle }}：{{ $route.meta.englishIntroduction }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { getStoredLanguage } from '@/utils/languageStorage'
export default defineComponent({
  setup () {
    const locale = ref(getStoredLanguage())
    return {
      locale
    }
  }
})
</script>

<style lang="scss" scoped>
.alert-container {
  width: 100%;
  background: #f5f5f5;
  margin-bottom: 16px;

  .alert-message {
    display: flex;
    height: 42px;
    align-items: center;
    padding: 10px 10px 10px 10px;
    font-size: 14px;
    color: #474747;
    font-weight: 400;

    .alert-icon {
      height: 16px;
      width: 16px;
      margin-right: 6px;
    }

    .alert-content {
      width: 1200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
