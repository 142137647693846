
import { defineComponent, ref, computed } from 'vue'
import HeaderNav from '@/components/header-nav/HeaderNav.vue'
import SiderMenu from '@/components/sider-menu/SiderMenu.vue'
import { useRoute } from 'vue-router'
import BreadcrumbNav from '@/components/breadcrumb-nav/BreadcrumbNav.vue'

export default defineComponent({
  components: {
    HeaderNav,
    SiderMenu,
    BreadcrumbNav
  },
  setup () {
    const route = useRoute()

    const collapsed = ref(false)

    const menuList = computed<any>(() => {
      return route.matched[0]
    })

    const handleCollasped = ():void => {
      collapsed.value = !collapsed.value
    }

    const breadcrumbList = computed<any[]>(() => {
      return route.matched.filter(e => e.meta.isBread)
    })

    return {
      collapsed,
      menuList,
      handleCollasped,
      breadcrumbList
    }
  }
})
