
import { ref, reactive } from 'vue'
import { queryBucketMoney } from '@/api/myTask'
import http from '@/utils/http'
import { message } from 'ant-design-vue'
import { title, getList } from '../myTaskModule'
interface ExpansionFormState {
  discountMonth: number,
  priceMonth: number,
  flag: boolean,
  dataSize: number,
  defaultDataSize: number,
  dilatationDataSize: number
}
// 网盘扩容
const expansionVisible = ref(false)
const onBtnState = ref(false)
const bucketId = ref('')

const expansionFormRef = ref(null)
const expansionPrice:ExpansionFormState = reactive({
  discountMonth: 0,
  priceMonth: 0,
  flag: false,
  dataSize: 0,
  defaultDataSize: 0,
  dilatationDataSize: 0
})

const handleExpansion = (item:any) => {
  expansionVisible.value = true
  queryBucketMoney(item.id).then((res) => {
    bucketId.value = res.data.bucketId
    expansionPrice.priceMonth = res.data.priceMonth === null ? 0 : res.data.priceMonth
    expansionPrice.discountMonth = res.data.discountMonth === null ? 0 : res.data.discountMonth
    expansionPrice.flag = res.data.flag
    expansionPrice.dataSize = res.data.dataSize
    expansionPrice.defaultDataSize = res.data.defaultDataSize
    expansionPrice.dilatationDataSize = res.data.dilatationDataSize
  })
  title.value = '缓存盘'
}
const handleConfirmExpansion = (data:any) => {
  const { params, cancelToken } = data
  handleExpansionDiskApi(
    params,
    '/storage/bucket/bucketDilatation',
    cancelToken.createCancelList
  ).then(() => {
    expansionVisible.value = false
    getList()
  })
}
// 网盘扩容
const handleExpansionDiskApi = (data:any, url:any, fn:any) => {
  return new Promise((resolve, reject) => {
    http({
      method: 'post',
      url: url,
      data: data,
      cancelToken: fn()
    }).then(() => {
      message.success('扩容成功', 0.5).then(() => {
        resolve(1)
      })
    }).catch(err => {
      reject(err)
    })
  })
}
export {
  handleExpansion,
  onBtnState,
  expansionFormRef,
  handleExpansionDiskApi,
  handleConfirmExpansion,
  expansionVisible,
  bucketId,
  expansionPrice,
  title
}
