import { onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { queryWorkOrderDetailApi } from '@/api/work-order'
import { WorkOrderSpace } from '@/interface/workOrder'

export const useWorkOrderDetail = () => {
  const route = useRoute()
  const workOrderInfo: WorkOrderSpace.WorkOrderInfoFace = reactive({
    workName: '',
    workCode: '',
    status: 0,
    workType: 1,
    createTime: '',
    workExplain: '',
    filePath: '',
    vos: []
  })
  const queryDetailInfo = async () => {
    const res = await queryWorkOrderDetailApi(route.query.id as string)
    Object.assign(workOrderInfo, {
      workName: res.data.workName,
      workCode: res.data.workCode,
      status: res.data.status,
      workType: res.data.workType,
      createTime: res.data.createTime,
      workExplain: res.data.workExplain,
      filePath: res.data.filePath,
      vos: res.data.vos
    })
  }

  onMounted(() => {
    queryDetailInfo()
  })

  return {
    workOrderInfo,
    queryDetailInfo
  }
}
