import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    open: _ctx.changeFee,
    "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.changeFee) = $event)),
    width: 746,
    centered: "",
    title: _ctx.$t('addTask.billingChanges'),
    onOk: _ctx.handleConfirmChangeFee,
    "ok-button-props": {
      disabled: _ctx.changeTaskBtnState,
    }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        "label-col": {
      style: {
        width: '126px',
      },
    },
        model: _ctx.changeFeeParams,
        ref: "changeFeeForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('addTask.chargeType'),
                    name: "costType",
                    rules: [
            {
              required: true,
              message: _ctx.$t('addTask.chargeTypePlaceHolder'),
            },
          ]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        style: {"width":"220px"},
                        value: _ctx.changeFeeParams.costType,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.changeFeeParams.costType) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select_option, { value: 2 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("addTask.day")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 3 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("addTask.month")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('addTask.orderDiscount')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        style: {"width":"220px"},
                        value: _ctx.computedDiscountPirce,
                        disabled: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('addTask.orderPrice')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        style: {"width":"220px"},
                        value: _ctx.computedTotalPrice,
                        disabled: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('addTask.usageDuration'),
                    name: "rentTime",
                    rules: [
            {
              required: true,
              message: _ctx.$t('addTask.usageDurationPlaceholder'),
            },
          ]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        min: 1,
                        style: {"width":"220px"},
                        value: _ctx.changeFeeParams.rentTime,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.changeFeeParams.rentTime) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('addTask.orderOriginalPrice')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        style: {"width":"220px"},
                        value: _ctx.computedOrderPrice,
                        disabled: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _: 1
  }, 8, ["open", "title", "onOk", "ok-button-props"]))
}