
import { defineComponent } from 'vue'
import { useEnterprise } from './enterprise-module'
import { DeleteOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    DeleteOutlined
  },
  setup () {
    const {
      formInfo,
      handleUpload,
      beforeUpload,
      fileList,
      isChecked,
      handleSubmit,
      form,
      rules
    } = useEnterprise()

    return {
      formInfo,
      handleUpload,
      beforeUpload,
      fileList,
      isChecked,
      handleSubmit,
      form,
      rules
    }
  }
})
