
import { defineComponent, onMounted, reactive, toRefs, computed } from 'vue'
import { useRoute } from 'vue-router'
import { queryNameAuthApi } from '@/api/user-center'
import { SecuritySet } from '@/interface/userCenter'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup () {
    const { t } = useI18n()
    const route = useRoute()
    const authList: SecuritySet.AuthListFace = reactive({
      comp: {
        info: '',
        remark: '',
        status: 0
      },
      name: {
        info: '',
        remark: '',
        status: 0
      },
      student: {
        info: '',
        remark: '',
        status: 0
      }
    })

    const queryNameAuth = async () => {
      const res = await queryNameAuthApi()
      Object.assign(authList, res.data)
    }

    const persionStatus = computed<string>(() => {
      if (authList.name.status === -1) {
        return t('userCenter.auditFailure')
      } else if (authList.name.status === 0) {
        return t('userCenter.unverified')
      } else if (authList.name.status === 1) {
        return t('userCenter.reviewed')
      } else {
        return t('userCenter.verified')
      }
    })

    const compStatus = computed<string>(() => {
      if (authList.comp.status === -1) {
        return t('userCenter.auditFailure')
      } else if (authList.comp.status === 0) {
        return t('userCenter.unverified')
      } else if (authList.comp.status === 1) {
        return t('userCenter.reviewed')
      } else {
        return t('userCenter.verified')
      }
    })

    const studentStatus = computed<string>(() => {
      if (authList.student.status === -1) {
        return t('userCenter.auditFailure')
      } else if (authList.student.status === 0) {
        return t('userCenter.unverified')
      } else if (authList.student.status === 1) {
        return t('userCenter.reviewed')
      } else {
        return t('userCenter.verified')
      }
    })

    const isShowSubView = computed(() => {
      if (route.path === '/user-center/security-setting/real-name-auth') {
        return true
      } else {
        return false
      }
    })

    onMounted(() => {
      queryNameAuth()
    })
    return {
      ...toRefs(authList),
      persionStatus,
      compStatus,
      studentStatus,
      isShowSubView
    }
  }
})
