
import { packageVisible, form, formRef, rowData, getList } from '../myTaskModule'
import { message } from 'ant-design-vue'
import _ from 'lodash'
import { packagingContainer } from '@/api/myTask'
const handlePackageImage = (item:any) => {
  packageVisible.value = true
  form.imageName = ''
  rowData.value = item
}
const handleConfirmPackage = () => {
  formRef.value.validateFields().then(() => {
    packageImage()
  })
}
const packageImage = _.debounce(() => {
  const params = {
    imageName: form.imageName,
    taskId: rowData.value.id,
    description: form.description
  }
  packagingContainer(params).then(() => {
    message.success('保存镜像开始')
    packageVisible.value = false
    getList()
  })
}, 500)
export {
  handlePackageImage,
  packageVisible,
  form,
  handleConfirmPackage,
  formRef
}
