
import { defineComponent, ref, onMounted, reactive } from 'vue'
export default defineComponent({
  emits: ['announcementVisible'],
  setup (props, { emit }) {
    const announcementVisible = ref<boolean>(true)
    const noticeMessage = reactive({
      noticeContent: ''
    })
    const wsRef = ref<any>(null)
    // 初始化websocket连接
    // const ip = '192.168.207.182'
    // const protocol = 'https'
    const ip = window.location.host
    const protocol = window.location.protocol

    onMounted(() => {
      initWs()
    })
    const initWs = async () => {
      let wsPrefix = ''
      if (protocol === 'http:') {
        wsPrefix = 'ws'
      } else {
        wsPrefix = 'wss'
      }
      const ws = new WebSocket(`${wsPrefix}://${ip}/ws/user/websocket/notice`)
      wsRef.value = ws
      ws.onmessage = async ({ data }) => {
        await Object.assign(noticeMessage, JSON.parse(data))
        if (!noticeMessage.noticeContent) {
          announcementVisible.value = false
        }
        emit('announcementVisible', announcementVisible.value)
      }
    }
    const handleCloseAnnouncement = () => {
      announcementVisible.value = false
      emit('announcementVisible', announcementVisible.value)
    }
    return {
      handleCloseAnnouncement,
      announcementVisible,
      noticeMessage
    }
  }
})
