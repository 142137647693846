import { ref, reactive } from 'vue'
import i18n from '@/i18n/i18n'
const { t } = i18n.global
interface MarketParamsState {
  regionId?: string;
  gpuLaveCount: number;
  videoMemory: number;
  gpuPrice: number;
  gpuModelList?: Array<number>;
  sort?: number;
  gpuAmount?:number | string;
}
interface CheckState {
  indeterminate: boolean;
  checkAll: boolean;
  checkedList: Array<number>;
}
const gpuNumMarks = ref({
  1: '1',
  10: '10'
})
const videoMemoryMarks = ref({
  1: '1',
  80: '80'
})
const gpuPriceMarks = ref({
  0.1: '0.1',
  100: '100'
})
const btnList = ref([{ lable: t('costCenter.unusedGpuAmount'), status: 0 }])
const state: CheckState = reactive({
  indeterminate: true,
  checkAll: false,
  checkedList: []
})
const clearVisible = ref<boolean>(false)
const plainOptions = ref<any>([])
const nodePlaceOptions = ref<any>([])
const hostData = ref<any>([])
const formState: MarketParamsState = reactive({
  regionId: '',
  gpuLaveCount: 10,
  videoMemory: 80,
  gpuPrice: 100,
  gpuModelList: [],
  gpuAmount: ''
})
export {
  nodePlaceOptions,
  hostData,
  formState,
  gpuNumMarks,
  videoMemoryMarks,
  gpuPriceMarks,
  state,
  clearVisible,
  plainOptions,
  btnList
}
