import { ref, reactive } from 'vue'
import i18n from '@/i18n/i18n'
const { t } = i18n.global
interface FormParamsState {
  remark: string,
  alias: string,
  publicKey: string,
  id: string
}
interface CheckState {
  indeterminate: boolean;
  checkAll: boolean;
  checkedList: Array<number>;
}
const originForm:FormParamsState = reactive({
  remark: '',
  alias: '',
  publicKey: '',
  id: ''
})
const form = reactive({ ...originForm })
const state:CheckState = reactive({
  indeterminate: true,
  checkAll: false,
  checkedList: []
})

const total = ref(0)
const title = ref<string>('')
const dialogVisible = ref<boolean>(false)
const selectRowData:any = ref([])
const selectedIds:any = ref([])
const formRef = ref<any>(null)

const columns = ref([
  {
    title: t('maintenanceCenter.alias'),
    dataIndex: 'alias',
    key: 'alias',
    ellipsis: true,
    isShow: true,
    disabled: true
  },
  {
    title: t('maintenanceCenter.publicKey'),
    dataIndex: 'publicKey',
    key: 'publicKey',
    scopedSlots: { customRender: 'publicKey' },
    ellipsis: true,
    isShow: true
  },
  {
    title: t('maintenanceCenter.remark'),
    dataIndex: 'remark',
    key: 'remark',
    scopedSlots: { customRender: 'remark' },
    ellipsis: true,
    isShow: true,
    disabled: true
  },
  {
    title: t('maintenanceCenter.operation'),
    dataIndex: 'operation',
    key: 'operation',
    ellipsis: true,
    align: 'center',
    width: 110,
    isShow: true,
    disabled: true,
    fixed: 'right'
  }
])
const dataSource = ref([])
const plainOptions = ref([])
const filterColumns:any = ref([])
const headerVisible = ref(false)
export {
  total,
  title,
  dialogVisible,
  form,
  selectRowData,
  selectedIds,
  formRef,
  columns,
  dataSource,
  state,
  plainOptions,
  filterColumns,
  headerVisible,
  originForm
}
