
import { defineComponent } from 'vue'
import { useStationMessage } from './station-message'
import StPagination from '@/components/st-pagination/StPagination.vue'
import ConfigColumns from '@/components/config-columns/ConfigColumns.vue'
import { EllipsisOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    StPagination,
    ConfigColumns,
    EllipsisOutlined
  },
  setup () {
    const {
      dataSource,
      total,
      columns,
      hanleChangePage,
      scrollHeight,
      handleChangeAll,
      filterColumns,
      isAllHidden,
      handleSetColumns,
      handleDel,
      handleChoostTools,
      handleRead,
      handleReadAll
    } = useStationMessage()

    return {
      dataSource,
      total,
      columns,
      hanleChangePage,
      scrollHeight,
      handleChangeAll,
      handleSetColumns,
      filterColumns,
      isAllHidden,
      handleDel,
      handleChoostTools,
      handleRead,
      handleReadAll
    }
  }
})
