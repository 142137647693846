import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ee697b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basic-info" }
const _hoisted_2 = { class: "btn-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_st_button = _resolveComponent("st-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      "label-col": { span: 7 },
      "wrapper-col": { span: 14 },
      model: _ctx.accountInfo,
      onFinish: _ctx.handleCommit
    }, {
      default: _withCtx(() => [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('userCenter.basic')), 1),
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "username", {}, undefined, true)
              ]),
              _: 3
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "schooling", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }),
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('userCenter.businessInformation')), 1),
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "occupation", {}, undefined, true)
              ]),
              _: 3
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "studyDirection", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }),
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "trade", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }),
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "awards", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }),
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('userCenter.contactInformation')), 1),
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "area", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }),
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "country", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_st_button, {
            type: "primary",
            "html-type": "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('userCenter.save')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 3
    }, 8, ["model", "onFinish"])
  ]))
}