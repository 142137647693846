import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f4aea0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_pagination, {
      size: "small",
      total: _ctx.total,
      "show-quick-jumper": true,
      "show-size-changer": "",
      "show-total": () => `${(_ctx.$i18n.locale === 'zh-CN' ? '共' : 'total')} ${_ctx.total} ${(_ctx.$i18n.locale === 'zh-CN' ? '条' : '')}`,
      onChange: _ctx.handlePaginationChange
    }, null, 8, ["total", "show-total", "onChange"])
  ]))
}