import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ca7f24ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-container" }
const _hoisted_2 = { class: "m-background" }
const _hoisted_3 = { class: "banner" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "main-page" }
const _hoisted_6 = { class: "left-bar" }
const _hoisted_7 = { class: "left-top" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "left-top" }
const _hoisted_14 = { class: "label" }
const _hoisted_15 = { class: "slider" }
const _hoisted_16 = { class: "title" }
const _hoisted_17 = { class: "slider" }
const _hoisted_18 = { class: "title" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "slider" }
const _hoisted_21 = { class: "title" }
const _hoisted_22 = { class: "model" }
const _hoisted_23 = { class: "title" }
const _hoisted_24 = { class: "model-group" }
const _hoisted_25 = { class: "m-check" }
const _hoisted_26 = { class: "right-content" }
const _hoisted_27 = { class: "sort-header" }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { class: "box-icon" }
const _hoisted_30 = { class: "sort-warpper" }
const _hoisted_31 = { class: "sort-list" }
const _hoisted_32 = { class: "left-sort" }
const _hoisted_33 = { class: "header" }
const _hoisted_34 = { class: "title" }
const _hoisted_35 = { class: "sort-tag" }
const _hoisted_36 = { class: "m-form" }
const _hoisted_37 = { class: "col-content" }
const _hoisted_38 = { class: "col-content" }
const _hoisted_39 = { class: "col-content" }
const _hoisted_40 = { class: "col-content" }
const _hoisted_41 = { class: "col-content" }
const _hoisted_42 = { class: "col-content" }
const _hoisted_43 = { class: "col-content" }
const _hoisted_44 = { class: "col-content" }
const _hoisted_45 = { class: "col-content" }
const _hoisted_46 = { class: "right-sort" }
const _hoisted_47 = { class: "right-sort-header" }
const _hoisted_48 = {
  key: 0,
  class: "net-tag"
}
const _hoisted_49 = ["src"]
const _hoisted_50 = {
  key: 1,
  class: "net-tag"
}
const _hoisted_51 = ["src"]
const _hoisted_52 = {
  key: 2,
  class: "net-tag"
}
const _hoisted_53 = ["src"]
const _hoisted_54 = { class: "right-sort-content" }
const _hoisted_55 = { class: "current-price" }
const _hoisted_56 = { class: "current-price-unit" }
const _hoisted_57 = { class: "old-price" }
const _hoisted_58 = {
  key: 0,
  class: "quote"
}
const _hoisted_59 = {
  key: 0,
  class: "right-sort-quote"
}
const _hoisted_60 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scroll_announcement = _resolveComponent("scroll-announcement")!
  const _component_header_nav = _resolveComponent("header-nav")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_slider = _resolveComponent("a-slider")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_scroll_announcement),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_header_nav),
        _createVNode(_component_svg_icon, { iconClass: "img_wenzi_market" })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_alert, {
          style: {"margin":"0.7rem 0 0rem 0rem","color":"#ee3b28","padding":"1rem","font-size":"14px","align-items":"center","min-width":"885px","border-radius":"0"},
          description: _ctx.$t('costCenter.marketTips'),
          type: "error",
          "show-icon": ""
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_svg_icon, {
              iconClass: "ic_tongzhi",
              style: {"width":"1rem","height":"1rem"}
            })
          ]),
          _: 1
        }, 8, ["description"]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("costCenter.region")), 1),
              _createElementVNode("div", {
                class: "clear",
                style: {"cursor":"pointer"},
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cleanSearch && _ctx.cleanSearch(...args)))
              }, _toDisplayString(_ctx.$t("costCenter.cleanSearch")), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodePlaceOptions, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "tab",
                key: index
              }, [
                _createElementVNode("ul", null, [
                  (item[0] && item[1])
                    ? (_openBlock(), _createElementBlock("li", {
                        key: 0,
                        class: _normalizeClass(_ctx.formState.regionId == item[0].id ? 'active' : 'default'),
                        onClick: ($event: any) => (_ctx.handleTabs(item[0]))
                      }, _toDisplayString(item[0].region), 11, _hoisted_9))
                    : _createCommentVNode("", true),
                  (item[0] && item[1])
                    ? (_openBlock(), _createElementBlock("li", {
                        key: 1,
                        class: _normalizeClass(_ctx.formState.regionId === item[1].id ? 'active' : 'default'),
                        onClick: ($event: any) => (_ctx.handleTabs(item[1]))
                      }, _toDisplayString(item[1].region), 11, _hoisted_10))
                    : _createCommentVNode("", true),
                  (item[0] && !item[1])
                    ? (_openBlock(), _createElementBlock("li", {
                        key: 2,
                        class: _normalizeClass(_ctx.formState.regionId === item[0].id ? 'active' : 'default'),
                        onClick: ($event: any) => (_ctx.handleTabs(item[0])),
                        style: {"border-radius":"8px"}
                      }, _toDisplayString(item[0].region), 11, _hoisted_11))
                    : _createCommentVNode("", true),
                  (!item[0] && item[1])
                    ? (_openBlock(), _createElementBlock("li", {
                        key: 3,
                        class: _normalizeClass(_ctx.formState.regionId === item[1].id ? 'active' : 'default'),
                        onClick: ($event: any) => (_ctx.handleTabs(item[1])),
                        style: {"border":"unset","cursor":"unset"}
                      }, _toDisplayString(item[1].region), 11, _hoisted_12))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("costCenter.refining")), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("costCenter.gpuNum")), 1),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 3 }),
                  _createVNode(_component_a_col, {
                    span: 16,
                    offset: 1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_slider, {
                        value: _ctx.formState.gpuLaveCount,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.gpuLaveCount) = $event)),
                        marks: _ctx.gpuNumMarks,
                        min: 1,
                        max: 10,
                        onAfterChange: _ctx.handleLaveGpu
                      }, {
                        mark: _withCtx(({ label, point }) => [
                          (point === 100)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(label), 1)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(label), 1)
                              ], 64))
                        ]),
                        _: 1
                      }, 8, ["value", "marks", "onAfterChange"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("costCenter.videoMemory")), 1),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 3 }),
                  _createVNode(_component_a_col, {
                    span: 16,
                    offset: 1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_slider, {
                        value: _ctx.formState.videoMemory,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.videoMemory) = $event)),
                        marks: _ctx.videoMemoryMarks,
                        min: 1,
                        max: 80,
                        onAfterChange: _ctx.handleLaveVideoMemory
                      }, {
                        mark: _withCtx(({ label, point }) => [
                          (point === 100)
                            ? (_openBlock(), _createElementBlock("strong", _hoisted_19, _toDisplayString(label), 1))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(label), 1)
                              ], 64))
                        ]),
                        _: 1
                      }, 8, ["value", "marks", "onAfterChange"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("costCenter.gpuPrice")), 1),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 3 }),
                  _createVNode(_component_a_col, {
                    span: 16,
                    offset: 1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_slider, {
                        value: _ctx.formState.gpuPrice,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.gpuPrice) = $event)),
                        marks: _ctx.gpuPriceMarks,
                        min: 0.1,
                        max: 100,
                        step: 0.1,
                        onAfterChange: _ctx.handleGpuPrice
                      }, {
                        mark: _withCtx(({ label, point }) => [
                          (point === 100)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(label), 1)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(label), 1)
                              ], 64))
                        ]),
                        _: 1
                      }, 8, ["value", "marks", "onAfterChange"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("costCenter.modelOptions")) + "：", 1),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_a_checkbox, {
                  style: {"margin-bottom":"4%","padding":"0px"},
                  checked: _ctx.checkAll,
                  "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.checkAll) = $event)),
                  indeterminate: _ctx.indeterminate,
                  onChange: _ctx.onCheckAllChange
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("costCenter.all")), 1)
                  ]),
                  _: 1
                }, 8, ["checked", "indeterminate", "onChange"]),
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_a_checkbox_group, {
                    value: _ctx.checkedList,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.checkedList) = $event)),
                    options: _ctx.plainOptions
                  }, null, 8, ["value", "options"])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.btnList, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "right",
                  onClick: ($event: any) => (_ctx.changeSort(item, index)),
                  key: index
                }, [
                  _createTextVNode(_toDisplayString(item.lable) + " ", 1),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["up", item.status === 1 ? 'opacity-5' : ''])
                    }, null, 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["down", item.status === 1 ? 'opacity-1' : ''])
                    }, null, 2)
                  ])
                ], 8, _hoisted_28))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("ul", _hoisted_31, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hostData, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "sort-card",
                    key: item
                  }, [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("span", _hoisted_34, _toDisplayString(item.gpuModel), 1),
                        _createElementVNode("span", _hoisted_35, _toDisplayString(item.region) + "/" + _toDisplayString(item.hoseAlias), 1)
                      ]),
                      _createElementVNode("div", _hoisted_36, [
                        _createVNode(_component_a_row, { type: "flex" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: _ctx.$t('costCenter.unusedGpuAmount')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_37, _toDisplayString(item.unusedGpuAmount) + "卡", 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: _ctx.$t('costCenter.platformArchitecture')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_38, _toDisplayString(item.platformArchitecture), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_row, { type: "flex" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: _ctx.$t('costCenter.cpuModel')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_39, _toDisplayString(item.cpuModel), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: _ctx.$t('costCenter.cpuCore')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_40, _toDisplayString(item.cpuCoreNum), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_row, { type: "flex" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: _ctx.$t('costCenter.memory')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_41, _toDisplayString(item.memory) + "GB", 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: _ctx.$t('costCenter.videoStorage')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_42, _toDisplayString(item.videoStorage) + "GB", 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_row, { type: "flex" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: _ctx.$t('costCenter.hardDisk')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_43, _toDisplayString(item.hardDisk) + "GB", 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: _ctx.$t('costCenter.cacheDiskMaxLimit')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_44, _toDisplayString(item.cacheDiskMaxLimit) + "GB", 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_row, { type: "flex" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: _ctx.$t('costCenter.cudaMaxVersion')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_45, _toDisplayString(item.toolkitVersion), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("div", _hoisted_47, [
                        (item.isObjectStorage == 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                              _createElementVNode("img", {
                                src: _ctx.isNetDiskImg,
                                alt: "",
                                style: {"width":"200px"}
                              }, null, 8, _hoisted_49)
                            ]))
                          : _createCommentVNode("", true),
                        (item.isObjectStorage == 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                              _createElementVNode("img", {
                                src: _ctx.isObjectImg,
                                alt: "",
                                style: {"width":"200px"}
                              }, null, 8, _hoisted_51)
                            ]))
                          : _createCommentVNode("", true),
                        (item.isObjectStorage == 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                              _createElementVNode("img", {
                                src: _ctx.isAllImg,
                                alt: "",
                                style: {"width":"200px"}
                              }, null, 8, _hoisted_53)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_54, [
                        _createElementVNode("span", _hoisted_55, [
                          _createTextVNode(" ￥ " + _toDisplayString(_ctx.calculatePrice(item.discountHour, item.priceHour)) + " ", 1),
                          _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.$t("costCenter.priceUnit")), 1)
                        ]),
                        _createElementVNode("span", _hoisted_57, " ￥" + _toDisplayString(item.priceHour) + _toDisplayString(_ctx.$t("costCenter.priceUnit")), 1),
                        (item.discountHour !== 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_58, _toDisplayString(item.discountHour * 10) + _toDisplayString(_ctx.$t("costCenter.discount")), 1))
                          : _createCommentVNode("", true)
                      ]),
                      (item.discountHour !== 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                            _createVNode(_component_svg_icon, {
                              iconClass: "ic_zhekou",
                              style: {"width":"1rem","height":"1rem"}
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("costCenter.discountTips")) + _toDisplayString(item.discountHour * 10) + _toDisplayString(_ctx.$t("costCenter.discount")) + " ￥" + _toDisplayString(_ctx.calculatePrice(item.discountHour, item.priceHour)) + _toDisplayString(_ctx.$t("costCenter.priceUnit")), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (item.unusedGpuAmount > 0)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            class: "quote-btn",
                            onClick: ($event: any) => (_ctx.handleDetail(item))
                          }, _toDisplayString(item.unusedGpuAmount) + _toDisplayString(_ctx.$t("costCenter.rentCards")), 9, _hoisted_60))
                        : (_openBlock(), _createBlock(_component_a_button, {
                            key: 2,
                            disabled: "",
                            class: "quote-disable-btn"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("costCenter.AllRent")), 1)
                            ]),
                            _: 1
                          }))
                    ])
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}