import { reactive, ref, computed, onMounted } from 'vue'
import { FindPasswordSpace } from '@/interface/findPasswordface'
import { RegisterInterface } from '@/interface/registerInterface'
import { encrtpt } from '@/utils/jsencrypt'
import { useRouter } from 'vue-router'
import {
  PhoneValidator
} from '@/interface/validator'
import { Rule } from 'ant-design-vue/es/form'
import { sendPhoneCodeByparamsApi, updateUserPasswordApi } from '@/api/register'
import { message } from 'ant-design-vue'
import { useCancelAjax } from '@/utils/cancel-ajax'
import { usePasswordVerifiers } from '@/utils/passwordValidator'
import { queryImgCodeApi } from '@/api/login'
import './findPassword.scss'

export const useFindPassword = () => {
  const router = useRouter()

  const {
    checkPassword,
    queryPasswordRule
  } = usePasswordVerifiers()

  const {
    cancelFnList,
    createCancelList
  } = useCancelAjax()

  const findPassword: FindPasswordSpace.FindPasswordFace = reactive({
    phone: '',
    password: '',
    codeNum: '',
    code: ''
  })

  const imgCodeInfo: RegisterInterface.imgCodeInfoFace = reactive({
    codeId: '',
    codeNum: ''
  })

  const queryCodeInfo = (): void => {
    queryImgCodeApi().then(res => {
      imgCodeInfo.codeId = res.data.codeId
      imgCodeInfo.codeNum = res.data.codeNum
    })
  }

  const handleRefreshImgCode = () => {
    queryCodeInfo()
  }

  const second = ref<number>(60)

  const countDown = ():void => {
    const timer = setInterval(() => {
      if (second.value === 0) {
        second.value = 60
        clearInterval(timer)
      } else {
        second.value = second.value - 1
      }
    }, 1000)
  }

  const disabled = computed<boolean>(() => {
    return !(findPassword.phone && findPassword.codeNum)
  })

  onMounted(() => {
    queryPasswordRule()
  })

  const validatorPhone = async (_rule:Rule, value: string): Promise<any> => {
    if (!value) {
      return Promise.reject(new Error('请输入手机号'))
    } else {
      const phoneValidator = new PhoneValidator()
      if (phoneValidator.isAcceptable(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error('手机号格式错误'))
      }
    }
  }

  const handleSendCode = () => {
    if (cancelFnList.value.length !== 0) {
      cancelFnList.value.forEach((cancelFn: any, index: number) => {
        cancelFn()
        delete cancelFnList.value[index]
      })
    }
    const params = {
      codeNum: findPassword.codeNum as string,
      codeId: imgCodeInfo.codeId as string,
      phone: findPassword.phone
    }
    sendPhoneCodeByparamsApi(params, createCancelList).then(() => {
      countDown()
    }).catch(() => {
      queryCodeInfo()
    })
  }

  const handleRegister = ():void => {
    const params = {
      phone: findPassword.phone,
      password: encrtpt(findPassword.password) as string,
      code: findPassword.code
    }
    updateUserPasswordApi(params).then(() => {
      message.success('密码修改成功', 0.5).then(() => {
        router.push('/login')
      })
    })
  }

  return {
    findPassword,
    second,
    countDown,
    queryCodeInfo,
    imgCodeInfo,
    handleRegister,
    handleSendCode,
    disabled,
    handleRefreshImgCode,
    checkPassword,
    validatorPhone
  }
}
