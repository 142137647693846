
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { searchForm, dataSource, total, selectedIds, selectRowData, tableHeight, spinning, state } from './rechargeRecord'
import { getRechargeList, exportRecharge } from '@/api/recharge'
import { SearchFormParamsFace } from '@/interface/rechargeInterface'
import { useI18n } from 'vue-i18n'
import fileDownload from 'js-file-download'
import ConfigColumns from '@/components/config-columns/ConfigColumns.vue'// 筛选表格列数据组件
import StAlert from '@/components/st-alert/StAlert.vue'// 简介栏
import StButton from '@/components/st-button/StButton.vue'
import DynamicSearch from '@/components/dynamic-search/DynamicSearch.vue'// 搜索表单组件
export default defineComponent({
  components: {
    ConfigColumns,
    StAlert,
    StButton,
    DynamicSearch
  },
  setup () {
    const { t } = useI18n()
    onMounted(() => {
      getList()
    })
    const getList = () => {
      getRechargeList(searchForm as SearchFormParamsFace).then((res) => {
        dataSource.value = res.data.rows
        total.value = res.data.total
      })
    }
    const handleExportExcel = () => {
      spinning.value = true
      var arr: any = []
      selectRowData.value.forEach((v: any) => {
        arr.push(v.id)
      })
      exportRecharge(arr).then((res: any) => {
        fileDownload(res.data, '充值记录' + '.xlsx')
        spinning.value = false
      })
    }
    const handleSearch = () => {
      searchForm.pageReq.pageNum = 1
      getList()
    }
    function handleResizeColumn (w: any, col: any) {
      col.width = w
    }
    const handlePageSize = (current: number, size: number) => {
      searchForm.pageReq.pageNum = current
      searchForm.pageReq.pageSize = size
      getList()
    }
    function onSelectChange (selectedRowKeys: any, selectedRows: any) {
      selectedIds.value = selectedRowKeys
      selectRowData.value = selectedRows
    }

    // 自定义筛选表格列数据
    const filtered: any = reactive({})
    const columns = ref([
      {
        title: t('costCenter.outTradeNo'),
        dataIndex: 'outTradeNo',
        key: 'outTradeNo',
        ellipsis: true,
        isShow: true,
        disabled: true
      },
      {
        title: t('costCenter.payPrice'),
        dataIndex: 'payPrice',
        key: 'payPrice',
        ellipsis: true,
        resizable: true,
        width: 100,
        isShow: true
      },
      {
        title: t('costCenter.payType'),
        dataIndex: 'payType',
        key: 'payType',
        ellipsis: true,
        resizable: true,
        width: 100,
        isShow: true,
        filteredValue: filtered.payTypeList || null,
        filters: [],
        state: {
          indeterminate: true,
          checkAll: false,
          checkedList: []
        },
        customFilterDropdown: true,
        filterDropdownOpen: false,
        filterMultiple: true,
        searchIndex: 'payTypeList'
      },
      {
        title: t('costCenter.payStatus'),
        dataIndex: 'payStatus',
        key: 'payStatus',
        ellipsis: true,
        resizable: true,
        align: 'center',
        width: 100,
        isShow: true,
        filteredValue: filtered.payStatusList || null,
        filters: [],
        state: {
          indeterminate: true,
          checkAll: false,
          checkedList: []
        },
        customFilterDropdown: true,
        filterDropdownOpen: false,
        filterMultiple: true,
        searchIndex: 'payStatusList'
      },
      {
        title: t('costCenter.phone'),
        dataIndex: 'phone',
        key: 'phone',
        ellipsis: true,
        resizable: true,
        width: 150,
        isShow: true
      },
      {
        title: t('costCenter.transactionId'),
        dataIndex: 'transactionId',
        key: 'transactionId',
        ellipsis: true,
        isShow: true
      },
      {
        title: t('costCenter.createTime'),
        dataIndex: 'createTime',
        key: 'createTime',
        ellipsis: true,
        resizable: true,
        width: 150,
        isShow: true
      },
      {
        title: t('costCenter.successTime'),
        dataIndex: 'successTime',
        key: 'successTime',
        ellipsis: true,
        resizable: true,
        width: 150,
        isShow: true,
        disabled: true
      }
    ])
    const isAllHidden = ref(false)
    const filterColumns: any = ref([])
    const filtersKeywords: any = ref([])
    const handleChangeAll = (params: any) => {
      columns.value.forEach((item: any) => {
        if (!item.disabled) {
          item.isShow = params
        }
      })
    }
    const handleSetColumns = () => {
      filterColumns.value = []
      const allDispear = columns.value.filter((item: any) => item.isShow)
      if (allDispear.length > 0) {
        isAllHidden.value = false
        columns.value.forEach((item: any) => {
          if (item.isShow) {
            filterColumns.value.push(item)
          }
        })
      } else {
        isAllHidden.value = true
        filterColumns.value = []
      }
    }
    // 过滤菜单组件表单参数。搜索表单searchForm需将所有可查询参数写好。数组类型默认值为null，避免ant多选菜单出现空白tag问题
    const dynamicForm = ref([
      {
        label: t('costCenter.outTradeNo'),
        name: 'outTradeNo',
        type: 'input',
        span: 6,
        isShow: true
      },
      {
        label: t('costCenter.payType'),
        name: 'payTypeList',
        type: 'select',
        span: 6,
        optionType: 'Enum',
        optionParams: 'CostPayTypeEnum',
        options: [],
        optionKey: 'value',
        optionLabel: 'name',
        isShow: true,
        mode: 'multiple'
      },
      {
        label: t('costCenter.payStatus'),
        name: 'payStatusList',
        type: 'select',
        span: 6,
        optionType: 'Enum',
        optionParams: 'CostPayStatusEnum',
        options: [],
        optionKey: 'value',
        optionLabel: 'name',
        isShow: true,
        mode: 'multiple'
      },
      {
        label: t('costCenter.createTime'),
        name: 'createTime',
        type: 'dateTime',
        span: 6,
        isShow: true,
        showTime: false
      }
    ])
    const handleFilterOptions = () => {
      dynamicForm.value.map((v: any) => {
        columns.value.map((s: any) => {
          if (v.type === 'select' && v.name === s.searchIndex) {
            s.filters = []
            v.options.map((item: any) => {
              const obj = {
                label: item[v.optionLabel],
                value: item[v.optionKey]
              }
              s.filters.push(obj)
            })
            s.optionLabel = v.optionLabel
            s.optionKey = v.optionKey
          }
        })
      })
    }
    const handleFilterSingleChange = (e: any, column: any) => {
      (searchForm as any)[column.searchIndex] = e.target.value
      searchForm.pageReq.pageNum = 1
      filtersKeywords.value = searchForm
      handleSearch()
    }
    const onCheckAllChangeFilter = (e: any, column: any) => {
      var arr: any[] = []
      column.filters.map((v: any) => {
        arr.push(v.value)
      })
      Object.assign(column.state, {
        checkedList: e.target.checked ? arr : [],
        indeterminate: false
      })
      columns.value.map((v: any) => {
        if (v.searchIndex === column.searchIndex) {
          v.state = column.state
        }
      })
    }
    const handleFilterVisible = (column: any) => {
      columns.value.map((v: any) => {
        if (v.searchIndex === column.searchIndex && column.filterMultiple) {
          v.filterDropdownOpen = !v.filterDropdownOpen
        }
      })
      handleFilterOptions()
    }
    const handleCheckedFilterChange = (e: any) => {
      var checkedLen = e.length
      columns.value.map((item: any) => {
        if (item.state) {
          item.state.indeterminate = !!checkedLen && checkedLen < item.filters.length
          item.state.checkAll = checkedLen === item.filters.length
        }
      })
    }
    const handleFilterMultipleChange = (checkedList: any, column: any) => {
      (searchForm as any)[column.searchIndex] = checkedList
      searchForm.pageReq.pageNum = 1
      filtered[column.searchIndex] = checkedList
      filtersKeywords.value[column.searchIndex] = filtered[column.searchIndex]
      filtersKeywords.value = searchForm // 双向赋值父子组件联动
      handleCancelFilters(column)
    }
    const handleCancelFilters = (column: any) => {
      columns.value.map((v: any) => {
        if (v.searchIndex === column.searchIndex) {
          v.filterDropdownOpen = !v.filterDropdownOpen
        }
      })
      handleSearch()
    }
    const handleSearchFilters = (params: any) => {
      selectedIds.value = []
      selectRowData.value = ''
      if (Object.keys(params).length === 0) {
        handleClearFilterTag(params)
      } else {
        for (var i in searchForm) {
          if (i !== 'pageReq') {
            (searchForm as any)[i] = params[i]
            filtered[i] = params[i]
          }
        }
        filtersKeywords.value = searchForm
        searchForm.pageReq.pageNum = 1
        getList()
      }
    }
    const handleClearFilterTag = (params: any) => {
      selectedIds.value = []
      selectRowData.value = ''
      if (Object.keys(params).length === 0) {
        dynamicForm.value.map((s) => {
          for (var i in searchForm) {
            if (i !== 'pageReq' && s.name === i) {
              if (s.type === 'select' && s.mode === 'multiple') {
                (searchForm as any)[s.name] = []
                filtered[s.name] = []
              } else {
                (searchForm as any)[s.name] = null
                filtered[s.name] = null
              }
            }
            if (i === 'startTime' || i === 'endTime') {
              (searchForm as any)[i] = null
              filtered[i] = null
            }
          }
        })
      } else {
        // 清除主列表筛选表头多选表单勾选值
        columns.value.map((v: any) => {
          if (v.searchIndex === params.key && v.state) {
            if (v.state.checkedList.length > 0) {
              v.state.checkedList = []
              v.state.indeterminate = false
            }
          }
        })
        dynamicForm.value.map((s: any) => {
          for (const i in searchForm) {
            if (params.type === 'dateRange' && (i === 'startTime' || i === 'endTime')) {
              (searchForm as any)[i] = null
              filtered[i] = null
            } else if (i !== 'pageReq' && params.key === i && s.name === params.key) {
              if (s.type === 'select' && s.mode === 'multiple') {
                (searchForm as any)[s.name] = []
                filtered[s.name] = []
              } else {
                (searchForm as any)[s.name] = null
                filtered[s.name] = null
              }
            }
          }
        })
      }
      filtersKeywords.value = searchForm
      searchForm.pageReq.pageNum = 1
      getList()
    }
    return {
      handleResizeColumn,
      handlePageSize,
      onSelectChange,
      handleExportExcel,
      handleSearch,
      filterColumns,
      tableHeight,
      spinning,
      state,
      total,
      dataSource,
      searchForm,
      selectedIds,
      selectRowData,
      columns,
      handleChangeAll,
      handleSetColumns,
      isAllHidden,
      dynamicForm,
      filtered,
      handleFilterSingleChange,
      onCheckAllChangeFilter,
      handleCancelFilters,
      handleFilterVisible,
      handleFilterMultipleChange,
      handleClearFilterTag,
      handleCheckedFilterChange,
      handleSearchFilters,
      filtersKeywords
    }
  }
})
