import { WorkOrderSpace } from '@/interface/workOrder'
import http from '@/utils/http'

const queryWorkOrderApi = (data: any) => {
  return http({
    method: 'post',
    url: '/user/work/queryMyWorkOrder',
    data: data
  })
}

const addWorkOrderApi = (data: WorkOrderSpace.AddWorkOrderParamsFace) => {
  return http({
    method: 'post',
    url: '/user/work/workOrderApply',
    data: data
  })
}

const modifyWorkOrderApi = (data: WorkOrderSpace.AddWorkOrderParamsFace) => {
  return http({
    method: 'post',
    url: '/user/work/workOrderSet',
    data: data
  })
}

const revokeWorkOrderApi = (data: WorkOrderSpace.RevokeWorkOrderParamsFace) => {
  return http({
    method: 'post',
    url: '/user/work/workOrderRevoke',
    data: data
  })
}

const queryWorkOrderDetailApi = (data: string) => {
  return http({
    method: 'post',
    url: `/user/work/queryInfoWorkOrder/${data}`
  })
}

const replayWorkOrderApi = (data: { workExplain: string; workId: string; filePath: string }) => {
  return http({
    method: 'post',
    url: '/user/work/workOrderReply',
    data: data
  })
}

export {
  addWorkOrderApi,
  queryWorkOrderApi,
  revokeWorkOrderApi,
  queryWorkOrderDetailApi,
  modifyWorkOrderApi,
  replayWorkOrderApi
}
