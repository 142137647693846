import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52171839"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container enterprise-container" }
const _hoisted_2 = { class: "form-box" }
const _hoisted_3 = {
  key: 0,
  class: "upload-box"
}
const _hoisted_4 = { class: "upload" }
const _hoisted_5 = {
  key: 1,
  class: "img-box"
}
const _hoisted_6 = { class: "model-box" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { style: {"color":"#3273f9"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_form, {
        "label-col": {span: 4},
        "wrapper-col": {span: 9},
        model: _ctx.formInfo,
        ref: "form",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.compName'),
            name: "compName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formInfo.compName,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInfo.compName) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.creditCode'),
            name: "creditCode"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formInfo.creditCode,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formInfo.creditCode) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.license')
          }, {
            default: _withCtx(() => [
              (!_ctx.formInfo.businessPicture)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_a_upload, {
                      "custom-request": _ctx.handleUpload,
                      "before-upload": _ctx.beforeUpload,
                      "file-list": _ctx.fileList
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_svg_icon, { "icon-class": "ic_jia" }),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('userCenter.license')), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["custom-request", "before-upload", "file-list"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_delete_outlined, {
                        onClick: _cache[2] || (_cache[2] = () => _ctx.formInfo.businessPicture = '')
                      })
                    ]),
                    _createElementVNode("img", {
                      src: '/api/user' + _ctx.formInfo.businessPicture
                    }, null, 8, _hoisted_7)
                  ]))
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, { "wrapper-col": {span: 9, offset: 4} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_checkbox, {
                checked: _ctx.isChecked,
                "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isChecked) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('userCenter.companyAgree')), 1)
                ]),
                _: 1
              }, 8, ["checked"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { "wrapper-col": {span: 9, offset: 4} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _ctx.handleSubmit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('userCenter.submitapplication')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ])
  ]))
}