
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { FormInstance, message } from 'ant-design-vue'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import { toFixedNum } from '@/utils/toFixedNum'
import { useCancelAjax } from '@/mixins/cancel-ajax'

export default defineComponent({
  emits: ['update:visible', 'update:state', 'expansionDiskFun'],
  props: {
    visible: {
      type: Boolean,
      required: true,
      defalut: false
    },
    mode: {
      type: String,
      required: true,
      defalut: 'cache'
    },
    discountMonth: {
      type: Number,
      required: true,
      defalut: 0
    },
    priceMonth: {
      type: Number,
      required: true,
      defalut: 0
    },
    flag: {
      type: Boolean
    },
    dataSize: {
      type: Number,
      defalut: 0
    },
    defaultDataSize: {
      type: Number,
      defalut: 0
    },
    dilatationDataSize: {
      type: Number,
      default: 0
    },
    bucketId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean
    },
    balance: {
      type: Number
    }
  },
  setup (props, { emit }) {
    const expansionFormRef = ref<FormInstance>()
    const expansionForm: { size: number; month: number} = reactive({
      size: 0,
      month: 1
    })

    const { cancelFnList, createCancelList } = useCancelAjax()

    watch(() => props.dilatationDataSize, () => {
      expansionForm.size = props.dilatationDataSize
    })

    const expirationTime = computed(() => {
      return dayjs().add(expansionForm.month * 30, 'day').format('YYYY-MM-DD HH:mm:ss')
    })

    const computedDiscountPrice = computed(() => {
      return toFixedNum(new BigNumber(props.discountMonth).multipliedBy(new BigNumber(props.priceMonth)).toNumber(), 2)
    })

    const computedDiscount = computed(() => {
      return props.discountMonth === 1 ? null : toFixedNum(new BigNumber(props.discountMonth).multipliedBy(new BigNumber(10)).toNumber(), 2)
    })

    const handleConfirm = () => {
      if (expansionForm.size.toString().includes('.')) {
        message.error('扩容大小只能为整数')
        return false
      } else if (expansionForm.size === 0) {
        message.error('扩容大小不能为0')
        return false
      } else {
        if (expansionFormRef.value) {
          expansionFormRef.value.validate().then(() => {
            if (cancelFnList.value.length !== 0) {
              cancelFnList.value.forEach((cancelFn, index) => {
                cancelFn()
                delete cancelFnList.value[index]
              })
            }
            if (props.mode === 'cache') {
              emit('expansionDiskFun', {
                params: {
                  bucketId: props.bucketId,
                  size: expansionForm.size,
                  money: totalPrice.value
                },
                cancelToken: {
                  createCancelList: createCancelList
                }
              })
            } else {
              emit('expansionDiskFun', {
                params: {
                  bucketId: props.bucketId,
                  size: expansionForm.size,
                  money: totalPrice.value,
                  month: expansionForm.month
                },
                cancelToken: {
                  createCancelList: createCancelList
                }
              })
            }
          })
        }
      }
    }

    const totalPrice = computed(() => {
      if (expansionForm.size === null) {
        return 0
      } else {
        if (props.mode === 'cache') {
          const discountMonth = new BigNumber(props.discountMonth)
          const priceMonth = new BigNumber(props.priceMonth)
          const size = new BigNumber(expansionForm.size)
          const price = toFixedNum(discountMonth.multipliedBy(priceMonth).multipliedBy(size).toNumber(), 2)
          if (props.balance) {
            if (price > props.balance) {
              emit('update:state', true)
            } else {
              emit('update:state', false)
            }
          }
          return price
        } else {
          const discountMonth = new BigNumber(props.discountMonth)
          const priceMonth = new BigNumber(props.priceMonth)
          const size = new BigNumber(expansionForm.size)
          const month = new BigNumber(expansionForm.month)
          const price = toFixedNum(discountMonth.multipliedBy(priceMonth).multipliedBy(size).multipliedBy(month).toNumber(), 2)
          if (props.balance) {
            if (price > props.balance) {
              emit('update:state', true)
            } else {
              emit('update:state', false)
            }
          }
          return price
        }
      }
    })

    const handleCanel = () => {
      emit('update:visible', false)
    }

    watch(() => props.visible, () => {
      if (props.visible) {
        expansionForm.size = 0
      }
    })

    return {
      handleCanel,
      expansionForm,
      handleConfirm,
      expansionFormRef,
      totalPrice,
      expirationTime,
      computedDiscountPrice,
      computedDiscount
    }
  }
})
