import { computed, onMounted, reactive, ref, watch } from 'vue'
import { WorkOrderSpace } from '@/interface/workOrder'
import type { FormInstance, UploadProps } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import { uploadPictureApi } from '@/api/upload'
import {
  addWorkOrderApi,
  modifyWorkOrderApi,
  queryWorkOrderApi,
  queryWorkOrderDetailApi,
  revokeWorkOrderApi
} from '@/api/work-order'
import { TableColumnTypeConfig } from '@/interface/table'
import { StationMessageSpace } from '@/interface/stationMessageface'
import { DynamicConditionSpace } from '@/interface/condition'
import { useRouter, useRoute } from 'vue-router'
import { useCondition } from '@/utils/condition-operate'
import { useI18n } from 'vue-i18n'

export const useWorkOrder = () => {
  const { t } = useI18n()
  const {
    initConditionList,
    initColumnsFiltered,
    clearFilterOptionsList,
    setColumnsFiltered,
    setFilerOptions,
    inputOrselectChange
  } = useCondition()

  const router = useRouter()
  const route = useRoute()
  const pageParams = reactive({
    pageNum: 1,
    pageSize: 10
  })

  const currentCondition = ref<string>('workCode')

  const filterOptions = ref<DynamicConditionSpace.ConditionFace[]>([])

  const conditionList = ref<DynamicConditionSpace.ConditionFace[]>([
    {
      name: t('workOrder.number'),
      key: 'workCode',
      type: 'AInput',
      placeholder: t('workOrder.pleaseEnter'),
      value: ''
    },
    {
      name: t('workOrder.name'),
      key: 'workName',
      type: 'AInput',
      placeholder: t('workOrder.pleaseEnter'),
      value: ''
    },
    {
      name: t('workOrder.level'),
      key: 'workGrade',
      type: 'ASelect',
      placeholder: t('workOrder.pleaseSelect'),
      value: undefined,
      label: '',
      multiple: false,
      filtered: true,
      options: [
        {
          value: 0,
          label: t('workOrder.normal')
        },
        {
          value: 1,
          label: t('workOrder.danger')
        }
      ]
    },
    {
      name: t('workOrder.type'),
      key: 'workType',
      type: 'ASelect',
      placeholder: t('workOrder.pleaseSelect'),
      value: undefined,
      label: '',
      multiple: false,
      filtered: true,
      options: [
        {
          value: 0,
          label: t('workOrder.task')
        },
        {
          value: 1,
          label: t('workOrder.storage')
        },
        {
          value: 2,
          label: t('workOrder.image')
        },
        {
          value: 3,
          label: t('workOrder.charging')
        },
        {
          value: 4,
          label: t('workOrder.user')
        }
      ]
    }
  ])

  const handleChangeCondition = (condition: string) => {
    currentCondition.value = condition
    const list = initConditionList(conditionList, condition)
    conditionList.value = list
  }

  const handleSelectChange = (params: DynamicConditionSpace.ConditionFace) => {
    const arr = inputOrselectChange(conditionList, params)
    conditionList.value = arr
  }

  const handleQuery = () => {
    const selectConditionParams = conditionList.value.filter(item => item.key === currentCondition.value)[0]
    if (selectConditionParams.value === '' || selectConditionParams.value === undefined || (selectConditionParams.value as []).length === 0) {
      if (filterOptions.value.length !== 0) {
        const index = filterOptions.value.findIndex(item => item.key === selectConditionParams.key)
        filterOptions.value.splice(index, 1)
        clearFilterOptionsList(index)
      }
      queryWorkOrder()
    } else {
      columns.value = setColumnsFiltered(columns, { key: selectConditionParams.key, value: selectConditionParams.value as number, label: selectConditionParams.label as string })
      filterOptions.value = [...setFilerOptions(filterOptions, selectConditionParams)]
      queryWorkOrder()
    }
  }

  const handleClear = () => {
    filterOptions.value = []
    const arr = initConditionList(conditionList)
    conditionList.value = arr
    const columnsList = initColumnsFiltered(columns)
    columns.value = columnsList
    queryWorkOrder()
  }

  const handleDelOption = (params: {index: number, key: string, filtered: boolean | undefined}) => {
    filterOptions.value.splice(params.index, 1)
    clearFilterOptionsList(params.index)

    const arr = initConditionList(conditionList, params.key)
    conditionList.value = arr

    const columnsArr = [...initColumnsFiltered(
      columns,
      {
        key: params.key,
        filtered: params.filtered
      }
    )]
    columns.value = columnsArr

    queryWorkOrder()
  }

  const conditionParams = computed(() => {
    const map = new Map()
    const searchForm: any = {
      ...pageParams
    }
    conditionList.value.forEach(item => {
      map.set(item.key, item.value)
    })
    for (const [k, v] of map) {
      searchForm[k] = v
    }
    return searchForm
  })

  const handleChangeStatus = (column: any) => {
    column.filtered = true
  }

  const handleconfirmFilter = (params: any, clearFilters: any) => {
    filterOptions.value = [...setFilerOptions(filterOptions, { ...params, filtered: true, type: 'ASelect' })]
    conditionList.value.forEach(item => {
      if (item.key === params.dataIndex) {
        const value = params.radioValue.split(',')[0]
        item.value = parseInt(value)
      }
    })
    queryWorkOrder()
    clearFilters({
      closeDropdown: true
    })
  }

  const columns = ref<TableColumnTypeConfig[]>([
    {
      title: t('workOrder.number'),
      dataIndex: 'workCode',
      ellipsis: true,
      isShow: true,
      disabled: true
    },
    {
      title: t('workOrder.name'),
      dataIndex: 'workName',
      ellipsis: true,
      isShow: true,
      disabled: false
    },
    {
      title: t('workOrder.desc'),
      dataIndex: 'workExplain',
      ellipsis: true,
      isShow: true,
      disabled: false
    },
    {
      title: t('workOrder.level'),
      dataIndex: 'workGrade',
      isShow: true,
      align: 'center',
      disabled: false,
      customFilterDropdown: true,
      filtered: false,
      filterDropdownOpen: false,
      radioValue: undefined
    },
    {
      title: t('workOrder.type'),
      dataIndex: 'workType',
      ellipsis: true,
      isShow: true,
      align: 'center',
      disabled: false,
      customFilterDropdown: true,
      filtered: false,
      filterDropdownOpen: false,
      radioValue: undefined
    },
    {
      title: t('workOrder.sub'),
      dataIndex: 'subAccount',
      ellipsis: true,
      isShow: true,
      disabled: false
    },
    {
      title: t('workOrder.status'),
      dataIndex: 'status',
      align: 'center',
      isShow: true,
      disabled: false
    },
    {
      title: t('workOrder.order'),
      dataIndex: 'createTime',
      ellipsis: true,
      isShow: true,
      disabled: false
    },
    {
      title: t('workOrder.unread'),
      dataIndex: 'count',
      isShow: true,
      disabled: false
    },
    {
      title: t('workOrder.operate'),
      dataIndex: 'operate',
      isShow: true,
      disabled: true,
      align: 'center'
    }
  ])

  const dataSource = ref<any[]>([])

  const total = ref<number>(0)

  const queryWorkOrder = async () => {
    const res = await queryWorkOrderApi(conditionParams.value)
    dataSource.value = res.data.rows
    total.value = res.data.total
  }

  const hanleChangePage = (params: StationMessageSpace.PagingParamsFace) => {
    pageParams.pageNum = params.pageNum
    pageParams.pageSize = params.pageSize
    queryWorkOrder()
  }

  const handleLinkDetail = (id: string) => {
    router.push({
      path: '/user-center/work-order-management/work-order-detail',
      query: {
        id: id
      }
    })
  }

  const showDetail = computed<boolean>(() => {
    if (route.path === '/user-center/work-order-management/work-order-detail') {
      return true
    } else {
      return false
    }
  })

  const filterList = (params: TableColumnTypeConfig) => {
    const list = conditionList.value.filter(item => item.key === params.dataIndex)[0].options
    return list
  }

  onMounted(() => {
    if (!showDetail.value) {
      queryWorkOrder()
    }
  })

  const visible = ref<boolean>(false)
  const title = ref<string>('')

  const form = ref<FormInstance>()

  const addForm: WorkOrderSpace.AddWorkOrderParamsFace = reactive({
    workName: '',
    workType: '',
    workGrade: '',
    workExplain: '',
    filePath: ''
  })

  const handleAddWorkOrder = () => {
    title.value = t('add.add')
    visible.value = true
  }

  const handleModifyWorkOrder = (id: string) => {
    title.value = t('add.edit')
    visible.value = true
    workId.value = id
    queryWorkOrderDetailApi(id).then(res => {
      Object.assign(addForm, {
        workName: res.data.workName,
        workType: res.data.workType,
        workGrade: res.data.workGrade,
        workExplain: res.data.workExplain,
        filePath: res.data.filePath
      })
    })
  }

  const fileList = ref<UploadProps['fileList']>([])
  const allowUploadList = ['jpg', 'jpeg', 'png', 'gif']
  const customRequest = (params: any) => {
    const { file } = params
    const formData = new FormData()
    formData.append('file', file)
    uploadPictureApi(formData).then((res) => {
      addForm.filePath = res.data
      if (form.value) {
        form.value.validateFields(['filePath'])
      }
    })
  }

  const handleConfirm = () => {
    if (form.value) {
      form.value.validate().then(() => {
        if (title.value === '添加') {
          addWorkOrderApi(addForm).then(() => {
            message.success('添加成功', 0.5).then(() => {
              queryWorkOrder()
              visible.value = false
            })
          })
        } else {
          const params = {
            ...addForm,
            id: workId.value
          }
          modifyWorkOrderApi(params).then(() => {
            message.success('修改成功', 0.5).then(() => {
              queryWorkOrder()
              visible.value = false
            })
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }

  watch(visible, () => {
    if (!visible.value) {
      if (form.value) {
        form.value.resetFields()
      }
    }
  })

  const revokeVisible = ref<boolean>(false)
  const workId = ref<string>('')
  const formRe = ref<FormInstance>()
  const recallForm: WorkOrderSpace.RevokeWorkOrderParamsFace = reactive({
    revokeReason: ''
  })
  const handleRevoke = (id: string) => {
    revokeVisible.value = true
    workId.value = id
  }
  const handleConfirmReVoke = () => {
    if (formRe.value) {
      formRe.value.validate().then(() => {
        const params = {
          revokeReason: recallForm.revokeReason,
          workId: workId.value
        }
        revokeWorkOrderApi(params).then(() => {
          message.success(t('workOrder.recallSuccess'), 0.5).then(() => {
            queryWorkOrder()
            revokeVisible.value = false
          })
        })
      }).catch(err => {
        return err
      })
    }
  }

  watch(revokeVisible, () => {
    if (!revokeVisible.value) {
      if (formRe.value) {
        formRe.value.resetFields()
      }
    }
  })

  return {
    visible,
    handleAddWorkOrder,
    addForm,
    form,
    fileList,
    allowUploadList,
    customRequest,
    handleConfirm,
    columns,
    dataSource,
    total,
    hanleChangePage,
    handleLinkDetail,
    handleRevoke,
    revokeVisible,
    handleConfirmReVoke,
    recallForm,
    handleModifyWorkOrder,
    title,
    currentCondition,
    conditionList,
    handleChangeCondition,
    handleSelectChange,
    filterOptions,
    handleQuery,
    handleClear,
    handleDelOption,
    showDetail,
    filterList,
    handleChangeStatus,
    handleconfirmFilter,
    formRe
  }
}
