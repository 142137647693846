
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue'
import { useRouter } from 'vue-router'
import { addOrModify, getNewestInvoice } from '@/api/myInvoice'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  setup () {
    const { t } = useI18n()
    const router = useRouter()
    const invoiceInfo:any = reactive({
      bankAccount: '',
      companyAddress: '',
      bankName: '',
      companyPhone: '',
      taxpayerNumber: '',
      invoiceTitle: '',
      invoiceType: 1,
      invoiceMode: 1,
      id: '',
      consigneeAddress: '',
      consigneeEmail: '',
      consignee: '',
      consigneePhone: '',
      remark: ''
    })
    const invoiceRef:any = ref(null)
    const rules = {
      invoiceType: [
        {
          required: true,
          message: t('costCenter.rules.invoiceType'),
          trigger: 'blur'
        }
      ],
      invoiceTitle: [
        {
          required: true,
          validator: isValidInvoiceTitle,
          trigger: 'change'
        }
      ],
      taxpayerNumber: [
        {
          required: true,
          validator: isValidTaxpayerNumber,
          trigger: 'change'
        }
      ],
      bankName: [
        {
          required: true,
          message: t('costCenter.rules.bankName'),
          trigger: 'blur'
        }
      ],
      bankAccount: [
        {
          required: true,
          validator: isValidBankAccount,
          trigger: 'change'
        }
      ],
      companyAddress: [
        { required: true, trigger: 'blur', message: t('costCenter.rules.companyAddress') }
      ],
      companyPhone: [
        {
          required: true,
          validator: isValidTelephone,
          trigger: 'change'
        }
      ],
      consignee: [{ required: true, message: t('costCenter.rules.consignee') }],
      consigneePhone: [
        {
          required: true,
          validator: isValidPhone,
          trigger: 'change'
        }
      ],
      consigneeAddress: [{ required: true, message: t('costCenter.rules.consigneeAddress') }],
      consigneeEmail: [
        { required: true, message: t('costCenter.rules.consigneeEmail') },
        { type: 'email', message: t('costCenter.rules.consigneeEmailRule') }
      ]
    }
    // function isValidAllPhone (rules:any, value:any) {
    //   if (value === '' || value == null || value === undefined) {
    //     return Promise.reject(t('costCenter.rules.consigneePhone'))
    //   } else {
    //     const reg = /^\d{1,}$/
    //     if (!reg.test(value)) {
    //       return Promise.reject(t('costCenter.rules.consigneePhoneRule'))
    //     } else {
    //       return Promise.resolve()
    //     }
    //   }
    // }
    function isValidInvoiceTitle (rules:any, value:any) {
      if (value === '' || value == null || value === undefined) {
        return Promise.reject(t('costCenter.rules.invoiceTitle'))
      } else {
        const reg = /^[0-9a-zA-Z\u4e00-\u9fa5]{0,50}$/
        if (!reg.test(value)) {
          return Promise.reject(t('costCenter.rules.invoiceTitleRule'))
        } else {
          return Promise.resolve()
        }
      }
    }
    function isValidTaxpayerNumber (rules:any, value:any) {
      if (value === '' || value == null || value === undefined) {
        return Promise.reject(t('costCenter.rules.taxpayerNumber'))
      } else {
        const reg = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/
        if (!reg.test(value)) {
          return Promise.reject(t('costCenter.rules.taxpayerNumberRule'))
        } else {
          return Promise.resolve()
        }
      }
    }
    function isValidBankAccount (rules:any, value:any) {
      if (value === '' || value == null || value === undefined) {
        return Promise.reject(t('costCenter.rules.bankAccount'))
      } else {
        const reg = /^([1-9]{1})(\d{15}|\d{16}|\d{17}|\d{18}|\d{19}|\d{20}|\d{21}|\d{25})$/
        if (!reg.test(value)) {
          return Promise.reject(t('costCenter.rules.bankAccountRule'))
        } else {
          return Promise.resolve()
        }
      }
    }
    function isValidPhone (rules:any, value:any) {
      if (value === '' || value == null || value === undefined) {
        return Promise.reject(t('login.rules.phone'))
      } else {
        var phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/
        if (!phoneReg.test(value)) {
          return Promise.reject(t('login.rules.phoneRule'))
        } else {
          return Promise.resolve()
        }
      }
    }
    // 国际固定电话
    function isValidTelephone (rules:any, value:any) {
      if (value === '' || value == null || value === undefined) {
        return Promise.reject(t('costCenter.rules.companyPhone'))
      } else {
        var phoneReg = /^\d{3,4}[-]\d{7,8}$/
        if (!phoneReg.test(value)) {
          return Promise.reject(t('costCenter.rules.companyPhoneRule'))
        } else {
          return Promise.resolve()
        }
      }
    }
    const handleConfirm = () => {
      invoiceRef.value
        .validateFields()
        .then(() => {
          const request = {
            ...invoiceInfo
          }
          addOrModify(request).then(() => {
            router.push({
              path: 'myInvoice',
              name: 'MyInvoice'
            } as any)
          })
        })
        .catch((err:any) => {
          return err
        })
    }
    const queryInvoiceInfo = () => {
      getNewestInvoice().then((res) => {
        if (res !== null) {
          Object.keys(invoiceInfo).forEach((key:any) => {
            invoiceInfo[key] = res.data[key]
          })
        }
      })
    }
    const handleBack = () => {
      router.go(-1)
    }
    const handleChangeMode = () => {
      if (invoiceInfo.invoiceType === '2') {
        invoiceInfo.invoiceMode = 2
      }
    }
    onMounted(() => {
      queryInvoiceInfo()
    })

    return {
      ...toRefs(invoiceInfo),
      handleConfirm,
      invoiceRef,
      invoiceInfo,
      handleBack,
      handleChangeMode,
      rules
    }
  }
})
