
import { defineComponent } from 'vue'
import { operateDataSource, operateLogDialogVisible, operateColumns } from '../js/tableOperateLog'
export default defineComponent({
  setup () {
    return {
      operateLogDialogVisible,
      operateDataSource,
      operateColumns

    }
  }
})
