import { delMyImageApi } from '@/api/image-center'
import { Modal, message } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'

export const delModule = () => {
  const { t } = useI18n()
  const handleDel = (data: { id: string, share: number }, queryMyImage: () => void) => {
    if (data.share === 1) {
      message.warning(t('image.nodel'))
    } else {
      Modal.confirm({
        title: t('del.title'),
        content: t('del.isDel'),
        centered: true,
        onOk: () => {
          delMyImageApi(data.id).then(() => {
            message.success(t('del.delSuccess'), 0.4).then(() => {
              queryMyImage()
            })
          })
        }
      })
    }
  }
  return {
    handleDel
  }
}
