
import { defineComponent } from 'vue'
import { taskRenewalVisible, changeTaskBtnState, changeFeeForm, computedDiscountPirce, computedTotalPrice, changeFeeParams, computedOrderPrice, handleTaskRenewal } from '../js/tableFeeChange'
export default defineComponent({
  setup () {
    return {
      taskRenewalVisible,
      changeTaskBtnState,
      handleTaskRenewal,
      computedDiscountPirce,
      computedTotalPrice,
      changeFeeForm,
      changeFeeParams,
      computedOrderPrice
    }
  }

})
