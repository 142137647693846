import {
  queryNetDiskContentApi,
  delNetDiskContentApi,
  createDirApi,
  queryUsageProportionApi,
  queryNetDiskContentByObjApi,
  createObjApi,
  removeObjNameApi,
  expansionNetDiskApi
} from '@/api/net-disk'
import { DynamicConditionSpace } from '@/interface/condition'
import { QueryNetDiskContentFace, DownloadParamsFace } from '@/interface/netDisk'
import { useCondition } from '@/utils/condition-operate'
import { FormInstance, Modal, message } from 'ant-design-vue'
import { Rule } from 'ant-design-vue/es/form'
import { computed, ref, onMounted, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useExpansion } from '@/mixins/expansion'
import { downloadObjectFileApi, downloadShareFileApi } from '@/api/upload'
import { useCancelAjax } from '@/utils/cancel-ajax'
import { uploadFileModule } from './upload-file'
import fileDownload from 'js-file-download'
import { downloadStore } from '@/store/download-process'
import { useI18n } from 'vue-i18n'

const useNetDiskDetail = () => {
  const route = useRoute()
  const { t } = useI18n()

  const download = downloadStore()

  const bucketId = ref<string>(route.query.id as string)

  const {
    createCancelList,
    cancelFnList
  } = useCancelAjax()

  const {
    initConditionList,
    inputOrselectChange,
    initColumnsFiltered,
    setColumnsFiltered,
    setFilerOptions,
    clearFilterOptionsList
  } = useCondition()

  const currentCondition = ref<string>(route.query.type === '1' ? 'objName' : 'name')

  const conditionList = ref<DynamicConditionSpace.ConditionFace[]>([
    {
      name: t('netDisk.fileName'),
      key: route.query.type === '1' ? 'objName' : 'name',
      type: 'AInput',
      value: '',
      placeholder: t('workOrder.pleaseEnter')
    },
    {
      name: t('netDisk.fileType'),
      key: 'type',
      type: 'ASelect',
      value: undefined,
      placeholder: t('workOrder.pleaseSelect'),
      filtered: false,
      label: '',
      multiple: false,
      options: [
        {
          label: t('netDisk.folder'),
          value: 0
        },
        {
          label: t('netDisk.file'),
          value: 1
        }
      ]
    }
  ])

  const filterOptions = ref<DynamicConditionSpace.FilterConditionFace[]>([])

  const handleChangeCondition = (condition: string) => {
    currentCondition.value = condition
    conditionList.value = initConditionList(conditionList, condition)
  }

  const handleSelectChange = (params: DynamicConditionSpace.ConditionFace) => {
    conditionList.value = inputOrselectChange(conditionList, params)
  }

  const handleDelOption = (params: {index: number, key: string, filtered: boolean | undefined}) => {
    filterOptions.value.splice(params.index, 1)
    clearFilterOptionsList(params.index)
    const arr = initConditionList(conditionList, params.key)
    conditionList.value = arr

    const columnsArr = [...initColumnsFiltered(
      columns,
      {
        key: params.key,
        filtered: params.filtered
      }
    )]
    columns.value = columnsArr

    if (route.query.type === '1') {
      queryNetDiskContentObj()
    } else {
      queryNetDiskContent()
    }
  }

  const handleClear = () => {
    filterOptions.value = []
    const arr = initConditionList(conditionList)
    conditionList.value = arr

    const columnsList = initColumnsFiltered(columns)
    columns.value = columnsList

    if (route.query.type === '1') {
      queryNetDiskContentObj()
    } else {
      queryNetDiskContent()
    }
  }

  const handleQuery = () => {
    const selectConditionParams = conditionList.value.filter(item => item.key === currentCondition.value)[0]
    if (selectConditionParams.value === '' || selectConditionParams.value === undefined || (selectConditionParams.value as []).length === 0) {
      if (filterOptions.value.length !== 0) {
        const index = filterOptions.value.findIndex(item => item.key === selectConditionParams.key)
        filterOptions.value.splice(index, 1)
        clearFilterOptionsList(index)
      }
    } else {
      columns.value = setColumnsFiltered(columns, { key: selectConditionParams.key, value: selectConditionParams.value as number, label: selectConditionParams.label as string })
      filterOptions.value = [...setFilerOptions(filterOptions, selectConditionParams)]
    }
    if (route.query.type === '1') {
      queryNetDiskContentObj()
    } else {
      queryNetDiskContent()
    }
  }

  const pageParams = reactive({
    pageNum: 1,
    pageSize: 10
  })

  const path = ref<string>(route.query.path as string)
  const bucketDir = ref<string>('')

  const conditionParams = computed<QueryNetDiskContentFace>(() => {
    const map = new Map()
    const searchForm: any = {
      ...pageParams,
      bucketId: route.query.id ? route.query.id : localStorage.getItem('bucketId')
    }
    if (route.query.type === '1') {
      searchForm.bucketDir = bucketDir.value
    } else {
      searchForm.path = path.value
    }
    conditionList.value.forEach(item => {
      if (item.value === undefined) {
        map.set(item.key, '')
      } else {
        map.set(item.key, item.value)
      }
    })
    for (const [k, v] of map) {
      searchForm[k] = v
    }
    return searchForm
  })

  // 表格内容
  const dataSource = ref<any[]>([])
  const total = ref<number>(0)
  const columns = ref<any[]>([
    {
      title: t('netDisk.fileName'),
      dataIndex: 'fileName',
      ellipsis: true,
      resizable: true,
      width: 200,
      isShow: true,
      disabled: true
    },
    {
      title: t('netDisk.size'),
      dataIndex: 'fileSize',
      customRender: ({ text, record }: any) => {
        return record.isDir ? '' : `${text}`
      },
      isShow: true,
      disabled: false
    },
    {
      title: t('netDisk.operate'),
      align: 'center',
      dataIndex: 'operate',
      isShow: true,
      disabled: true
    }
  ])

  const queryNetDiskContent = async () => {
    const res = await queryNetDiskContentApi(conditionParams.value)
    total.value = res.data.total
    dataSource.value = res.data.rows
    path.value = res.data.path
    setScrollHeight()
  }

  const queryNetDiskContentObj = async () => {
    const res = await queryNetDiskContentByObjApi(conditionParams.value)
    total.value = res.data.total
    dataSource.value = res.data.rows
    path.value = res.data.path
    setScrollHeight()
  }

  const handleChangePage = (params: { pageNum: number; pageSize: number; }) => {
    pageParams.pageNum = params.pageNum
    pageParams.pageSize = params.pageSize
    handleQuery()
  }

  // 查询文件夹内容
  const handleQueryFolderInfo = (params: any) => {
    pageParams.pageNum = 1
    if (route.query.type === '2' || route.query.type === '4') {
      isTopDir.value = false
      path.value = path.value + '/' + params.fileName
      queryNetDiskContent()
    } else {
      isTopDirObj.value = false
      bucketDir.value = bucketDir.value + params.fileName
      queryNetDiskContentObj()
    }
  }

  // 返回上级目录
  const isTopDirObj = ref(true)
  const isTopDir = ref(true)
  const handleBackFolder = () => {
    if (route.query.type === '2' || route.query.type === '4') {
      path.value = path.value.substring(path.value.lastIndexOf('/'), 0)
      if (path.value === route.query.path) {
        isTopDir.value = true
      }
      queryNetDiskContent()
    } else {
      const lastIndex = bucketDir.value.lastIndexOf('/')
      const secondLastIndex = bucketDir.value.lastIndexOf('/', lastIndex - 1)
      bucketDir.value = bucketDir.value.substring(0, secondLastIndex + 1)
      if (bucketDir.value === '') {
        isTopDirObj.value = true
      }
      queryNetDiskContentObj()
    }
  }

  // 表格列设置
  const handleChangeAll = (params: boolean) => {
    columns.value.forEach(item => {
      if (!item.disabled) {
        item.isShow = params
      }
    })
  }
  const filterColumns = ref<any[]>([])
  const isAllHidden = ref<boolean>(false)
  const handleSetColumns = () => {
    filterColumns.value = []
    const allDispear = columns.value.filter(item => item.isShow)
    if (allDispear.length > 0) {
      isAllHidden.value = false
      columns.value.forEach(item => {
        if (item.isShow) {
          filterColumns.value.push(item)
        }
      })
    } else {
      isAllHidden.value = true
      filterColumns.value = []
    }
  }

  const scrollHeight = ref<number>(0)
  const setScrollHeight = () => {
    const height = document.querySelector('.table-box')?.clientHeight as number
    scrollHeight.value = height
  }

  const handleChoostTools = (params: { key: string; }) => {
    const [data, type] = params.key.split(';')
    if (type === 'del') {
      handleDel(JSON.parse(data))
    }
  }

  // 删除
  const handleDel = (params: any) => {
    Modal.confirm({
      title: '系统提示',
      content: '是否确认删除',
      centered: true,
      onOk: () => {
        if (route.query.type === '1') {
          removeObjNameApi({
            bucketId: route.query.id as string,
            fileName: bucketDir.value + params.fileName
          }).then(() => {
            message.success('删除成功', 0.5).then(() => {
              queryNetDiskContentObj()
            })
          })
        } else {
          const data = {
            bucketId: route.query.id as string,
            fileName: path.value + '/' + params.fileName
          }
          delNetDiskContentApi(data).then(() => {
            message.success('删除成功', 0.5).then(() => {
              queryNetDiskContent()
            })
          })
        }
      }
    })
  }

  // 添加文件夹
  const visible = ref<boolean>(false)

  const form = ref<FormInstance>()

  const addForm: { fileName: string } = reactive({
    fileName: ''
  })

  const handleAddFolder = () => {
    visible.value = true
  }

  const checkFolderName = async (_rule: Rule, value: string) => {
    if (value === '') {
      return Promise.reject(new Error(t('netDisk.fileNameErr')))
    } else {
      if (value.includes('/')) {
        return Promise.reject(new Error(t('netDisk.fileNameTypeErr')))
      } else {
        return Promise.resolve()
      }
    }
  }

  const handleConfirmFolder = () => {
    if (form.value) {
      form.value.validate().then(() => {
        if (route.query.type === '2' || route.query.type === '4') {
          createDirApi({
            bucketId: route.query.id as string,
            fileName: path.value + '/' + addForm.fileName
          }).then(() => {
            message.success('添加成功', 0.5).then(() => {
              visible.value = false
              queryNetDiskContent()
            })
          })
        } else {
          createObjApi({
            bucketId: route.query.id as string,
            bucketDir: bucketDir.value + addForm.fileName
          }).then(() => {
            message.success('添加成功', 0.5).then(() => {
              visible.value = false
              queryNetDiskContentObj()
            })
          })
        }
      }).catch(err => {
        return err
      })
    }
  }

  watch(visible, () => {
    if (!visible.value) {
      if (form.value) {
        form.value.resetFields()
      }
    }
  })

  // 查询存储桶
  const useSize = ref<string>('')
  const totalSize = ref<string>('')
  const proportion = ref<string>('')
  const queryUsageProportion = async () => {
    const res = await queryUsageProportionApi(route.query.id as string)
    useSize.value = res.data.bucketUse
    totalSize.value = res.data.bucketSize
    proportion.value = res.data.proportion
  }

  // 扩容
  const { queryBucketMoneyApi, expansionPrice } = useExpansion()
  const expansionVisible = ref<boolean>(false)
  const title = ref<string>('')
  const handleExpansion = () => {
    if (route.query.type === '2') {
      title.value = t('netDisk.sharedStorage')
    } else if (route.query.type === '4') {
      title.value = t('netDisk.distributedStorage')
    } else {
      title.value = t('netDisk.objectStorage')
    }
    expansionVisible.value = true
  }

  const handleConfirmExpansion = (data: any) => {
    const { params, cancelToken } = data
    expansionNetDiskApi(params, cancelToken.createCancelList).then(() => {
      message.success(t('netDisk.expandSuccess'), 0.5).then(() => {
        queryUsageProportion()
        expansionVisible.value = false
      })
    })
  }

  // 下载
  const downloadParams: DownloadParamsFace = reactive({
    fileName: '',
    downloading: false,
    range: 0,
    fileBlob: [],
    percentage: 0
  })

  const chunkDownLoad = async (params: any, chunkSize: number) => {
    download.setVisible()
    const sliceCount = Math.ceil(params.size / chunkSize)
    const objData = {
      bucketId: route.query.id as string,
      objName: bucketDir.value + params.fileName,
      index: 1,
      slicingSize: 0
    }

    const shareData = {
      bucketId: route.query.id as string,
      fileUrl: path.value + '/' + params.fileName,
      index: 1,
      slicingSize: 0
    }

    const slices: any[] = []
    for (let i = 0; i < sliceCount; i++) {
      if (i * chunkSize < params.size) {
        slices.push(i * chunkSize)
      }
    }
    slices.push(params.size)

    const sliceList: number[] = []
    slices.forEach((_, index) => {
      if (index !== slices.length - 1) {
        sliceList.push(slices[index + 1] - slices[index])
      }
    })

    for (let a = 0; a < sliceList.length; a++) {
      downloadParams.percentage = Math.floor(((a + 1) / slices.length) * 100)
      if (route.query.type === '1') {
        objData.index = a + 1
        objData.slicingSize = sliceList[a]
        const res = await downloadObjectFileApi(objData, createCancelList)
        const process = Math.floor(((a + 1) / sliceList.length) * 100)
        download.setProcess(process)
        downloadParams.fileBlob.push(res.data)
      } else {
        shareData.index = a + 1
        shareData.slicingSize = sliceList[a]
        const res = await downloadShareFileApi(shareData, createCancelList)
        const process = Math.floor(((a + 1) / sliceList.length) * 100)
        download.setProcess(process)
        downloadParams.fileBlob.push(res.data)
      }
    }

    const blob = new Blob(downloadParams.fileBlob)
    fileDownload(blob, params.fileName)

    setTimeout(() => {
      download.setVisible()
    }, 1000)
  }

  const handleDownload = async (params: any) => {
    downloadParams.fileBlob = []
    const chunkSize = 20 * 1024 * 1024
    if (cancelFnList.value.length > 0) {
      cancelFnList.value.forEach((fn: any, index: number) => {
        fn()
        delete cancelFnList.value[index]
      })
    }
    if (params.size < chunkSize) {
      if (route.query.type === '1') {
        // 对象存储
        const data = {
          bucketId: route.query.id as string,
          objName: bucketDir.value + params.fileName,
          index: 1,
          slicingSize: params.size
        }
        const res = await downloadObjectFileApi(data, createCancelList)
        downloadParams.fileBlob.push(res.data)
      } else {
        const data = {
          bucketId: route.query.id as string,
          fileUrl: path.value + '/' + params.fileName,
          index: 1,
          slicingSize: params.size
        }
        const res = await downloadShareFileApi(data, createCancelList)
        downloadParams.fileBlob.push(res.data)
      }
      const blob = new Blob(downloadParams.fileBlob)
      fileDownload(blob, params.fileName)
    } else {
      chunkDownLoad(params, chunkSize)
    }
  }

  // 上传
  const {
    handleUpload
  } = uploadFileModule(path, bucketDir, handleQuery)

  onMounted(() => {
    if (route.query.type === '2' || route.query.type === '4') {
      columns.value.splice(2, 0, {
        title: t('netDisk.updateTime'),
        dataIndex: 'dateTime',
        isShow: true,
        disabled: false
      })
      queryNetDiskContent()
    } else {
      columns.value.splice(2, 0, {
        title: t('netDisk.createTime'),
        dataIndex: 'createTime',
        isShow: true,
        disabled: false
      })
      queryNetDiskContentObj()
    }
    queryUsageProportion()
    queryBucketMoneyApi(route.query.id as string)
    localStorage.setItem('bucketId', route.query.id as string)
  })

  return {
    currentCondition,
    conditionList,
    filterOptions,
    handleChangeCondition,
    handleSelectChange,
    handleQuery,
    columns,
    dataSource,
    total,
    handleChangePage,
    handleChangeAll,
    isAllHidden,
    handleSetColumns,
    filterColumns,
    scrollHeight,
    handleChoostTools,
    handleDel,
    handleQueryFolderInfo,
    handleClear,
    handleDelOption,
    handleAddFolder,
    visible,
    addForm,
    form,
    handleConfirmFolder,
    checkFolderName,
    useSize,
    totalSize,
    proportion,
    handleExpansion,
    expansionPrice,
    title,
    expansionVisible,
    handleConfirmExpansion,
    bucketId,
    handleDownload,
    handleUpload,
    handleBackFolder,
    isTopDir,
    isTopDirObj
  }
}

export {
  useNetDiskDetail
}
