
import { defineComponent, PropType } from 'vue'
interface MenuInfoFace {
  path: string,
  hidden: boolean,
  children: MenuInfoFace[],
  meta: {
    icon: string
    menuTitle: string
  }
}

export default defineComponent({
  props: {
    menuInfo: {
      type: Object as PropType<MenuInfoFace>,
      default: () => ({})
    },
    collapsed: {
      type: Boolean
    }
  }
})
