import { shareMyImageApi } from '@/api/image-center'
import { message } from 'ant-design-vue'
import { FormInstance, Rule } from 'ant-design-vue/es/form'
import { ref, toRaw, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
interface ImageInfoFace {
  imageName: string;
  filePath: string;
}

const shareModule = (queryMyImage: () => void) => {
  const { t } = useI18n()
  const visible = ref<boolean>(false)
  const existenceUsers = ref<any[]>([])
  const imageId = ref<string>('')
  const title = ref<string>('')

  const imageInfo: ImageInfoFace = reactive({
    imageName: '',
    filePath: ''
  })

  const shareType = ref<number>(0)

  const shareFormRef = ref<FormInstance>()

  const shareColumns = [
    {
      title: t('image.columns.number'),
      dataIndex: 'order',
      width: 50
    },
    {
      title: t('image.columns.shareUserId'),
      dataIndex: 'userId'
    },
    {
      title: t('image.columns.permission'),
      dataIndex: 'flag',
      width: 180
    },
    {
      title: t('image.columns.remark'),
      dataIndex: 'remark'
    },
    {
      title: t('image.columns.operate'),
      dataIndex: 'operate',
      align: 'center',
      width: 80
    }
  ]

  const users = ref<any[]>([])

  const handleShare = (params: any, type: number) => {
    imageInfo.filePath = params.filePath
    imageInfo.imageName = params.imageName
    imageId.value = params.id
    visible.value = true
    shareType.value = type
    if (type === 0) {
      users.value = toRaw([...params.users])
      existenceUsers.value = params.users
      title.value = '共享'
    } else {
      users.value = toRaw([...params.userSubs])
      existenceUsers.value = params.userSubs
      title.value = '子用户共享'
    }
  }

  const handlePushUsers = () => {
    users.value.push({
      flag: 0,
      id: '',
      order: 0,
      userId: '',
      userName: '',
      remark: ''
    })
  }

  const handleDelUser = (index: number) => {
    users.value.splice(index, 1)
  }

  const checkUserId = async (_rule: Rule, value: string) => {
    if (value !== '') {
      const set = new Set()
      for (let i = 0; i < users.value.length; i++) {
        if (!set.has(users.value[i].userId) || users.value[i].userId !== value) {
          set.add(users.value[i].userId)
        } else {
          return Promise.reject(new Error('共享用户ID重复，请检查'))
        }
      }
      return Promise.resolve()
    }
  }

  const handleConfirmShare = () => {
    const userIds: any[] = []
    users.value.forEach(item => {
      userIds.push(item.userId)
    })
    const arr = existenceUsers.value.filter(item => userIds.includes(item.userId))
    const newAddArr = [...users.value, ...arr]
    const map = new Map()
    newAddArr.forEach(item => {
      if (!map.has(item.userId)) {
        map.set(item.userId, {
          flag: item.flag,
          userId: item.userId,
          id: item.id,
          remark: item.remark
        })
      }
    })
    if (shareFormRef.value) {
      shareFormRef.value.validate().then(() => {
        const params = {
          imageId: imageId.value,
          users: [...map.values()],
          shareType: shareType.value
        }
        shareMyImageApi(params).then(() => {
          message.success('共享成功', 0.5).then(() => {
            queryMyImage()
            visible.value = false
          })
        })
      }).catch((err) => {
        return err
      })
    }
  }

  return {
    shareFormRef,
    shareColumns,
    users,
    handleShare,
    handlePushUsers,
    handleDelUser,
    checkUserId,
    handleConfirmShare,
    visible,
    imageInfo,
    title
  }
}

export {
  shareModule
}
