import { ref, reactive, onMounted } from 'vue'
import { StationMessageSpace } from '@/interface/stationMessageface'
import { stationMessageApi, delStationMessageApi, markStationMessageApi, markAllStationMessageApi } from '@/api/stationMessage'
import { TableColumnTypeConfig } from '@/interface/table'
import { useI18n } from 'vue-i18n'
import { Modal, message } from 'ant-design-vue'

export const useStationMessage = () => {
  const { t } = useI18n()
  const searchForm: StationMessageSpace.PagingParamsFace = reactive({
    pageNum: 1,
    pageSize: 10
  })

  const dataSource = ref<any[]>([])
  const total = ref<number>(0)

  const columns = ref<TableColumnTypeConfig[]>([
    {
      title: t('stationMessage.title'),
      dataIndex: 'noticeTitle',
      isShow: true,
      disabled: true
    },
    {
      title: t('stationMessage.message'),
      dataIndex: 'noticeContent',
      isShow: true,
      disabled: false,
      ellipsis: true
    },
    {
      title: t('stationMessage.createTime'),
      dataIndex: 'createTime',
      isShow: true,
      disabled: false
    },
    {
      title: t('del.operate'),
      dataIndex: 'operate',
      width: 180,
      isShow: true,
      disabled: true,
      align: 'center'
    }
  ])

  const handleChangeAll = (params: boolean) => {
    columns.value.forEach(item => {
      if (!item.disabled) {
        item.isShow = params
      }
    })
  }

  const filterColumns = ref<any[]>([])
  const isAllHidden = ref(false)
  const handleSetColumns = () => {
    filterColumns.value = []
    const allDispear: any[] = []
    columns.value.forEach(item => {
      if (item.isShow) {
        allDispear.push(item)
      }
    })
    if (allDispear.length > 0) {
      isAllHidden.value = false
      filterColumns.value = allDispear
    } else {
      isAllHidden.value = true
      filterColumns.value = []
    }
  }

  const queryStationMsg = async () => {
    const res = await stationMessageApi(searchForm)
    dataSource.value = res.data.rows
    total.value = res.data.total
    setAntTableHeight()
  }

  const hanleChangePage = (params: StationMessageSpace.PagingParamsFace) => {
    searchForm.pageNum = params.pageNum
    searchForm.pageSize = params.pageSize
    queryStationMsg()
  }

  const scrollHeight = ref<number>(0)
  const setAntTableHeight = ():void => {
    const height = document.querySelector('.table-box')?.clientHeight
    scrollHeight.value = height as number
  }

  const handleDel = (id: string) => {
    Modal.confirm({
      title: t('del.title'),
      content: t('del.isDel'),
      centered: true,
      onOk: () => {
        delStationMessageApi(id).then(() => {
          message.success(t('del.delSuccess'), 0.5).then(() => {
            queryStationMsg()
          })
        })
      }
    })
  }

  const handleRead = (id: string) => {
    Modal.confirm({
      title: t('del.title'),
      content: t('del.isRead'),
      centered: true,
      onOk: () => {
        markStationMessageApi(id).then(() => {
          message.success(t('del.readSuccess'), 0.5).then(() => {
            queryStationMsg()
          })
        })
      }
    })
  }

  const handleReadAll = () => {
    Modal.confirm({
      title: t('del.title'),
      content: t('del.isRead'),
      centered: true,
      onOk: () => {
        markAllStationMessageApi().then(() => {
          message.success(t('del.readSuccess'), 0.5).then(() => {
            queryStationMsg()
          })
        })
      }
    })
  }

  const handleChoostTools = (menuItem: any) => {
    const [key, id] = menuItem.key.split(',')
    if (key === 'del') {
      handleDel(id)
    }
  }

  onMounted(() => {
    queryStationMsg()
  })

  return {
    dataSource,
    total,
    columns,
    hanleChangePage,
    scrollHeight,
    handleChangeAll,
    filterColumns,
    isAllHidden,
    handleSetColumns,
    handleDel,
    handleChoostTools,
    handleRead,
    handleReadAll
  }
}
