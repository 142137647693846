import { DynamicConditionSpace } from '@/interface/condition'
import { toRaw } from 'vue'

interface InitConditionListFace {
  (conditionList: any, condition?: string) : DynamicConditionSpace.ConditionFace[]
}
interface inputOrselectChangeFace {
  (conditionList: any, condition: DynamicConditionSpace.ConditionFace) : DynamicConditionSpace.ConditionFace[]
}

export const useCondition = () => {
  let filterOptionsList: any[] = []

  const initConditionList: InitConditionListFace = (conditionList, condition) => {
    const arr = [...toRaw(conditionList.value)]
    if (condition) {
      arr.forEach(item => {
        if (item.key === condition) {
          if (item.type === 'ASelect') {
            if (item.multiple) {
              item.value = []
            } else {
              item.value = undefined
            }
          } else {
            item.value = ''
          }
        }
      })
    } else {
      // 清除全部条件
      arr.forEach(item => {
        if (item.type === 'ASelect') {
          if (item.multiple) {
            item.value = []
          } else {
            item.value = undefined
          }
        } else {
          item.value = ''
        }
      })
      filterOptionsList = []
    }

    return arr
  }

  const inputOrselectChange: inputOrselectChangeFace = (conditionList, params) => {
    const arr = [...toRaw(conditionList.value)]
    arr.forEach(item => {
      if (item.key === params.type) {
        if (item.type === 'ASelect') {
          const a = item.options?.filter((c: { value: number }) => c.value === params.value)[0]
          item.label = a?.label
        }
        item.value = params.value
      }
    })
    return arr
  }

  const initColumnsFiltered = (columns: any, params?: {filtered: boolean | undefined; key: string}): any[] => {
    if (params) {
      if (params.filtered) {
        const arr = [...toRaw(columns.value)]
        arr.forEach(c => {
          if (c.dataIndex === params.key) {
            c.filtered = false
            c.radioValue = undefined
          }
        })
        return arr
      } else {
        return columns.value
      }
    } else {
      const arr = [...toRaw(columns.value)]
      arr.forEach(c => {
        c.filtered = false
        c.radioValue = undefined
      })
      return arr
    }
  }

  const setColumnsFiltered = (columns: any, params: { key: string; value: number; label: string }): any[] => {
    const arr = [...toRaw(columns.value)]
    arr.forEach(item => {
      if (item.dataIndex === params.key) {
        item.radioValue = params.value as number + ',' + params.label
        item.filtered = true
      }
    })
    return arr
  }

  const setFilerOptions = (filterOptions: any, params: { [propName: string]: any }): any[] => {
    if (filterOptions.value.length !== 0) {
      if (params.value === '') {
        filterOptionsList = []
      }
    }
    const option = {
      name: params.name ? params.name : params.title,
      type: params.type ? params.type : 'ASelect',
      key: params.key ? params.key : params.dataIndex,
      value: params.value,
      label: '',
      filtered: params.filtered
    }
    if (params.type === 'ASelect') {
      option.label = params.label ? params.label : params.radioValue.split(',')[1]
    }
    if (filterOptions.value.length > 0) {
      const index = filterOptions.value.findIndex((item: { key: string }) => item.key === (params.key ? params.key : params.dataIndex))
      if (index === -1) {
        filterOptionsList.push(option)
      } else {
        filterOptionsList.splice(index, 1, option)
      }
    } else {
      filterOptionsList.push(option)
    }
    return filterOptionsList
  }

  const clearFilterOptionsList = (index:number) => {
    filterOptionsList.splice(index, 1)
  }

  return {
    initConditionList,
    inputOrselectChange,
    initColumnsFiltered,
    setColumnsFiltered,
    setFilerOptions,
    clearFilterOptionsList
  }
}
