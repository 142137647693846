import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "s-tag-item" }
const _hoisted_2 = { class: "s-tag-item-svg" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "s-tag-item-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["s-tag", 's-tag-' + _ctx.props.color])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("i", _hoisted_3, [
          _renderSlot(_ctx.$slots, "icon")
        ])
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.props.label), 1)
    ])
  ], 2))
}