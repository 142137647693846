
import { defineComponent } from 'vue'
import { useName } from './name-certification'
import { DeleteOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    DeleteOutlined
  },
  setup () {
    const {
      formInfo,
      handleUploadFront,
      handleUploadEnd,
      fileListEnd,
      fileListFront,
      beforeUpload,
      checkIdCard,
      checkName,
      form,
      handleSubmit
    } = useName()

    return {
      formInfo,
      handleUploadFront,
      handleUploadEnd,
      fileListEnd,
      fileListFront,
      beforeUpload,
      checkIdCard,
      form,
      checkName,
      handleSubmit
    }
  }
})
