import { ref, reactive } from 'vue'
const spinning = ref(false)
const dialogVisible = ref(false)
const formRef:any = ref(null)
const form = reactive({
  claimAmount: '',
  ownerId: '',
  remark: '',
  orderId: ''
})
const selectedIds:any = ref([])
const selectRowData:any = ref([])
const searchForm = reactive({
  createTime: '',
  number: '',
  costTypeList: null,
  pageReq: {
    pageNum: 1,
    pageSize: 10
  },
  productType: 1,
  taskName: '',
  taskId: '',
  phone: '',
  statusList: null
})
const orderType = ref(1)
const total = ref(0)
const checkDetail = ref(true)
const tableHeight = ref(
  (window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight) - 430
)
window.addEventListener('resize', function () {
  tableHeight.value =
    (window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) - 430
})
function onSelectChange (selectedRowKeys:any, selectedRows:any) {
  selectedIds.value = selectedRowKeys
  selectRowData.value = selectedRows
}
function filterArrar (list:any, list1:any) {
  list.map((item:any) => {
    list1.forEach((value:any) => {
      if (item.key === value.key) {
        value.title = item.title
      }
    })
  })
  return list1
}

// 订单详情
const detailList:any = ref(null)
const taskVisible = ref(true)
const billSource = ref([])
const billState = reactive({
  orderId: '',
  pageReq: {
    pageNum: 1,
    pageSize: 10
  }
})
const billColumns = ref([
  {
    title: '流水号',
    dataIndex: 'billNumber',
    key: 'billNumber',
    scopedSlots: { customRender: 'billNumber' },
    ellipsis: true
  },
  {
    title: '计费周期',
    dataIndex: 'costStartTime',
    key: 'costStartTime',
    scopedSlots: { customRender: 'costStartTime' },
    ellipsis: true
  },
  {
    title: '使用时长',
    dataIndex: 'useTime',
    key: 'useTime',
    width: '100px'
  },
  {
    title: '应付金额',
    dataIndex: 'amountPayable',
    key: 'amountPayable',
    width: '100px'
  },
  {
    title: '优惠券抵扣',
    dataIndex: 'couponDerate',
    key: 'couponDerate',
    width: '100px'
  },
  {
    title: '实际支付',
    dataIndex: 'actualAmountPaid',
    key: 'actualAmountPaid',
    width: '100px'
  },
  {
    title: '交易时间',
    dataIndex: 'createTime',
    key: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    ellipsis: true
  }
])
const productList = ref({})
const sColums = ref([
  {
    title: '存储卷名称',
    dataIndex: 'taskName',
    key: 'taskName',
    width: '20%'
  },
  {
    title: '扩容大小',
    dataIndex: 'size',
    key: 'size'
  },
  {
    title: '产品单价',
    dataIndex: 'unitPrice',
    key: 'unitPrice'
  }
])
const mColums = ref([
  {
    title: '实例名称',
    dataIndex: 'taskName',
    key: 'taskName',
    width: '20%'
  },
  {
    title: '节点别名',
    dataIndex: 'hostName',
    key: 'hostName'
  },
  {
    title: 'CPU/核心数',
    dataIndex: 'cpuCoreNum',
    key: 'cpuCoreNum'
  },
  {
    title: '运算卡型号/数量',
    dataIndex: 'gpuAmount',
    key: 'gpuAmount'
  },
  {
    title: '内存',
    dataIndex: 'memory',
    key: 'memory'
  },
  {
    title: '系统盘',
    dataIndex: 'hardDisk',
    key: 'hardDisk'
  },
  {
    title: '网络',
    dataIndex: 'downlinkRate',
    key: 'downlinkRate'
  },
  {
    title: '产品单价',
    dataIndex: 'unitPrice',
    key: 'unitPrice'
  }
])
const columns = ref([
  {
    title: '实例名称',
    dataIndex: 'taskName',
    key: 'taskName',
    width: '20%'
  },
  {
    title: '节点别名',
    dataIndex: 'hostName',
    key: 'hostName'
  },
  {
    title: 'CPU/核心数',
    dataIndex: 'cpuCoreNum',
    key: 'cpuCoreNum'
  },
  {
    title: '运算卡型号/数量',
    dataIndex: 'gpuAmount',
    key: 'gpuAmount'
  },
  {
    title: '内存',
    dataIndex: 'memory',
    key: 'memory'
  },
  {
    title: '系统盘',
    dataIndex: 'hardDisk',
    key: 'hardDisk'
  },
  {
    title: '网络',
    dataIndex: 'downlinkRate',
    key: 'downlinkRate'
  },
  {
    title: '产品单价',
    dataIndex: 'unitPrice',
    key: 'unitPrice'
  }
])
const nColumns = ref([
  {
    title: '免费网络带宽',
    dataIndex: 'downlinkRate',
    key: 'downlinkRate'
  },
  {
    title: '扩容网络带宽',
    dataIndex: 'dilatationBandwidth',
    key: 'dilatationBandwidth'
  },
  {
    title: '产品单价',
    dataIndex: 'unitPrice',
    key: 'unitPrice'
  }
])
export {
  spinning,
  dialogVisible,
  formRef,
  form,
  orderType,
  total,
  checkDetail,
  searchForm,
  filterArrar,
  tableHeight,
  onSelectChange,
  selectedIds,
  selectRowData,
  // 订单详情
  detailList,
  taskVisible,
  billColumns,
  billSource,
  billState,
  productList,
  sColums,
  mColums,
  columns,
  nColumns
}
