import { ref, reactive, watch } from 'vue'
const spinning = ref(false)
const selectRowData:any = ref([])
const selectedIds = ref([])
const total = ref(0)
const dataSource = ref([
])
const state = reactive({
  indeterminate: true,
  checkAll: false,
  checkedList: []
})
const plainOptions = ref([])
const searchForm = reactive({
  createTime: '',
  outTradeNo: '',
  pageReq: {
    pageNum: 1,
    pageSize: 10
  },
  payStatusList: null,
  payTypeList: null,
  phone: ''
})
const tableHeight = ref(
  (window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight) - 400
)
window.addEventListener('resize', function () {
  tableHeight.value =
    (window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) - 400
})
// 监听变化
watch(
  () => tableHeight.value,
  (newValue) => {
    if (newValue) {
      tableHeight.value = newValue
    }
  }
)
watch(
  () => state.checkedList,
  (val) => {
    state.indeterminate = !!val.length && val.length < plainOptions.value.length
    state.checkAll = val.length === plainOptions.value.length
  }
)
export {
  spinning,
  selectRowData,
  selectedIds,
  searchForm,
  total,
  dataSource,
  state,
  plainOptions,
  tableHeight
}
