
import { defineComponent, ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import {
  detailList, taskVisible, billColumns, billSource, billState, productList,
  sColums, mColums, columns, nColumns
} from './ordermanagement'
import { getBillList } from '@/api/order'
import { BigNumber } from 'bignumber.js'
export default defineComponent({
  name: 'OrderDetail',
  setup () {
    const router = useRouter()
    const dataSource: any = ref([])
    const total = ref(0)
    const tableHeight = ref('')
    window.addEventListener('resize', function () {
      tableHeight.value = window.innerHeight - 116 + 'px'
    })
    // 监听变化
    watch(
      () => tableHeight.value,
      (newValue) => {
        if (newValue) {
          tableHeight.value = newValue
        }
      }
    )
    watch(
      () => productList.value,
      () => {
        tableHeight.value = window.innerHeight - 116 + 'px'
      }
    )
    watch(
      () => detailList.value,
      (data) => {
        if (data) {
          getList()
        }
      }
    )
    onMounted(() => {
      detailList.value = localStorage.getItem('orderDetail') || '{}'
      if (detailList.value) {
        getList()
        getBill()
      }
    })
    const getList = () => {
      dataSource.value = []
      var detail = JSON.parse(detailList.value)
      if (detail.productType === 1) {
        taskVisible.value = true
      } else {
        taskVisible.value = false
      }
      productList.value = detail
      if (detail.tagType === '2') {
        columns.value = sColums.value
      } else if (detail.tagType === '3') {
        columns.value = nColumns.value
      } else {
        columns.value = mColums.value
      }
      dataSource.value.push(detail.orderExtInfoVO)
    }
    const getBill = () => {
      billSource.value = []
      var detail = JSON.parse(detailList.value)
      billState.orderId = detail.id
      getBillList(billState).then((res: any) => {
        billSource.value = res.data.rows
        total.value = res.data.total
      })
    }
    const handlePage = (e: number) => {
      billState.pageReq.pageNum = e
      getBill()
    }
    const handlePageSize = (current: number, size: number) => {
      billState.pageReq.pageNum = current
      billState.pageReq.pageSize = size
      getBill()
    }
    function handleResizeColumn (w: any, col: any) {
      col.width = w
    }
    const handleBack = () => {
      router.go(-1)
    }
    const calculatePrice = (discount: any, price: any) => {
      if (discount !== 1) {
        var num = mutiply(discount, price)
        var num1 = ''
        num1 = ceil(num, 2)
        return num1
      } else {
        return price
      }
    }
    // 向上取整保留两位小数
    function ceil (num: any, byte: any) {
      var x = String(num).indexOf('.') + 1 // 小数点的位置
      var y = String(num).length - x // 小数的位数
      if (y > 2) {
        // 小数位数大于两位向上取整保留两位显示
        const n = Math.pow(10, byte)
        return Math.ceil(mutiply(num, n)) / n
      } else {
        return num
      }
    }
    function mutiply (a: any, b: any) {
      a = BigNumber(a)
      b = BigNumber(b)
      return a.multipliedBy(b).toNumber()
    }
    return {
      dataSource,
      total,
      handlePage,
      handlePageSize,
      handleResizeColumn,
      calculatePrice,
      handleBack,
      detailList,
      taskVisible,
      billColumns,
      billSource,
      billState,
      productList,
      sColums,
      mColums,
      columns,
      nColumns,
      tableHeight
    }
  }
})
