import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5296558"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "announcement"
}
const _hoisted_2 = { class: "text-animation" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_ctx.announcementVisible && _ctx.noticeMessage.noticeContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_svg_icon, {
          "icon-class": "ic_laba",
          class: "laba"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "text",
            innerHTML: _ctx.noticeMessage.noticeContent
          }, null, 8, _hoisted_3)
        ]),
        _createVNode(_component_svg_icon, {
          "icon-class": "ic_guanbi",
          class: "close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleCloseAnnouncement()))
        })
      ]))
    : _createCommentVNode("", true)
}