import http from '@/utils/http'
import { SubuserSpace } from '@/interface/userCenter'

const querySubuserApi = (data: SubuserSpace.SubInfoFace) => {
  return http({
    method: 'post',
    url: '/user/user/sub/queryUserSub',
    data: data
  })
}

const queryHostListApi = () => {
  return http({
    method: 'get',
    url: '/user/user/realm/queryUserRealmDict'
  })
}

const addSubuserApi = (data: SubuserSpace.AddUserSubParams) => {
  return http({
    method: 'post',
    url: '/user/user/sub/addUserSub',
    data: data
  })
}

const querySubuserDetailApi = (data: string) => {
  return http({
    method: 'get',
    url: `/user/user/sub/queryUserSub/${data}`
  })
}

const modifySubuserApi = (data: SubuserSpace.AddUserSubParams) => {
  return http({
    method: 'post',
    url: '/user/user/sub/setUserSub',
    data: data
  })
}

const delSubuserApi = (data: string) => {
  return http({
    method: 'post',
    url: `/user/user/sub/delUserSub/${data}`
  })
}

const stopUseSubuserApi = (data: string) => {
  return http({
    method: 'post',
    url: `/user/user/subManage/freezeUserSub/${data}`
  })
}

const enableSubuserApi = (data: string) => {
  return http({
    method: 'post',
    url: `/user/user/subManage/thawUserSub/${data}`
  })
}

const queryDomainNameApi = (data: SubuserSpace.DomainNameFace) => {
  return http({
    method: 'post',
    url: '/user/user/realm/queryUserRealm',
    data: data
  })
}

const addDomainNameApi = (data: SubuserSpace.AddDomainNameFace) => {
  return http({
    method: 'post',
    url: '/user/user/realm/addUserRealm',
    data: data
  })
}

const queryDomainNameDetailApi = (data: string) => {
  return http({
    method: 'get',
    url: `/user/user/realm/queryUserRealm/${data}`
  })
}

const modifyDomainNameApi = (data: SubuserSpace.AddDomainNameFace) => {
  return http({
    method: 'post',
    url: '/user/user/realm/setUserRealm',
    data: data
  })
}

const delDomainNameApi = (data: string) => {
  return http({
    method: 'post',
    url: `/user/user/realm/delUserRealm/${data}`
  })
}

export {
  querySubuserApi,
  queryHostListApi,
  addSubuserApi,
  querySubuserDetailApi,
  modifySubuserApi,
  delSubuserApi,
  stopUseSubuserApi,
  enableSubuserApi,
  queryDomainNameApi,
  addDomainNameApi,
  queryDomainNameDetailApi,
  modifyDomainNameApi,
  delDomainNameApi
}
