import { ref, computed, reactive } from 'vue'
import { BigNumber } from 'bignumber.js'
import { toFixedNum } from '@/utils/toFixedNum'
import { getList, balance, title } from '../myTaskModule'
import { message } from 'ant-design-vue'
import { increaseBandwidth, queryBandwidthPrice } from '@/api/myTask'
interface ExpandNetParamsState {
  uplinkRate: number,
  deviceId: string,
  discountHour: number,
  taskId:string
}
const netVisible = ref(false)
const expansionFormRef = ref()
const expansionNetForm: ExpandNetParamsState = reactive({
  uplinkRate: 0,
  deviceId: '',
  discountHour: 0,
  taskId: ''
})
const computedNetDiscountPrice = ref(0)
const netPriceHour = ref(0)
const computedNetPrice:any = ref(0)
const freeUpBandwidth = ref(0)
const userAccount = ref(0)
const totalNetPrice = computed(() => {
  const currentBandWidth = new BigNumber(
    new BigNumber(expansionNetForm.uplinkRate).minus(
      new BigNumber(freeUpBandwidth.value)
    )
  )
    .abs()
    .toNumber()
  return toFixedNum(
    new BigNumber(computedNetDiscountPrice.value)
      .multipliedBy(new BigNumber(currentBandWidth))
      .toNumber(),
    2
  )
})
const handleExpandNet = (id: string) => {
  queryBandwidthPrice(id).then((res: any) => {
    (Object.keys(expansionNetForm) as Array<keyof ExpandNetParamsState>).forEach(
      <K extends keyof ExpandNetParamsState>(key: K) => {
        expansionNetForm[key] = res.data[key]
      })
    expansionNetForm.taskId = id
    netPriceHour.value = res.data.priceHour // 原价
    freeUpBandwidth.value = res.data.upBandwidth // 免费带宽
    computedNetDiscountPrice.value = toFixedNum(
      new BigNumber(res.data.discountHour)
        .multipliedBy(new BigNumber(res.data.priceHour))
        .toNumber(),
      2
    )
    if (res.data.discountHour < 1) {
      computedNetPrice.value = new BigNumber(res.data.discountHour)
        .multipliedBy(new BigNumber(10))
        .toNumber()
    } else {
      computedNetPrice.value = null
    }
    title.value = '带宽扩容'
    netVisible.value = true
  })
}
const handleConfirmNet = () => {
  const params = {
    deviceId: expansionNetForm.deviceId,
    discount: expansionNetForm.discountHour,
    increaseBandwidthAmount: new BigNumber(
      new BigNumber(expansionNetForm.uplinkRate).minus(
        new BigNumber(freeUpBandwidth.value)
      )
    )
      .abs()
      .toNumber(),
    orderPrice: totalNetPrice.value,
    taskId: expansionNetForm.taskId,
    unitPrice: netPriceHour.value,
    upBandwidth: freeUpBandwidth.value
  }
  increaseBandwidth(params).then(() => {
    message.success('扩容成功')
    netVisible.value = false
    getList()
  })
}
const handleCanelNet = () => {
  netVisible.value = false
}
export {
  netVisible,
  expansionFormRef,
  expansionNetForm,
  computedNetDiscountPrice,
  netPriceHour,
  computedNetPrice,
  freeUpBandwidth,
  userAccount,
  totalNetPrice,
  handleConfirmNet,
  handleCanelNet,
  handleExpandNet,
  balance,
  title
}
