import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-312be01b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "inner-container security-container"
}
const _hoisted_2 = { class: "common-box" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "tips" }
const _hoisted_5 = { class: "tools" }
const _hoisted_6 = { class: "common-box" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "tips" }
const _hoisted_9 = { class: "tools" }
const _hoisted_10 = { class: "common-box" }
const _hoisted_11 = { class: "title" }
const _hoisted_12 = { class: "tips" }
const _hoisted_13 = { class: "tools" }
const _hoisted_14 = { class: "common-box" }
const _hoisted_15 = { class: "title" }
const _hoisted_16 = { class: "tips" }
const _hoisted_17 = { class: "tools" }
const _hoisted_18 = { class: "common-box" }
const _hoisted_19 = { class: "title" }
const _hoisted_20 = { class: "tips" }
const _hoisted_21 = { class: "tools" }
const _hoisted_22 = {
  key: 0,
  class: "qrcode-img"
}
const _hoisted_23 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.isShowSubView)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('userCenter.loginPasswords')), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('userCenter.safeTips')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _createVNode(_component_svg_icon, {
                  "icon-class": _ctx.authInfo.passWordFlag ? 'ic_yishezhi' : 'ic_weishezhi'
                }, null, 8, ["icon-class"]),
                _createElementVNode("span", null, _toDisplayString(_ctx.authInfo.passWordFlag ? _ctx.$t('userCenter.setting') : _ctx.$t('userCenter.unset')), 1)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSetPassword && _ctx.handleSetPassword(...args)))
                }, _toDisplayString(_ctx.$t('userCenter.editPwd')), 1)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", {
                  onClick: _cache[1] || (_cache[1] = () => _ctx.$router.push('/find-password'))
                }, _toDisplayString(_ctx.$t('userCenter.findPwd')), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('userCenter.bindPhone')), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('userCenter.bindPhoneTipsOne')) + _toDisplayString(_ctx.authInfo.phone) + _toDisplayString(_ctx.$t('userCenter.bindPhoneTipsTwo')), 1),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _createVNode(_component_svg_icon, {
                  "icon-class": _ctx.authInfo.phoneFlag ? 'ic_yishezhi' : 'ic_weishezhi'
                }, null, 8, ["icon-class"]),
                _createElementVNode("span", null, _toDisplayString(_ctx.authInfo.phoneFlag ? _ctx.$t('userCenter.setting') : _ctx.$t('userCenter.unset')), 1)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.hanleChangePhone && _ctx.hanleChangePhone(...args)))
                }, _toDisplayString(_ctx.authInfo.phoneFlag ? _ctx.$t('userCenter.replace') : _ctx.$t('userCenter.bind')) + _toDisplayString(_ctx.$t('userCenter.phone')), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('userCenter.bindWechat')), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('userCenter.bindWechatTips')), 1),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _createVNode(_component_svg_icon, {
                  "icon-class": _ctx.authInfo.openFlag ? 'ic_yishezhi' : 'ic_weishezhi'
                }, null, 8, ["icon-class"]),
                _createElementVNode("span", null, _toDisplayString(_ctx.authInfo.openFlag ? _ctx.$t('userCenter.setting') : _ctx.$t('userCenter.unset')), 1)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", {
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleBindChat && _ctx.handleBindChat(...args)))
                }, _toDisplayString(_ctx.authInfo.openFlag ? _ctx.$t('userCenter.replace') : _ctx.$t('userCenter.bind')) + _toDisplayString(_ctx.$t('userCenter.wechat')), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('userCenter.safeEmail')), 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('userCenter.safeEmailTips')), 1),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _createVNode(_component_svg_icon, {
                  "icon-class": _ctx.authInfo.emailFlag ? 'ic_yishezhi' : 'ic_weishezhi'
                }, null, 8, ["icon-class"]),
                _createElementVNode("span", null, _toDisplayString(_ctx.authInfo.emailFlag ? _ctx.$t('userCenter.setting') : _ctx.$t('userCenter.unset')), 1)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", {
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleBindEmail && _ctx.handleBindEmail(...args)))
                }, _toDisplayString(_ctx.authInfo.emailFlag ? _ctx.$t('userCenter.replace') : _ctx.$t('userCenter.bind')) + _toDisplayString(_ctx.$t('userCenter.emailTitle')), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('userCenter.nameAuthentication')), 1),
          _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('userCenter.nameTips')), 1),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _createVNode(_component_svg_icon, {
                  "icon-class": _ctx.authInfo.authFlag ? 'ic_yishezhi' : 'ic_weishezhi'
                }, null, 8, ["icon-class"]),
                _createElementVNode("span", null, _toDisplayString(_ctx.authInfo.authFlag ? _ctx.$t('userCenter.setting') : _ctx.$t('userCenter.unset')), 1)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", {
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleRealNameAuth && _ctx.handleRealNameAuth(...args)))
                }, _toDisplayString(_ctx.$t('userCenter.immediateAuthentication')), 1)
              ])
            ])
          ])
        ]),
        _createVNode(_component_a_modal, {
          open: _ctx.passwordVisible,
          "onUpdate:open": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.passwordVisible) = $event)),
          title: "修改密码",
          centered: "",
          width: 746,
          onOk: _ctx.handleCommitPassword
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              "label-col": {
          style: {
            width: '126px'
          }
        },
              model: _ctx.passwordInfo,
              ref: "passwordForm"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: "旧密码",
                          name: "oldPassword"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input_password, {
                              style: {"width":"220px"},
                              value: _ctx.passwordInfo.oldPassword,
                              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.passwordInfo.oldPassword) = $event)),
                              autocomplete: "off"
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: "新密码",
                          name: "newPassword"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input_password, {
                              style: {"width":"220px"},
                              value: _ctx.passwordInfo.newPassword,
                              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.passwordInfo.newPassword) = $event)),
                              autocomplete: "off"
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["open", "onOk"]),
        _createVNode(_component_a_modal, {
          open: _ctx.phoneVisible,
          "onUpdate:open": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.phoneVisible) = $event)),
          width: 746,
          centered: "",
          title: "更换手机",
          onOk: _ctx.handleConfirmChangePhone
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              ref: "phoneForm",
              model: _ctx.phoneInfo,
              "label-col": {
          span: 14
        }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { gutter: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 14 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "旧手机验证码" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.phoneInfo.oldCode,
                              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.phoneInfo.oldCode) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 5 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, { onClick: _ctx.handleSendMsgCode }, {
                          default: _withCtx(() => [
                            _createTextVNode("发送验证码")
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { gutter: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 14 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "新手机号码" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.phoneInfo.newPhone,
                              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.phoneInfo.newPhone) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 5 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          disabled: !_ctx.phoneInfo.newPhone,
                          onClick: _ctx.handleSendNewMsgCode
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" 发送验证码 ")
                          ]),
                          _: 1
                        }, 8, ["disabled", "onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { gutter: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 14 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "验证码" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.phoneInfo.code,
                              "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.phoneInfo.code) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["open", "onOk"]),
        _createVNode(_component_a_modal, {
          open: _ctx.emailVisible,
          "onUpdate:open": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.emailVisible) = $event)),
          width: 746,
          centered: "",
          title: _ctx.authInfo.emailFlag ? '更换邮箱' : '绑定邮箱',
          onOk: _ctx.handleConfirmChangeEmail
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              model: _ctx.emailInfo,
              "label-col": {
          span: 14
        }
            }, {
              default: _withCtx(() => [
                (_ctx.authInfo.emailFlag)
                  ? (_openBlock(), _createBlock(_component_a_row, {
                      key: 0,
                      gutter: 16
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, { span: 14 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, { label: "旧邮箱验证码" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  value: _ctx.emailInfo.oldCode,
                                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.emailInfo.oldCode) = $event))
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_col, { span: 5 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, { onClick: _ctx.handleSendEmailCode }, {
                              default: _withCtx(() => [
                                _createTextVNode("发送验证码")
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_row, { gutter: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 14 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "新邮箱" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.emailInfo.email,
                              "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.emailInfo.email) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 5 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          disabled: !_ctx.emailInfo.email,
                          onClick: _ctx.handleSendNewEmailCode
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" 发送验证码 ")
                          ]),
                          _: 1
                        }, 8, ["disabled", "onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { gutter: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 14 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "验证码" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.emailInfo.code,
                              "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.emailInfo.code) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["open", "title", "onOk"]),
        _createVNode(_component_a_modal, {
          open: _ctx.bindChatVisible,
          "onUpdate:open": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.bindChatVisible) = $event)),
          title: _ctx.$t('userCenter.binding'),
          centered: "",
          width: 746
        }, {
          default: _withCtx(() => [
            (_ctx.frameUrl)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.$t("userCenter.scanTips")), 1),
                  _createElementVNode("img", {
                    src: _ctx.frameUrl,
                    alt: ""
                  }, null, 8, _hoisted_23)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["open", "title"])
      ]))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}