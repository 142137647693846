
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    workName: {
      type: String,
      required: true,
      default: ''
    },
    workCode: {
      type: String,
      required: true,
      default: ''
    },
    status: {
      type: Number,
      required: true,
      default: 0
    },
    workType: {
      type: Number,
      required: true,
      default: 0
    },
    createTime: {
      type: String,
      required: true,
      default: ''
    }
  }
})
