import http from '@/utils/http'
// 获取用户所有开票记录
const getMakeInvoice = (data:any) => {
  return http({
    method: 'post',
    url: '/cost/client/invoice/getMakeInvoice',
    data: data
  })
}
// 获取用户可开票金额及已开票金额
const getAmountInvoiced = () => {
  return http({
    method: 'get',
    url: '/cost/client/invoice/getAmountInvoiced'
  })
}
// 获取用户最新发票信息
const getNewestInvoice = () => {
  return http.request({
    method: 'get',
    url: '/cost/client/invoice/getNewestInvoice'
  })
}
// 开发票
const makeInvoice = (data:any) => {
  return http.request({
    method: 'post',
    url: '/cost/client/invoice/makeInvoice',
    data: data
  })
}
// 新增或修改发票信息
const addOrModify = (data:any) => {
  return http.request({
    method: 'post',
    url: '/cost/client/invoice/addOrModify',
    data: data
  })
}
export {
  getMakeInvoice,
  getAmountInvoiced,
  getNewestInvoice,
  makeInvoice,
  addOrModify
}
