
import { defineComponent } from 'vue'
import {
  handlePackageImage,
  form,
  handleConfirmPackage,
  formRef
} from '../js/tablePackageImage'
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    },
    rules: {
      type: Object
    }
  },
  setup (props, { emit }) {
    const handleCancel = () => {
      emit('close')
    }
    return {
      props,
      handleCancel,
      handleConfirmPackage,
      handlePackageImage,
      form,
      formRef
    }
  }
})
