import {
  queryReleaseTime, killContainer, notGpuModel, resetContainer,
  modTaskStatus, restartContainer, deleteTask, startContainer
} from '@/api/myTask'
import { getStoredLanguage } from '@/utils/languageStorage'
import { message, Modal } from 'ant-design-vue'
import {
  getList,
  stopLoading,
  taskInfo,
  stopDialogVisible,
  stopTips,
  selectedIds,
  costFlag,
  rowData,
  selectRowData
} from '../myTaskModule'
import { h } from 'vue'
// 实例关机
// 格式化时间，动态转换小时或天
const dayFormatTime = (hours: any) => {
  if (typeof hours !== 'number' || hours < 0) {
    throw new Error('Invalid input: hours must be a non-negative number.')
  }
  const locale: string = getStoredLanguage()
  const days = Math.floor(hours / 24)
  const remainderHours = hours % 24
  // 使用模板字符串和条件运算符简化代码，并解决硬编码问题
  const languageResources: any = {
    en: {
      days: 'days',
      hours: 'hours',
      zero: ''
    },
    'zh-CN': {
      days: '天',
      hours: '小时',
      zero: '零'
    }
  }

  let languageResource: any = languageResources[locale]
  if (!languageResource) {
    // 如果没有找到对应的语言资源，可以使用默认语言或抛出错误
    languageResource = languageResources['zh-CN'] // 或 throw new Error('Unsupported locale.');
  }
  let result
  if (days > 0) {
    result = `${days}${languageResource.days}${
      remainderHours > 0
        ? `${languageResource.zero}${remainderHours}${languageResource.hours}`
        : ''
    }`
  } else {
    result = `${remainderHours > 0 ? remainderHours : languageResource.days}${
      languageResource.hours
    }`
  }
  return result
}
// 实例关机
const handleStop = (data:any) => {
  rowData.value = data
  stopDialogVisible.value = true
  stopLoading.value = true
  // 查询当前实例释放时间进行动态显示提示语（1、若缓存盘释放时间、实例释放时间为0。 2、若实例释放时间不为0且大于0，而缓存盘释放时间为0。3、二者释放时间都不为0且大于0）
  queryReleaseTime().then((res) => {
    if (res.data) {
      for (const i in taskInfo) {
        taskInfo[i] =
          res.data[i] !== 0 ? dayFormatTime(res.data[i]) : res.data[i]
      }
      if (taskInfo.cacheReleaseHours === 0 && taskInfo.taskReleaseHours === 0) {
        stopTips.value =
          '<p style="line-height: 30px;">1、实例关机不会清空缓存盘中的数据，为保证数据安全，请在关机前将重要数据存放到共享/对象存储中<br />2、实例关机不会自动进行释放操作，为保证数据安全，请及时备份数据或者点击保存镜像进行保存（保存镜像不会保存缓存盘中的数据<br />3、按量计费的实例关机后运算卡将会释放，再次开机时可能面临空闲运算卡不足无法开机的情况</p>'
      } else if (
        taskInfo.cacheReleaseHours === 0 &&
        taskInfo.taskReleaseHours > 0
      ) {
        stopTips.value =
          '<p style="line-height: 30px;">1、实例关机不会清空缓存盘中的数据，为保证数据安全，请在关机前将重要数据存放到共享/对象存储中<br />2、实例关机<span style="color:#DC2424">' +
          taskInfo.taskReleaseHours +
          '后</span>，将自动进行释放操作，请及时备份数据或者点击保存镜像进行保存(保存镜像不会保存缓存盘中的数据)<br />3、按量计费的实例关机后运算卡将会释放，再次开机时可能面临空闲运算卡不足无法开机的情况</p>'
      } else {
        stopTips.value = '<p style="line-height: 30px;">1、实例关机<span style="color:#DC2424"> ' + taskInfo.cacheReleaseHours + '后 </span>将清空缓存盘中的数据，请在关机前将重要数据存放到共享/对象存储中 <br />2、实例关机<span style="color:#DC2424">' +
        taskInfo.taskReleaseHours + '后</span>，将自动进行释放操作，请及时备份数据或者点击保存镜像进行保存(保存镜像不会保存缓存盘中的数据)<br />3、按量计费的实例关机后运算卡将会释放，再次开机时可能面临空闲运算卡不足无法开机的情况</p>'
      }
    }
  })
}
const stopContainer = () => {
  killContainer(rowData.value.id).then(() => {
    message.success('实例关机中')
    stopDialogVisible.value = false
    selectedIds.value = []
    getList()
  })
}
const handleStart = (item: any) => {
  Modal.confirm({
    title: '系统提示',
    content: '是否确认启动当前实例：' + item.taskName + '?',
    centered: true,
    onOk: () => {
      startContainer(item.id).then(() => {
        message.success('实例开机中')
        selectedIds.value = []
        selectRowData.value = ''
        getList()
      })
    }
  })
}
// 无卡模式
const handleNoCardModel = (item: any) => {
  if (!costFlag.value) {
    Modal.confirm({
      title: '确认无卡模式开机吗？',
      width: '500px',
      content: '无卡模式配置为：1核心CPU，2GB内存，无运算卡',
      centered: true,
      onOk () {
        notGpuModel(item.id).then(() => {
          message.success('无卡模式开机中')
          getList()
        })
      }
    })
  } else {
    Modal.confirm({
      title: '确认无卡模式开机吗？',
      width: '500px',
      content: h('p', { style: 'line-height: 30px;' }, [
        h('span', '无卡模式配置为：1核心CPU，2GB内存，无运算卡'),
        h('span', '配置费用 :'),
        h('span', { style: 'color: #DC2424' }, '￥0.10'),
        h('span', '/ 时, 最低消费'),
        h('span', { style: 'color: #DC2424' }, '￥0.10')
      ]),
      centered: true,
      onOk () {
        notGpuModel(item.id).then(() => {
          message.success('无卡模式开机中')
          getList()
        })
      }
    })
  }
}
// 重置实例
const handleResetContainer = (item: any) => {
  Modal.confirm({
    title: '系统提示',
    content: '是否确认重置当前实例：' + item.taskName + '的系统?',
    centered: true,
    onOk: () => {
      resetContainer(item.id).then(() => {
        message.success('重置成功')
        selectedIds.value = []
        selectRowData.value = ''
        getList()
      })
    }
  })
}
// 状态变更
const handleChangeStatus = (item:any) => {
  Modal.confirm({
    title: '实例状态变更',
    content: '确认将实例' + item.taskName + '状态变更为已关机吗？',
    centered: true,
    onOk: () => {
      modTaskStatus(item.id).then(() => {
        message.success('状态变更成功')
        selectedIds.value = []
        selectRowData.value = ''
        getList()
      })
    }
  })
}
// 重启实例
const handleTaskReset = (item:any) => {
  Modal.confirm({
    title: '系统提示',
    content: '是否确认重启当前实例：' + item.taskName + '?',
    centered: true,
    onOk: () => {
      restartContainer(item.id).then(() => {
        message.success('实例重启中')
        selectedIds.value = []
        selectRowData.value = ''
        getList()
      })
    }
  })
}
// 释放实例
const handleTaskRelease = (item: any) => {
  Modal.confirm({
    title: '确认释放实例' + item.taskName + '吗？',
    content:
      '释放后将会清除实例的所有数据，包含缓存盘(共享存储、对象存储中的数据除外)',
    centered: true,
    onOk: () => {
      deleteTask(item.id).then(() => {
        message.success('实例释放中')
        selectedIds.value = []
        selectRowData.value = ''
        getList()
      })
    }
  })
}
export {
  stopLoading,
  handleStop,
  stopTips,
  stopDialogVisible,
  stopContainer,
  handleNoCardModel,
  handleResetContainer,
  handleChangeStatus,
  handleTaskReset,
  handleTaskRelease,
  handleStart
}
