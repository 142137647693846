import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "find-password" }
const _hoisted_2 = { class: "form-box" }
const _hoisted_3 = { class: "center" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "code-box" }
const _hoisted_6 = { class: "code" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "code-box" }
const _hoisted_9 = { class: "code" }
const _hoisted_10 = { class: "tips" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_st_button = _resolveComponent("st-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_login_register_bg = _resolveComponent("login-register-bg")!

  return (_openBlock(), _createBlock(_component_login_register_bg, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("login.findPassword")), 1),
            _createVNode(_component_a_form, {
              class: "find-password-form",
              model: _ctx.findPassword,
              ref: "form",
              onFinish: _ctx.handleRegister
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  name: "phone",
                  rules: [
                {
                  required: true,
                  validator: _ctx.validatorPhone
                }
              ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      size: "large",
                      autocomplete: "off",
                      placeholder: _ctx.$t('login.phone'),
                      value: _ctx.findPassword.phone,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.findPassword.phone) = $event))
                    }, {
                      prefix: _withCtx(() => [
                        _createVNode(_component_svg_icon, {
                          "icon-class": "ic_phone",
                          class: "ic-svg"
                        })
                      ]),
                      _: 1
                    }, 8, ["placeholder", "value"])
                  ]),
                  _: 1
                }, 8, ["rules"]),
                _createVNode(_component_a_form_item, {
                  name: "password",
                  rules: [
                {
                  required: true,
                  validator: _ctx.checkPassword
                }
              ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_password, {
                      size: "large",
                      autocomplete: "off",
                      placeholder: _ctx.$t('login.password'),
                      value: _ctx.findPassword.password,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.findPassword.password) = $event))
                    }, {
                      prefix: _withCtx(() => [
                        _createVNode(_component_svg_icon, {
                          "icon-class": "ic_password",
                          class: "ic-svg"
                        })
                      ]),
                      _: 1
                    }, 8, ["placeholder", "value"])
                  ]),
                  _: 1
                }, 8, ["rules"]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_a_form_item, {
                    class: "item-code",
                    name: "codeNum"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        size: "large",
                        autocomplete: "off",
                        placeholder: _ctx.$t('login.codeNum'),
                        value: _ctx.findPassword.codeNum,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.findPassword.codeNum) = $event))
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_component_svg_icon, {
                            "icon-class": "ic_prove",
                            class: "ic-svg"
                          })
                        ]),
                        _: 1
                      }, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.imgCodeInfo.codeNum)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: 'data:image/png;base64,' + _ctx.imgCodeInfo.codeNum,
                          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleRefreshImgCode && _ctx.handleRefreshImgCode(...args)))
                        }, null, 8, _hoisted_7))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_a_form_item, {
                    class: "item-code",
                    name: "code"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        size: "large",
                        autocomplete: "off",
                        placeholder: _ctx.$t('login.phoneVerifyCode'),
                        value: _ctx.findPassword.code,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.findPassword.code) = $event))
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_component_svg_icon, {
                            "icon-class": "ic_prove",
                            class: "ic-svg"
                          })
                        ]),
                        _: 1
                      }, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_9, [
                    (_ctx.second === 60)
                      ? (_openBlock(), _createBlock(_component_st_button, {
                          key: 0,
                          type: "primary",
                          class: "code-btn",
                          onClick: _ctx.handleSendCode,
                          disabled: _ctx.disabled
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("login.sendCode")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick", "disabled"]))
                      : (_openBlock(), _createBlock(_component_st_button, {
                          key: 1,
                          disabled: _ctx.second !== 60,
                          class: "code-btn"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("login.isSent")) + "（" + _toDisplayString(_ctx.second) + "s） ", 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"]))
                  ])
                ]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_st_button, {
                      type: "primary",
                      class: "login-btn",
                      "html-type": "submit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("login.confirm")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "onFinish"]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_router_link, { to: "/login" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("login.backLogin")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}