
import { defineComponent } from 'vue'
import {
  handleChangeImage,
  changeImageVisible,
  changeImageDialogVisible,
  imageOptions,
  imageForm,
  changeImage,
  getMainImage,
  getImage,
  primaryImage,
  handleConfirmChangeImage,
  imageFormRef
} from '../js/tableChangeImage'
export default defineComponent({
  props: {
    rules: {
      type: Object
    }
  },
  setup (props) {
    return {
      props,
      handleChangeImage,
      changeImageVisible,
      changeImageDialogVisible,
      imageOptions,
      imageForm,
      changeImage,
      getMainImage,
      getImage,
      primaryImage,
      handleConfirmChangeImage,
      imageFormRef
    }
  }
})
