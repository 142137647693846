import { computed, nextTick, reactive, ref, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import * as echarts from 'echarts'
import {
  CardFace,
  CreateColumnParamsFace
} from '@/interface/netDisk'
import {
  queryNetDiskInfoApi,
  queryPrecentInfoApi,
  queryRegionListApi,
  queryIpListApi,
  queryCategoryListApi,
  initBucketApi,
  shareBucketApi
} from '@/api/net-disk'
import { DynamicConditionSpace } from '@/interface/condition'
import { useCondition } from '@/utils/condition-operate'
import { TableColumnTypeConfig } from '@/interface/table'
import { FormInstance, message } from 'ant-design-vue'
import { Rule } from 'ant-design-vue/es/form'
import { useExpansion } from '@/mixins/expansion'
import { useI18n } from 'vue-i18n'

// 使用率
const precentInfoModule = () => {
  const loading = ref(true)
  const { t } = useI18n()

  const cardList = ref<CardFace[]>([
    {
      usedGpu: 0,
      totalGpu: 0,
      totalKey: 'nfsBucketSize',
      usedKey: 'nfsBucketUse',
      echartsName: 'nfs-echarts',
      title: t('netDisk.sharedStorage'),
      isShow: false,
      showFlag: 'nfsFlag',
      tableKey: 'nfsBucketFlag',
      colorKey: 'gColors',
      bucketType: 2,
      isCreatedVolumn: false,
      markClass: 'nfs-mark'
    },
    {
      usedGpu: 0,
      totalGpu: 0,
      totalKey: 'objBucketSize',
      usedKey: 'objBucketUse',
      echartsName: 'obj-echarts',
      title: t('netDisk.objectStorage'),
      isShow: false,
      showFlag: 'objFlag',
      tableKey: 'objBucketFlag',
      colorKey: 'dColors',
      bucketType: 1,
      isCreatedVolumn: false,
      markClass: 'obj-mark'
    },
    {
      usedGpu: 0,
      totalGpu: 0,
      totalKey: 'distributedBucketSize',
      usedKey: 'distributedBucketUse',
      echartsName: 'distributed-echarts',
      title: '分布式存储',
      isShow: false,
      showFlag: 'distributedFlag',
      tableKey: 'distributedBucketFlag',
      colorKey: 'fColors',
      bucketType: 4,
      isCreatedVolumn: false,
      markClass: 'distributed-mark'
    },
    {
      usedGpu: 0,
      totalGpu: 0,
      totalKey: 'dataBucketSize',
      usedKey: 'dataBucketUse',
      echartsName: 'data-echarts',
      title: t('netDisk.cacheDisk'),
      isShow: false,
      showFlag: 'dataFlag',
      colorKey: 'hColors',
      bucketType: 3,
      isCreatedVolumn: true,
      markClass: 'data-mark'
    }
  ])

  const echartsColor: any = {
    hColors: [
      ['#00CC85', 'rgba(0,204,133,0.26)'],
      ['rgba(0,204,133,0.12)', 'rgba(0,204,133,0.12)']
    ],
    gColors: [
      ['#FFB026', '#FFE2AF'],
      ['rgba(255,247,233,0.5)', 'rgba(255,247,233,0.5)']
    ],
    dColors: [
      ['#3273F9', 'rgba(50,115,249,0.26)'],
      ['rgba(50,115,249,0.12)', 'rgba(50,115,249,0.12)']
    ],
    fColors: [
      ['#FD4B35', 'rgba(253, 75, 53, 0.26)'],
      ['rgba(253, 75, 53, 0.12)', 'rgba(253, 75, 53, 0.12)']
    ]
  }

  const queryPrecentInfo = async (callback: () => void, bucketType: any) => {
    const res = await queryPrecentInfoApi()
    initCardList(res, callback, bucketType)
  }

  const getData = (color: any, vmData: any, flag: any) => {
    const list1 = color[0]
    const list2 = color[1]
    const data: any[] = []
    vmData.forEach((e: any, index: number, arr: any) => {
      if (index === 0) {
        data.push({
          value: e,
          name: index,
          itemStyle: {
            color: list1[index],
            borderWidth: 6,
            borderColor: '#ffffff'
          }
        })
      } else {
        data.push({
          value: e - arr[index - 1],
          name: index,
          itemStyle: {
            color: list1[index]
          }
        })
      }
    })
    const option = {
      series: [
        {
          type: 'pie',
          radius: ['0%', '65%'],
          center: ['50%', '50%'],
          startAngle: 50,
          silent: true,
          label: {
            show: false
          },
          data: data
        },
        {
          type: 'pie',
          radius: ['0%', '75%'],
          center: ['50%', '50%'],
          startAngle: 50,
          itemStyle: {
            color: function (params: any) {
              return list2[params.dataIndex]
            }
          },
          silent: true,
          label: {
            show: false
          },
          emphasis: {
            scale: false
          },
          data: [100]
        }
      ]
    }
    echarts.init(document.getElementById(flag)).setOption(option)
  }

  const renderCharts = (params: any) => {
    if (params.totalGpu === '0') {
      getData(echartsColor[params.colorKey], [0, 1], params.echartsName)
    } else {
      getData(echartsColor[params.colorKey], [parseFloat(params.usedGpu), parseFloat(params.totalGpu)], params.echartsName)
    }
  }

  const initCardList = (res: any, callback: () => void, bucketType: any) => {
    const map = new Map()
    const { entries } = Object
    for (const [key, value] of entries(res.data)) {
      map.set(key, value)
    }
    for (const item of cardList.value) {
      item.totalGpu = map.get(item.totalKey)
      item.usedGpu = map.get(item.usedKey)
      item.isShow = map.get(item.showFlag)
      if (item.tableKey) {
        item.isCreatedVolumn = map.get(item.tableKey)
      }
      if (item.isShow) {
        nextTick(() => {
          renderCharts(item)
        })
      }
    }
    const a = cardList.value.find(item => item.isShow)
    bucketType.value = a?.bucketType
    callback()
    loading.value = false
  }

  return {
    loading,
    cardList,
    queryPrecentInfo
  }
}

// 表格查询
const useTableModule = (cardList: any) => {
  const router = useRouter()
  const { t } = useI18n()
  const bucketType = ref<number>(2)

  const handleTabs = (type: number) => {
    bucketType.value = type
    isAllHidden.value = false
    filterColumns.value = []
    dataSource.value = []
    if (bucketType.value === 3) {
      conditionListCache.value = initConditionList(conditionListCache)
    } else {
      conditionList.value = initConditionList(conditionList)
    }
    filterOptions.value = []
    const chooseBucket = cardList.value.filter((item: { bucketType: number }) => item.bucketType === type)
    if (chooseBucket[0].isCreatedVolumn) {
      queryNetDiskInfo()
    }
  }

  const {
    inputOrselectChange,
    clearFilterOptionsList,
    setColumnsFiltered,
    setFilerOptions,
    initConditionList,
    initColumnsFiltered
  } = useCondition()

  const columns = ref<any[]>([])

  const dataSource = ref<any[]>([])

  const total = ref<number>(0)

  const flagCache = ref(false)

  const queryNetDiskInfo = async () => {
    if (bucketType.value === 2) {
      columns.value = [
        {
          title: t('netDisk.volumn'),
          dataIndex: 'name',
          ellipsis: true,
          width: 250,
          isShow: true,
          disabled: true,
          fixed: 'left'
        },
        {
          title: t('netDisk.volumnId'),
          dataIndex: 'id',
          ellipsis: true,
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.region'),
          dataIndex: 'storageDeviceArea',
          ellipsis: true,
          isShow: true,
          disabled: false
        },
        {
          title: `${t('netDisk.used')}/${t('netDisk.totalSpace')}`,
          dataIndex: 'useTotal',
          width: 140,
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.storageType'),
          dataIndex: 'type',
          customRender: ({ text }:any) => {
            switch (text) {
              case 0:
                return t('netDisk.sharedStorage')
            }
          },
          ellipsis: true,
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.storageDevice'),
          dataIndex: 'storageDeviceName',
          ellipsis: true,
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.sharedState'),
          dataIndex: 'share',
          align: 'center',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.people'),
          dataIndex: 'shareNum',
          align: 'center',
          isShow: true,
          width: 80,
          customRender: ({ record }: any) => {
            return `${record.users.length}`
          },
          disabled: false
        },
        {
          title: t('netDisk.createTime'),
          dataIndex: 'createTime',
          isShow: true,
          disabled: false
        },
        {
          title: t('workOrder.operate'),
          dataIndex: 'operate',
          align: 'center',
          fixed: 'right',
          isShow: true,
          disabled: false
        }
      ]
    } else if (bucketType.value === 1) {
      columns.value = [
        {
          title: t('netDisk.volumn'),
          dataIndex: 'name',
          width: 240,
          isShow: true,
          disabled: true,
          fixed: 'left'
        },
        {
          title: t('netDisk.bucketID'),
          dataIndex: 'id',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.region'),
          dataIndex: 'storageDeviceArea',
          isShow: true,
          disabled: false
        },
        {
          title: `${t('netDisk.used')}/${t('netDisk.totalSpace')}`,
          dataIndex: 'useTotal',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.storageType'),
          dataIndex: 'type',
          customRender: () => {
            return t('netDisk.objectStorage')
          },
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.storageDevice'),
          dataIndex: 'storageDeviceName',
          width: 120,
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.sharedState'),
          dataIndex: 'share',
          align: 'center',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.people'),
          dataIndex: 'shareNum',
          align: 'center',
          isShow: true,
          width: 80,
          customRender: ({ record }:any) => {
            return `${record.users.length}`
          },
          disabled: false
        },
        {
          title: t('netDisk.createTime'),
          dataIndex: 'createTime',
          isShow: true,
          disabled: false
        },
        {
          title: t('workOrder.operate'),
          dataIndex: 'operate',
          align: 'center',
          fixed: 'right',
          isShow: true,
          disabled: false
        }
      ]
    } else if (bucketType.value === 4) {
      columns.value = [
        {
          title: t('netDisk.volumn'),
          dataIndex: 'name',
          width: 250,
          isShow: true,
          disabled: true,
          fixed: 'left'
        },
        {
          title: t('netDisk.volumnId'),
          dataIndex: 'id',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.region'),
          dataIndex: 'storageDeviceArea',
          isShow: true,
          disabled: false
        },
        {
          title: `${t('netDisk.used')}/${t('netDisk.totalSpace')}`,
          dataIndex: 'useTotal',
          width: 140,
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.storageType'),
          dataIndex: 'type',
          customRender: () => {
            return t('netDisk.distributedStorage')
          },
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.storageDevice'),
          dataIndex: 'storageDeviceName',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.sharedState'),
          dataIndex: 'share',
          align: 'center',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.people'),
          dataIndex: 'shareNum',
          align: 'center',
          isShow: true,
          width: 80,
          customRender: ({ record }: any) => {
            return `${record.users.length}`
          },
          disabled: false
        },
        {
          title: t('netDisk.createTime'),
          dataIndex: 'createTime',
          isShow: true,
          disabled: false
        },
        {
          title: t('workOrder.operate'),
          dataIndex: 'operate',
          align: 'center',
          fixed: 'right',
          isShow: true,
          disabled: false
        }
      ]
    } else {
      columns.value = [
        {
          title: t('netDisk.cacheDiskName'),
          dataIndex: 'name',
          isShow: true,
          disabled: true,
          fixed: 'left'
        },
        {
          title: t('netDisk.cacheDiskID'),
          dataIndex: 'id',
          width: 100,
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.taskID'),
          dataIndex: 'taskId',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.taskStatus'),
          dataIndex: 'taskStatus',
          align: 'center',
          width: 100,
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.size'),
          dataIndex: 'size',
          customRender: ({ text }: any) => {
            return `${text}GB`
          },
          width: 80,
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.node'),
          dataIndex: 'node',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.subUser'),
          dataIndex: 'userSubName',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.createTime'),
          dataIndex: 'createTime',
          isShow: true,
          disabled: false
        },
        {
          title: t('netDisk.releaseTime'),
          dataIndex: 'releaseTime',
          isShow: true,
          disabled: false
        },
        {
          title: t('workOrder.operate'),
          dataIndex: 'operate',
          align: 'center',
          isShow: true,
          disabled: true,
          fixed: 'right'
        }
      ]
    }
    const res = await queryNetDiskInfoApi(conditionParams.value)
    dataSource.value = res.data.rows
    total.value = res.data.total
    flagCache.value = res.data.flag
    setScrollHeight()
  }

  const currentCondition = ref<string>('bucketName')

  const conditionList = ref<DynamicConditionSpace.ConditionFace[]>([
    {
      name: t('netDisk.volumn'),
      key: 'bucketName',
      type: 'AInput',
      value: '',
      placeholder: t('workOrder.pleaseEnter')
    }
  ])

  const conditionListCache = ref<DynamicConditionSpace.ConditionFace[]>([
    {
      name: t('netDisk.cacheDiskName'),
      key: 'bucketName',
      type: 'AInput',
      value: '',
      placeholder: t('workOrder.pleaseEnter')
    }
  ])

  const filterOptions = ref<DynamicConditionSpace.FilterConditionFace[]>([])

  const handleSelectChange = (params: DynamicConditionSpace.ConditionFace) => {
    if (bucketType.value !== 3) {
      const arr = inputOrselectChange(conditionList, params)
      conditionList.value = arr
    } else {
      const arr = inputOrselectChange(conditionListCache, params)
      conditionListCache.value = arr
    }
  }

  const handleDelOption = (params: {index: number, key: string, filtered: boolean | undefined}) => {
    filterOptions.value.splice(params.index, 1)
    clearFilterOptionsList(params.index)
    if (bucketType.value !== 3) {
      const arr = initConditionList(conditionList, params.key)
      conditionList.value = arr
    } else {
      const arr = initConditionList(conditionListCache, params.key)
      conditionListCache.value = arr
    }

    const columnsArr = [...initColumnsFiltered(
      columns,
      {
        key: params.key,
        filtered: params.filtered
      }
    )]
    columns.value = columnsArr

    queryNetDiskInfo()
  }

  const handleClear = () => {
    filterOptions.value = []
    if (bucketType.value !== 3) {
      const arr = initConditionList(conditionList)
      conditionList.value = arr
    } else {
      const arr = initConditionList(conditionListCache)
      conditionListCache.value = arr
    }

    const columnsList = initColumnsFiltered(columns)
    columns.value = columnsList

    queryNetDiskInfo()
  }

  const pageParams = reactive({
    pageNum: 1,
    pageSize: 10
  })

  const conditionParams = computed(() => {
    const map = new Map()
    const searchForm: any = {
      ...pageParams,
      bucketType: bucketType.value
    }
    if (bucketType.value !== 3) {
      conditionList.value.forEach(item => {
        map.set(item.key, item.value)
      })
    } else {
      conditionListCache.value.forEach(item => {
        map.set(item.key, item.value)
      })
    }

    for (const [k, v] of map) {
      searchForm[k] = v
    }
    return searchForm
  })

  const handleQuery = () => {
    let selectConditionParams: any = null
    if (bucketType.value !== 3) {
      selectConditionParams = conditionList.value.filter(item => item.key === currentCondition.value)[0]
    } else {
      selectConditionParams = conditionListCache.value.filter(item => item.key === currentCondition.value)[0]
    }
    if (selectConditionParams.value === '' || selectConditionParams.value === undefined || (selectConditionParams.value as []).length === 0) {
      if (filterOptions.value.length !== 0) {
        const index = filterOptions.value.findIndex(item => item.key === selectConditionParams.key)
        filterOptions.value.splice(index, 1)
        clearFilterOptionsList(index)
      }
      queryNetDiskInfo()
    } else {
      columns.value = setColumnsFiltered(columns, { key: selectConditionParams.key, value: selectConditionParams.value as number, label: selectConditionParams.label as string })
      filterOptions.value = [...setFilerOptions(filterOptions, selectConditionParams)]
      queryNetDiskInfo()
    }
  }

  // 分页查询
  const handleChangePage = (params: { pageNum: number; pageSize: number; }) => {
    pageParams.pageNum = params.pageNum
    pageParams.pageSize = params.pageSize
    queryNetDiskInfo()
  }

  // 表格列设置
  const handleChangeAll = (params: boolean) => {
    columns.value.forEach(item => {
      if (!item.disabled) {
        item.isShow = params
      }
    })
  }
  const filterColumns = ref<TableColumnTypeConfig[]>([])
  const isAllHidden = ref<boolean>(false)
  const handleSetColumns = () => {
    filterColumns.value = []
    const allDispear = columns.value.filter(item => item.isShow)
    if (allDispear.length > 0) {
      isAllHidden.value = false
      columns.value.forEach(item => {
        if (item.isShow) {
          filterColumns.value.push(item)
        }
      })
    } else {
      isAllHidden.value = true
      filterColumns.value = []
    }
  }

  // 表格滚动高度设置
  const scrollHeight = ref<number>(0)
  const setScrollHeight = () => {
    const height = document.querySelector('.table-box')?.clientHeight as number
    scrollHeight.value = height
  }

  // 详情跳转
  const handleDetail = (data: any) => {
    router.push({
      path: '/storage-center/net-disk/net-disk-detail',
      query: {
        path: data.fullPath,
        type: bucketType.value,
        id: data.id,
        power: data.power
      }
    })
  }

  return {
    conditionParams,
    handleQuery,
    queryNetDiskInfo,
    currentCondition,
    conditionList,
    bucketType,
    dataSource,
    columns,
    total,
    handleSelectChange,
    filterOptions,
    handleDelOption,
    handleClear,
    handleChangeAll,
    handleSetColumns,
    filterColumns,
    isAllHidden,
    handleChangePage,
    flagCache,
    scrollHeight,
    handleTabs,
    handleDetail,
    conditionListCache
  }
}

// 新建存储卷
const createVolumnModule = (
  cardList: any,
  queryNetDiskInfo: () => void,
  bucketType: any
) => {
  const createColumnParams: CreateColumnParamsFace = reactive({
    regionId: '',
    categoryId: '',
    chooseIp: ''
  })

  const regionList = ref<any[]>([])
  const queryRegionList = async () => {
    const res = await queryRegionListApi()
    regionList.value = res.data
  }

  const ipList = ref([])
  const categoryList = ref([])
  const queryIpList = async () => {
    const data = {
      flag: parseInt((Math.random() * 100).toFixed(0)),
      regionId: createColumnParams.regionId,
      type: bucketType.value
    }
    const res = await queryIpListApi(data)
    ipList.value = res.data
  }

  const queryCategoryList = async () => {
    const data = {
      flag: '',
      regionId: createColumnParams.regionId,
      type: bucketType.value
    }
    const res = await queryCategoryListApi(data)
    categoryList.value = res.data
  }

  const handleChangeRegion = (e: string) => {
    if (e) {
      queryCategoryList()
    } else {
      createColumnParams.chooseIp = ''
      createColumnParams.categoryId = ''
    }
  }

  const handleChangeCategory = async () => {
    queryIpList()
  }

  const createVolumnVisible = ref(false)
  const handleCreateVolumn = () => {
    createVolumnVisible.value = true
    queryRegionList()
  }
  const formColumn = ref<FormInstance>()
  const handleConfirmCreate = () => {
    if (formColumn.value) {
      formColumn.value.validate().then(() => {
        initBucketApi(createColumnParams.categoryId).then(() => {
          message.success('初始化成功', 0.5).then(() => {
            createVolumnVisible.value = false
            createColumnParams.regionId = ''
            createColumnParams.chooseIp = ''
            createColumnParams.categoryId = ''
            cardList.value.map((item: { bucketType: number; isCreatedVolumn: boolean }) => {
              if (item.bucketType === bucketType.value) {
                item.isCreatedVolumn = true
              }
            })
            queryNetDiskInfo()
          })
        })
      }).catch(err => {
        return err
      })
    }
  }

  return {
    createColumnParams,
    regionList,
    ipList,
    categoryList,
    handleChangeRegion,
    handleChangeCategory,
    createVolumnVisible,
    handleCreateVolumn,
    formColumn,
    handleConfirmCreate
  }
}

// 共享
const shareModule = (queryNetDiskInfo: () => void) => {
  const { t } = useI18n()
  const storageInfo = reactive({
    columnName: ''
  })

  const bucketId = ref<string>('')

  const shareTitle = ref<string>('')

  const visible = ref(false)

  const existenceUsers = ref<any[]>([])

  const shareType = ref(0)

  const shareFormRef = ref<FormInstance>()

  const shareColumns = [
    {
      title: t('netDisk.number'),
      dataIndex: 'order',
      width: 50
    },
    {
      title: t('netDisk.shareUserId'),
      dataIndex: 'userId'
    },
    {
      title: t('netDisk.permission'),
      dataIndex: 'flag',
      width: 180
    },
    {
      title: t('netDisk.remark'),
      dataIndex: 'remark'
    },
    {
      title: t('netDisk.operate'),
      dataIndex: 'operate',
      align: 'center',
      width: 80
    }
  ]

  const users = ref<any[]>([])

  const handleShare = (params: any, type: number) => {
    visible.value = true
    shareType.value = type
    storageInfo.columnName = params.name
    bucketId.value = params.id
    if (type === 0) {
      users.value = toRaw([...params.users])
      existenceUsers.value = params.users
      shareTitle.value = t('netDisk.share')
    } else {
      users.value = toRaw([...params.userSubs])
      existenceUsers.value = params.userSubs
      shareTitle.value = t('netDisk.subUserShare')
    }
  }

  const handlePushUsers = () => {
    users.value.push({
      flag: 0,
      id: '',
      order: 0,
      userId: '',
      userName: '',
      remark: ''
    })
  }

  const handleDelUser = (index: number) => {
    users.value.splice(index, 1)
  }

  const checkUserId = async (_rule: Rule, value: string) => {
    if (value !== '') {
      const set = new Set()
      for (let i = 0; i < users.value.length; i++) {
        if (!set.has(users.value[i].userId) || users.value[i].userId !== value) {
          set.add(users.value[i].userId)
        } else {
          return Promise.reject(new Error('共享用户ID重复，请检查'))
        }
      }
      return Promise.resolve()
    }
  }

  const handleConfirm = () => {
    const userIds: any[] = []
    users.value.forEach(item => {
      userIds.push(item.userId)
    })
    const arr = existenceUsers.value.filter(item => userIds.includes(item.userId))
    const newAddArr = [...users.value, ...arr]
    const map = new Map()
    newAddArr.forEach(item => {
      if (!map.has(item.userId)) {
        map.set(item.userId, {
          flag: item.flag,
          userId: item.userId,
          id: item.id,
          remark: item.remark
        })
      }
    })
    if (shareFormRef.value) {
      shareFormRef.value.validate().then(() => {
        const params = {
          bucketId: bucketId.value,
          users: [...map.values()],
          shareType: shareType.value
        }
        shareBucketApi(params).then(() => {
          message.success('共享成功', 0.5).then(() => {
            queryNetDiskInfo()
            visible.value = false
          })
        })
      }).catch((err) => {
        return err
      })
    }
  }

  const handleChoostTools = (params: { key: string }) => {
    const [data, type] = params.key.split(';')
    if (type === 'subShare') {
      handleShare(JSON.parse(data), 1)
    }
  }

  return {
    shareFormRef,
    shareColumns,
    users,
    handleShare,
    handlePushUsers,
    handleDelUser,
    checkUserId,
    handleConfirm,
    visible,
    storageInfo,
    shareTitle,
    handleChoostTools
  }
}

// 网盘扩容
const expansionModule = (queryNetDiskInfo: () => void) => {
  const expansionVisible = ref<boolean>(false)
  const title = ref<string>('')
  const bucketId = ref<string>('')
  const okBtnState = ref<boolean>(false)
  const { queryBucketMoneyApi, expansionPrice, handleExpansionDiskApi } = useExpansion()

  const handleExpansion = (id: string) => {
    expansionVisible.value = true
    bucketId.value = id
    queryBucketMoneyApi(id)
    title.value = '缓存盘扩容'
  }

  const handleConfirmExpansion = (data: any) => {
    const { params, cancelToken } = data
    handleExpansionDiskApi(params, '/storage/bucket/bucketDilatation', cancelToken.createCancelList).then(() => {
      queryNetDiskInfo()
      expansionVisible.value = false
    })
  }

  return {
    handleExpansion,
    expansionVisible,
    title,
    expansionPrice,
    handleConfirmExpansion,
    bucketId,
    okBtnState
  }
}

export {
  precentInfoModule,
  useTableModule,
  createVolumnModule,
  shareModule,
  expansionModule
}
