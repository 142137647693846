import { ref, reactive, computed, onMounted, watch } from 'vue'
import { DynamicConditionSpace } from '@/interface/condition'
import type { SubuserSpace } from '@/interface/userCenter'
import {
  querySubuserApi,
  queryHostListApi,
  addSubuserApi,
  querySubuserDetailApi,
  modifySubuserApi,
  delSubuserApi,
  stopUseSubuserApi,
  enableSubuserApi
} from '@/api/subuser'
import { LowercaseNumberValidator } from '@/interface/validator'
import { FormInstance, Rule } from 'ant-design-vue/es/form'
import { encrtpt } from '@/utils/jsencrypt'
import { Modal, message } from 'ant-design-vue'
import { TableColumnTypeConfig } from '@/interface/table'
import { StationMessageSpace } from '@/interface/stationMessageface'
import { useCondition } from '@/utils/condition-operate'
import { useI18n } from 'vue-i18n'

const useSubuserModule = () => {
  const { t } = useI18n()
  const {
    initConditionList,
    initColumnsFiltered,
    clearFilterOptionsList,
    setColumnsFiltered,
    setFilerOptions,
    inputOrselectChange
  } = useCondition()
  const currentCondition = ref<string>('account')

  const conditionList = ref<DynamicConditionSpace.ConditionFace[]>([
    {
      name: t('subUserManagement.subUserAccount'),
      key: 'account',
      type: 'AInput',
      value: '',
      placeholder: t('workOrder.pleaseEnter')
    },
    {
      name: t('subUserManagement.status'),
      key: 'status',
      type: 'ASelect',
      value: undefined,
      filtered: true,
      placeholder: t('workOrder.pleaseSelect'),
      label: '',
      multiple: false,
      options: [
        {
          label: t('subUserManagement.enable'),
          value: 1
        },
        {
          label: t('subUserManagement.stop'),
          value: 2
        }
      ]
    }
  ])

  const filterOptions = ref<DynamicConditionSpace.FilterConditionFace[]>([])

  const handleChangeCondition = (condition:string) => {
    currentCondition.value = condition
    const list = initConditionList(conditionList, condition)
    conditionList.value = list
  }

  const handleDelOption = (params: {index: number, key: string, filtered: boolean | undefined}) => {
    filterOptions.value.splice(params.index, 1)
    clearFilterOptionsList(params.index)
    const arr = initConditionList(conditionList, params.key)
    conditionList.value = arr

    const columnsArr = [...initColumnsFiltered(
      columns,
      {
        key: params.key,
        filtered: params.filtered
      }
    )]
    columns.value = columnsArr

    querySubuser()
  }

  const handleClear = () => {
    filterOptions.value = []
    const arr = initConditionList(conditionList)
    conditionList.value = arr

    const columnsList = initColumnsFiltered(columns)
    columns.value = columnsList

    querySubuser()
  }

  const handleQuery = () => {
    const selectConditionParams = conditionList.value.filter(item => item.key === currentCondition.value)[0]
    if (selectConditionParams.value === '' || selectConditionParams.value === undefined || (selectConditionParams.value as []).length === 0) {
      if (filterOptions.value.length !== 0) {
        const index = filterOptions.value.findIndex(item => item.key === selectConditionParams.key)
        filterOptions.value.splice(index, 1)
        clearFilterOptionsList(index)
      }
      querySubuser()
    } else {
      columns.value = setColumnsFiltered(columns, { key: selectConditionParams.key, value: selectConditionParams.value as number, label: selectConditionParams.label as string })
      filterOptions.value = [...setFilerOptions(filterOptions, selectConditionParams)]
      querySubuser()
    }
  }

  const handleSelectChange = (params: DynamicConditionSpace.ConditionFace) => {
    const arr = inputOrselectChange(conditionList, params)
    conditionList.value = arr
  }

  const handleChangeStatus = (column: any) => {
    column.filtered = true
  }

  const handleconfirm = (params: any, clearFilters: any) => {
    filterOptions.value = [...setFilerOptions(filterOptions, { ...params, filtered: true, type: 'ASelect' })]
    console.log(filterOptions.value)
    conditionList.value.forEach(item => {
      if (item.key === params.dataIndex) {
        const value = params.radioValue.split(',')[0]
        item.value = parseInt(value)
      }
    })
    querySubuser()
    clearFilters({
      closeDropdown: true
    })
  }

  const pageParams = reactive({
    pageNum: 1,
    pageSize: 10
  })

  const conditionParams = computed<SubuserSpace.SubInfoFace>(() => {
    const map = new Map()
    const searchForm: any = {
      ...pageParams
    }
    conditionList.value.forEach(item => {
      map.set(item.key, item.value)
    })
    for (const [k, v] of map) {
      searchForm[k] = v
    }
    return searchForm
  })

  const total = ref<number>(0)
  const dataSource = ref<any[]>([])

  const hanleChangePage = (params: StationMessageSpace.PagingParamsFace) => {
    pageParams.pageNum = params.pageNum
    pageParams.pageSize = params.pageSize
    querySubuser()
  }

  const columns = ref<TableColumnTypeConfig[]>([
    {
      title: t('subUserManagement.account'),
      dataIndex: 'account',
      isShow: true,
      disabled: true
    },
    {
      title: t('subUserManagement.userName'),
      dataIndex: 'userName',
      isShow: true,
      disabled: false
    },
    {
      title: t('subUserManagement.realmName'),
      dataIndex: 'realmName',
      isShow: true,
      disabled: false
    },
    {
      title: t('subUserManagement.status'),
      dataIndex: 'status',
      align: 'center',
      isShow: true,
      disabled: false,
      customFilterDropdown: true,
      radioValue: undefined,
      filtered: false,
      filterDropdownOpen: false
    },
    {
      title: t('subUserManagement.isAdmin'),
      dataIndex: 'isManage',
      align: 'center',
      customRender: ({ text }) => {
        return text === 1 ? t('subUserManagement.false') : t('subUserManagement.true')
      },
      isShow: true,
      disabled: false
    },
    {
      title: t('subUserManagement.operate'),
      dataIndex: 'operate',
      align: 'center',
      isShow: true,
      disabled: true
    }
  ])

  const filterColumns = ref<TableColumnTypeConfig[]>([])

  const isAllHidden = ref<boolean>(false)

  const handleChangeAll = (params: boolean) => {
    columns.value.forEach(item => {
      if (!item.disabled) {
        item.isShow = params
      }
    })
  }

  const handleSetColumns = () => {
    filterColumns.value = []
    const allDispear: any[] = []
    columns.value.forEach(item => {
      if (item.isShow) {
        allDispear.push(item)
      }
    })
    if (allDispear.length > 0) {
      isAllHidden.value = false
      filterColumns.value = allDispear
    } else {
      isAllHidden.value = true
      filterColumns.value = []
    }
  }

  const querySubuser = async () => {
    const res = await querySubuserApi(conditionParams.value)
    dataSource.value = res.data.rows
    total.value = res.data.total
    setAntTableHeight()
  }

  const hostList = ref<SubuserSpace.HostFace[]>([])

  const queryHostList = async () => {
    const res = await queryHostListApi()
    hostList.value = res.data
  }

  const filterList = (params: TableColumnTypeConfig) => {
    const list = conditionList.value.filter(item => item.key === params.dataIndex)[0].options
    return list
  }

  const tableHeight = ref<number>(0)
  const setAntTableHeight = ():void => {
    const height = document.querySelector('.table-box')?.clientHeight
    tableHeight.value = height as number
  }
  onMounted(() => {
    querySubuser()
  })

  const lowercaseNumberValidator = new LowercaseNumberValidator()
  const checkAccount = (_rule: Rule, value: string) => {
    if (value === '') {
      return Promise.reject(new Error(t('subUserManagement.accountInput')))
    } else {
      if (lowercaseNumberValidator.isAcceptable(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error(t('subUserManagement.accountLimit')))
      }
    }
  }

  const visible = ref<boolean>(false)
  const form = ref<FormInstance>()
  const title = ref<string>('')
  const addForm: SubuserSpace.AddUserSubParams = reactive({
    account: '',
    password: '',
    realmId: '',
    status: 1,
    auth: 0,
    isManage: 0
  })

  const handleAddSubuser = () => {
    visible.value = true
    title.value = t('add.add')
    queryHostList()
  }

  const hanldleSubmit = () => {
    if (form.value) {
      form.value.validate().then(() => {
        if (title.value === '添加') {
          const params = {
            ...addForm,
            password: encrtpt(addForm.password) as string
          }
          addSubuserApi(params).then(() => {
            message.success('添加成功', 0.5).then(() => {
              querySubuser()
              visible.value = false
            })
          })
        } else {
          const params = {
            ...addForm,
            password: addForm.password ? encrtpt(addForm.password) as string : '',
            id: rowId.value
          }
          modifySubuserApi(params).then(() => {
            message.success('修改成功', 0.5).then(() => {
              querySubuser()
              visible.value = false
            })
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }

  const rowId = ref<string>('')
  const handleChoostTools = (menuItem: any) => {
    const [key, id] = menuItem.key.split(',')
    rowId.value = id
    if (key === 'modify') {
      visible.value = true
      title.value = t('add.edit')
      queryHostList()
      querySubuserDetailApi(id).then(res => {
        Object.assign(addForm, {
          account: res.data.account,
          password: '',
          realmId: res.data.realmId,
          status: res.data.status,
          auth: res.data.auth,
          isManage: res.data.isManage
        })
      })
    } else {
      Modal.confirm({
        title: '系统提示',
        content: '是否确认删除',
        centered: true,
        onOk: () => {
          delSubuserApi(rowId.value).then(() => {
            message.success('删除成功', 0.5).then(() => {
              querySubuser()
            })
          })
        }
      })
    }
  }

  const handleSwitchUser = (params: {id: string; status: number}) => {
    Modal.confirm({
      title: '系统提示',
      content: `是否确认${params.status === 1 ? '停用' : '启用'}`,
      centered: true,
      onOk: () => {
        if (params.status === 1) {
          stopUseSubuserApi(params.id).then(() => {
            message.success('设置成功', 0.5).then(() => {
              querySubuser()
            })
          })
        } else {
          enableSubuserApi(params.id).then(() => {
            message.success('设置成功', 0.5).then(() => {
              querySubuser()
            })
          })
        }
      }
    })
  }

  watch(visible, () => {
    if (!visible.value) {
      if (form.value) {
        form.value.resetFields()
      }
    }
  })

  return {
    conditionList,
    currentCondition,
    handleChangeCondition,
    filterOptions,
    handleSelectChange,
    querySubuser,
    handleAddSubuser,
    visible,
    addForm,
    checkAccount,
    hostList,
    form,
    hanldleSubmit,
    columns,
    total,
    hanleChangePage,
    dataSource,
    handleChoostTools,
    title,
    handleSwitchUser,
    filterColumns,
    isAllHidden,
    handleChangeAll,
    handleSetColumns,
    filterList,
    handleQuery,
    handleDelOption,
    handleClear,
    tableHeight,
    handleChangeStatus,
    handleconfirm
  }
}

export {
  useSubuserModule
}
