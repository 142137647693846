import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "提示",
    open: _ctx.props.visible,
    "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.visible) = $event)),
    onOk: _ctx.props.confirm,
    onCancel: _ctx.handleCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.props.stopData
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["open", "onOk", "onCancel"]))
}