import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11c6140a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "control-container" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "left-top-content" }
const _hoisted_4 = { class: "tab-menu" }
const _hoisted_5 = { class: "tab-title" }
const _hoisted_6 = { class: "tabs area" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "tab-card-content" }
const _hoisted_9 = { class: "total-task-content" }
const _hoisted_10 = { class: "detail" }
const _hoisted_11 = { class: "text" }
const _hoisted_12 = { class: "text-title" }
const _hoisted_13 = { class: "running-task-content" }
const _hoisted_14 = { class: "detail" }
const _hoisted_15 = { class: "text" }
const _hoisted_16 = { class: "text-title" }
const _hoisted_17 = { class: "shutdown-task-content" }
const _hoisted_18 = { class: "detail" }
const _hoisted_19 = { class: "text" }
const _hoisted_20 = { class: "text-title" }
const _hoisted_21 = { class: "overtime-task-content" }
const _hoisted_22 = { class: "detail" }
const _hoisted_23 = { class: "text" }
const _hoisted_24 = { class: "text-tag" }
const _hoisted_25 = { class: "text-title" }
const _hoisted_26 = { class: "text-num" }
const _hoisted_27 = { class: "release-task-content" }
const _hoisted_28 = { class: "detail" }
const _hoisted_29 = { class: "text" }
const _hoisted_30 = { class: "text-tag" }
const _hoisted_31 = { class: "text-title" }
const _hoisted_32 = { class: "left-middle-content" }
const _hoisted_33 = { class: "header" }
const _hoisted_34 = { class: "trapezoid-label" }
const _hoisted_35 = { class: "list" }
const _hoisted_36 = { class: "label" }
const _hoisted_37 = { class: "storage-tag" }
const _hoisted_38 = { class: "text" }
const _hoisted_39 = { class: "text-title" }
const _hoisted_40 = { class: "text-num" }
const _hoisted_41 = { class: "progress" }
const _hoisted_42 = { class: "title" }
const _hoisted_43 = { class: "nums" }
const _hoisted_44 = { class: "used-space-num" }
const _hoisted_45 = { class: "total-space-num" }
const _hoisted_46 = { class: "list" }
const _hoisted_47 = { class: "label" }
const _hoisted_48 = { class: "storage-tag" }
const _hoisted_49 = { class: "text" }
const _hoisted_50 = { class: "text-title" }
const _hoisted_51 = { class: "text-num" }
const _hoisted_52 = { class: "text" }
const _hoisted_53 = { class: "text-title" }
const _hoisted_54 = {
  class: "text-num",
  style: {"color":"#8C040B"}
}
const _hoisted_55 = { style: {"width":"30%"} }
const _hoisted_56 = { style: {"position":"relative"} }
const _hoisted_57 = { class: "left-middle-content" }
const _hoisted_58 = { class: "header" }
const _hoisted_59 = { class: "trapezoid-label" }
const _hoisted_60 = { class: "list" }
const _hoisted_61 = {
  class: "label",
  style: {"width":"15%"}
}
const _hoisted_62 = { class: "text" }
const _hoisted_63 = { class: "text-title" }
const _hoisted_64 = { class: "text-num" }
const _hoisted_65 = { class: "text" }
const _hoisted_66 = { class: "text-title" }
const _hoisted_67 = { class: "text-num-none" }
const _hoisted_68 = { class: "text" }
const _hoisted_69 = { class: "text-title" }
const _hoisted_70 = { class: "text-num-none" }
const _hoisted_71 = { class: "list" }
const _hoisted_72 = {
  class: "label",
  style: {"display":"flex","width":"60%"}
}
const _hoisted_73 = {
  class: "text",
  style: {"margin-left":"40px"}
}
const _hoisted_74 = { class: "text-title" }
const _hoisted_75 = { class: "text-num" }
const _hoisted_76 = { style: {"width":"30%"} }
const _hoisted_77 = { style: {"position":"relative"} }
const _hoisted_78 = { class: "left-bottom-content" }
const _hoisted_79 = { class: "right" }
const _hoisted_80 = { class: "right-top-content" }
const _hoisted_81 = { class: "r-header" }
const _hoisted_82 = { class: "r-label" }
const _hoisted_83 = { class: "r-account" }
const _hoisted_84 = ["src"]
const _hoisted_85 = { class: "text" }
const _hoisted_86 = { class: "tag" }
const _hoisted_87 = { key: 0 }
const _hoisted_88 = { key: 1 }
const _hoisted_89 = { class: "r-user" }
const _hoisted_90 = { class: "r-user-id" }
const _hoisted_91 = { class: "r-user-content" }
const _hoisted_92 = { class: "r-fee" }
const _hoisted_93 = { class: "r-coupon" }
const _hoisted_94 = { class: "r-money" }
const _hoisted_95 = { class: "money-num" }
const _hoisted_96 = { class: "right-bottom-content" }
const _hoisted_97 = { class: "r-msg" }
const _hoisted_98 = { class: "r-msg-title" }
const _hoisted_99 = { class: "r-msg-title-m1" }
const _hoisted_100 = { class: "r-row" }
const _hoisted_101 = { class: "t-tag" }
const _hoisted_102 = { class: "detail" }
const _hoisted_103 = { class: "title" }
const _hoisted_104 = { class: "msg" }
const _hoisted_105 = {
  key: 0,
  class: "bind-wechat"
}
const _hoisted_106 = { class: "bind-wechat-bg" }
const _hoisted_107 = { class: "bind-content" }
const _hoisted_108 = { class: "desc" }
const _hoisted_109 = { class: "go-bind" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_st_button = _resolveComponent("st-button")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("myTask.myTask")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, {
            justify: "space-between",
            align: "middle"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodePlaceOptions, (item, index) => {
                return (_openBlock(), _createBlock(_component_a_col, {
                  span: 10,
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("ul", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (m, indexs) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: indexs,
                          class: _normalizeClass(_ctx.formState.regionId === m.id ? 'active' : 'default'),
                          onClick: ($event: any) => (_ctx.handleTabs(m))
                        }, _toDisplayString(m.region), 11, _hoisted_7))
                      }), 128))
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_a_col, { span: 2 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "tab-detail",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.jumpToTask(1)))
                  }, _toDisplayString(_ctx.$t("myTask.checkDetail")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_svg_icon, { "icon-class": "ic_shili" }),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("myTask.myTask")), 1),
                  _createElementVNode("div", {
                    class: "text-num",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.jumpToTask(1)))
                  }, _toDisplayString(_ctx.taskList.totalTaskCount), 1)
                ])
              ])
            ]),
            _createElementVNode("li", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_svg_icon, { "icon-class": "ic_yunxingz" }),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("myTask.running")), 1),
                  _createElementVNode("div", {
                    class: "text-num",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.jumpToTask(2)))
                  }, _toDisplayString(_ctx.taskList.runningTaskCount), 1)
                ])
              ])
            ]),
            _createElementVNode("li", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_svg_icon, { "icon-class": "ic_yiguanji" }),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t("myTask.powerOff")), 1),
                  _createElementVNode("div", {
                    class: "text-num",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.jumpToTask(3)))
                  }, _toDisplayString(_ctx.taskList.closedTaskCount), 1)
                ])
              ])
            ]),
            _createElementVNode("li", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_svg_icon, { "icon-class": "ic_guoqi" }),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("myTask.dailyFormat")), 1),
                  _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("myTask.expireTask")), 1),
                  _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.taskList.expireTaskCount), 1)
                ])
              ])
            ]),
            _createElementVNode("li", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createVNode(_component_svg_icon, { "icon-class": "ic_shifang" }),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t("costCenter.accordingTimes")), 1),
                  _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t("myTask.releaseTask")), 1),
                  _createElementVNode("div", {
                    class: "text-num",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.jumpToTask(3)))
                  }, _toDisplayString(_ctx.taskList.releaseTaskCount), 1)
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.$t("myTask.netDisk")), 1),
          _createElementVNode("div", {
            class: "check-detail",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleJump('/storage-center/net-disk')))
          }, _toDisplayString(_ctx.$t("myTask.checkDetail")), 1)
        ]),
        _createElementVNode("div", {
          class: "content",
          style: _normalizeStyle({ background: 'url(' + _ctx.bgImage + ')' })
        }, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, [
                  _createVNode(_component_svg_icon, { "icon-class": "ic_bao" }),
                  _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t("myTask.dailyFormat")), 1)
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t("myTask.myStorage")), 1),
                  _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.storageMsgList.bucketCount), 1)
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t("myTask.zoneFormat")), 1),
                  _createElementVNode("div", _hoisted_43, [
                    _createVNode(_component_a_progress, {
                      percent: _ctx.storagePercent,
                      "show-info": false,
                      "stroke-color": {
                      '0%': '#3273F9',
                      '100%': '#3273F9',
                    }
                    }, null, 8, ["percent"]),
                    _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.storageMsgList.useSize) + "G/", 1),
                    _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.storageMsgList.sumSize) + "G", 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("div", _hoisted_47, [
                  _createVNode(_component_svg_icon, { "icon-class": "ic_bao" }),
                  _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$t("costCenter.accordingTimes")), 1)
                ]),
                _createElementVNode("div", _hoisted_49, [
                  _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.$t("myTask.myCache")), 1),
                  _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.storageMsgList.dataCount), 1)
                ]),
                _createElementVNode("div", _hoisted_52, [
                  _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t("myTask.releaseTask")), 1),
                  _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.storageMsgList.dataReleaseCount), 1)
                ]),
                _createElementVNode("div", _hoisted_55, [
                  _createElementVNode("div", _hoisted_56, [
                    _createVNode(_component_svg_icon, {
                      "icon-class": "ic_wangpan",
                      class: "huancun-svg",
                      style: {"width":"100%","bottom":"-50px"}
                    })
                  ])
                ])
              ])
            ])
          ])
        ], 4)
      ]),
      _createElementVNode("div", _hoisted_57, [
        _createElementVNode("div", _hoisted_58, [
          _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$t("myTask.containerImage")), 1),
          _createElementVNode("div", {
            class: "check-detail",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleJump('/image-center/container-image')))
          }, _toDisplayString(_ctx.$t("myTask.checkDetail")), 1)
        ]),
        _createElementVNode("div", {
          class: "content",
          style: _normalizeStyle({ background: 'url(' + _ctx.sgImage + ')' })
        }, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("div", _hoisted_60, [
                _createElementVNode("div", _hoisted_61, [
                  _createVNode(_component_svg_icon, { "icon-class": "ic_rongqi" })
                ]),
                _createElementVNode("div", _hoisted_62, [
                  _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.$t("myTask.myImage")), 1),
                  _createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.imageMsgList.userCount), 1)
                ]),
                _createElementVNode("div", _hoisted_65, [
                  _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.$t("myTask.othersShare")), 1),
                  _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.imageMsgList.othersShareCount), 1)
                ]),
                _createElementVNode("div", _hoisted_68, [
                  _createElementVNode("div", _hoisted_69, _toDisplayString(_ctx.$t("myTask.sharing")), 1),
                  _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.imageMsgList.shareCount), 1)
                ])
              ])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("div", _hoisted_71, [
                _createElementVNode("div", _hoisted_72, [
                  _createVNode(_component_svg_icon, {
                    "icon-class": "ic_rongqi",
                    style: {"width":"100px"}
                  }),
                  _createElementVNode("div", _hoisted_73, [
                    _createElementVNode("div", _hoisted_74, _toDisplayString(_ctx.$t("myTask.systemImage")), 1),
                    _createElementVNode("div", _hoisted_75, _toDisplayString(_ctx.imageMsgList.systemCount), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_76, [
                  _createElementVNode("div", _hoisted_77, [
                    _createVNode(_component_svg_icon, {
                      "icon-class": "ic_jingxiang",
                      class: "huancun-svg",
                      style: {"right":"-10%","bottom":"-50px","width":"100%"}
                    })
                  ])
                ])
              ])
            ])
          ])
        ], 4)
      ]),
      _createElementVNode("div", _hoisted_78, [
        _createVNode(_component_a_tabs, {
          activeKey: _ctx.activeKey,
          "onUpdate:activeKey": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.activeKey) = $event)),
          class: "s-tabs"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_a_tab_pane, {
              tab: _ctx.$t('myTask.delResources'),
              key: 1
            }, null, 8, ["tab"])),
            (_openBlock(), _createBlock(_component_a_tab_pane, {
              tab: _ctx.$t('myTask.expireResources'),
              key: 2
            }, null, 8, ["tab"]))
          ]),
          _: 1
        }, 8, ["activeKey"]),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), { type: _ctx.activeKey }, null, 8, ["type"]))
      ])
    ]),
    _createElementVNode("div", _hoisted_79, [
      _createElementVNode("div", _hoisted_80, [
        _createElementVNode("div", _hoisted_81, [
          _createElementVNode("div", _hoisted_82, _toDisplayString(_ctx.$t('myTask.personCenter')), 1),
          _createVNode(_component_svg_icon, {
            "icon-class": "ic_VIP",
            "icon-text": _ctx.useLevel,
            "icon-style": _ctx.textStyle,
            "icon-position": _ctx.textPositions
          }, null, 8, ["icon-text", "icon-style", "icon-position"])
        ]),
        _createElementVNode("div", _hoisted_83, [
          _createElementVNode("img", {
            src: _ctx.userProfilePicture,
            alt: "",
            style: {"width":"30px","height":"30px","border-radius":"50%"}
          }, null, 8, _hoisted_84),
          _createElementVNode("div", _hoisted_85, _toDisplayString(_ctx.permission.$state.accountInfo.account), 1),
          _createElementVNode("div", _hoisted_86, [
            (_ctx.permission.$state.accountInfo.isMaster == true)
              ? (_openBlock(), _createElementBlock("span", _hoisted_87, _toDisplayString(_ctx.$t('myTask.masterAccount')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_88, _toDisplayString(_ctx.$t('myTask.subAccount')), 1))
          ])
        ]),
        _createElementVNode("div", _hoisted_89, [
          _createElementVNode("div", _hoisted_90, _toDisplayString(_ctx.$t('myTask.user')) + "ID: ", 1),
          _createElementVNode("div", _hoisted_91, [
            _createTextVNode(" ID:" + _toDisplayString(_ctx.permission.$state.accountInfo.userId) + " ", 1),
            _createVNode(_component_svg_icon, {
              "icon-class": "ic_fuzhi",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleCopy(_ctx.permission.$state.accountInfo.userId)))
            })
          ]),
          _createElementVNode("div", {
            class: "r-user-more",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleJump('/user-center/basic-settings')))
          }, _toDisplayString(_ctx.$t('myTask.checkMore')), 1)
        ]),
        _createElementVNode("div", _hoisted_92, [
          _createElementVNode("div", _hoisted_93, [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('myTask.useBalance')), 1),
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('costCenter.coupon')) + "     ", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.priceList.couponCount), 1),
              _createTextVNode("    " + _toDisplayString(_ctx.$t('myTask.pieces')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_94, [
            _createElementVNode("div", _hoisted_95, [
              _createTextVNode(_toDisplayString(_ctx.priceList.balance) + " ", 1),
              _createElementVNode("span", null, "(" + _toDisplayString(_ctx.$t('costCenter.yuan')) + ")", 1)
            ]),
            _createVNode(_component_st_button, {
              type: "primary",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleJump('/cost-center/recharge')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('costCenter.recharge')), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_96, [
        _createElementVNode("div", _hoisted_97, [
          _createElementVNode("div", _hoisted_98, [
            _createElementVNode("div", _hoisted_99, _toDisplayString(_ctx.$t('myTask.stationMsgs')), 1),
            _createElementVNode("div", {
              class: "r-msg-title-m2",
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.handleJump('/user-center/station-message')))
            }, _toDisplayString(_ctx.$t('myTask.checkMore')), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["r-msg-content", !_ctx.weChatFlag ? 'active' : ''])
          }, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.noticeMsgList, (item, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, [
                  _createElementVNode("div", _hoisted_100, [
                    _createElementVNode("div", _hoisted_101, _toDisplayString(_ctx.$t('myTask.notice')), 1),
                    _createElementVNode("div", _hoisted_102, [
                      _createVNode(_component_a_badge, {
                        dot: item.status == 1
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_103, _toDisplayString(item.noticeTitle), 1)
                        ]),
                        _: 2
                      }, 1032, ["dot"]),
                      _createElementVNode("div", _hoisted_104, _toDisplayString(item.noticeContent), 1)
                    ])
                  ])
                ]))
              }), 128))
            ]),
            (!_ctx.weChatFlag)
              ? (_openBlock(), _createElementBlock("div", _hoisted_105, [
                  _createElementVNode("div", _hoisted_106, [
                    _createElementVNode("div", _hoisted_107, [
                      _createVNode(_component_svg_icon, {
                        "icon-class": "ic_guanbi",
                        class: "close-icon",
                        onClick: _ctx.handleClose
                      }, null, 8, ["onClick"]),
                      _createElementVNode("div", _hoisted_108, _toDisplayString(_ctx.$t('myTask.noticeTips')), 1),
                      _createElementVNode("div", _hoisted_109, [
                        _createVNode(_component_router_link, { to: "/user-center/security-setting" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('myTask.goBind')) + " ", 1),
                            _createVNode(_component_svg_icon, { "icon-class": "ic_1" })
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ])
    ])
  ]))
}