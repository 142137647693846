import { DownloadFileFace, DownloadObjectFileFace } from '@/interface/netDisk'
import http from '@/utils/http'

// 上传图片
const uploadPictureApi = (data: FormData) => {
  return http.request({
    method: 'post',
    url: '/user/picture/uploadPicture',
    headers: {
      'content-type': 'multipart/form-data'
    },
    data: data
  })
}

// 文件下载共享存储、分布式存储
const downloadShareFileApi = (data: DownloadFileFace, fn: any) => {
  return http({
    method: 'post',
    url: '/storage/nfsOperation/downloadFile',
    headers: {
      contentType: 'application/octet-stream'
    },
    responseType: 'blob',
    cancelToken: fn(),
    data: data
  })
}

// 文件下载对象存储
const downloadObjectFileApi = (data: DownloadObjectFileFace, fn: any) => {
  return http({
    method: 'post',
    url: '/storage/objOperation/downloadObject',
    headers: {
      contentType: 'application/octet-stream'
    },
    responseType: 'blob',
    cancelToken: fn(),
    data: data
  })
}

export {
  uploadPictureApi,
  downloadShareFileApi,
  downloadObjectFileApi
}
