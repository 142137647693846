
import { useRouter, useRoute } from 'vue-router'
import { watch, ref, onMounted, onBeforeUnmount, defineComponent, reactive, h, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { getToken } from '@/utils/token'
import { tableHeight } from './js/tableHeight'
import { message, Modal } from 'ant-design-vue'
import { CheckOutlined } from '@ant-design/icons-vue'
// 初始化参数定义类型公共文件及公共方法
import {
  timer, costFlag, balance, getList, selectedIds, onSelectChange,
  dataSource, total, searchForm, handlePageSize, editableData, handleSaveNote,
  handleEditNote, handleSearch, selectRowData
} from './myTaskModule'
// 初始化渲染加载收费标识、余额数据
import { getIsCost, balanceCoupon, generateCustomPort, generateClientTunnel } from '@/api/myTask'
// 更换镜像
import { handleChangeImage } from './js/tableChangeImage'
import tableChangeImage from './component/tableChangeImage.vue'
// 关机界面
import tableStopContaner from './component/tableStopContaner.vue'
// 关机、无卡模式、重置系统、状态变更、实例重启、实例释放基本方法
import {
  stopLoading, handleStop, stopDialogVisible, stopTips, stopContainer, handleNoCardModel, handleResetContainer,
  handleChangeStatus, handleTaskReset, handleTaskRelease, handleStart
} from './js/tableOperation'
// 计费变更、实例升配、实例续租界面及方法
import { handleFeeChange, handleTaskEscalate, handleTaskRelet } from './js/tableFeeChange'
import tableChangeFee from './component/tableChangeFee.vue'// 计费变更
import tableTaskEscalate from './component/tableTaskEscalate.vue'// 实例升配
import tableTaskRelet from './component/tableTaskRelet.vue'// 实例续租
// 镜像打包界面及方法
import { handlePackageImage, packageVisible } from './js/tablePackageImage'
import tablePackageImage from './component/tablePackageImage.vue'
// 网盘扩容界面及方法
import tableExpansionModal from './component/tableExpansionModal.vue'
import {
  handleExpansion, expansionVisible, bucketId, expansionPrice, onBtnState,
  handleConfirmExpansion, title
} from './js/tableExpansion'
// 操作记录
import tableOperateLog from './component/tableOperateLog.vue'
import { handleOperateLog } from './js/tableOperateLog'
// 带宽扩容
import tableExpandNet from './component/tableExpandNet.vue'
import { handleExpandNet } from './js/tableExpandNet'

// import StButton from '@/components/st-button/StButton.vue'
import STag from '@/components/s-tag/STag.vue'// 状态标签
import StAlert from '@/components/st-alert/StAlert.vue'// 简介栏
import ConfigColumns from '@/components/config-columns/ConfigColumns.vue'// 筛选表格列数据组件
import DynamicSearch from '@/components/dynamic-search/DynamicSearch.vue'// 搜索表单组件
export default defineComponent({
  components: {
    tableStopContaner,
    tablePackageImage,
    tableChangeFee,
    tableExpansionModal,
    tableOperateLog,
    tableTaskEscalate,
    tableTaskRelet,
    tableExpandNet,
    STag,
    CheckOutlined,
    StAlert,
    ConfigColumns,
    // StButton,
    DynamicSearch,
    tableChangeImage
  },
  setup () {
    const router = useRouter()
    const { t } = useI18n()
    const filtered: any = reactive({})
    const columns = ref([
      {
        title: t('myTask.columns.taskId'),
        dataIndex: 'taskName',
        key: 'taskName',
        ellipsis: true,
        resizable: true,
        width: 100,
        align: 'center',
        isShow: true,
        disabled: true
      },
      {
        title: t('myTask.columns.status'),
        dataIndex: 'status',
        key: 'status',
        scopedSlots: { customRender: 'status' },
        align: 'center',
        width: 150,
        isShow: true,
        filteredValue: filtered.statusS || null,
        filters: [],
        state: {
          indeterminate: true,
          checkAll: false,
          checkedList: []
        },
        customFilterDropdown: true,
        filterDropdownOpen: false,
        filterMultiple: true,
        searchIndex: 'statusS'
      },
      {
        title: t('myTask.columns.hostStatus'),
        dataIndex: 'hostStatus',
        key: 'hostStatus',
        scopedSlots: { customRender: 'hostStatus' },
        ellipsis: true,
        align: 'center',
        width: 100,
        isShow: true,
        filteredValue: filtered.hostStatusS || null,
        filters: [],
        state: {
          indeterminate: true,
          checkAll: false,
          checkedList: []
        },
        customFilterDropdown: true,
        filterDropdownOpen: false,
        filterMultiple: true,
        searchIndex: 'hostStatusS'
      },
      {
        title: t('myTask.columns.gpuModel'),
        dataIndex: 'gpuModel',
        key: 'gpuModel',
        scopedSlots: { customRender: 'gpuModel' },
        resizable: true,
        ellipsis: true,
        width: 210,
        isShow: true
        // align: "center",
      },
      {
        title: t('myTask.columns.runnindMode'),
        dataIndex: 'runnindMode',
        key: 'runnindMode',
        scopedSlots: { customRender: 'runnindMode' },
        ellipsis: true,
        resizable: true,
        align: 'center',
        width: 100,
        isShow: localStorage.getItem('headerKey') === 'Admin-Token',
        filteredValue: filtered.runnindModeS || null,
        filters: [],
        state: {
          indeterminate: true,
          checkAll: false,
          checkedList: []
        },
        customFilterDropdown: true,
        filterDropdownOpen: false,
        filterMultiple: true,
        searchIndex: 'runnindModeS'
      },
      {
        title: t('myTask.columns.costType'),
        dataIndex: 'costType',
        key: 'costType',
        resizable: true,
        ellipsis: true,
        align: 'center',
        width: 110,
        isShow: false,
        filteredValue: filtered.costTypeS || null,
        filters: [],
        state: {
          indeterminate: true,
          checkAll: false,
          checkedList: []
        },
        customFilterDropdown: true,
        filterDropdownOpen: false,
        filterMultiple: true,
        searchIndex: 'costTypeS'
      },
      {
        title: t('myTask.columns.userSubName'),
        dataIndex: 'userSubName',
        key: 'userSubName',
        resizable: true,
        ellipsis: true,
        align: 'center',
        width: 100,
        isShow: false
      },
      {
        title: t('myTask.columns.releaseTime'),
        dataIndex: 'releaseTime',
        key: 'releaseTime',
        resizable: true,
        ellipsis: true,
        align: 'center',
        width: 120,
        isShow: true,
        // 指定排序规则，日期空值处理为特定值
        sorter: (a: any, b: any) => {
          if (a.releaseTime === null) {
            return -1 // 假设 NULL 日期应该排在最前面
          } else if (b.releaseTime === null) {
            return 1 // 假设 NULL 日期应该排在最后面
          } else {
            const aTime = new Date(a.releaseTime).getTime()
            const bTime = new Date(b.releaseTime).getTime()
            return aTime - bTime // 正常日期排序
          }
        }
      },
      {
        title: t('myTask.columns.note'),
        dataIndex: 'note',
        key: 'note',
        resizable: true,
        ellipsis: true,
        align: 'center',
        width: 120,
        isShow: true
      },
      {
        title: 'SSH',
        dataIndex: 'ssh',
        key: 'ssh',
        resizable: true,
        ellipsis: true,
        width: 180,
        isShow: true
        // align: "center",
      },
      {
        title: t('myTask.columns.tools'),
        dataIndex: 'tools',
        key: 'tools',
        ellipsis: true,
        resizable: true,
        width: 100,
        align: 'center',
        isShow: true
      },
      {
        title: t('myTask.columns.operation'),
        dataIndex: 'operation',
        key: 'operation',
        ellipsis: true,
        align: 'center',
        width: 110,
        isShow: true,
        disabled: true,
        fixed: 'right'
      }
    ])
    const rules = {
      imageName: [{ required: true, trigger: 'change', validator: checkName }],
      description: [{ required: false, trigger: 'change', validator: checkDescription }],
      image: [{ required: true, trigger: 'change', message: '请选择' }]
    }
    function checkName (rules: any, value: any) {
      if (value === '' || value == null || value === undefined) {
        return Promise.reject(t('myTask.rules.imageName'))
      } else {
        const reg = /^[a-z0-9][a-z0-9]{3,9}$/
        if (!reg.test(value)) {
          return Promise.reject(t('myTask.rules.imageNameRule'))
        } else {
          return Promise.resolve()
        }
      }
    }
    function checkDescription (rules: any, value: any) {
      if (value === '' || value == null || value === undefined) {
        return Promise.resolve()
      } else {
        const reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{2,50}$/
        if (!reg.test(value)) {
          return Promise.reject(t('myTask.rules.description'))
        } else {
          return Promise.resolve()
        }
      }
    }
    // 监听主列表高度变化
    watch(
      () => tableHeight.value,
      (newValue) => {
        if (newValue) {
          tableHeight.value = newValue
        }
      }
    )
    onMounted(() => {
      getList()
      handleChargingMoney()
      handleBalance()
      handleSetColumns()
      runEveryTenSec()
    })

    const handleChargingMoney = () => {
      // 当有token才可调用判断是否停用计费服务接口
      if (getToken()) {
        getIsCost().then((res) => {
          costFlag.value = res.data
        })
      }
    }
    // 调取业务查询菜单接口（当前用户余额）
    const handleBalance = () => {
      balanceCoupon({
        productType: 1
      }).then((res) => {
        balance.value = res.data.balance
      })
    }
    // 跳转指定网址
    const handleJump = (data: string) => {
      window.open(data, '_blank')
    }
    const handleMenuClick = ({ item, key }: any) => {
      if (key === 1) {
        handleNoCardModel(item.item)
      } else if (key === 2) {
        handleFeeChange(item.item)
      } else if (key === 3) {
        handleResetContainer(item.item)
      } else if (key === 4) {
        handleChangeImage(item.item)
      } else if (key === 5) {
        handlePackageImage(item.item)
      } else if (key === 6) {
        handleExpansion(item.item)
      } else if (key === 8) {
        handleOperateLog(item.item)
      } else if (key === 9) {
        router.push({
          path: '/ai-center/my-task/work-monitoring',
          query: {
            id: item.item.id
          }
        })
      } else if (key === 10) {
        handleChangeStatus(item.item)
      } else if (key === 11) {
        handleTaskEscalate(item.item)
      } else if (key === 12) {
        handleTaskRelet(item.item)
      } else if (key === 13) {
        handleExpandNet(item.item.id)
      } else if (key === 14) {
        handleTaskReset(item.item)
      } else if (key === 15) {
        handleTaskRelease(item.item)
      }
    }
    // 自定义筛选表格列数据
    const handleChangeAll = (params:any) => {
      columns.value.forEach((item) => {
        if (!item.disabled) {
          item.isShow = params
        }
      })
    }
    const handleSetColumns = () => {
      filterColumns.value = []
      const allDispear = columns.value.filter((item) => item.isShow)
      if (allDispear.length > 0) {
        isAllHidden.value = false
        columns.value.forEach((item) => {
          if (item.isShow) {
            filterColumns.value.push(item)
          }
        })
      } else {
        isAllHidden.value = true
        filterColumns.value = []
      }
    }
    function handleResizeColumn (w: any, col: any) {
      col.width = w
    }
    function handleCopy (data:any) {
      var save = function (e:any) {
        e.clipboardData.setData('text/plain', data)
        e.preventDefault() // 阻止默认行为
      }
      document.addEventListener('copy', save)
      document.execCommand('copy')
      document.removeEventListener('copy', save)
      message.success('复制成功')
    }
    // 便捷工具Jupyter
    const handleJumpto = (record:any) => {
      var url =
        'http://' +
        record.agentIp +
        ':' +
        record.jupyterPort +
        '/?token=' +
        record.jupyterPasswd

      window.open(url, '_blank')
    }
    // 便捷工具SitonTools
    const handleJumpService = (record:any) => {
      var url =
        'http://' +
        record.agentIp +
        ':' +
        record.sitonAiToolPort +
        '/login?auth_key=' +
        record.id
      window.open(url, '_blank')
    }
    // 便捷工具自定义服务-实名认证
    const handleRealNameAuth = (record:any) => {
      // 若需要实名认证，判断当前实例是否已经实名认证过，若没认证则跳转实名认证界面；若已认证则生成端口调取自定义服务
      if (record.realNameAutn === true) {
        Modal.confirm({
          title: '自定义服务',
          width: '600px',
          okText: '去认证',
          content: '为配合监管要求，防止将实例用于传播涉暴、涉黄等内容。使用自定义服务提供公网服务，需先进行实名认证。',
          centered: true,
          onOk: () => {
            if (!record.userAutn) {
              router.push({
                path: '/user-center/security'
              })
            } else {
              handleCustomPort(record)
            }
          }
        })
      } else {
        handleCustomPort(record)
      }
    }
    const handleCustomPort = (record:any) => {
      var customPorts = null
      // 判断是否有端口，若无生成自定义端口，有则直接创建隧道跳转至新界面
      if (!record.customPort) {
        Modal.confirm({
          title: '系统提示',
          content: '是否生成自定义服务端口?',
          centered: true,
          onOk: () => {
            generateCustomPort(record.id).then((res) => {
              message.success('生成端口成功')
              customPorts = res.data
              handleOpen(
                'http://' + record.agentIp + ':' + customPorts,
                customPorts,
                record
              )
            })
          }
        })
      } else {
        customPorts = record.customPort
        handleOpen('http://' + record.agentIp + ':' + customPorts, customPorts, record)
      }
    }
    // 已实名认证并生成自定义端口后，创建隧道并跳转至新界面
    const handleOpen = async (data:any, port:string | number, val:any) => {
      Modal.confirm({
        title: '自定义服务',
        width: '600px',
        content: h('p', { style: 'overflow: hidden;white-space: nowrap;width: 100%;text-overflow: ellipsis;' }, [
          h('span', '您确认要使用自定义服务吗？请您把实例内自定义服务的端口配置为:'),
          h('span', { style: 'color: #DC2424;' }, port)
        ]),
        centered: true,
        onOk: () => {
          generateClientTunnel(val.id).then(() => {
            message.success('创建隧道成功')
            getList()
            handleJump('http://' + val.agentIp + ':' + port)
          })
        }
      })
    }
    const handleAdd = () => {
      clearInterval(timer.value)
      router.push({
        path: '/ai-center/my-task/add-task'
      })
    }
    // 监听跳转至其余子界面菜单。判断当时计时器是否存在，避免多次调取
    const addTaskVisible = ref(false)
    const timerRunning = ref(false)
    const route = useRoute()
    watchEffect(() => {
      if (route.path.includes('add-task') || route.path.includes('work-monitoring')) {
        addTaskVisible.value = true
        handleSearch()
      } else if (getLastParam(route.path) === 'my-task') {
        // 避免在其余模块界面调用接口
        addTaskVisible.value = false
      }
    })
    function getLastParam (str:any) {
      const lastSlashIndex = str.lastIndexOf('/')
      if (lastSlashIndex !== -1) {
        return str.slice(lastSlashIndex + 1)
      }
      return '' // 如果没有'/'字符，返回空字符串
    }
    // 监听，当路由发生变化时刷新页面，解决路由返回主界面跳转不刷新的问题
    watch(router.currentRoute, () => {
      if (router.currentRoute.value.path === '/ai-center/my-task') {
        getList()
      }
    })
    watch(addTaskVisible, () => {
      if (!addTaskVisible.value) {
        if (!timerRunning.value) {
          clearInterval(timer.value)
          runEveryTenSec()
        }
      }
    })
    // 每隔10秒更新主列表数据
    const runEveryTenSec = () => {
      if (!timerRunning.value) {
        timerRunning.value = true
        timer.value = setInterval(() => {
          getList()
          timerRunning.value = false
        }, 10000)
      }
    }
    // 销毁定时器
    onBeforeUnmount(() => {
      clearInterval(timer.value)
    })
    // 过滤菜单组件表单参数，搜索表单需将所有可查询参数写好。数组类型默认值为null，避免ant多选菜单出现空白tag问题
    const dynamicForm = ref([
      {
        label: t('myTask.taskId'),
        name: 'taskId',
        type: 'input',
        span: 6,
        isShow: true
      },
      {
        label: t('myTask.taskName'),
        name: 'taskName',
        type: 'input',
        span: 6,
        isShow: true
      },
      {
        label: t('myTask.runningHost'),
        name: 'calculateDeviceId',
        type: 'select',
        options: [],
        optionKey: 'id',
        optionLabel: 'ip',
        optionApi: '/maintenance/calculateDevice/queryOnlineCalculateDeviceIp',
        optionMethod: 'get',
        optionType: 'Api',
        span: 6,
        isShow: true,
        mode: 'combobox'
      },
      {
        label: t('myTask.costType'),
        name: 'costTypeS',
        type: 'select',
        span: 6,
        optionType: 'Enum',
        optionParams: 'CostTypeEnum',
        options: [],
        optionKey: 'value',
        optionLabel: 'name',
        isShow: true,
        mode: 'multiple'
      },
      {
        label: t('myTask.taskStatus'),
        name: 'statusS',
        type: 'select',
        options: [],
        optionKey: 'value',
        optionLabel: 'name',
        optionType: 'Enum',
        optionParams: 'AirestTaskStatusEnum',
        span: 6,
        isShow: true,
        mode: 'multiple'
      },
      // {
      //   label: t('myTask.createBy'),
      //   name: 'userId',
      //   type: 'select',
      //   options: [],
      //   optionKey: 'id',
      //   optionLabel: 'phone',
      //   optionApi: '/user/admin/queryUserDict',
      //   optionMethod: 'get',
      //   optionType: 'Api',
      //   span: 6,
      //   isShow: true,
      //   mode: 'combobox'
      // },
      {
        label: t('myTask.columns.region'),
        name: 'regionS',
        type: 'select',
        options: [],
        optionKey: 'id',
        optionLabel: 'region',
        optionApi: '/maintenance/regionManage/getRegionName',
        optionMethod: 'post',
        optionType: 'Api',
        span: 6,
        isShow: true,
        mode: 'multiple'
      },
      {
        label: t('myTask.columns.hostStatus'),
        name: 'hostStatusS',
        type: 'select',
        span: 6,
        optionType: 'Enum',
        optionParams: 'MaintenanceHostStatusEnum',
        options: [],
        optionKey: 'value',
        optionLabel: 'name',
        isShow: true,
        mode: 'multiple'
      },
      {
        label: t('myTask.columns.runnindMode'),
        name: 'runnindModeS',
        type: 'select',
        span: 6,
        optionType: 'Enum',
        optionParams: 'AirestRunnindModeEnum',
        options: [],
        optionKey: 'value',
        optionLabel: 'name',
        isShow: true,
        mode: 'multiple'
      }
    ])
    const isAllHidden: any = ref(false)
    const filterColumns: any = ref([])
    const filtersKeywords:any = ref([])
    const handleFilterOptions = async () => {
      await dynamicForm.value.map((v:any) => {
        columns.value.map((s:any) => {
          if (v.type === 'select' && v.name === s.searchIndex) {
            s.filters = []
            v.options.map((item:any) => {
              const obj = {
                label: item[v.optionLabel],
                value: item[v.optionKey]
              }
              s.filters.push(obj)
            })
            s.optionLabel = v.optionLabel
            s.optionKey = v.optionKey
          }
        })
      })
    }
    const handleFilterSingleChange = (e:any, column:any) => {
      (searchForm as any)[column.searchIndex] = e.target.value
      searchForm.pageReq.pageNum = 1
      filtersKeywords.value = searchForm
      handleSearch()
    }
    const onCheckAllChangeFilter = (e:any, column:any) => {
      var arr:any[] = []
      column.filters.map((v:any) => {
        arr.push(v.value)
      })
      Object.assign(column.state, {
        checkedList: e.target.checked ? arr : [],
        indeterminate: false
      })
      columns.value.map((v:any) => {
        if (v.searchIndex === column.searchIndex) {
          v.state = column.state
        }
      })
    }
    const handleFilterVisible = async (column:any) => {
      await handleFilterOptions()
      columns.value.map((v:any) => {
        if (v.searchIndex === column.searchIndex && column.filterMultiple) {
          v.filterDropdownOpen = !v.filterDropdownOpen
        }
      })
    }
    const handleCheckedFilterChange = (e:any) => {
      var checkedLen = e.length
      columns.value.map((item:any) => {
        if (item.state) {
          item.state.indeterminate = !!checkedLen && checkedLen < item.filters.length
          item.state.checkAll = checkedLen === item.filters.length
        }
      })
    }
    const handleFilterMultipleChange = (checkedList:any, column:any) => {
      (searchForm as any)[column.searchIndex] = checkedList
      searchForm.pageReq.pageNum = 1
      filtered[column.searchIndex] = checkedList
      filtersKeywords.value[column.searchIndex] = filtered[column.searchIndex]
      filtersKeywords.value = searchForm // 双向赋值父子组件联动
      handleCancelFilters(column)
    }
    const handleCancelFilters = (column:any) => {
      columns.value.map((v:any) => {
        if (v.searchIndex === column.searchIndex) {
          v.filterDropdownOpen = !v.filterDropdownOpen
        }
      })
      handleSearch()
    }
    const handleSearchFilters = (params:any) => {
      selectedIds.value = []
      selectRowData.value = ''
      if (Object.keys(params).length === 0) {
        handleClearFilterTag(params)
      } else {
        for (var i in searchForm) {
          if (i !== 'pageReq') {
            (searchForm as any)[i] = params[i]
            filtered[i] = params[i]
          }
        }
        filtersKeywords.value = searchForm
        searchForm.pageReq.pageNum = 1
        getList()
      }
    }
    const handleClearFilterTag = (params:any) => {
      selectedIds.value = []
      selectRowData.value = ''
      if (Object.keys(params).length === 0) {
        dynamicForm.value.map((s) => {
          for (var i in searchForm) {
            if (i !== 'pageReq' && s.name === i) {
              if (s.type === 'select' && s.mode === 'multiple') {
                (searchForm as any)[s.name] = []
                filtered[s.name] = []
              } else {
                (searchForm as any)[s.name] = null
                filtered[s.name] = null
              }
            }
            if (i === 'startTime' || i === 'endTime') {
              (searchForm as any)[i] = null
              filtered[i] = null
            }
          }
        })
      } else {
        // 清除主列表筛选表头多选表单勾选值
        columns.value.map((v:any) => {
          if (v.searchIndex === params.key && v.state) {
            if (v.state.checkedList.length > 0) {
              v.state.checkedList = []
              v.state.indeterminate = false
            }
          }
        })
        dynamicForm.value.map((s:any) => {
          for (const i in searchForm) {
            if (params.type === 'dateRange' && (i === 'startTime' || i === 'endTime')) {
              (searchForm as any)[i] = null
              filtered[i] = null
            } else if (i !== 'pageReq' && params.key === i && s.name === params.key) {
              if (s.type === 'select' && s.mode === 'multiple') {
                (searchForm as any)[s.name] = []
                filtered[s.name] = []
              } else {
                (searchForm as any)[s.name] = null
                filtered[s.name] = null
              }
            }
          }
        })
      }
      filtersKeywords.value = searchForm
      searchForm.pageReq.pageNum = 1
      getList()
    }
    return {
      tableHeight,
      rules,
      handleJump,
      stopLoading,
      handleStop,
      stopDialogVisible,
      stopTips,
      runEveryTenSec,
      stopContainer,
      handleMenuClick,
      handleNoCardModel,
      handleFeeChange,
      packageVisible,
      expansionVisible,
      bucketId,
      expansionPrice,
      onBtnState,
      handleConfirmExpansion,
      title,
      balance,
      dataSource,
      columns,
      filterColumns,
      handleResizeColumn,
      selectedIds,
      onSelectChange,
      total,
      searchForm,
      handlePageSize,
      costFlag,
      handleChangeStatus,
      handleStart,
      handleCopy,
      handleJumpService,
      handleRealNameAuth,
      handleJumpto,
      editableData,
      handleSaveNote,
      handleEditNote,
      handleChangeAll,
      handleSetColumns,
      handleAdd,
      dynamicForm,
      isAllHidden,
      filtered,
      handleFilterSingleChange,
      onCheckAllChangeFilter,
      handleCancelFilters,
      handleFilterVisible,
      handleFilterMultipleChange,
      handleClearFilterTag,
      handleCheckedFilterChange,
      handleSearchFilters,
      filtersKeywords,
      addTaskVisible
    }
  }
})

