import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4841cbe2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container container-image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_component_a_tab_pane, {
          tab: _ctx.$t('image.systemImage'),
          key: 1
        }, null, 8, ["tab"])),
        (_openBlock(), _createBlock(_component_a_tab_pane, {
          tab: _ctx.$t('image.myImage'),
          key: 2
        }, null, 8, ["tab"]))
      ]),
      _: 1
    }, 8, ["activeKey"]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent)))
  ]))
}