import http from '@/utils/http'
import { SearchFormParamsFace } from '@/interface/rechargeInterface'
interface Response {
  [propName: string]: any;
}
const getRechargeList = (data:SearchFormParamsFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/cost/manage/recharge/list',
    data: data
  })
}
const exportRecharge = (data:any) => {
  return http({
    method: 'post',
    url: '/cost/manage/recharge/export',
    headers: {
      contentType: 'application/octet-stream'
    },
    responseType: 'blob',
    data: data
  })
}
const weChatPay = (price:number): Promise<Response> => {
  return http({
    method: 'get',
    url: `/cost/pay/weChat/${price}/`
  })
}
export {
  getRechargeList,
  exportRecharge,
  weChatPay
}
