import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a674bab6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-form" }
const _hoisted_2 = { class: "search-box" }
const _hoisted_3 = {
  key: 4,
  style: {"margin-left":"16px"}
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 5,
  style: {"margin-left":"16px"}
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 6,
  style: {"margin-left":"16px"}
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 7,
  style: {"margin-left":"16px"}
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 8,
  style: {"margin-left":"16px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_input_group = _resolveComponent("a-input-group")!
  const _component_a_tag = _resolveComponent("a-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_input_group, {
        compact: "",
        class: "d-search-input"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            placeholder: _ctx.$t('maintenanceCenter.selectPlaceholder'),
            value: _ctx.searchKey,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchKey) = $event)),
            onChange: _cache[1] || (_cache[1] = (value, option) => _ctx.handleClearSearchKey(value, option, _ctx.searchKey)),
            class: "d-select"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formList, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.name,
                  value: item.label
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      (_ctx.searchKey == item.label)
                        ? (_openBlock(), _createBlock(_component_svg_icon, {
                            key: 0,
                            style: {"width":"14px","height":"14px","margin-right":"5px","margin-bottom":"-3px"},
                            "icon-class": "sousuo"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createTextVNode(" " + _toDisplayString(item.label), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["placeholder", "value"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formList, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.label
            }, [
              (item.type == 'select' && item.label == _ctx.searchKey)
                ? (_openBlock(), _createBlock(_component_a_select, {
                    key: 0,
                    placeholder: _ctx.$t('maintenanceCenter.selectPlaceholder'),
                    allowClear: "",
                    value: _ctx.searchForm[item.name],
                    "onUpdate:value": ($event: any) => ((_ctx.searchForm[item.name]) = $event),
                    class: "d-select-input",
                    mode: item.mode,
                    onChange: (value, option) => _ctx.handleSelect(value, option, item.name)
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (items) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: items[item.optionLabel],
                          value: items[item.optionKey]
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(items[item.optionLabel]), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["placeholder", "value", "onUpdate:value", "mode", "onChange"]))
                : _createCommentVNode("", true),
              (item.type == 'input' && item.label == _ctx.searchKey && item.isShow)
                ? (_openBlock(), _createBlock(_component_a_input, {
                    key: 1,
                    placeholder: _ctx.$t('maintenanceCenter.inputPlaceholder'),
                    value: _ctx.searchForm[item.name],
                    "onUpdate:value": ($event: any) => ((_ctx.searchForm[item.name]) = $event),
                    valueModifiers: { trim: true },
                    allowClear: "",
                    class: "d-input"
                  }, null, 8, ["placeholder", "value", "onUpdate:value"]))
                : _createCommentVNode("", true),
              (item.type == 'dateRange' && item.label == _ctx.searchKey && item.isShow)
                ? (_openBlock(), _createBlock(_component_a_range_picker, {
                    key: 2,
                    format: "YYYY-MM-DD HH:mm:ss",
                    "value-format": "YYYY-MM-DD HH:mm:ss",
                    value: _ctx.timeRange,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.timeRange) = $event)),
                    onChange: _ctx.timeRangeChange,
                    "range-separator": "-",
                    "start-placeholder": _ctx.$t('maintenanceCenter.startPlaceholder'),
                    "end-placeholder": _ctx.$t('maintenanceCenter.endPlaceholder'),
                    "show-time": "",
                    style: {"width":"350px"}
                  }, null, 8, ["value", "onChange", "start-placeholder", "end-placeholder"]))
                : _createCommentVNode("", true),
              (item.type == 'dateTime' && item.label == _ctx.searchKey && item.isShow)
                ? (_openBlock(), _createBlock(_component_a_date_picker, {
                    key: 3,
                    style: {"width":"250px"},
                    value: _ctx.searchForm[item.name],
                    "onUpdate:value": ($event: any) => ((_ctx.searchForm[item.name]) = $event),
                    format: "YYYY-MM-DD",
                    "value-format": "YYYY-MM-DD HH:mm:ss",
                    showTime: item.showTime,
                    onChange: ($event: any) => (_ctx.handleChange($event as any, item.name as any))
                  }, null, 8, ["value", "onUpdate:value", "showTime", "onChange"]))
                : _createCommentVNode("", true),
              (item.type == 'input' && item.label == _ctx.searchKey)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _createElementVNode("button", {
                      class: "stn-primary",
                      onClick: ($event: any) => (_ctx.handleInput(_ctx.searchForm[item.name], item.name))
                    }, _toDisplayString(_ctx.$t("myTask.search")), 9, _hoisted_4)
                  ]))
                : _createCommentVNode("", true),
              (item.type == 'dateTime' && item.label == _ctx.searchKey)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createElementVNode("button", {
                      class: "stn-primary",
                      onClick: ($event: any) => (_ctx.handleInput(_ctx.searchForm[item.name], item.name))
                    }, _toDisplayString(_ctx.$t("myTask.search")), 9, _hoisted_6)
                  ]))
                : _createCommentVNode("", true),
              (
            item.type == 'select' && item.label == _ctx.searchKey && item.mode == 'multiple'
          )
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    _createElementVNode("button", {
                      class: "stn-primary",
                      onClick: ($event: any) => (_ctx.handleSelectMultipleSearch(_ctx.searchForm[item.name], item.name))
                    }, _toDisplayString(_ctx.$t("myTask.search")), 9, _hoisted_8)
                  ]))
                : _createCommentVNode("", true),
              (
            item.type == 'select' && item.label == _ctx.searchKey && item.mode == 'combobox'
          )
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                    _createElementVNode("button", {
                      class: "stn-primary",
                      onClick: ($event: any) => (_ctx.handleSelectSingleSearch(_ctx.searchForm[item.name], item.name))
                    }, _toDisplayString(_ctx.$t("myTask.search")), 9, _hoisted_10)
                  ]))
                : _createCommentVNode("", true),
              (item.type == 'dateRange' && item.label == _ctx.searchKey)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                    _createElementVNode("button", {
                      class: "stn-primary",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.timeRangeChange()))
                    }, _toDisplayString(_ctx.$t("myTask.search")), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      })
    ]),
    (!_ctx.clearFilterText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "search-tag",
          style: _normalizeStyle({ paddingTop: _ctx.tagsList.length == 0 ? '0' : '16px' })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagsList, (tag, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: tag.label
            }, [
              (tag.value)
                ? (_openBlock(), _createBlock(_component_a_tag, {
                    key: 0,
                    closable: "",
                    onClose: ($event: any) => (_ctx.handleClose(tag, index)),
                    class: "filter-tag"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(tag.label + ":" + tag.value), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClose"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          _createElementVNode("span", {
            class: "clear-text",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.clearAllTags && _ctx.clearAllTags(...args)))
          }, "清除")
        ], 4))
      : _createCommentVNode("", true)
  ]))
}