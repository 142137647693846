import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb317c34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_nav = _resolveComponent("header-nav")!
  const _component_sider_menu = _resolveComponent("sider-menu")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_breadcrumb_nav = _resolveComponent("breadcrumb-nav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_nav),
    _createVNode(_component_a_layout, { class: "out-layout" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_sider, {
          class: "sider-menu",
          trigger: null,
          collapsible: "",
          collapsed: _ctx.collapsed,
          "onUpdate:collapsed": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.collapsed) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_sider_menu, {
              menu: _ctx.menuList,
              collapsed: _ctx.collapsed,
              "onUpdate:collapsed": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.collapsed) = $event))
            }, null, 8, ["menu", "collapsed"]),
            _createElementVNode("div", {
              class: "trigger-box",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCollasped && _ctx.handleCollasped(...args)))
            }, [
              _createVNode(_component_svg_icon, {
                "icon-class": !_ctx.collapsed ? 'ic_shouqi' : 'ic_dakai'
              }, null, 8, ["icon-class"])
            ])
          ]),
          _: 1
        }, 8, ["collapsed"]),
        _createVNode(_component_a_layout, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_layout_content, { class: "content" }, {
              default: _withCtx(() => [
                (_ctx.$route.name !== 'ControlConsole')
                  ? (_openBlock(), _createBlock(_component_breadcrumb_nav, {
                      key: 0,
                      "breadcrumb-list": _ctx.breadcrumbList
                    }, null, 8, ["breadcrumb-list"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_router_view)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}