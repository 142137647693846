
import { defineComponent } from 'vue'
import { useSubUser } from './subuser'
import DomainName from './components/domain-name/DomainName.vue'
import UserManagement from './components/user-management/UserManagement.vue'

export default defineComponent({
  components: {
    DomainName,
    UserManagement
  },
  setup () {
    const {
      activeKey,
      currentComponent
    } = useSubUser()
    return {
      activeKey,
      currentComponent
    }
  }
})
