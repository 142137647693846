
import { defineComponent } from 'vue'
import { useStudent } from './student-module'
import { DeleteOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    DeleteOutlined
  },
  setup () {
    const {
      form,
      formInfo,
      handleUpload,
      fileList,
      beforeUpload,
      checkCharacters,
      checkCreditCode,
      isChecked,
      handleChangeTime,
      handleSubmit
    } = useStudent()
    return {
      form,
      formInfo,
      handleUpload,
      fileList,
      beforeUpload,
      checkCharacters,
      checkCreditCode,
      isChecked,
      handleChangeTime,
      handleSubmit
    }
  }
})
