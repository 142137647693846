import {
  queryDomainNameApi,
  addDomainNameApi,
  queryDomainNameDetailApi,
  modifyDomainNameApi,
  delDomainNameApi
} from '@/api/subuser'
import { DynamicConditionSpace } from '@/interface/condition'
import { SubuserSpace } from '@/interface/userCenter'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import type { TableColumnTypeConfig } from '@/interface/table'
import type { FormInstance } from 'ant-design-vue'
import { Modal, message } from 'ant-design-vue'
import { WebsiteValidator } from '@/interface/validator'
import { Rule } from 'ant-design-vue/es/form'
import { useI18n } from 'vue-i18n'

export const useDomainName = () => {
  const { t } = useI18n()
  const currentCondition = ref('realm')
  const filterOptions = ref<DynamicConditionSpace.FilterConditionFace[]>([])
  const conditionList = ref<DynamicConditionSpace.ConditionFace[]>([
    {
      name: t('subUserManagement.realmNameMessage'),
      key: 'realm',
      type: 'AInput',
      value: '',
      placeholder: t('workOrder.pleaseEnter')
    }
  ])
  const handleSelectChange = (params: DynamicConditionSpace.ConditionFace) => {
    conditionList.value.forEach(item => {
      if (item.key === params.type) {
        if (item.type === 'ASelect') {
          const arr = item.options?.filter(item => item.value === params.value)[0]
          item.label = arr?.label
        }
        item.value = params.value
      }
    })
  }

  const pageParams: { pageNum: number; pageSize: number } = reactive({
    pageNum: 1,
    pageSize: 10
  })

  const conditionParams = computed<SubuserSpace.DomainNameFace>(() => {
    const map = new Map()
    const searchForm: any = {
      pageNum: pageParams.pageNum,
      pageSize: pageParams.pageSize
    }
    conditionList.value.forEach(item => {
      map.set(item.key, item.value)
    })
    for (const [k, v] of map) {
      searchForm[k] = v
    }
    return searchForm
  })

  const dataSource = ref<any[]>([])
  const total = ref<number>(0)
  const columns = ref<TableColumnTypeConfig[]>([
    {
      title: t('subUserManagement.realmNameMessage'),
      dataIndex: 'realm',
      isShow: true,
      disabled: true
    },
    {
      title: t('subUserManagement.status'),
      dataIndex: 'status',
      align: 'center',
      isShow: true,
      disabled: false
    },
    {
      title: t('subUserManagement.createBy'),
      dataIndex: 'createBy',
      isShow: true,
      disabled: false
    },
    {
      title: t('subUserManagement.createTime'),
      dataIndex: 'createTime',
      isShow: true,
      disabled: false
    },
    {
      title: t('subUserManagement.operate'),
      dataIndex: 'operate',
      align: 'center',
      isShow: true,
      disabled: true
    }
  ])

  const queryDomainName = () => {
    queryDomainNameApi(conditionParams.value).then(res => {
      total.value = res.data.total
      dataSource.value = res.data.rows
    })
  }

  const handleQuery = () => {
    const selectConditionParams = conditionList.value.filter(item => item.key === currentCondition.value)[0]
    if (selectConditionParams.value === '' || selectConditionParams.value === undefined || (selectConditionParams.value as []).length === 0) {
      queryDomainName()
    } else {
      if (filterOptions.value.length > 0) {
        const index = filterOptions.value.findIndex((item) => item.key === selectConditionParams.key)
        if (index === -1) {
          filterOptions.value.push({
            name: selectConditionParams.name,
            type: selectConditionParams.type,
            label: selectConditionParams.label as string,
            key: selectConditionParams.key,
            value: selectConditionParams.value
          })
        } else {
          filterOptions.value.splice(index, 1, {
            name: selectConditionParams.name,
            type: selectConditionParams.type,
            label: selectConditionParams.label as string,
            key: selectConditionParams.key,
            value: selectConditionParams.value
          })
        }
      } else {
        filterOptions.value.push({
          name: selectConditionParams.name,
          type: selectConditionParams.type,
          label: selectConditionParams.label as string,
          key: selectConditionParams.key,
          value: selectConditionParams.value
        })
      }
      queryDomainName()
    }
  }

  const handleDelOption = (params: {index: number, key: string}) => {
    filterOptions.value.splice(params.index, 1)
    conditionList.value.forEach((item: any) => {
      if (item.key === params.key) {
        item.value = ''
      }
    })
    queryDomainName()
  }

  const handleClear = () => {
    filterOptions.value = []
    conditionList.value.forEach(item => {
      item.value = ''
    })
    queryDomainName()
  }

  const handleChangePage = (params: { pageNum: number; pageSize: number }) => {
    pageParams.pageNum = params.pageNum
    pageParams.pageSize = params.pageSize
    queryDomainName()
  }

  const visible = ref<boolean>(false)
  const title = ref<string>('')
  const form = ref<FormInstance>()
  const addForm = reactive({
    realm: '',
    status: 0
  })
  const websiteValidator = new WebsiteValidator()
  const checkReamlName = (_rule: Rule, value: string) => {
    if (value === '') {
      return Promise.reject(new Error(t('subUserManagement.realmNameMessageInput')))
    } else {
      if (websiteValidator.isAcceptable(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error(t('subUserManagement.realmNameLimit')))
      }
    }
  }

  const handleAdd = () => {
    title.value = t('add.add')
    visible.value = true
  }

  const domainNameId = ref<string>('')
  const handleModify = (id: string) => {
    title.value = t('add.edit')
    visible.value = true
    domainNameId.value = id
    queryDomainNameDetailApi(id).then(res => {
      addForm.realm = res.data.realm
      addForm.status = res.data.status
    })
  }

  const handleChoostTools = (menuItem: any) => {
    const [type, id] = menuItem.key.split(',')
    if (type === 'del') {
      Modal.confirm({
        title: t('del.title'),
        content: t('del.isDel'),
        centered: true,
        onOk: () => {
          delDomainNameApi(id).then(() => {
            message.success(t('del.delSuccess'), 0.5).then(() => {
              queryDomainName()
            })
          })
        }
      })
    }
  }

  const handleSubmit = () => {
    if (form.value) {
      form.value.validate().then(() => {
        if (title.value === t('add.add')) {
          addDomainNameApi(addForm).then(() => {
            message.success(t('subUserManagement.addSuccess'), 0.5).then(() => {
              visible.value = false
              queryDomainName()
            })
          })
        } else {
          const params = {
            ...addForm,
            id: domainNameId.value
          }
          modifyDomainNameApi(params).then(() => {
            message.success(t('subUserManagement.editSuccess'), 0.5).then(() => {
              visible.value = false
              queryDomainName()
            })
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }

  watch(visible, () => {
    if (!visible.value) {
      if (form.value) {
        form.value.resetFields()
      }
    }
  })

  onMounted(() => {
    queryDomainName()
  })

  return {
    conditionList,
    currentCondition,
    filterOptions,
    dataSource,
    total,
    columns,
    handleQuery,
    handleSelectChange,
    handleDelOption,
    handleClear,
    handleChangePage,
    visible,
    title,
    form,
    handleSubmit,
    addForm,
    checkReamlName,
    handleAdd,
    handleModify,
    handleChoostTools
  }
}
