import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: 746,
    open: _ctx.taskRenewalVisible,
    "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.taskRenewalVisible) = $event)),
    title: _ctx.$t('addTask.taskRenewal'),
    centered: "",
    onOk: _ctx.handleTaskRenewal,
    "ok-button-props": {
      disabled: _ctx.changeTaskBtnState,
    }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        "label-col": {
      style: {
        width: '126px',
      },
    },
        ref: "changeFeeForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('addTask.orderDiscount')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        style: {"width":"220px"},
                        value: _ctx.computedDiscountPirce,
                        disabled: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('addTask.orderPrice')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        style: {"width":"220px"},
                        value: _ctx.computedTotalPrice,
                        disabled: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('addTask.usageDuration'),
                    rules: [
            {
              required: true,
              message: _ctx.$t('addTask.usageDurationPlaceholder'),
            },
          ]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        min: 1,
                        style: {"width":"220px"},
                        value: _ctx.changeFeeParams.rentTime,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.changeFeeParams.rentTime) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('addTask.orderOriginalPrice')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        style: {"width":"220px"},
                        value: _ctx.computedOrderPrice,
                        disabled: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["open", "title", "onOk", "ok-button-props"]))
}