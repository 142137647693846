import http from '@/utils/http'
import { SearchFormParamsFace, OrderClaimParamsFace, SearchBillStateParamsFace } from '@/interface/orderInterface'
interface Response {
  [propName: string]: any;
}
const getOrderList = (data:SearchFormParamsFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/cost/manage/order/list',
    data: data
  })
}
const exportOrder = (data:any): Promise<Response> => {
  return http({
    method: 'post',
    url: '/cost/manage/order/export',
    headers: {
      contentType: 'application/octet-stream'
    },
    responseType: 'blob',
    data: data
  })
}
// 赔付
const getClaim = (data:OrderClaimParamsFace) => {
  return http({
    method: 'post',
    url: '/cost/owner/claim',
    data: data
  })
}
// 订单详细信息
const getOrderDetail = (orderId:string) => {
  return http({
    method: 'get',
    url: `/cost/manage/order/detail/${orderId}`
  })
}
// 分页条件查询账单信息
const getBillList = (data:SearchBillStateParamsFace) => {
  return http.request({
    method: 'post',
    url: '/cost/manage/bill/list',
    data: data
  })
}
export {
  getOrderList,
  exportOrder,
  getClaim,
  getOrderDetail,
  getBillList
}
