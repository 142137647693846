import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-358c4fac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container inviting-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "invite-step" }
const _hoisted_4 = { class: "step" }
const _hoisted_5 = { class: "step" }
const _hoisted_6 = { class: "step" }
const _hoisted_7 = { class: "link-result" }
const _hoisted_8 = { class: "link-box" }
const _hoisted_9 = { class: "input-box" }
const _hoisted_10 = { class: "result-box" }
const _hoisted_11 = { class: "reward-box" }
const _hoisted_12 = { class: "content" }
const _hoisted_13 = { class: "span-title" }
const _hoisted_14 = { class: "num" }
const _hoisted_15 = { class: "success-invite" }
const _hoisted_16 = { class: "content" }
const _hoisted_17 = { class: "span-title" }
const _hoisted_18 = { class: "num" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_st_button = _resolveComponent("st-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("userCenter.inviteTips")), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_svg_icon, { "icon-class": "01" }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("userCenter.inviteTipsOne")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_svg_icon, { "icon-class": "02" }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("userCenter.inviteTipsTwo")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_svg_icon, { "icon-class": "03" }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("userCenter.inviteTipsThree")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.$t("userCenter.ownLink")), 1),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_a_input, {
            value: _ctx.info.url,
            disabled: ""
          }, null, 8, ["value"]),
          _createVNode(_component_st_button, {
            type: "primary",
            onClick: _ctx.handleCopy
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("userCenter.copy")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("userCenter.guideTips")), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_svg_icon, { "icon-class": "ic_xingzhuang" }),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("userCenter.gainMoney")), 1),
            _createElementVNode("span", _hoisted_14, [
              _createTextVNode(" ￥"),
              _createElementVNode("b", null, _toDisplayString(_ctx.info.money), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_svg_icon, { "icon-class": "ic_xingzhuang" }),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("userCenter.gainInvite")), 1),
            _createElementVNode("span", _hoisted_18, [
              _createElementVNode("b", null, _toDisplayString(_ctx.info.count), 1),
              _createTextVNode("人 ")
            ])
          ])
        ])
      ])
    ])
  ]))
}