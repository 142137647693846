
import { defineComponent, computed, reactive, ref, nextTick, onMounted } from 'vue'
import touxiang from '@/assets/img/ic_touxiang.png'
import { permissionStore } from '@/store/permission'
import FormInfo from './components/FormInfo.vue'
import { queryUserApi, queryUserPortraitApi, setUserApi, setUserPortraitApi } from '@/api/user-center'
import { uploadPictureApi } from '@/api/upload'
import { Modal, message } from 'ant-design-vue'
import type { UserCenter } from '@/interface/userCenter'
import { PictureValidator } from '@/interface/validator'
import { VueCropper } from 'vue-cropper'
import 'vue-cropper/dist/index.css'

export default defineComponent({
  components: {
    FormInfo,
    VueCropper
  },
  setup () {
    const permission = permissionStore()
    const checkPictureSuffix = new PictureValidator()

    const userProfilePicture = ref<string>('')
    const queryUserPortrait = ():void => {
      queryUserPortraitApi().then(res => {
        userProfilePicture.value = res.data ? `/api/user${res.data}` : touxiang
      })
    }

    const userId = ref('')
    const queryUser = ():void => {
      queryUserApi().then((res) => {
        userId.value = res.data.id
        Object.assign(accountInfo, {
          username: res.data.username,
          schooling: res.data.schooling,
          occupation: res.data.occupation,
          studyDirection: res.data.studyDirection,
          trade: res.data.trade,
          awards: res.data.awards,
          country: res.data.country
        })
      })
    }

    onMounted(() => {
      queryUser()
      queryUserPortrait()
    })

    const pictureVisible = ref(false)
    const cropper = ref<any>()
    const fileName = ref<string>('')
    const avatarFile = ref<string>()
    const selectFile = (e: Event):void => {
      const files = (e.target as HTMLInputElement).files
      if (files && files.length !== 0) {
        const file = files[0]
        const name = file.name
        fileName.value = name
        if (!checkPictureSuffix.isAcceptable(name)) {
          message.error('图片格式错误')
        } else {
          pictureVisible.value = true
          const blob = new Blob([file], { type: file.type })
          avatarFile.value = URL.createObjectURL(blob)
        }
      }
    }
    const handleUploadPicture = ():void => {
      cropper.value.getCropBlob((data: Blob) => {
        const file = new File([data], fileName.value, { type: data.type })
        const formData = new FormData()
        formData.append('file', file)
        uploadPictureApi(formData).then(res => {
          setUserPortraitApi({
            portrait: res.data
          }).then(() => {
            queryUserPortrait()
            permission.setMenu()
            pictureVisible.value = false
          })
        })
      })
    }

    const inputFile = ref<any>()
    const handleChangeUserProfilePicture = (): void => {
      inputFile.value.click()
    }

    const handleCopy = ():void => {
      const input = document.createElement('input')
      input.value = userId.value
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      message.success('已复制')
    }

    const userLevel = computed<string>(() => {
      const gradeName = permission.$state.accountInfo.gradeName.toLowerCase()
      return `ic_${gradeName}`
    })

    const editUsernameFlag = ref<boolean>(false)
    const username = ref<string>('')
    const userInput = ref<any>()
    const handleEditUsername = ():void => {
      editUsernameFlag.value = !editUsernameFlag.value
      if (editUsernameFlag.value) {
        nextTick(() => {
          userInput.value.focus()
        })
      }
    }
    const handleBlur = ():void => {
      if (username.value !== '') {
        Modal.confirm({
          title: '系统提示',
          content: '是否确认修改用户名？',
          centered: true,
          onOk: () => {
            const params = {
              ...accountInfo,
              username: username.value
            }
            setUserApi(params).then(() => {
              message.success('修改成功', 0.5).then(() => {
                queryUser()
                editUsernameFlag.value = false
              })
            })
          }
        })
      } else {
        editUsernameFlag.value = false
      }
    }

    const accountInfo:UserCenter.AccountInfoFace = reactive({
      username: '',
      schooling: '',
      occupation: '',
      studyDirection: '',
      trade: '',
      awards: '',
      country: ''
    })

    const handleCommit = (value: any):void => {
      setUserApi(value).then(() => {
        message.success('修改成功', 0.5).then(() => {
          queryUser()
        })
      })
    }

    return {
      userProfilePicture,
      handleCopy,
      userLevel,
      handleCommit,
      accountInfo,
      editUsernameFlag,
      username,
      handleBlur,
      handleEditUsername,
      userInput,
      userId,
      selectFile,
      handleChangeUserProfilePicture,
      inputFile,
      pictureVisible,
      avatarFile,
      cropper,
      handleUploadPicture
    }
  }
})
