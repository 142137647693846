
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    color: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  setup (props) {
    return {
      props
    }
  }
})
