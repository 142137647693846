import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b1f5130"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container detail-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_work_order_info = _resolveComponent("work-order-info")!
  const _component_work_order_chat = _resolveComponent("work-order-chat")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_work_order_info, _normalizeProps(_guardReactiveProps(_ctx.workOrderInfo)), null, 16),
    _createVNode(_component_work_order_chat, {
      "create-time": _ctx.workOrderInfo.createTime,
      "work-explain": _ctx.workOrderInfo.workExplain,
      "file-path": _ctx.workOrderInfo.filePath,
      vos: _ctx.workOrderInfo.vos,
      onQuery: _ctx.queryDetailInfo
    }, null, 8, ["create-time", "work-explain", "file-path", "vos", "onQuery"])
  ]))
}