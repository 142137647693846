import { ref } from 'vue'
import axios from 'axios'
export const useCancelAjax = () => {
  const cancelFnList = ref<any[]>([])
  const createCancelList = () => {
    return new axios.CancelToken(function executor (c) {
      cancelFnList.value.push(c)
    })
  }

  return {
    cancelFnList,
    createCancelList
  }
}
