import { reactive, ref } from 'vue'
import type { SecuritySet } from '@/interface/userCenter'
import { FormInstance, Upload, message } from 'ant-design-vue'
import type { UploadProps } from 'ant-design-vue'
import { PictureValidator, CharactersValidator, CreditCodeValidator } from '@/interface/validator'
import { uploadPictureApi } from '@/api/upload'
import { studentAuthApi } from '@/api/user-center'
import { useI18n } from 'vue-i18n'

const useStudent = () => {
  const { t } = useI18n()
  const chineseValidator = new CharactersValidator()
  const creditCodeValidator = new CreditCodeValidator()
  const checkPictureSuffix = new PictureValidator()
  const form = ref<FormInstance>()
  const isChecked = ref(false)
  const formInfo: SecuritySet.StudentFormInfoFace = reactive({
    realName: '',
    certType: 1,
    certNumber: '',
    education: '',
    schoolLocation: '',
    schoolName: '',
    college: '',
    major: '',
    rangeTime: [],
    studentPicture: ''
  })

  const startSchoolTime = ref<string>('')
  const endSchoolTime = ref<string>('')
  const handleChangeTime = (_date: Date[], dateString: string[]) => {
    startSchoolTime.value = dateString[0]
    endSchoolTime.value = dateString[1]
  }

  const checkCharacters = async (rules: any, value: string) => {
    if (!value) {
      const { field } = rules
      if (field === 'education') {
        return Promise.reject(new Error(t('userCenter.educationErrMsg')))
      } else if (field === 'realName') {
        return Promise.reject(new Error(t('userCenter.realNameErrMsg')))
      } else if (field === 'schoolLocation') {
        return Promise.reject(new Error(t('userCenter.schoolLocationErrmsg')))
      } else if (field === 'schoolName') {
        return Promise.reject(new Error(t('userCenter.schoolErrMsg')))
      } else if (field === 'college') {
        return Promise.reject(new Error(t('userCenter.collageErrMsg')))
      } else if (field === 'major') {
        return Promise.reject(new Error(t('userCenter.majorErrMsg')))
      }
    } else {
      if (chineseValidator.isAcceptable(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error(t('userCenter.onlyChinese')))
      }
    }
  }

  const checkCreditCode = (_rule: any, value: string) => {
    if (value === '') {
      return Promise.reject(new Error(t('userCenter.cardErrMsg')))
    } else {
      const flag = creditCodeValidator.isAcceptable(value)
      if (flag) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error(t('userCenter.onlyUpN')))
      }
    }
  }

  const handleUpload = (params: {file: File}) => {
    fileList.value = []
    const { file } = params
    const formData = new FormData()
    formData.append('file', file)
    uploadPictureApi(formData).then(res => {
      formInfo.studentPicture = res.data
    })
  }

  const beforeUpload: UploadProps['beforeUpload'] = (file: File) => {
    if (!checkPictureSuffix.isAcceptable(file.name)) {
      message.error(t('userCenter.imgType'))
      return Upload.LIST_IGNORE
    }
  }

  const fileList = ref<UploadProps['fileList']>([])

  const handleSubmit = () => {
    if (form.value) {
      form.value.validate().then(() => {
        if (!formInfo.studentPicture) {
          message.error(t('userCenter.uploadStudent'))
        } else if (!isChecked.value) {
          message.error(t('userCenter.agreeReal'))
        } else {
          const params = {
            realName: formInfo.realName,
            certType: formInfo.certType,
            certNumber: formInfo.certNumber,
            education: formInfo.education,
            schoolLocation: formInfo.schoolLocation,
            schoolName: formInfo.schoolName,
            college: formInfo.college,
            major: formInfo.major,
            studentPicture: formInfo.studentPicture,
            startSchoolTime: startSchoolTime.value,
            endSchoolTime: endSchoolTime.value
          }
          studentAuthApi(params).then(() => {
            message.success(t('userCenter.okStudent'), 0.5)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }

  return {
    formInfo,
    form,
    handleUpload,
    beforeUpload,
    fileList,
    checkCharacters,
    checkCreditCode,
    isChecked,
    handleSubmit,
    handleChangeTime
  }
}

export {
  useStudent
}
