
import { defineComponent, onMounted, nextTick, ref } from 'vue'
import http from '@/utils/http'
import * as echarts from 'echarts'
import dayjs from 'dayjs'
import * as math from 'mathjs'
import { BigNumber } from 'bignumber.js'
import { useRouter, useRoute } from 'vue-router'
import StButton from '@/components/st-button/StButton.vue'
export default defineComponent({
  components: {
    StButton
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const currentTime = ref([dayjs().startOf('day'), dayjs()])
    const diskIOParams = ref('')
    const diskOptions:any = ref([])
    const netParams = ref('')
    const netOptions:any = ref([])
    const gpuParams = ref('')
    const gpuOptions:any = ref([])
    const caculateColorShadowList = ref([
      '#EEF5FF',
      '#F0FFFF',
      '#FCF2F3',
      '#FCF2FB',
      '#F2F8FC',
      '#FCF6F2',
      '#F8FEFE'
    ])
    const colorShadowList = ref([
      'rgba(115,176,239,0.46)',
      'rgba(115,233,239,0.46)',
      'rgba(213,144,246,0.46)',
      'rgba(239,115,147,0.46)',
      'rgba(144,173,246,0.46)',
      'rgba(239,164,115,0.46)',
      'rgba(115,233,239,0.46)'
    ])
    const colorOffSetListOne = ref([
      '#0E5FFF',
      '#83F9F4',
      '#E93FFF',
      '#FD829B',
      '#6BD1FD',
      '#F7AF76'
    ])
    const caculateYData = ref([])
    const memoryYData = ref([])
    const showSystemVisible = ref(true)
    const showSystemSpinning = ref(false)
    const showDiskVisible = ref(true)
    const showDiskSpinning = ref(false)
    const showNetVisible = ref(true)
    const showNetSpinning = ref(false)
    const diskYData = ref([])
    const netYData = ref([])
    const gpuYData = ref([])
    onMounted(() => {
      getSystemCharts()
      getDiskCharts()
      getNetCharts()
      getGpuCharts()
    })
    const handleBack = () => {
      router.go(-1)
    }
    function formatDate (d:any) {
      var date = new Date(d)
      var YY = date.getFullYear() + '-'
      var MM =
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-'
      var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var mm =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return YY + MM + DD + ' ' + hh + mm + ss
    }
    const getSystemCharts = async () => {
      var startTime = formatDate(currentTime.value[0])
      var endTime = formatDate(currentTime.value[1])
      var caculateYList:any = []
      var memoryYList:any = []
      showSystemSpinning.value = true
      const res = await http.request({
        method: 'post',
        url: '/maintenance/docker/client/monitor/getContainerSystem',
        data: {
          startTime: startTime,
          endTime: endTime,
          taskId: route.query.id
        }
      })
      if (res.data.data) {
        showSystemVisible.value = true
        res.data.data[0].cpu_usage.map((v:any) => {
          caculateYList.push(ceil(v.cpu_usage_percent, 2))
        })
        res.data.data[0].memory_usage.map((v:any) => {
          memoryYList.push(ceil(v.memory_usage_percent, 2))
        })
        memoryYData.value = memoryYList
        caculateYData.value = caculateYList
        await initCpuCharts(res.data.date)
        await initMemoryCharts(res.data.date)
      } else {
        showSystemVisible.value = false
      }
      showSystemSpinning.value = false
    }
    const getGpuCharts = async () => {
      var startTime = formatDate(currentTime.value[0])
      var endTime = formatDate(currentTime.value[1])
      var option:any = []
      const res = await http.request({
        method: 'post',
        url: '/maintenance/docker/client/monitor/getContainerGpu',
        data: {
          startTime: startTime,
          endTime: endTime,
          taskId: route.query.id
        }
      })
      if (res.data.groups) {
        res.data.groups.map((s:any, index:number) => {
          option.push({
            value: '运算卡' + s.minorNumber,
            id: '运算卡' + s.minorNumber,
            stats: s.stats,
            name: 'gpuMonitorCharts' + index
          })
          if (!gpuParams.value) {
            if (index === 0) {
              gpuParams.value = '运算卡' + s.minorNumber
            }
          }
          if ('运算卡' + s.minorNumber === gpuParams.value) {
            gpuYData.value = s.stats
          }
        })
        gpuOptions.value = option
        nextTick(() => {
          initGpuCharts(res.data.date)
        })
      }
    }
    const initGpuCharts = (dateTimeZone:any) => {
      const option:any = {
        backgroundColor: '#fff',
        dataZoom: [
          {
            show: true,
            bottom: '2%',
            start: 0,
            end: 100,
            handleIcon:
              'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAFAAAAAAV6Uf1AAABMElEQVQ4Ec2UL04DURDGZ162wWwVggtgCA4sAoHHgSXBo2pA9QAYOAEWHDeoqMY1GC6AQHUN2aTD+z2Y5nWzAbqsYJLNTGfm+zo7f1akJ9Emz+HYyqqqzxYix2q2a6qbUb9FPQsij2U5uJuMtWriVoj2Ru8nanprYlvNRP+toq+mdvF0vfHgPnT8k0/ZH9WXYnL/HQmZKR7zUv4XFpUqohJIMv/vTJVTr0zpyXxev/xUSRszrzkcDrbpWaCxXUggBgceOzAdjFxuzgvhyaXNR9zxRRpxjoj2wc5yBstIm48geHRgTzC6iuMDy9aVBJzjqWj2FyLHF6x9bNhRTjZ9XohZ7hFp85EBHt3fHrFM3A6s6wo4P+A0Z9ZcNVytQ0S+nwe43q5/hQjmrt8jsP9LPgCaLJ7zlI/MWgAAAABJRU5ErkJggg==',
            handleSize: 15,
            height: 13,
            handleStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '#fff',
              fontSize: 12
            },
            fillerColor: '#F5F8FF',
            borderColor: '#E5EAF6',
            brushSelect: false
          }
        ],
        legend: {
          right: '7%',
          top: '3%',
          itemHeight: 10,
          itemWidth: 10,
          textStyle: {
            borderWidth: 4,
            color: '#556677'
          }
        },

        grid: {
          top: '15%',
          left: '5%',
          right: '5%'
        },
        xAxis: [
          {
            type: 'category',
            data: dateTimeZone,
            axisLine: {
              lineStyle: {
                color: ['#EDEFF5'],
                width: 1,
                type: 'dashed'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#BFC2C2'
              },
              // 默认x轴字体大小
              fontSize: 12,
              // margin:文字到x轴的距离
              margin: 15
            },
            axisPointer: {
              label: {
                padding: [0, 0, 10, 0],
                margin: 15,
                fontSize: 12
              }
            },
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              lineStyle: {
                color: ['#EDEFF5'],
                width: 1,
                type: 'dashed'
              }
            },
            axisLabel: {
              color: '#BFC2C2'
            }
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,0)',
              shadowColor: 'rgba(0,0,0,0)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: '#fff',
          textStyle: {
            color: '#5c6c7c'
          },
          padding: [10, 10],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)',
          formatter: (params:any) => {
            var msg1 = ''
            var msg2 = ''
            var msg3 = ''
            var msg4 = ''
            params.map((s:any, index:number) => {
              if (s.seriesName === '功耗') {
                msg1 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  params[index].value +
                  'w' +
                  '<br>'
              } else if (s.seriesName === '温度') {
                msg2 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  params[index].value +
                  '℃' +
                  '<br>'
              } else if (s.seriesName === '运算卡使用率') {
                msg3 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  params[index].value +
                  '%' +
                  '<br>'
              } else if (s.seriesName === '显存使用率') {
                msg4 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  params[index].value +
                  '%'
              }
            })
            return params[0].name + '<br/>' + msg1 + msg2 + msg3 + msg4
          }
        },
        color: colorOffSetListOne.value,
        series: []
      }

      gpuOptions.value.map((s:any, index:number) => {
        var seriesLineData = []
        seriesLineData.push(
          {
            name: '功耗',
            type: 'line',
            data: s.stats.map((v:any) => {
              return v.PowerDraw
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[0]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[0]
            }
          },
          {
            name: '温度',
            type: 'line',
            data: s.stats.map((v:any) => {
              return v.Temperature
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[1]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[1]
            }
          },
          {
            name: '运算卡使用率',
            type: 'line',
            data: s.stats.map((v:any) => {
              return v.UtilizationGPU
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[2]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[2]
            }
          },
          {
            name: '显存使用率',
            type: 'line',
            data: s.stats.map((v:any) => {
              return v.UtilizationMemory
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[3]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[3]
            }
          }
        )
        option.series = seriesLineData
        var chartsId = 'gpuMonitorCharts' + index
        var myChart = echarts.init(document.getElementById(chartsId))
        nextTick(() => {
          myChart.resize()
          myChart.setOption(option)
          reloadCharts(myChart)
        })
      })
    }
    const getDiskCharts = async () => {
      var startTime = formatDate(currentTime.value[0])
      var endTime = formatDate(currentTime.value[1])
      var option:any = []
      showDiskSpinning.value = true
      const res = await http.request({
        method: 'post',
        url: '/maintenance/docker/client/monitor/getContainerDisk',
        data: {
          startTime: startTime,
          endTime: endTime,
          taskId: route.query.id
        }
      })
      if (res.data.data) {
        showDiskVisible.value = true
        res.data.data.map((s:any) => {
          option.push({ value: s.name, id: s.name })
        })

        diskOptions.value = option
        if (!diskIOParams.value) {
          diskIOParams.value = diskOptions.value[0].value
        }
        res.data.data.map((s:any) => {
          if (s.name === diskIOParams.value) {
            diskYData.value = s.stats
          }
        })
        await initDiskCharts(res.data.date)
      } else {
        showDiskVisible.value = false
      }
      showDiskSpinning.value = false
    }
    const initDiskCharts = async (dateTimeZone:any) => {
      const option = {
        backgroundColor: '#fff',
        dataZoom: [
          {
            show: true,
            bottom: '2%',
            start: 0,
            end: 100,
            handleIcon:
              'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAFAAAAAAV6Uf1AAABMElEQVQ4Ec2UL04DURDGZ162wWwVggtgCA4sAoHHgSXBo2pA9QAYOAEWHDeoqMY1GC6AQHUN2aTD+z2Y5nWzAbqsYJLNTGfm+zo7f1akJ9Emz+HYyqqqzxYix2q2a6qbUb9FPQsij2U5uJuMtWriVoj2Ru8nanprYlvNRP+toq+mdvF0vfHgPnT8k0/ZH9WXYnL/HQmZKR7zUv4XFpUqohJIMv/vTJVTr0zpyXxev/xUSRszrzkcDrbpWaCxXUggBgceOzAdjFxuzgvhyaXNR9zxRRpxjoj2wc5yBstIm48geHRgTzC6iuMDy9aVBJzjqWj2FyLHF6x9bNhRTjZ9XohZ7hFp85EBHt3fHrFM3A6s6wo4P+A0Z9ZcNVytQ0S+nwe43q5/hQjmrt8jsP9LPgCaLJ7zlI/MWgAAAABJRU5ErkJggg==',
            handleSize: 15,
            height: 13,
            handleStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '#fff',
              fontSize: 12
            },
            fillerColor: '#F5F8FF',
            borderColor: '#E5EAF6',
            brushSelect: false
          }
        ],
        legend: {
          right: '12%',
          top: '3%',
          itemHeight: 10,
          itemWidth: 10,
          textStyle: {
            borderWidth: 4,
            color: '#556677'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,0)',
              shadowColor: 'rgba(0,0,0,0)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: '#fff',
          textStyle: {
            color: '#5c6c7c'
          },
          padding: [10, 10],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)',
          formatter: (params:any) => {
            var msg1 = ''
            var msg2 = ''
            var msg3 = ''
            var msg4 = ''
            var msg5 = ''
            var msg6 = ''
            params.map((s:any, index:number) => {
              if (s.seriesName === '读取') {
                msg1 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  formatSeriesBytes(params[index].data[2]) +
                  '/s' +
                  '<br>'
              } else if (s.seriesName === '写入') {
                msg2 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  formatSeriesBytes(params[index].data[2]) +
                  '/s' +
                  '<br>'
              } else if (s.seriesName === '读次数') {
                msg3 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  params[index].value +
                  '次/s' +
                  '<br>'
              } else if (s.seriesName === '写次数') {
                msg4 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  params[index].value +
                  '次/s' +
                  '<br>'
              } else if (s.seriesName === '读延迟') {
                msg5 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  formatSeriesBytes(params[index].data[2]) +
                  '/s' +
                  '<br>'
              } else if (s.seriesName === '写延迟') {
                msg6 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  formatSeriesBytes(params[index].data[2]) +
                  '/s' +
                  '<br>'
              }
            })
            return params[0].name + '<br/>' + msg1 + msg2 + msg3 + msg4 + msg5 + msg6
          }
        },
        grid: {
          top: '14%'
        },
        xAxis: [
          {
            type: 'category',
            data: dateTimeZone,
            axisLine: {
              lineStyle: {
                color: ['#EDEFF5'],
                width: 1,
                type: 'dashed'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#BFC2C2',
              // 默认x轴字体大小
              fontSize: 12,
              // margin:文字到x轴的距离
              margin: 15
            },
            axisPointer: {
              label: {
                padding: [0, 0, 10, 0],
                margin: 15,
                fontSize: 12
              }
            },
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            name: '(KB/s)',
            type: 'value',
            nameTextStyle: {
              padding: [20, 30, 10, 0]
            },
            splitLine: {
              lineStyle: {
                color: ['#EDEFF5'],
                width: 1,
                type: 'dashed'
              }
            },
            axisLabel: {

              color: '#BFC2C2'

            }
          }
        ],
        color: colorOffSetListOne.value,
        series: [
          {
            name: '读取',
            type: 'line',
            data: diskYData.value.map((s:any) => {
              if (s.ReadTime === 0 || s.ReadBytes === 0) {
                s.readData = 0
                s.readAxis = 0
              } else {
                s.readData = ceil(
                  math.format(
                    math.divide(
                      math.bignumber(s.ReadBytes),
                      math.bignumber(s.ReadTime / 1000)
                    )
                  ),
                  2
                )
                s.readAxis = formatNumsBytes(s.readData)
              }
              return [s.CreateTime, s.readAxis, s.readData]
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[0]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[0]
            }
          },
          {
            name: '写入',
            type: 'line',
            data: diskYData.value.map((s:any) => {
              if (s.WriteTime === 0 || s.WriteBytes === 0) {
                s.writeData = 0
                s.writeAxis = 0
              } else {
                s.writeData = ceil(
                  math.format(
                    math.divide(
                      math.bignumber(s.WriteBytes),
                      math.bignumber(s.WriteTime / 1000)
                    )
                  ),
                  2
                )
                s.writeAxis = formatNumsBytes(s.writeData)
              }
              return [s.CreateTime, s.writeAxis, s.writeData]
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[1]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[1]
            }
          },
          {
            name: '读次数',
            type: 'line',
            data: diskYData.value.map((s:any) => {
              return s.ReadCount
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[2]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[2]
            }
          },
          {
            name: '写次数',
            type: 'line',
            data: diskYData.value.map((s:any) => {
              return s.WriteCount
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[3]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[3]
            }
          },
          {
            name: '读延迟',
            type: 'line',
            data: diskYData.value.map((s:any) => {
              if (s.ReadTime === 0 || s.ReadCount === 0) {
                s.readDelayData = 0
                s.readDelayAxis = 0
              } else {
                s.readDelayData = ceil(
                  math.format(
                    math.divide(
                      math.bignumber(s.ReadTime / 1000),
                      math.bignumber(s.ReadCount)
                    )
                  ),
                  2
                )
                s.readDelayAxis = formatNumsBytes(s.readDelayData)
              }
              return [s.CreateTime, s.readDelayAxis, s.readDelayData]
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[4]
                }
              ])
            },
            lineStyle: {
              width: 3,
              shadowColor: colorShadowList.value[4]
            }
          },
          {
            name: '写延迟',
            type: 'line',
            data: diskYData.value.map((s:any) => {
              if (s.WriteTime === 0 || s.WriteCount === 0) {
                s.writeDelayData = 0
                s.writeDelayAxis = 0
              } else {
                s.writeDelayData = ceil(
                  math.format(
                    math.divide(
                      math.bignumber(s.WriteTime / 1000),
                      math.bignumber(s.WriteCount)
                    )
                  ),
                  2
                )
                s.writeDelayAxis = formatNumsBytes(s.writeDelayData)
              }
              return [s.CreateTime, s.writeDelayAxis, s.writeDelayData]
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[5]
                }
              ])
            },
            lineStyle: {
              width: 3,
              shadowColor: colorShadowList.value[5]
            }
          }
        ]
      }
      var myChart = echarts.init(document.getElementById('diskMonitorCharts'))
      await nextTick(() => {
        myChart.resize()
      })
      myChart.setOption(option)
      reloadCharts(myChart)
    }
    const handleChangeDiskIO = () => {
      clearDiskCharts()
      getDiskCharts()
    }
    const handleChangeNet = () => {
      clearNetCharts()
      getNetCharts()
    }
    const handleChangeGpu = () => {
      clearGpuCharts()
      getGpuCharts()
    }
    const getNetCharts = async () => {
      var startTime = formatDate(currentTime.value[0])
      var endTime = formatDate(currentTime.value[1])
      var option:any = []
      var date = []
      showNetSpinning.value = true
      const res = await http.request({
        method: 'post',
        url: '/maintenance/docker/client/monitor/getContainerNet',
        data: {
          startTime: startTime,
          endTime: endTime,
          taskId: route.query.id
        }
      })
      date = res.data.date
      if (res.data.data) {
        showNetVisible.value = true
        res.data.data.map((s:any) => {
          option.push({ value: s.name, id: s.name })
          if (s.name === netParams.value) {
            netYData.value = s.stats
            s.stats.map((v:any) => {
              if (v.RecvBytes) {
                // 上行
                var upParams = formatBytes(v.RecvBytes)
                v.RecvFormatBytes = upParams.sizes
                v.RecvFormat = upParams.format
              }
              if (v.SentBytes) {
                // 下行
                var downParams = formatBytes(v.SentBytes)
                v.SentFormatBytes = downParams.sizes
                v.SentFormat = downParams.format
              }
            })
          }
        })
        netOptions.value = option
        if (!netParams.value) {
          netParams.value = netOptions.value[0].value
        }
        await initNetCharts(date)
      } else {
        showNetVisible.value = false
      }
      showNetSpinning.value = false
    }
    const initNetCharts = async (dateTimeZone:any) => {
      const option = {
        backgroundColor: '#fff',
        dataZoom: [
          {
            show: true,
            bottom: '2%',
            start: 0,
            end: 100,
            handleIcon:
              'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAFAAAAAAV6Uf1AAABMElEQVQ4Ec2UL04DURDGZ162wWwVggtgCA4sAoHHgSXBo2pA9QAYOAEWHDeoqMY1GC6AQHUN2aTD+z2Y5nWzAbqsYJLNTGfm+zo7f1akJ9Emz+HYyqqqzxYix2q2a6qbUb9FPQsij2U5uJuMtWriVoj2Ru8nanprYlvNRP+toq+mdvF0vfHgPnT8k0/ZH9WXYnL/HQmZKR7zUv4XFpUqohJIMv/vTJVTr0zpyXxev/xUSRszrzkcDrbpWaCxXUggBgceOzAdjFxuzgvhyaXNR9zxRRpxjoj2wc5yBstIm48geHRgTzC6iuMDy9aVBJzjqWj2FyLHF6x9bNhRTjZ9XohZ7hFp85EBHt3fHrFM3A6s6wo4P+A0Z9ZcNVytQ0S+nwe43q5/hQjmrt8jsP9LPgCaLJ7zlI/MWgAAAABJRU5ErkJggg==',
            handleSize: 15,
            height: 13,
            handleStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '#fff',
              fontSize: 12
            },
            fillerColor: '#F5F8FF',
            borderColor: '#E5EAF6',
            brushSelect: false
          }
        ],
        legend: {
          right: '12%',
          top: '3%',
          itemHeight: 10,
          itemWidth: 10,
          textStyle: {
            borderWidth: 4,
            color: '#556677'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,0)',
              shadowColor: 'rgba(0,0,0,0)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: '#fff',
          textStyle: {
            color: '#5c6c7c'
          },
          padding: [10, 10],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)',
          formatter: (params:any) => {
            var msg1 = ''
            var msg2 = ''
            params.map((s:any, index:number) => {
              if (s.seriesName === '上行') {
                msg1 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  formatSeriesBytes(params[index].data[3]) +
                  '/s' +
                  '<br>'
              } else if (s.seriesName === '下行') {
                msg2 =
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  formatSeriesBytes(params[index].data[3]) +
                  '/s'
              }
            })
            return params[0].name + '<br/>' + msg1 + msg2
          }
        },
        grid: {
          top: '14%'
        },
        xAxis: [
          {
            type: 'category',
            data: dateTimeZone,
            axisLine: {
              lineStyle: {
                color: ['#EDEFF5'],
                width: 1,
                type: 'dashed'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#BFC2C2',
              // 默认x轴字体大小
              fontSize: 12,
              // margin:文字到x轴的距离
              margin: 15
            },
            axisPointer: {
              label: {
                padding: [0, 0, 10, 0],
                margin: 15,
                fontSize: 12
              }
            },
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            name: '(KB/s)',
            type: 'value',
            nameTextStyle: {
              padding: [20, 30, 10, 0]
            },
            splitLine: {
              lineStyle: {
                color: ['#EDEFF5'],
                width: 1,
                type: 'dashed'
              }
            },
            axisLabel: {
              color: '#BFC2C2'
            }
          }
        ],
        color: colorOffSetListOne.value,
        series: [
          {
            name: '上行',
            type: 'line',
            data: netYData.value.map((s:any) => {
              return [s.CreateTime, s.RecvFormatBytes, s.RecvFormat, s.RecvBytes]
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[0]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[0]
            }
          },
          {
            name: '下行',
            type: 'line',
            data: netYData.value.map((s:any) => {
              return [s.CreateTime, s.SentFormatBytes, s.SentFormat, s.SentBytes]
            }),
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[1]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[1]
            }
          }
        ]
      }
      var myChart = echarts.init(document.getElementById('netMonitorCharts'))
      await nextTick(() => {
        myChart.resize()
      })
      myChart.setOption(option)
      reloadCharts(myChart)
    }
    function formatBytes (size:number) {
      var params:any = {
        sizes: '',
        format: ''
      }
      if (!size) return '-'
      var num = 1024.0 // byte
      if (size < num) {
        params.format = 'B'
        params.sizes = size
        return params
      } else {
        params.format = 'KB'
        params.sizes = (size / num).toFixed(2)
        return params
      }
    }
    function formatSeriesBytes (size:number) {
      if (!size) return '-'
      var num = 1024.0 // byte
      if (size < num) return size + 'B'
      if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + 'KB' // kb
      if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + 'MB' // M
      if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + 'GB' // G
      return (size / Math.pow(num, 4)).toFixed(2) + 'TB' // T
    }
    function formatNumsBytes (size:number) {
      if (!size) return '0'
      var num = 1024.0 // byte
      if (size < num) return size
      if (size >= num) return (size / num).toFixed(2) // kb
    }
    // 向上取整保留两位小数
    function ceil (num:any, byte:any) {
      var x = String(num).indexOf('.') + 1 // 小数点的位置
      var y = String(num).length - x // 小数的位数
      if (y > 2) {
        // 小数位数大于两位向上取整保留两位显示
        const n = Math.pow(10, byte)
        return Math.ceil(mutiply(num, n)) / n
      } else {
        return num
      }
    }
    function mutiply (a:any, b:any) {
      a = BigNumber(a)
      b = BigNumber(b)
      return a.multipliedBy(b).toNumber()
    }
    async function initMemoryCharts (dateTimeZone:any) {
      const option = {
        backgroundColor: '#fff',
        dataZoom: [
          {
            show: true,
            bottom: '2%',
            start: 0,
            end: 100,
            handleIcon:
              'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAFAAAAAAV6Uf1AAABMElEQVQ4Ec2UL04DURDGZ162wWwVggtgCA4sAoHHgSXBo2pA9QAYOAEWHDeoqMY1GC6AQHUN2aTD+z2Y5nWzAbqsYJLNTGfm+zo7f1akJ9Emz+HYyqqqzxYix2q2a6qbUb9FPQsij2U5uJuMtWriVoj2Ru8nanprYlvNRP+toq+mdvF0vfHgPnT8k0/ZH9WXYnL/HQmZKR7zUv4XFpUqohJIMv/vTJVTr0zpyXxev/xUSRszrzkcDrbpWaCxXUggBgceOzAdjFxuzgvhyaXNR9zxRRpxjoj2wc5yBstIm48geHRgTzC6iuMDy9aVBJzjqWj2FyLHF6x9bNhRTjZ9XohZ7hFp85EBHt3fHrFM3A6s6wo4P+A0Z9ZcNVytQ0S+nwe43q5/hQjmrt8jsP9LPgCaLJ7zlI/MWgAAAABJRU5ErkJggg==',
            handleSize: 15,
            height: 13,
            handleStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '#fff',
              fontSize: 12
            },
            fillerColor: '#F5F8FF',
            borderColor: '#E5EAF6',
            brushSelect: false
          }
        ],
        legend: {
          right: '12%',
          top: '3%',
          itemHeight: 10,
          itemWidth: 10,
          textStyle: {
            borderWidth: 4,
            color: '#556677'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,0)',
              shadowColor: 'rgba(0,0,0,0)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: '#fff',
          textStyle: {
            color: '#5c6c7c'
          },
          padding: [10, 10],
          formatter: (params:any) => {
            var msg1 = ''
            params.map((s:any, index:number) => {
              if (s.seriesName === '内存') {
                msg1 =
                  params[index].name +
                  '<br/>' +
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  params[index].value +
                  '%'
              }
            })
            return msg1
          },
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
        },
        grid: {
          top: '14%'
        },
        xAxis: [
          {
            type: 'category',
            data: dateTimeZone,
            axisLine: {
              lineStyle: {
                color: ['#EDEFF5'],
                width: 1,
                type: 'dashed'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#BFC2C2',
              // 默认x轴字体大小
              fontSize: 12,
              // margin:文字到x轴的距离
              margin: 15
            },
            axisPointer: {
              label: {
                padding: [0, 0, 10, 0],
                margin: 15,
                fontSize: 12
              }
            },
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            name: '(%)',
            type: 'value',
            nameTextStyle: {
              padding: [20, 30, 10, 0]
            },
            splitLine: {
              lineStyle: {
                color: ['#EDEFF5'],
                width: 1,
                type: 'dashed'
              }
            },
            axisLabel: {
              color: '#BFC2C2'
            }
          }
        ],
        color: ['#0CEBDF'],
        series: [
          {
            name: '内存',
            type: 'line',
            data: memoryYData.value,
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[1]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[1]
            }
          }
        ]
      }
      var myChart = echarts.init(document.getElementById('memoryMonitorCharts'))
      await nextTick(() => {
        myChart.resize()
      })
      myChart.setOption(option)
      reloadCharts(myChart)
    }
    async function initCpuCharts (dateTimeZone:any) {
      const option = {
        backgroundColor: '#fff',
        dataZoom: [
          {
            show: true,
            bottom: '2%',
            start: 0,
            end: 100,
            handleIcon:
              'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAFAAAAAAV6Uf1AAABMElEQVQ4Ec2UL04DURDGZ162wWwVggtgCA4sAoHHgSXBo2pA9QAYOAEWHDeoqMY1GC6AQHUN2aTD+z2Y5nWzAbqsYJLNTGfm+zo7f1akJ9Emz+HYyqqqzxYix2q2a6qbUb9FPQsij2U5uJuMtWriVoj2Ru8nanprYlvNRP+toq+mdvF0vfHgPnT8k0/ZH9WXYnL/HQmZKR7zUv4XFpUqohJIMv/vTJVTr0zpyXxev/xUSRszrzkcDrbpWaCxXUggBgceOzAdjFxuzgvhyaXNR9zxRRpxjoj2wc5yBstIm48geHRgTzC6iuMDy9aVBJzjqWj2FyLHF6x9bNhRTjZ9XohZ7hFp85EBHt3fHrFM3A6s6wo4P+A0Z9ZcNVytQ0S+nwe43q5/hQjmrt8jsP9LPgCaLJ7zlI/MWgAAAABJRU5ErkJggg==',
            handleSize: 15,
            height: 13,
            handleStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '#fff',
              fontSize: 12
            },
            fillerColor: '#F5F8FF',
            borderColor: '#E5EAF6',
            brushSelect: false
          }
        ],
        legend: {
          right: '12%',
          top: '3%',
          itemHeight: 10,
          itemWidth: 10,
          textStyle: {
            borderWidth: 4,
            color: '#556677'
          }
        },
        grid: {
          top: '14%'
        },
        xAxis: [
          {
            type: 'category',
            data: dateTimeZone,
            axisLine: {
              lineStyle: {
                color: ['#EDEFF5'],
                width: 1,
                type: 'dashed'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#BFC2C2',
              // 默认x轴字体大小
              fontSize: 12,
              // margin:文字到x轴的距离
              margin: 15
            },
            axisPointer: {
              label: {
                padding: [0, 0, 10, 0],
                margin: 15,
                fontSize: 12
              }
            },
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '(%)',
            nameTextStyle: {
              padding: [20, 30, 10, 0]
            },
            splitLine: {
              lineStyle: {
                color: ['#EDEFF5'],
                width: 1,
                type: 'dashed'
              }
            },
            axisLabel: {
              color: '#BFC2C2'
            }
          }
        ],
        color: colorOffSetListOne.value,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,0)',
              shadowColor: 'rgba(0,0,0,0)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: '#fff',
          textStyle: {
            color: '#5c6c7c'
          },
          padding: [10, 10],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)',
          formatter: (params:any) => {
            var msg1 = ''
            params.map((s:any, index:number) => {
              if (s.seriesName === 'CPU') {
                msg1 =
                  params[index].name +
                  '<br/>' +
                  params[index].marker +
                  params[index].seriesName +
                  ':' +
                  params[index].value +
                  '%'
              }
            })
            return msg1
          }
        },
        series: [
          {
            name: 'CPU',
            type: 'line',
            data: caculateYData.value,
            symbolSize: 1,
            yAxisIndex: 0,
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFF'
                },
                {
                  offset: 1,
                  color: caculateColorShadowList.value[0]
                }
              ])
            },
            lineStyle: {
              width: 2,
              shadowColor: colorShadowList.value[0]
            }
          }
        ]
      }
      var myChart = echarts.init(document.getElementById('cpuMonitorCharts'))
      await nextTick(() => {
        myChart.resize()
      })
      myChart.setOption(option)
      reloadCharts(myChart)
    }
    function reloadCharts (chart:any) {
      if (chart) {
        window.onresize = chart.resize
        window.addEventListener('resize', function () {
          chart.resize()
        })
      }
    }
    function clearCpuCharts () {
      const ccharts = (echarts.getInstanceByDom(document.getElementById('cpuMonitorCharts') as HTMLElement))
      if (ccharts) {
        echarts.dispose(ccharts)
      }
    }
    function clearDiskCharts () {
      const ccharts = (echarts.getInstanceByDom(document.getElementById('diskMonitorCharts') as HTMLElement))
      if (ccharts) {
        echarts.dispose(ccharts)
      }
    }
    function clearNetCharts () {
      const ccharts = (echarts.getInstanceByDom(document.getElementById('netMonitorCharts') as HTMLElement))
      if (ccharts) {
        echarts.dispose(ccharts)
      }
    }
    function clearGpuCharts () {
      gpuOptions.value.map((s: any) => {
        const ccharts = (echarts.getInstanceByDom(document.getElementById(s.name) as HTMLElement))
        if (ccharts) {
          echarts.dispose(ccharts)
        }
      })
    }
    function clearMemoryCharts () {
      const ccharts = echarts.getInstanceByDom(document.getElementById('memoryMonitorCharts') as HTMLElement)
      if (ccharts) {
        echarts.dispose(ccharts)
      }
    }
    const onRangeChange = (dates: any) => {
      if (dates) {
        clearCpuCharts()
        clearMemoryCharts()
        clearDiskCharts()
        clearGpuCharts()
        clearNetCharts()
        getGpuCharts()
        getDiskCharts()
        getSystemCharts()
        getNetCharts()
      }
    }
    const ranges = ref([
      {
        label: '今天',
        value: [dayjs().startOf('day'), dayjs()]
      },
      {
        label: '近 3 天',
        value: [dayjs().add(-3, 'day'), dayjs()]
      },
      {
        label: '近 7 天',
        value: [dayjs().add(-7, 'day'), dayjs()]
      },
      {
        label: '近 30 天',
        value: [dayjs().add(-30, 'day'), dayjs()]
      }
    ])
    return {
      ranges,
      currentTime,
      diskOptions,
      diskIOParams,
      handleChangeDiskIO,
      handleChangeNet,
      netParams,
      netOptions,
      gpuParams,
      gpuOptions,
      handleChangeGpu,
      handleBack,
      onRangeChange,
      showSystemVisible,
      showDiskVisible,
      showNetVisible,
      showSystemSpinning,
      showNetSpinning,
      showDiskSpinning
    }
  }
})
