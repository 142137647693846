
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    stopData: {
      type: String
    },
    confirm: {
      type: Function
    }
  },
  setup (props, { emit }) {
    const handleCancel = () => {
      emit('close')
    }
    return {
      props,
      handleCancel
    }
  }
})
