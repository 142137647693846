
import { BigNumber } from 'bignumber.js'
import { toFixedNum } from '@/utils/toFixedNum'
import { balance, getList, changeTaskBtnState, taskEscalationVisible, specificationNum } from '../myTaskModule'
import { discountHour, priceHour, taskId } from '../js/tableFeeChange'
import { defineComponent, computed } from 'vue'
import { message } from 'ant-design-vue'
import { taskUpgrade } from '@/api/myTask'
export default defineComponent({
  setup () {
    const taskEscalationDiscountPrice = computed(() => {
      if (discountHour.value === 1) {
        return '无折扣'
      } else {
        return parseFloat(
          toFixedNum(new BigNumber(discountHour.value).multipliedBy(10).toNumber(), 2)
        )
      }
    })
    const taskEscalationTotalPrice = computed(() => {
      if (specificationNum.value === 0 || specificationNum.value === null) {
        return ''
      } else {
        const num = new BigNumber(specificationNum.value)
        const price = new BigNumber(priceHour.value)
        const totalPrice = toFixedNum(
          num.multipliedBy(price).multipliedBy(discountHour.value).toNumber(),
          2
        )
        if (totalPrice > balance.value) {
          changeTaskBtnState.value = true
        } else {
          changeTaskBtnState.value = false
        }
        return totalPrice
      }
    })
    const handleConfirmEscalation = () => {
      if (specificationNum.value === null || specificationNum.value === 0) {
        message.error('请填写资源规格数量')
        return false
      } else {
        changeTaskBtnState.value = true
        const params = {
          discount: discountHour.value,
          orderPrice: taskEscalationTotalPrice.value,
          specificationNum: specificationNum.value,
          taskId: taskId.value,
          unitPrice: priceHour.value
        }
        taskUpgrade(params).then(() => {
          message.success('实例升配成功', 0.5).then(() => {
            changeTaskBtnState.value = false
            taskEscalationVisible.value = false
            getList()
          })
        })
          .catch(() => {
            changeTaskBtnState.value = false
          })
      }
    }
    return {
      changeTaskBtnState, taskEscalationVisible, specificationNum, taskEscalationDiscountPrice, taskEscalationTotalPrice, handleConfirmEscalation
    }
  }

})

