
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
interface BreadcrumbItemFace {
  path: string;
  meta: {
    menuTitle: string;
    englishTitle: string;
  };
  [propName: string]: any;
}

export default defineComponent({
  props: {
    breadcrumbList: {
      type: Array as PropType<BreadcrumbItemFace[]>,
      default: () => []
    }
  },
  setup () {
    const router = useRouter()
    const handleBreadLink = (path: string) => {
      router.push(path)
    }
    return {
      handleBreadLink
    }
  }
})
