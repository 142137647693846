import {
  primaryImage,
  changeImageVisible,
  changeImageDialogVisible,
  imageOptions,
  imageState,
  imageForm,
  imageFormRef,
  rowData,
  getList
} from '../myTaskModule'
import { queryImageDict, changeTaskImage } from '@/api/myTask'
import { message } from 'ant-design-vue'
import { ImageChangeParamStateFace } from '@/interface/myTaskInterface'
const handleChangeImage = (item: any) => {
  rowData.value = item
  primaryImage.value = item.containerMirroring
  Object.assign(imageState, {
    type: 0,
    imageId: '',
    frameName: '',
    edition: '',
    pythonEdition: '',
    toolkitEdition: ''
  })
  imageOptions.value = []
  getImageOptions()
  changeImageDialogVisible.value = true
}
const getImageOptions = () => {
  imageOptions.value = []
  if (changeImageVisible.value) {
    imageState.type = 0
  } else {
    imageState.type = 1
  }
  const params = {
    edition: imageState.edition,
    frameName: imageState.frameName,
    pythonEdition: imageState.pythonEdition,
    type: imageState.type,
    toolkitEdition: '',
    imageId: ''
  }
  queryImageDict(params).then((res: any) => {
    imageOptions.value = res.data
  })
}
const loadData = (selectedOptions: any) => {
  const targetOption = selectedOptions[selectedOptions.length - 1]
  getLoadImageOptions(targetOption)
}
const getMainImage = (data: string) => {
  imageState.imageId = data
  getFullImage()
}
const getFullImage = () => {
  if (changeImageVisible.value) {
    imageState.type = 0
    imageState.imageId = ''
    imageState.edition = imageForm.image[1]
    imageState.frameName = imageForm.image[0]
    imageState.pythonEdition = imageForm.image[2]
    imageState.toolkitEdition = imageForm.image[3]
  } else {
    imageState.type = 1
  }
}
const changeImage = () => {
  changeImageVisible.value = !changeImageVisible.value
  imageOptions.value = []
  imageState.edition = ''
  imageState.frameName = ''
  imageState.pythonEdition = ''
  imageForm.image = []
  imageState.imageId = ''
  getImageOptions()
}
const getImage = (data: any) => {
  if (data) {
    if (data.length === 4) {
      getLoadImageOptions(imageForm.image)
    }
  }
}
// toolkit版本校验
const getLoadImageOptions = (targetOption: any) => {
  if (targetOption.level === 4) {
    imageState.toolkitEdition = targetOption[3]
    getFullImage()
  }
}
// 更换镜像
const handleConfirmChangeImage = () => {
  imageFormRef.value.validateFields().then(() => {
    const params:ImageChangeParamStateFace = {
      imageNodeDO: {
        frameName: imageForm.image[0],
        edition: imageForm.image[1],
        imageId: imageState.type === 0 ? '' : imageState.imageId,
        pythonEdition: imageForm.image[2],
        structure: rowData.value.structure,
        toolkitEdition: imageForm.image[3],
        type: imageState.type
      },
      taskId: rowData.value.id
    }
    changeTaskImage(params).then(() => {
      message.success('更换镜像成功')
      changeImageDialogVisible.value = false
      getList()
    })
  })
}
export {
  handleChangeImage,
  changeImageVisible,
  changeImageDialogVisible,
  imageOptions,
  imageState,
  imageForm,
  getImageOptions,
  loadData,
  changeImage,
  getMainImage,
  getImage,
  primaryImage,
  handleConfirmChangeImage,
  imageFormRef
}
