
import {
  defineComponent, onMounted, watch, toRefs
} from 'vue'
import ScrollAnnouncement from '@/components/scroll-announcement/ScrollAnnouncement.vue'
import { selectProductInfo, getRegionName, getGpuManageModel } from '@/api/market'
import {
  nodePlaceOptions,
  hostData,
  formState,
  plainOptions,
  state,
  clearVisible,
  btnList,
  gpuNumMarks,
  videoMemoryMarks,
  gpuPriceMarks
} from './js/market-module'
import { useRouter } from 'vue-router'
import { BigNumber } from 'bignumber.js'
import isNetDiskImg from '@/assets/img/ic_zhichi.png'
import isObjectImg from '@/assets/img/ic_object.png'
import isAllImg from '@/assets/img/ic_all.png'
import HeaderNav from '@/components/header-nav/HeaderNav.vue'
export default defineComponent({
  components: {
    ScrollAnnouncement,
    HeaderNav
  },
  setup () {
    const router = useRouter()
    onMounted(() => {
      getNodePlace()
      getGpuModel()
    })
    // 监听算力型号勾选列表
    watch(
      () => state.checkedList,
      (val) => {
        state.indeterminate =
          (!!val.length && val.length < plainOptions.value.length) ||
          (clearVisible.value === false && val.length === 0)
        state.checkAll = val.length === plainOptions.value.length
        formState.gpuModelList = state.checkedList
        getList()
      }
    )
    // 全选事件
    const onCheckAllChange = (e: any) => {
      Object.assign(state, {
        checkedList: e.target.checked ? plainOptions.value : [],
        indeterminate: false
      })
      hostData.value = []
      var arr: any = []
      if (e.target.checked) {
        plainOptions.value.forEach((v: any) => {
          arr.push(v.value)
        })
      }
      state.checkedList = arr
    }
    // 主列表查询
    const getList = () => {
      selectProductInfo(formState).then(res => {
        hostData.value = res.data
      })
    }
    // Gpu型号查询
    const getGpuModel = () => {
      getGpuManageModel().then(res => {
        plainOptions.value = []
        res.data.forEach((v: any) => {
          plainOptions.value.push({ value: v.model, label: v.model })
        })
      })
    }
    // 区域查询
    const getNodePlace = () => {
      getRegionName().then(res => {
        var newListArr = []
        for (let i = 0; i < res.data.length; i += 2) {
          newListArr.push(res.data.slice(i, i + 2))
        }
        nodePlaceOptions.value = newListArr
        var nodeItem = nodePlaceOptions.value[0][0]
        handleTabs(nodeItem)
      })
    }
    // 区域点击切换事件
    const handleTabs = (item: any) => {
      formState.regionId = item.id
      hostData.value = []
      getList()
    }
    // 运算卡滑动条滑动后触发事件
    const handleLaveGpu = () => {
      hostData.value = []
      getList()
    }
    // 运算卡显存滑动条滑动后触发事件
    const handleLaveVideoMemory = (data: number) => {
      formState.videoMemory = data
      hostData.value = []
      getList()
    }
    // 运算卡价格滑动条滑动后触发事件
    const handleGpuPrice = (data: number) => {
      formState.gpuPrice = data
      hostData.value = []
      getList()
    }
    // 空闲运算卡升降序事件
    const changeSort = (item: any, index: number) => {
      btnList.value[index].status === 0
        ? (btnList.value[index].status = 1)
        : (btnList.value[index].status = 0)
      hostData.value = []
      formState.sort = item.status
      getList()
    }
    // 跳转详情
    const handleDetail = (item: any) => {
      localStorage.setItem('marketData', JSON.stringify(item))
      router.push({ path: 'market-detail' })
    }
    // 初始化查询条件
    const cleanSearch = () => {
      state.checkedList = []
      state.indeterminate = true
      formState.gpuLaveCount = 10
      formState.videoMemory = 80
      formState.gpuPrice = 100
      clearVisible.value = false
      var nodeItem = nodePlaceOptions.value[0][0]
      handleTabs(nodeItem)
    }
    const calculatePrice = (discount: string | number, price: string | number) => {
      if (discount !== 1) {
        var num = mutiply(discount, price)
        var num1 = ceil(num, 2)
        return num1
      } else {
        return price
      }
    }
    // 向上取整保留两位小数
    function ceil (num: number, byte: number) {
      var x = String(num).indexOf('.') + 1 // 小数点的位置
      var y = String(num).length - x // 小数的位数
      if (y > 2) {
        // 小数位数大于两位向上取整保留两位显示
        const n = Math.pow(10, byte)
        return Math.ceil(mutiply(num, n)) / n
      } else {
        return num
      }
    }
    function mutiply (a: any, b: any) {
      a = BigNumber(a)
      b = BigNumber(b)
      return a.multipliedBy(b).toNumber()
    }
    return {
      nodePlaceOptions,
      gpuNumMarks,
      videoMemoryMarks,
      gpuPriceMarks,
      formState,
      handleTabs,
      onCheckAllChange,
      ...toRefs(state),
      handleDetail,
      handleLaveGpu,
      handleLaveVideoMemory,
      handleGpuPrice,
      btnList,
      hostData,
      isNetDiskImg,
      calculatePrice,
      isObjectImg,
      isAllImg,
      cleanSearch,
      changeSort,
      plainOptions
    }
  }
})
