import { ref } from 'vue'
const tableHeight = ref(
  (window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) - 350
)
window.addEventListener('resize', function () {
  tableHeight.value =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 350
})
export {
  tableHeight
}
