import { ref, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import { useCancelAjax } from '@/utils/cancel-ajax'
import { getRegionName, checkToolkitVersion } from '@/api/market'
import { listAvailableSpecification, queryImageDict, dataSetInfo, publishInfo, createTaskMultiMachine } from '@/api/myTask'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import { toFixedNum } from '@/utils/toFixedNum'
import { message } from 'ant-design-vue'
const { cancelFnList } = useCancelAjax()
const router = useRouter()
const isOpen = ref(false)
const tableData = ref([])
// 运算卡序号
const gpuManageSerialNoList = ref([])
const maxNum:any = ref(0)
const singleGpuSegAmount = ref(0)
// 资源规格
const resourceSpecListTableData = ref([])
const resourceSpecList:any = ref([])
// 运行模式
const cpuNumber = ref(2)
const currentIndex = ref(0)
const runningMode = ref(1)
const formInfoList:any = ref([
  {
    taskName: dayjs().format('YYYYMMDDHHmm'),
    specificationNum: 0,
    specificationId: '',
    runnindMode: runningMode.value,
    regionId: '',
    calculateDeviceId: '',
    downlinkRate: 5,
    uplinkRate: 5,
    configInfo: [],
    imgMode: true,
    cascaderValue: [],
    storageBucketArr: '',
    storageBucket: '',
    imageNodeDO: {
      edition: '',
      frameName: '',
      imageId: '',
      pythonEdition: '',
      structure: '',
      toolkitEdition: '',
      type: ''
    },
    gpuMode: '',
    gpuSerialNo: '',
    netHostAuth: '',
    privllegedAuth: '',
    isShowNetHost: '',
    isShowPrivlleged: '',
    linuxCapabilitySList: [],
    linuxCapabilityS: [],
    orderDTO: []
  }
])
// 平台镜像
const specificationInfo: any = ref([])
const cascaderRef = ref(null)
const myImgList = ref([])
const imgType = ref(0)
const imageOptions = ref([])
// 资源规格
const specificationIdArr:any = ref([])
// 区域
const areaList = ref([])
const selectedArea = ref('')

// 查询区域
const queryAreaList = async () => {
  await getRegionName().then(res => {
    areaList.value = res.data
    if (areaList.value.length > 0) {
      selectedArea.value = res.data[0].id
    }
  })
}
// 查询区域下资源规格信息
const querySpecification = async () => {
  listAvailableSpecification(selectedArea.value).then((res:any) => {
    if (res) {
      tableData.value = res.data
      resourceSpecList.value = tableData.value.filter((item:any) => parseInt(item.gpuMode) !== 4)
      resourceSpecListTableData.value = resourceSpecList.value
    }
  })
}
// 选择运算卡序号
const handleChangeGpuNum = (e:any) => {
  const itemArr:any = gpuManageSerialNoList.value.filter((item:any) => item.gpuManageSerialNo === e)
  maxNum.value = itemArr[0].amount
  if (itemArr[0].amount > 0) {
    formInfoList.value[currentIndex.value].specificationNum = 1
  } else {
    formInfoList.value[currentIndex.value].specificationNum = 0
  }
  formInfoList.value[currentIndex.value].orderDTO = {
    costType: 1,
    discount: specificationInfo.value[0].discountHour,
    orderPrice: toFixedNum(BigNumber(specificationInfo.value[0].discountHour).multipliedBy(BigNumber(specificationInfo.value[0].priceHour).multipliedBy(BigNumber(formInfoList.value[currentIndex.value].specificationNum))).toNumber(), 2),
    unitPrice: specificationInfo.value[0].priceHour
  }
}
const handleChangeSplitAmount = (e:any) => {
  if (e > maxNum.value) {
    message.error('所填规格数量大于当前可用规格数量', 0.5).then(() => {
      formInfoList.value[currentIndex.value].specificationNum = maxNum.value
      formInfoList.value[currentIndex.value].orderDTO = {
        costType: 1,
        discount: specificationInfo.value[0].discountHour,
        orderPrice: toFixedNum(BigNumber(specificationInfo.value[0].discountHour).multipliedBy(BigNumber(specificationInfo.value[0].priceHour).multipliedBy(BigNumber(formInfoList.value[currentIndex.value].specificationNum))).toNumber(), 2),
        unitPrice: specificationInfo.value[0].priceHour
      }
    })
  } else {
    formInfoList.value[currentIndex.value].orderDTO = {
      costType: 1,
      discount: specificationInfo.value[0].discountHour,
      orderPrice: toFixedNum(BigNumber(specificationInfo.value[0].discountHour).multipliedBy(BigNumber(specificationInfo.value[0].priceHour).multipliedBy(BigNumber(formInfoList.value[currentIndex.value].specificationNum))).toNumber(), 2),
      unitPrice: specificationInfo.value[0].priceHour
    }
  }
}
// 运行模式
const handleChangeRunningMode = ({ target }:any) => {
  currentIndex.value = 0
  if (target.value === 4) {
    resourceSpecList.value = tableData.value.filter((item:any) => parseInt(item.gpuMode) === target.value)
    resourceSpecListTableData.value = tableData.value.filter((item:any) => parseInt(item.gpuMode) === target.value)
  } else {
    resourceSpecListTableData.value = tableData.value.filter((item:any) => parseInt(item.gpuMode) === 1)
    resourceSpecList.value = tableData.value.filter((item:any) => parseInt(item.gpuMode) === 1)
  }
  specificationInfo.value = []
  formInfoList.value = []
  resourceSpecList.value.forEach((item:any) => {
    item.disabled = false
  })
  if (target.value === 1 || target.value === 4) {
    formInfoList.value.push(
      {
        taskName: dayjs().format('YYYYMMDDHHmm'),
        specificationNum: 0,
        specificationId: '',
        runnindMode: runningMode.value,
        regionId: '',
        calculateDeviceId: '',
        downlinkRate: 5,
        uplinkRate: 5,
        configInfo: [],
        imgMode: true,
        cascaderValue: [],
        storageBucketArr: '',
        storageBucket: '',
        imageNodeDO: {
          edition: '',
          frameName: '',
          imageId: '',
          pythonEdition: '',
          structure: '',
          toolkitEdition: '',
          type: ''
        },
        gpuMode: '',
        gpuSerialNo: '',
        netHostAuth: '',
        privllegedAuth: '',
        isShowNetHost: '',
        isShowPrivlleged: '',
        linuxCapabilitySList: [],
        linuxCapabilityS: []
      }
    )
  } else {
    currentIndex.value = 0
    for (let i = 0; i < cpuNumber.value; i++) {
      formInfoList.value.push(
        {
          taskName: dayjs().format('YYYYMMDDHHmm'),
          specificationNum: 0,
          specificationId: '',
          runnindMode: runningMode.value,
          regionId: '',
          calculateDeviceId: '',
          downlinkRate: 5,
          uplinkRate: 5,
          configInfo: [],
          imgMode: true,
          cascaderValue: [],
          storageBucketArr: '',
          storageBucket: '',
          imageNodeDO: {
            edition: '',
            frameName: '',
            imageId: '',
            pythonEdition: '',
            structure: '',
            toolkitEdition: '',
            type: ''
          },
          netHostAuth: '',
          privllegedAuth: '',
          isShowNetHost: '',
          isShowPrivlleged: '',
          linuxCapabilitySList: [],
          linuxCapabilityS: []
        }
      )
    }
  }
}
// 查询平台镜像
const formatImgSelect = () => {
  queryImageDict({ type: imgType.value }).then(res => {
    if (imgType.value === 0) {
      imageOptions.value = res.data
    } else {
      myImgList.value = res.data
    }
  })
}
const handelImageChange = () => {
  const [frameName, edition, pythonEdition, toolkitEdition] = formInfoList.value[currentIndex.value].cascaderValue
  const calculateDeviceId = formInfoList.value[currentIndex.value].calculateDeviceId
  const structure = specificationInfo.value[0].platformArchitecture
  checkToolkitVersion({
    calculateDeviceId: calculateDeviceId,
    toolkitVersion: toolkitEdition
  }).then(() => {
    formInfoList.value[currentIndex.value].imageNodeDO.frameName = frameName
    formInfoList.value[currentIndex.value].imageNodeDO.edition = edition
    formInfoList.value[currentIndex.value].imageNodeDO.pythonEdition = pythonEdition
    formInfoList.value[currentIndex.value].imageNodeDO.toolkitEdition = toolkitEdition
    formInfoList.value[currentIndex.value].imageNodeDO.structure = structure
    formInfoList.value[currentIndex.value].imageNodeDO.type = imgType.value
  }).catch(() => {
    formInfoList.value[currentIndex.value].imageNodeDO.frameName = frameName
    formInfoList.value[currentIndex.value].imageNodeDO.edition = edition
    formInfoList.value[currentIndex.value].imageNodeDO.pythonEdition = pythonEdition
    formInfoList.value[currentIndex.value].imageNodeDO.toolkitEdition = toolkitEdition
    formInfoList.value[currentIndex.value].imageNodeDO.structure = structure
    formInfoList.value[currentIndex.value].imageNodeDO.type = imgType.value
  })
}
// 平台镜像
const handleChangeImgMode = () => {
  Object.keys(formInfoList.value[currentIndex.value].imageNodeDO).forEach(key => {
    formInfoList.value[currentIndex.value].imageNodeDO[key] = ''
  })
  formInfoList.value[currentIndex.value].imgMode = !(formInfoList.value[currentIndex.value].imgMode)
  imgType.value = formInfoList.value[currentIndex.value].imgMode ? 0 : 1
  formInfoList.value[currentIndex.value].imageNodeDO.type = imgType.value
  formInfoList.value[currentIndex.value].cascaderValue = []
  formatImgSelect()
}
// 选择资源规格
const handleChange = (e:any) => {
  specificationInfo.value = resourceSpecList.value.filter((event:any) => event.id === e)
  if (runningMode.value === 4) {
    gpuManageSerialNoList.value = specificationInfo.value[0].availableSegGpuVOList
    singleGpuSegAmount.value = specificationInfo.value[0].singleGpuSegAmount
    formInfoList.value[currentIndex.value].gpuSerialNo = ''
    formInfoList.value[currentIndex.value].specificationNum = 0
  }
  formInfoList.value[currentIndex.value].cascaderValue = []
  formInfoList.value[currentIndex.value].linuxCapabilityS = []
  formInfoList.value[currentIndex.value].calculateDeviceId = specificationInfo.value[0].calculateDeviceId
  formInfoList.value[currentIndex.value].configInfo = specificationInfo.value
  formInfoList.value[currentIndex.value].downlinkRate = specificationInfo.value[0].downlinkRate
  formInfoList.value[currentIndex.value].uplinkRate = specificationInfo.value[0].uplinkRate
  formInfoList.value[currentIndex.value].netHostAuth = !!specificationInfo.value[0].netHostAuth
  formInfoList.value[currentIndex.value].privllegedAuth = !!specificationInfo.value[0].privllegedAuth
  formInfoList.value[currentIndex.value].isShowNetHost = !!specificationInfo.value[0].netHostAuth
  formInfoList.value[currentIndex.value].isShowPrivlleged = !!specificationInfo.value[0].privllegedAuth

  formInfoList.value[currentIndex.value].linuxCapabilitySList = specificationInfo.value[0].linuxCapabilityS
  if (runningMode.value !== 4) {
    if (specificationInfo.value[0].availableAmount >= 1) {
      formInfoList.value[currentIndex.value].specificationNum = 1
    } else {
      formInfoList.value[currentIndex.value].specificationNum = 0
    }
    const discountHour = BigNumber(specificationInfo.value[0].discountHour)
    const priceHour = BigNumber(specificationInfo.value[0].priceHour)
    const specificationNum = BigNumber(formInfoList.value[currentIndex.value].specificationNum)

    const orderPrice = toFixedNum(discountHour.multipliedBy(priceHour).multipliedBy(specificationNum).toNumber(), 2)
    formInfoList.value[currentIndex.value].orderDTO = {
      costType: 1,
      discount: specificationInfo.value[0].discountHour,
      orderPrice: orderPrice,
      unitPrice: specificationInfo.value[0].priceHour
    }
  }

  if (runningMode.value === 2) {
    resourceSpecList.value.forEach((ev:any) => {
      if (ev.id === e) {
        ev.disabled = true
      } else {
        ev.disabled = false
      }
      specificationIdArr.value.push(ev.id)
    })
    formInfoList.value.forEach((e:any) => {
      if (specificationIdArr.value.includes(e.specificationId)) {
        resourceSpecList.value.forEach((es:any) => {
          if (es.id === e.specificationId) {
            es.disabled = true
          }
        })
      }
    })
  }
  formatImgSelect()
}
const handleAmountChange = (e:any) => {
  if (e > formInfoList.value[currentIndex.value].configInfo[0].availableAmount) {
    message.error('所填规格数量大于当前可用规格数量', 0.5).then(() => {
      formInfoList.value[currentIndex.value].specificationNum = formInfoList.value[currentIndex.value].configInfo[0].availableAmount
      formInfoList.value[currentIndex.value].orderDTO = {
        costType: 1,
        discount: specificationInfo.value[0].discountHour,
        orderPrice: toFixedNum(BigNumber(specificationInfo.value[0].discountHour).multipliedBy(BigNumber(specificationInfo.value[0].priceHour).multipliedBy(BigNumber(formInfoList.value[currentIndex.value].specificationNum))).toNumber(), 2),
        unitPrice: specificationInfo.value[0].priceHour
      }
    })
  } else {
    formInfoList.value[currentIndex.value].orderDTO = {
      costType: 1,
      discount: specificationInfo.value[0].discountHour,
      orderPrice: toFixedNum(BigNumber(specificationInfo.value[0].discountHour).multipliedBy(BigNumber(specificationInfo.value[0].priceHour).multipliedBy(BigNumber(formInfoList.value[currentIndex.value].specificationNum))).toNumber(), 2),
      unitPrice: specificationInfo.value[0].priceHour
    }
  }
}
// 数据集版本
const dataSetOptions = ref([
  {
    value: '数据集',
    label: '数据集',
    disabled: true,
    children: []
  }
])
const queryDataSetVersion = () => {
  dataSetInfo().then(res => {
    const map = new Map()
    res.data.forEach((item:any) => {
      map.set('value', item.id)
      map.set('label', item.dataName)
      map.set('isLeaf', false)
      const obj:any = {}
      for (const [k, v] of map) {
        obj[k] = v
      }
      dataSetOptions.value.push(obj)
    })
  })
}
const dataSetLoadData = (selectedOptions:any) => {
  const targetOption = selectedOptions[selectedOptions.length - 1]
  targetOption.loading = true
  publishInfo(targetOption.value).then(res => {
    targetOption.loading = false
    if (res.data.length === 0) {
      targetOption.isLeaf = true
      message.error('当前数据集不存在版本', 0.5).then(() => {
        formInfoList.value[currentIndex.value].storageBucketArr = []
      })
    } else {
      targetOption.children = [
        {
          value: '版本',
          label: '版本',
          disabled: true
        }
      ]
      const map = new Map()
      res.data.forEach((item:any) => {
        map.set('label', item.versionNumber)
        map.set('value', item.filePath)
        const obj:any = {}
        for (const [k, v] of map) {
          obj[k] = v
        }
        targetOption.children.push(obj)
      })
    }
  })
}

const handleTabs = (index:number) => {
  currentIndex.value = index
}
const handleSubmit = () => {
  for (const i in formInfoList.value) {
    if (runningMode.value === 4) {
      if (formInfoList.value[i].gpuSerialNo === '') {
        message.error(`请选择实例${parseInt(i) + 1}的运算卡`)
        return false
      }
    }

    if (formInfoList.value[i].specificationId === '') {
      message.error(`请选择实例${parseInt(i) + 1}的资源规格`)
      return false
    }
    if (formInfoList.value[i].specificationNum === 0) {
      message.error(`实例${parseInt(i) + 1}的规格数量不能为0`)
      return false
    }
    if (imgType.value === 0) {
      if (formInfoList.value[i].cascaderValue.length === 0) {
        message.error(`请选择实例${parseInt(i) + 1}的平台镜像`)
        return false
      }
    } else {
      if (formInfoList.value[i].imageNodeDO.imageId === '') {
        message.error(`请选择实例${parseInt(i) + 1}的平台镜像`)
        return false
      }
    }
  }
  const params:any = []
  formInfoList.value.forEach((item:any) => {
    params.push({
      ...item,
      regionId: selectedArea.value,
      configInfo: toRaw(item.configInfo),
      storageBucket: item.storageBucketArr[1]
    })
  })
  params.forEach((e:any, index:number) => {
    e.taskName = (e.taskName + index) + (Math.random() * 10)
    delete e.imgMode
    delete e.configInfo
    delete e.cascaderValue
    delete e.storageBucketArr
    delete e.linuxCapabilitySList
    if (runningMode.value !== 4) {
      delete e.gpuMode
      delete e.gpuSerialNo
    } else {
      e.runnindMode = 1
      e.gpuMode = 4
    }
    e.netHostAuth = e.netHostAuth ? 1 : 0
    e.privllegedAuth = e.privllegedAuth ? 1 : 0
  })

  if (cancelFnList.length !== 0) {
    cancelFnList.value.forEach((cancelFn:any, index:number) => {
      cancelFn()
      delete cancelFnList.value[index]
    })
  }
  createTaskMultiMachine(params).then(() => {
    message.success('实例创建成功')
    window.history.back()
  })
}
export {
  isOpen,
  queryAreaList,
  querySpecification,
  handleChangeGpuNum,
  handleChangeSplitAmount,
  handleChangeRunningMode,
  formatImgSelect,
  handelImageChange,
  handleChangeImgMode,
  cascaderRef,
  handleChange,
  handleAmountChange,
  queryDataSetVersion,
  dataSetLoadData,
  handleTabs,
  handleSubmit,
  tableData,
  specificationInfo,
  areaList,
  cpuNumber,
  currentIndex,
  dataSetOptions,
  formInfoList,
  gpuManageSerialNoList,
  imageOptions,
  myImgList,
  resourceSpecList,
  resourceSpecListTableData,
  runningMode,
  selectedArea,
  singleGpuSegAmount
}
