
import { defineComponent } from 'vue'
import Condition from '@/components/condition/Condition'
import { useSubuserModule } from './user-management'
import StPagination from '@/components/st-pagination/StPagination.vue'
import { EllipsisOutlined } from '@ant-design/icons-vue'
import ConfigColumns from '@/components/config-columns/ConfigColumns.vue'

export default defineComponent({
  components: {
    Condition,
    StPagination,
    EllipsisOutlined,
    ConfigColumns
  },
  setup () {
    const {
      conditionList,
      currentCondition,
      handleChangeCondition,
      filterOptions,
      handleSelectChange,
      querySubuser,
      handleAddSubuser,
      visible,
      addForm,
      checkAccount,
      hostList,
      form,
      hanldleSubmit,
      hanleChangePage,
      total,
      columns,
      dataSource,
      handleChoostTools,
      title,
      handleSwitchUser,
      handleChangeAll,
      handleSetColumns,
      isAllHidden,
      filterColumns,
      filterList,
      handleQuery,
      handleDelOption,
      handleClear,
      tableHeight,
      handleChangeStatus,
      handleconfirm
    } = useSubuserModule()

    return {
      conditionList,
      currentCondition,
      handleChangeCondition,
      filterOptions,
      handleSelectChange,
      querySubuser,
      handleAddSubuser,
      visible,
      addForm,
      checkAccount,
      hostList,
      form,
      hanldleSubmit,
      hanleChangePage,
      total,
      columns,
      dataSource,
      handleChoostTools,
      title,
      handleSwitchUser,
      handleChangeAll,
      handleSetColumns,
      isAllHidden,
      filterColumns,
      filterList,
      handleconfirm,
      handleChangeStatus,
      handleQuery,
      handleDelOption,
      handleClear,
      tableHeight
    }
  }
})
