
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import LoginRegisterBg from '@/components/Login-register-bg/LoginRegisterBg.vue'
import { queryImgCodeApi, setUserInfoApi } from '@/api/login'
import { RegisterInterface } from '@/interface/registerInterface'
import { useCancelAjax } from '@/utils/cancel-ajax'
import { sendPhoneCodeByparamsApi } from '@/api/register'
import { message } from 'ant-design-vue'
import { PhoneValidator } from '@/interface/validator'
import { Rule } from 'ant-design-vue/es/form'
import { usePasswordVerifiers } from '@/utils/passwordValidator'
import { encrtpt } from '@/utils/jsencrypt'
import { getToken } from '@/utils/token'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    LoginRegisterBg
  },
  setup () {
    const router = useRouter()
    const {
      cancelFnList,
      createCancelList
    } = useCancelAjax()
    // 图片验证码
    const imgCodeInfo: RegisterInterface.imgCodeInfoFace = reactive({
      codeId: '',
      codeNum: ''
    })
    const queryImgCodeInfo = async () => {
      const res = await queryImgCodeApi()
      imgCodeInfo.codeId = res.data.codeId
      imgCodeInfo.codeNum = res.data.codeNum
    }
    const handleRefreshImgCode = () => {
      queryImgCodeInfo()
    }

    // 手机验证码
    const second = ref<number>(60)
    const countDown = ():void => {
      const timer = setInterval(() => {
        if (second.value === 0) {
          second.value = 60
          clearInterval(timer)
        } else {
          second.value = second.value - 1
        }
      }, 1000)
    }
    const handleSendCode = ():void => {
      if (cancelFnList.length !== 0) {
        cancelFnList.value.forEach((cancelFn: any, index: number) => {
          cancelFn()
          delete cancelFnList.value[index]
        })
      }
      const params = {
        codeId: imgCodeInfo.codeId,
        codeNum: formInfo.code as string,
        phone: formInfo.account
      }
      sendPhoneCodeByparamsApi(params, createCancelList).then(() => {
        countDown()
      }).catch(err => {
        if (!(err.code === 'ERR_CANCELED')) {
          queryImgCodeInfo()
        }
      })
    }

    const disabled = computed(() => {
      return !(formInfo.account && formInfo.code)
    })

    // 完善信息
    const checked = ref<boolean>(false)
    const formInfo = reactive({
      account: '',
      password: '',
      code: '',
      imgVerifyCode: ''
    })
    const handleSet = (params: any) => {
      if (!checked.value) {
        message.warning(
          '请先阅读并同意《' +
            '思腾合力AI开放平台' +
            '服务条款》《' +
            '思腾合力AI开放平台' +
            '隐私政策》'
        )
      } else {
        const data = {
          phone: params.account,
          password: encrtpt(params.password) as string,
          smsCode: params.imgVerifyCode,
          token: getToken() as string
        }
        setUserInfoApi(data).then(() => {
          message.success('信息完善成功')
          router.push({
            path: '/ai-center/ControlConsole'
          })
        })
      }
    }

    const validatorPhone = async (_rule:Rule, value: string): Promise<any> => {
      if (!value) {
        return Promise.reject(new Error('请输入手机号'))
      } else {
        const phoneValidator = new PhoneValidator()
        if (phoneValidator.isAcceptable(value)) {
          return Promise.resolve()
        } else {
          return Promise.reject(new Error('手机号格式错误'))
        }
      }
    }

    const {
      queryPasswordRule,
      checkPassword
    } = usePasswordVerifiers()

    onMounted(() => {
      queryImgCodeInfo()
      queryPasswordRule()
    })

    return {
      imgCodeInfo,
      handleRefreshImgCode,
      second,
      handleSendCode,
      formInfo,
      disabled,
      handleSet,
      checked,
      validatorPhone,
      checkPassword
    }
  }
})
