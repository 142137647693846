
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['click'],
  props: {
    disabled: {
      type: Boolean
    }
  },
  setup (props, ctx) {
    const btnClick = () => {
      ctx.emit('click')
    }
    return {
      btnClick
    }
  }
})
