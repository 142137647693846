
import { DynamicConditionSpace } from '@/interface/condition'
import { querySystemImageFace } from '@/interface/imageCenter'
import ConditionSearch from '@/components/condition/Condition'
import ConfigColumns from '@/components/config-columns/ConfigColumns.vue'
import { querySystemImageApi } from '@/api/image-center'
import StPagination from '@/components/st-pagination/StPagination.vue'
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import { TableColumnTypeConfig } from '@/interface/table'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    ConditionSearch,
    StPagination,
    ConfigColumns
  },
  setup () {
    const { t } = useI18n()
    const currentCondition = ref<string>('imageName')

    const conditionList = ref<DynamicConditionSpace.ConditionFace[]>([
      {
        name: t('image.imageName'),
        type: 'AInput',
        key: 'imageName',
        value: '',
        placeholder: t('image.inputPlaceholder')
      },
      {
        name: 'Python',
        type: 'AInput',
        key: 'pythonEdition',
        value: '',
        placeholder: t('image.inputPlaceholder')
      },
      {
        name: 'Toolkit',
        type: 'AInput',
        key: 'toolkitEdition',
        value: '',
        placeholder: t('image.inputPlaceholder')
      }
    ])

    const filterOptions = ref<DynamicConditionSpace.FilterConditionFace[]>([])

    const pageParams: { pageNum: number; pageSize: number; } = reactive({
      pageNum: 1,
      pageSize: 10
    })

    const conditionParams = computed<querySystemImageFace>(() => {
      const map = new Map()
      const searchForm: any = {
        ...pageParams
      }
      conditionList.value.forEach(item => {
        map.set(item.key, item.value)
      })
      for (const [k, v] of map) {
        searchForm[k] = v
      }
      return searchForm
    })

    const handleChangeCondition = (condition: string) => {
      currentCondition.value = condition
      conditionList.value.forEach(item => {
        if (item.key === condition) {
          if (item.type === 'ASelect') {
            if (item.multiple) {
              item.value = []
            } else {
              item.value = undefined
            }
          } else {
            item.value = ''
          }
        }
      })
    }

    const handleSelectChange = (params: DynamicConditionSpace.ConditionFace) => {
      conditionList.value.forEach(item => {
        if (item.key === params.type) {
          if (item.type === 'ASelect') {
            const arr = item.options?.filter(item => item.value === params.value)[0]
            item.label = arr?.label
          }
          item.value = params.value
        }
      })
    }

    const handleQuery = () => {
      const selectConditionParams = conditionList.value.filter(item => item.key === currentCondition.value)[0]
      if (selectConditionParams.value === '' || selectConditionParams.value === undefined || (selectConditionParams.value as []).length === 0) {
        querySystemImage()
      } else {
        if (filterOptions.value.length > 0) {
          const index = filterOptions.value.findIndex((item) => item.key === selectConditionParams.key)
          if (index === -1) {
            filterOptions.value.push({
              name: selectConditionParams.name,
              type: selectConditionParams.type,
              label: selectConditionParams.label as string,
              key: selectConditionParams.key,
              value: selectConditionParams.value
            })
          } else {
            filterOptions.value.splice(index, 1, {
              name: selectConditionParams.name,
              type: selectConditionParams.type,
              label: selectConditionParams.label as string,
              key: selectConditionParams.key,
              value: selectConditionParams.value
            })
          }
        } else {
          filterOptions.value.push({
            name: selectConditionParams.name,
            type: selectConditionParams.type,
            label: selectConditionParams.label as string,
            key: selectConditionParams.key,
            value: selectConditionParams.value
          })
        }
        querySystemImage()
      }
    }

    const handleDelOption = (params: {index: number, key: string}) => {
      filterOptions.value.splice(params.index, 1)
      conditionList.value.forEach((item: any) => {
        if (item.key === params.key) {
          if (item.type === 'ASelect') {
            item.value = undefined
          } else {
            item.value = ''
          }
        }
      })
      querySystemImage()
    }

    const handleClear = () => {
      filterOptions.value = []
      conditionList.value.forEach(item => {
        if (item.type === 'ASelect') {
          item.value = undefined
        } else {
          item.value = ''
        }
      })
      querySystemImage()
    }

    const dataSource = ref<any[]>([])

    const total = ref<number>(0)

    const columns = ref<TableColumnTypeConfig[]>([
      {
        title: t('image.columns.number'),
        dataIndex: 'id',
        isShow: true,
        disabled: true,
        fixed: 'left'
      },
      {
        title: t('image.columns.imageName'),
        dataIndex: 'imageName',
        isShow: true,
        disabled: false,
        width: 120
      },
      {
        title: t('image.columns.address'),
        dataIndex: 'filePath',
        isShow: true,
        disabled: false,
        width: 400
      },
      {
        title: `${t('image.columns.frameName')}/${t('image.columns.version')}`,
        dataIndex: 'frameName',
        isShow: true,
        disabled: false,
        width: 180,
        customRender: ({ text, record }) => {
          return `${text}/${record.edition}`
        }
      },
      {
        title: t('image.columns.structure'),
        dataIndex: 'structure',
        isShow: true,
        disabled: false
      },
      {
        title: 'Python',
        dataIndex: 'pythonEdition',
        isShow: true,
        disabled: false
      },
      {
        title: 'Toolkit',
        dataIndex: 'toolkitEdition',
        isShow: true,
        disabled: false
      },
      {
        title: t('image.columns.state'),
        dataIndex: 'status',
        isShow: true,
        disabled: false,
        align: 'center'
      },
      {
        title: t('image.columns.lockState'),
        dataIndex: 'lockStatus',
        isShow: true,
        disabled: false,
        align: 'center'
      },
      {
        title: t('image.columns.createBy'),
        dataIndex: 'createBy',
        isShow: true,
        disabled: false
      },
      {
        title: t('image.columns.createTime'),
        dataIndex: 'createTime',
        isShow: true,
        disabled: false,
        fixed: 'right',
        width: 150
      }
    ])

    const tableHeight = ref<number>(0)

    const setTable = () => {
      const height = document.querySelector('.table-box')?.clientHeight
      tableHeight.value = height as number
    }

    const querySystemImage = async () => {
      const res = await querySystemImageApi(conditionParams.value)
      dataSource.value = res.data.rows
      total.value = res.data.total
      setTable()
    }

    const filterColumns = ref<TableColumnTypeConfig[]>([])

    const isAllHidden = ref<boolean>(false)

    const handleChangeAll = (params: boolean) => {
      columns.value.forEach(item => {
        if (!item.disabled) {
          item.isShow = params
        }
      })
    }

    const handleSetColumns = () => {
      filterColumns.value = []
      const allDispear: any[] = []
      columns.value.forEach(item => {
        if (item.isShow) {
          allDispear.push(item)
        }
      })
      if (allDispear.length > 0) {
        isAllHidden.value = false
        filterColumns.value = allDispear
      } else {
        isAllHidden.value = true
        filterColumns.value = []
      }
    }

    const handleChangePage = (params: { pageNum: number; pageSize: number }) => {
      pageParams.pageNum = params.pageNum
      pageParams.pageSize = params.pageSize
      querySystemImage()
    }

    onMounted(() => {
      querySystemImage()
    })

    return {
      currentCondition,
      conditionList,
      filterOptions,
      dataSource,
      total,
      columns,
      pageParams,
      handleChangeCondition,
      handleSelectChange,
      handleQuery,
      handleDelOption,
      handleClear,
      tableHeight,
      handleSetColumns,
      handleChangeAll,
      filterColumns,
      isAllHidden,
      handleChangePage
    }
  }
})
