
import { TableColumnTypeConfig } from '@/interface/table'
import { computed, defineComponent, ref, PropType, watch } from 'vue'

export default defineComponent({
  emits: ['changeAll', 'confirm'],
  props: {
    columns: {
      type: Array as PropType<TableColumnTypeConfig[]>,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const visible = ref(false)
    const checkedAll = ref<boolean>(true)

    watch(() => props.columns, () => {
      if (props.columns.filter((item: any) => item.isShow !== true).length > 0) {
        checkedAll.value = false
      } else {
        checkedAll.value = true
      }
    }, {
      deep: true
    })

    const computedColums = computed(() => {
      const isAll = props.columns.filter((item:any) => item.isShow !== true)
      return isAll
    })

    const handleChangeAll = (e:any) => {
      emit('changeAll', e.target.checked)
    }

    const handleConfirm = () => {
      emit('confirm')
      visible.value = false
    }

    return {
      visible,
      checkedAll,
      computedColums,
      handleChangeAll,
      handleConfirm
    }
  }
})
