
import { defineComponent, onMounted, reactive, toRefs, ref, watchEffect, watch } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'
import {
  getMakeInvoice,
  getAmountInvoiced,
  getNewestInvoice,
  makeInvoice
} from '@/api/myInvoice'
import ConfigColumns from '@/components/config-columns/ConfigColumns.vue'
export default defineComponent({
  components: {
    ConfigColumns
  },
  setup () {
    const { t } = useI18n()
    const invocieMoney:any = reactive({
      cumulativeRecharge: 0,
      invoicableMoney: 0,
      rechargeInvoiced: 0
    })
    const tableHeight = ref((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 540)
    window.addEventListener('resize', function () {
      tableHeight.value = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 540
    })
    // 监听变化
    watch(() => tableHeight.value, (newValue) => {
      if (newValue) {
        tableHeight.value = newValue
      }
    })
    const queryCanApplyMoney = () => {
      getAmountInvoiced().then((res) => {
        const { canInvoicedAmount, alreadyInvoicedAmount, accuRechargeAmount } = res.data
        invocieMoney.cumulativeRecharge = accuRechargeAmount // 累计充值
        invocieMoney.invoicableMoney = canInvoicedAmount // 已开票
        invocieMoney.rechargeInvoiced = alreadyInvoicedAmount // 可开票
      })
    }

    const columns = ref([
      {
        title: t('costCenter.applyTime'),
        dataIndex: 'createTime',
        isShow: true,
        disabled: true
      },
      {
        title: t('costCenter.invoiceTitle'),
        dataIndex: 'invoiceTitle',
        isShow: true
      },
      {
        title: t('costCenter.invoiceTotal'),
        dataIndex: 'invoiceMoney',
        customRender: ({ text }:any) => {
          return `${text}元`
        },
        isShow: true
      },
      {
        title: t('costCenter.billingMethod'),
        dataIndex: 'invoiceMode',
        customRender: ({ text }:any) => {
          return text === 1 ? '电子发票' : '邮寄发票'
        },
        isShow: true
      },
      {
        title: t('costCenter.invoiceType'),
        dataIndex: 'invoiceType',
        customRender: ({ text }:any) => {
          return text === 1 ? '增值税普通发票' : '增值税专用发票'
        },
        isShow: true
      },
      {
        title: t('costCenter.invoiceStatus'),
        dataIndex: 'invoiceStatus',
        align: 'center',
        isShow: true
      },
      {
        title: t('costCenter.rejection'),
        dataIndex: 'remark',
        isShow: true
      }
    ])
    const tableData = ref([])
    const state = reactive({
      indeterminate: true,
      checkAll: false,
      checkedList: []
    })
    const filterColumns:any = ref([])
    const total = ref(0)
    const requestBody = reactive({
      pageNum: 1,
      pageSize: 10
    })
    const queryApplyInvoiceList = () => {
      getMakeInvoice(requestBody).then((res) => {
        tableData.value = res.data.rows
        total.value = res.data.total
      })
    }
    const onChange = (e:number) => {
      requestBody.pageNum = e
      queryApplyInvoiceList()
    }

    const handlePageSize = (pageNum:number, pageSize:number) => {
      requestBody.pageNum = pageNum
      requestBody.pageSize = pageSize
      queryApplyInvoiceList()
    }

    // 查询发票信息
    const invoiceInfo = ref('')
    const queryInvoiceInfo = () => {
      getNewestInvoice().then((res) => {
        invoiceInfo.value = res.data
      })
    }

    const route = useRoute()
    const routeVisible = ref(false)
    watchEffect(() => {
      if (route.path.includes('invoiceSet')) {
        routeVisible.value = true
      } else {
        routeVisible.value = false
        queryInvoiceInfo()
      }
    })

    const invoiceVisble = ref(false)
    const invoiceMoney = ref(1)
    const handleApplyInvoice = () => {
      invoiceVisble.value = true
    }

    const handleconfirm = () => {
      if (!invoiceMoney.value) {
        message.error(t('costCenter.messageWarningOne'))
        return false
      } else if (invoiceMoney.value > parseFloat(invocieMoney.invoicableMoney)) {
        message.error(t('costCenter.messageWarningTwo'))
        return false
      }
      if (invoiceInfo.value === null) {
        message.error(t('costCenter.messageWarningThree'))
        return false
      }
      getNewestInvoice().then((res) => {
        if (res.data == null) {
          message.error(t('costCenter.messageWarningThree'))
        } else {
          const request = {
            invoiceId: res.data.id,
            invoiceMoney: invoiceMoney.value
          }
          makeInvoice(request).then(() => {
            message.success(t('costCenter.messageSuccess'), 0.5).then(() => {
              queryApplyInvoiceList()
              queryCanApplyMoney()
              invoiceVisble.value = false
            })
          })
        }
      })
    }
    const isAllHidden = ref(false)
    const handleChangeAll = (params: any) => {
      columns.value.forEach((item: any) => {
        if (!item.disabled) {
          item.isShow = params
        }
      })
    }
    const handleSetColumns = () => {
      filterColumns.value = []
      const allDispear = columns.value.filter((item: any) => item.isShow)
      if (allDispear.length > 0) {
        isAllHidden.value = false
        columns.value.forEach((item: any) => {
          if (item.isShow) {
            filterColumns.value.push(item)
          }
        })
      } else {
        isAllHidden.value = true
        filterColumns.value = []
      }
    }
    onMounted(() => {
      queryCanApplyMoney()
      queryApplyInvoiceList()
      queryInvoiceInfo()
    })
    return {
      ...toRefs(invocieMoney),
      columns,
      tableData,
      invoiceInfo,
      routeVisible,
      handleApplyInvoice,
      invoiceVisble,
      invoiceMoney,
      handleconfirm,
      total,
      onChange,
      handlePageSize,
      requestBody,
      tableHeight,
      filterColumns,
      state,
      handleSetColumns,
      handleChangeAll,
      isAllHidden
    }
  }
})
