import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tips-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_sub_menu = _resolveComponent("sub-menu", true)!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!

  return (!_ctx.menuInfo.hidden)
    ? (_openBlock(), _createBlock(_component_a_sub_menu, {
        class: _normalizeClass(_ctx.collapsed ? 'is-icon-sub' : 'not-icon-sub'),
        key: _ctx.menuInfo.path
      }, {
        icon: _withCtx(() => [
          _createElementVNode("span", null, [
            _createVNode(_component_svg_icon, {
              "icon-class": _ctx.menuInfo.meta.icon
            }, null, 8, ["icon-class"])
          ])
        ]),
        title: _withCtx(() => [
          _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.menuInfo.meta.menuTitle), 513), [
            [_vShow, !_ctx.collapsed]
          ])
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuInfo.children, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.path
            }, [
              (!item.children && !item.hidden)
                ? (_openBlock(), _createBlock(_component_a_menu_item, {
                    key: item.path
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(item.meta.menuTitle), 1)
                    ]),
                    _: 2
                  }, 1024))
                : (item.children && item.children.length && item.children.find(e => e.hidden))
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: item.path,
                      class: _normalizeClass(_ctx.collapsed ? 'is-icon' : 'not-icon')
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_svg_icon, {
                          "icon-class": item.meta.icon
                        }, null, 8, ["icon-class"])
                      ]),
                      title: _withCtx(() => [
                        _createElementVNode("span", _hoisted_1, _toDisplayString(item.meta.menuTitle), 1)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(item.meta.menuTitle), 1)
                      ]),
                      _: 2
                    }, 1032, ["class"]))
                  : (_openBlock(), _createBlock(_component_sub_menu, {
                      key: 2,
                      "menu-info": item
                    }, null, 8, ["menu-info"]))
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}