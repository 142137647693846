import { h, computed, resolveComponent, PropType, ref } from 'vue'
import { InputGroup, Select, SelectOption, Row, Col } from 'ant-design-vue'
import { DynamicConditionSpace } from '@/interface/condition'
import { useI18n } from 'vue-i18n'
import './condition.scss'

function renderCondition (h: any, condition: any, emit: any, isFocus: any) {
  if (condition.value.type === 'ASelect') {
    return h(
      resolveComponent(condition.value.type),
      {
        value: condition.value.value,
        placeholder: `${condition.value.placeholder}${condition.value.name}`,
        mode: condition.value.multiple ? 'multiple' : '',
        onChange (event: string | number) {
          emit('change', {
            value: event,
            type: condition.value.key,
            name: condition.value.name
          })
        },
        onFocus () {
          isFocus.value = true
        },
        onBlur () {
          isFocus.value = false
        }
      },
      {
        default: () => condition.value.options.map((item: any) => {
          return h(
            SelectOption,
            {
              value: item.value
            },
            {
              default: () => h('span', item.label)
            }
          )
        })
      }
    )
  } else if (condition.value.type === 'AInput') {
    return h(
      resolveComponent(condition.value.type),
      {
        value: condition.value.value,
        placeholder: `${condition.value.placeholder}${condition.value.name}`,
        onChange (event: any) {
          emit('change', {
            value: event.target.value,
            type: condition.value.key,
            name: condition.value.name
          })
        },
        onfocus () {
          isFocus.value = true
        },
        onblur () {
          isFocus.value = false
        }
      }
    )
  }
}

export default {
  emits: ['changeCondition', 'change', 'query', 'clear', 'del'],
  props: {
    conditionList: {
      type: Array as PropType<DynamicConditionSpace.ConditionFace[]>,
      default: () => []
    },
    currentCondition: {
      type: String,
      default: ''
    },
    filterOptions: {
      type: Array as PropType<DynamicConditionSpace.FilterConditionFace[]>,
      default: () => []
    }
  },
  setup (props: any, { emit, slots }: any) {
    const isFocus = ref<boolean>(false)
    const { t } = useI18n()
    const condition = computed(() => {
      return props.conditionList.filter((item: any) => item.key === props.currentCondition)[0]
    })
    return () => h(
      'div',
      { class: 'form-box' },
      [
        h(
          'div',
          {
            class: 'condition-box'
          },
          [
            h(
              'div',
              {
                class: 'btn-group'
              },
              slots.btn ? slots.btn() : ''
            ),
            h(
              'div',
              {
                class: 'condition'
              },
              h(InputGroup, null, {
                default: () => h(Row, null, {
                  default: () => [
                    h(
                      Col,
                      { span: 9 },
                      {
                        default: () => h(
                          'div',
                          {
                            class: `select-input-box ${isFocus.value ? 'focus' : ''}`
                          },
                          [
                            h(
                              Select,
                              {
                                style: {
                                  minWidth: '140px'
                                },
                                value: props.currentCondition,
                                onChange (event) {
                                  emit('changeCondition', event)
                                  isFocus.value = true
                                },
                                onBlur () {
                                  isFocus.value = false
                                }
                              },
                              {
                                default: () => props.conditionList.map((item:any) => {
                                  return h(
                                    SelectOption,
                                    {
                                      value: item.key
                                    },
                                    {
                                      default: () => h('span', item.name)
                                    }
                                  )
                                })
                              }
                            ),
                            h(
                              'div',
                              {
                                class: 'dynamic-input-select',
                                style: {
                                  width: '100%'
                                }
                              },
                              h(
                                renderCondition(h, condition, emit, isFocus)
                              )
                            )
                          ]
                        )
                      }
                    ),
                    h(
                      Col,
                      {
                        span: 4,
                        style: {
                          marginLeft: '16px'
                        }
                      },
                      {
                        default: () => h(
                          resolveComponent('StButton'),
                          {
                            onClick () {
                              emit('query')
                            },
                            type: 'default'
                          },
                          {
                            default: () => t('image.search')
                          }
                        )
                      }
                    )
                  ]
                })
              })
            )

          ]
        ),
        h(
          'ul',
          {
            class: 'filter-box',
            style: {
              marginTop: props.filterOptions.length > 0 ? '12px' : '0'
            }
          },
          [
            props.filterOptions.map((item: any, index: number) => {
              return h(
                'li',
                {
                  class: 'filter-item'
                },
                [
                  h(
                    'span',
                    `${item.name}: ${item.type === 'ASelect' ? item.label : item.value}`
                  ),
                  h(
                    resolveComponent('SvgIcon'),
                    {
                      iconClass: 'ic_guanbi',
                      onClick () {
                        emit('del', {
                          index: index,
                          key: item.key,
                          filtered: item.filtered
                        })
                      }
                    }
                  )
                ]
              )
            }),
            props.filterOptions.length > 0 ? h(
              'li',
              {
                class: 'clear',
                onClick () {
                  emit('clear')
                }
              },
              '清除'
            ) : ''
          ]
        )
      ]
    )
  }
}
