import { ref, reactive, computed } from 'vue'
import { BigNumber } from 'bignumber.js'
import { toFixedNum } from '@/utils/toFixedNum'
import { balance, getList, taskEscalationVisible, taskRenewalVisible } from '../myTaskModule'
import { message } from 'ant-design-vue'
import { billingChanges, selectProductPriceById, taskRenewal } from '@/api/myTask'
interface changeFeeParamsState {
  costType: number | string;
  rentTime: number;
}
// 计费变更
const changeTaskBtnState = ref(false)
const specificationNumFee = ref<number>(0)
const changeFeeForm = ref<any>(null)
const changeFee = ref<boolean>(false)
const costType = ref('')
const changeFeeParams: changeFeeParamsState = reactive({
  costType: '',
  rentTime: 1
})
const priceDay = ref<number>(0)
const priceMonth = ref<number>(0)
const priceHour = ref<number>(0)
const discountDay = ref<number>(0)
const discountMonth = ref<number>(0)
const discountHour = ref<number>(0)
const taskId = ref<string>('')

const computedOrderPrice = computed(() => {
  if (changeFeeParams.costType === 2) {
    return priceDay.value
  } else if (changeFeeParams.costType === 3) {
    return priceMonth.value
  } else {
    return ''
  }
})
const computedDiscountPirce = computed(() => {
  if (changeFeeParams.costType === 2) {
    if (discountDay.value === 1) {
      return '无折扣'
    } else {
      return parseFloat(
        toFixedNum(
          new BigNumber(discountDay.value).multipliedBy(10).toNumber(),
          2
        )
      )
    }
  } else if (changeFeeParams.costType === 3) {
    if (discountDay.value === 1) {
      return '无折扣'
    } else {
      return parseFloat(
        toFixedNum(
          new BigNumber(discountMonth.value).multipliedBy(10).toNumber(),
          2
        )
      )
    }
  } else {
    return ''
  }
})
const computedTotalPrice = computed(() => {
  if (changeFeeParams.costType === 2) {
    if (changeFeeParams.rentTime === null) {
      return ''
    }
    const time = new BigNumber(changeFeeParams.rentTime)
    const price = new BigNumber(priceDay.value)
    const totalPrice = toFixedNum(
      time
        .multipliedBy(price)
        .multipliedBy(discountDay.value)
        .multipliedBy(specificationNumFee.value)
        .toNumber(),
      2
    )
    if (totalPrice > balance.value) {
      changeTaskBtnState.value = true
    } else {
      changeTaskBtnState.value = false
    }
    return totalPrice
  } else if (changeFeeParams.costType === 3) {
    if (changeFeeParams.rentTime === null) {
      return ''
    }
    const time = new BigNumber(changeFeeParams.rentTime)
    const price = new BigNumber(priceMonth.value)
    const totalPrice = toFixedNum(
      time
        .multipliedBy(price)
        .multipliedBy(discountMonth.value)
        .multipliedBy(specificationNumFee.value)
        .toNumber(),
      2
    )
    if (totalPrice > balance.value) {
      changeTaskBtnState.value = true
    } else {
      changeTaskBtnState.value = false
    }
    return totalPrice
  } else {
    return ''
  }
})
const handleConfirmChangeFee = () => {
  changeFeeForm.value
    .validate()
    .then(() => {
      const params:any = {
        costType: changeFeeParams.costType,
        discount:
          changeFeeParams.costType === 2 ? discountDay.value : discountMonth.value,
        orderPrice: computedTotalPrice.value,
        rentTime: changeFeeParams.rentTime,
        taskId: taskId.value,
        unitPrice: changeFeeParams.costType === 2 ? priceDay.value : priceMonth.value
      }
      billingChanges(params).then(() => {
        message.success('计费变更成功', 0.5).then(() => {
          changeFee.value = false
          getList()
        })
      })
    })
    .catch((err:any) => {
      return err
    })
}
// 计费变更
const handleFeeChange = (item:any) => {
  changeFee.value = true
  changeFeeParams.costType = ''
  changeFeeParams.rentTime = 1
  specificationNumFee.value = item.specificationNum
  selectProductPriceById(item).then((res) => {
    priceDay.value = res.data.priceDay
    priceMonth.value = res.data.priceMonth
    discountDay.value = res.data.discountDay
    discountMonth.value = res.data.discountMonth
    taskId.value = item.id
  })
}
// 实例升配
const handleTaskEscalate = (item:any) => {
  taskEscalationVisible.value = true
  selectProductPriceById(item).then((res) => {
    priceHour.value = res.data.priceHour
    discountHour.value = res.data.discountHour
    taskId.value = item.id
  })
}
// 实例续租
const handleTaskRelet = (item:any) => {
  taskRenewalVisible.value = true
  changeFeeParams.costType = item.costType
  specificationNumFee.value = item.specificationNum
  changeFeeParams.rentTime = 1
  selectProductPriceById(item).then((res) => {
    priceDay.value = res.data.priceDay
    priceMonth.value = res.data.priceMonth
    discountDay.value = res.data.discountDay
    discountMonth.value = res.data.discountMonth
    taskId.value = item.id
  })
}
const handleTaskRenewal = () => {
  if (changeFeeParams.rentTime === null) {
    message.error('请填写使用时长')
    return false
  } else {
    const params = {
      discount:
        changeFeeParams.costType === 2 ? discountDay.value : discountMonth.value,
      orderPrice: computedTotalPrice.value,
      rentTime: changeFeeParams.rentTime,
      taskId: taskId.value,
      unitPrice: changeFeeParams.costType === 2 ? priceDay.value : priceMonth.value
    }
    taskRenewal(params).then(() => {
      message.success('实例续租成功', 0.5).then(() => {
        getList()
        taskRenewalVisible.value = false
      })
    })
  }
}
export {
  specificationNumFee,
  changeFeeForm,
  changeFee,
  costType,
  changeFeeParams,
  priceDay,
  priceMonth,
  priceHour,
  discountDay,
  discountMonth,
  discountHour,
  taskId,
  computedOrderPrice,
  computedDiscountPirce,
  computedTotalPrice,
  handleConfirmChangeFee,
  handleFeeChange,
  changeTaskBtnState,
  handleTaskEscalate,
  handleTaskRelet,
  taskRenewalVisible,
  handleTaskRenewal
}
