import { ref, reactive } from 'vue'
import { getTaskPage, updateNote } from '@/api/myTask'
import { message } from 'ant-design-vue'
import _ from 'lodash'
import i18n from '@/i18n/i18n'
const { t } = i18n.global
const title = ref('')
const adminFlag = ref(false)// 后台管理员标识
const costFlag = ref()// 判断是否开启计费服务标识
const total = ref(0)// 页码总数
const timer:any = ref(null)// 计时器定时刷新界面
const editableData:any = ref([]) // 列表备注字段可编辑数据

interface SearchFormParamsState {
  taskId: string | null,
  calculateDeviceId: string | null,
  costTypeS: any[] | null,
  userId:string | null,
  pageReq: {
    pageNum: number,
    pageSize: number
  },
  taskName:string | null,
  statusS:any[] | null,
  regionS: any[] | null,
  hostStatusS: any[] | null,
  runnindModeS: any[]| null
}
const searchForm:SearchFormParamsState = reactive({
  taskId: '',
  calculateDeviceId: '',
  costTypeS: null,
  userId: '',
  pageReq: {
    pageNum: 1,
    pageSize: 10
  },
  taskName: '',
  statusS: null,
  regionS: null,
  hostStatusS: null,
  runnindModeS: null
})
// 更换镜像
interface ImageParamsState {
  type:number;
  imageId?:string;
  edition? :string;
  frameName?:string;
  pythonEdition? :string;
  toolkitEdition? :string;
}
const primaryImage = ref<string>('')
const changeImageVisible = ref(true)
const changeImageDialogVisible = ref(false)// 更换镜像弹窗
const imageOptions = ref([])
const imageState:ImageParamsState = reactive({
  type: 0,
  imageId: '',
  frameName: '',
  edition: '',
  pythonEdition: '',
  toolkitEdition: ''
})
const imageForm = reactive({
  image: []
})
const imageFormRef = ref()
// 打包镜像
interface packageImageFormParamsState {
  imageName: string,
  description?: string,
}
const formRef = ref()
const packageVisible = ref(false)
const form:packageImageFormParamsState = reactive({
  imageName: '',
  description: ''
})
// 操作日志
const operateLogDialogVisible:any = ref(false)
const operateDataSource:any = ref([])
const operateColumns = ref([
  {
    title: t('myTask.operatingTime'),
    dataIndex: 'createTime',
    key: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    ellipsis: true
  },
  {
    title: t('myTask.action'),
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    ellipsis: true
  },
  {
    title: t('myTask.createByTask'),
    dataIndex: 'createBy',
    key: 'createBy',
    scopedSlots: { customRender: 'createBy' },
    ellipsis: true
  }
])
// 点击主列表选择行数据
const rowData = ref()// 存放已选择的单行数据
const selectRowData:any = ref([])
const selectedIds:any = ref([])
const dataSource:any = ref([]) // 主界面数据
// 任务关机
const stopLoading = ref(false)
const stopDialogVisible = ref(false)
const stopTips = ref('')
const taskInfo:any = reactive({
  taskReleaseHours: '',
  cacheReleaseHours: ''
})
// 账户余额
const balance = ref(0)
// 实例升配
const changeTaskBtnState = ref(false)
const taskEscalationVisible = ref(false)
const specificationNum = ref(0)
// 实例续租
const taskRenewalVisible = ref(false)
// 主界面查询
const getList = () => {
  getTaskPage(searchForm).then((res) => {
    dataSource.value = res.data.records
    total.value = res.data.total
  })
}
const handleSearch = () => {
  searchForm.pageReq.pageNum = 1
  getList()
}
// 分页
const handlePageSize = (current:any, size:any) => {
  searchForm.pageReq.pageNum = current
  searchForm.pageReq.pageSize = size
  getList()
}
// 选择行数据
function onSelectChange (selectedRowKeys:any, selectedRows:any) {
  selectedIds.value = selectedRowKeys
  selectRowData.value = selectedRows[0]
}
// 修改备注
const handleSaveNote = _.debounce(async (key) => {
  let note = ''
  if (editableData.value[key].note) {
    note = editableData.value[key].note
  }
  await updateNote(editableData.value[key].id, { note: note }).then(() => {
    message.success('修改成功')
    getList()
    Object.assign(
      dataSource.value.filter((item:any, index:number) => key === index)[0],
      editableData.value[key]
    )
    delete editableData.value[key]
  })
}, 500)
const handleEditNote = (key:number) => {
  editableData.value[key] = _.cloneDeep(
    dataSource.value.filter((items:any, index:number) => key === index)[0]
  )
}
export {
  changeImageDialogVisible,
  costFlag,
  total,
  packageVisible,
  timer,
  editableData,
  form,
  adminFlag,
  formRef,
  rowData,
  operateLogDialogVisible,
  operateDataSource,
  operateColumns,
  searchForm,
  selectRowData,
  selectedIds,
  dataSource,
  taskInfo,
  primaryImage,
  changeImageVisible,
  imageOptions,
  imageState,
  imageForm,
  imageFormRef,
  balance,
  stopLoading,
  stopDialogVisible,
  stopTips,
  getList,
  onSelectChange,
  handlePageSize,
  changeTaskBtnState,
  taskEscalationVisible,
  specificationNum,
  taskRenewalVisible,
  title,
  handleSaveNote,
  handleEditNote,
  handleSearch
}
