import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b9c1875e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basic-container inner-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "operate-username" }
const _hoisted_5 = { class: "username" }
const _hoisted_6 = {
  key: 0,
  class: "name"
}
const _hoisted_7 = { class: "user-id" }
const _hoisted_8 = { class: "divider-box" }
const _hoisted_9 = { class: "form-box" }
const _hoisted_10 = { class: "cropper-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_form_info = _resolveComponent("form-info")!
  const _component_vue_cropper = _resolveComponent("vue-cropper")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: "avatarFile",
      type: "file",
      hidden: "",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectFile($event))),
      ref: "inputFile"
    }, null, 544),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", {
        class: "user-profile-picture",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChangeUserProfilePicture && _ctx.handleChangeUserProfilePicture(...args)))
      }, [
        _createElementVNode("img", {
          src: _ctx.userProfilePicture,
          alt: "",
          class: "svg-img"
        }, null, 8, _hoisted_3),
        _createVNode(_component_svg_icon, {
          "icon-class": "ic_xiangji",
          class: "xj-svg"
        })
      ], 512), [
        [_vShow, _ctx.userProfilePicture]
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.editUsernameFlag)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.accountInfo.username), 1))
            : (_openBlock(), _createBlock(_component_a_input, {
                key: 1,
                size: "small",
                value: _ctx.username,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.username) = $event)),
                onBlur: _ctx.handleBlur,
                ref: "userInput"
              }, null, 8, ["value", "onBlur"])),
          _createElementVNode("span", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleEditUsername && _ctx.handleEditUsername(...args)))
          }, [
            _createVNode(_component_svg_icon, { "icon-class": "ic_bianji" })
          ]),
          _createElementVNode("span", null, [
            _createVNode(_component_svg_icon, { "icon-class": _ctx.userLevel }, null, 8, ["icon-class"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", null, " ID: " + _toDisplayString(_ctx.userId), 1),
          _createElementVNode("span", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleCopy && _ctx.handleCopy(...args)))
          }, _toDisplayString(_ctx.$t('userCenter.copy')), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_a_divider, {
        style: {"border-color":"#cfcdcd"},
        dashed: ""
      })
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_form_info, {
        onCommit: _ctx.handleCommit,
        "account-info": _ctx.accountInfo
      }, {
        username: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.name'),
            name: "username",
            rules: [
              {
                required: true,
                message: _ctx.$t('userCenter.nameErrMsg')
              }
            ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.accountInfo.username,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.accountInfo.username) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label", "rules"])
        ]),
        schooling: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.schooling'),
            name: "schooling",
            rules: [
              {
                required: true,
                message: _ctx.$t('userCenter.educationErrMsg')
              }
            ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.accountInfo.schooling,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.accountInfo.schooling) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label", "rules"])
        ]),
        occupation: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.occupation'),
            name: "occupation",
            rules: [
              {
                required: true,
                message: _ctx.$t('userCenter.occupationErrMsg')
              }
            ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.accountInfo.occupation,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.accountInfo.occupation) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label", "rules"])
        ]),
        studyDirection: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.studyDirection'),
            name: "studyDirection",
            rules: [
              {
                required: true,
                message: _ctx.$t('userCenter.studydirectionErrMsg')
              }
            ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.accountInfo.studyDirection,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.accountInfo.studyDirection) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label", "rules"])
        ]),
        trade: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.trade'),
            name: "trade",
            rules: [
              {
                required: true,
                message: _ctx.$t('userCenter.mainApplicationIndustryErrMsg')
              }
            ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.accountInfo.trade,
                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.accountInfo.trade) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label", "rules"])
        ]),
        awards: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.awards'),
            name: "awards"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_textarea, {
                value: _ctx.accountInfo.awards,
                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.accountInfo.awards) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        area: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.area'),
            name: "area"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('userCenter.country')), 1)
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        country: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.country'),
            name: "country",
            rules: [
              {
                required: true,
                message: _ctx.$t('userCenter.localAreaErrMsg')
              }
            ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.accountInfo.country,
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.accountInfo.country) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label", "rules"])
        ]),
        _: 1
      }, 8, ["onCommit", "account-info"])
    ]),
    _createVNode(_component_a_modal, {
      open: _ctx.pictureVisible,
      "onUpdate:open": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.pictureVisible) = $event)),
      width: 746,
      centered: "",
      title: _ctx.$t('userCenter.pictureUpload'),
      onOk: _ctx.handleUploadPicture
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_vue_cropper, {
            ref: "cropper",
            img: _ctx.avatarFile,
            canMoveBox: false,
            fixedBox: true,
            autoCrop: true,
            autoCropWidth: "200",
            autoCropHeight: "200"
          }, null, 8, ["img"])
        ])
      ]),
      _: 1
    }, 8, ["open", "title", "onOk"])
  ]))
}