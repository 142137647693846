import { checkLoginRuleApi, queryImgCodeApi } from '@/api/login'
import { LoginParamsFace } from '@/interface/loginInerface'
import { RegisterInterface } from '@/interface/registerInterface'
import { userStore } from '@/store/user'
import { checkUserState } from '../login/loginModule'
import { onMounted, reactive } from 'vue'
import './sub-user-login.scss'

export const useSubUserLogin = () => {
  const user = userStore()
  const imgCodeInfo: RegisterInterface.imgCodeInfoFace = reactive({
    codeId: '',
    codeNum: ''
  })

  const queryCodeInfo = (): void => {
    queryImgCodeApi().then(res => {
      imgCodeInfo.codeId = res.data.codeId
      imgCodeInfo.codeNum = res.data.codeNum
    })
  }

  const handleRefreshImgCode = () => {
    queryCodeInfo()
  }

  const formInfo: LoginParamsFace = reactive({
    account: '',
    password: '',
    codeNum: ''
  })

  interface LoginRuleFlag {
    codeFlag: boolean;
    msgFlag: boolean;
    registerFlag: boolean;
    scanFlag: boolean;
  }

  const loginRuleFlag: LoginRuleFlag = reactive({
    codeFlag: false,
    msgFlag: false,
    registerFlag: false,
    scanFlag: false
  })

  const checkLoginRule = () => {
    checkLoginRuleApi().then(res => {
      loginRuleFlag.codeFlag = res.data.codeFlag
      loginRuleFlag.registerFlag = res.data.registerFlag
      loginRuleFlag.scanFlag = res.data.scanFlag
      loginRuleFlag.msgFlag = res.data.msgFlag
      if (res.data.codeFlag) {
        queryCodeInfo()
      }
    })
  }

  const handleRegister = (value: LoginParamsFace) => {
    let params: { account: string; password: string; codeId?: string } = {
      account: '',
      password: ''
    }
    if (!loginRuleFlag.codeFlag) {
      params.account = value.account
      params.password = value.password
    } else {
      params = {
        ...formInfo,
        codeId: imgCodeInfo.codeId
      }
    }
    user.subUserLogin(params, loginRuleFlag.codeFlag).then(res => {
      checkUserState(res.data.status)
    })
  }

  onMounted(() => {
    queryCodeInfo()
    checkLoginRule()
  })

  return {
    imgCodeInfo,
    formInfo,
    handleRefreshImgCode,
    handleRegister,
    loginRuleFlag
  }
}
