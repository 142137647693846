
import { defineComponent, onMounted } from 'vue'
import { useSecurityModule } from './security-module'

export default defineComponent({
  setup () {
    const {
      authInfo,
      queryUserAuth,
      passwordVisible,
      handleSetPassword,
      passwordForm,
      passwordInfo,
      handleCommitPassword,
      phoneVisible,
      phoneForm,
      phoneInfo,
      hanleChangePhone,
      handleSendMsgCode,
      handleSendNewMsgCode,
      handleConfirmChangePhone,
      handleBindEmail,
      emailVisible,
      emailInfo,
      handleSendEmailCode,
      handleSendNewEmailCode,
      handleConfirmChangeEmail,
      handleRealNameAuth,
      isShowSubView,
      bindChatVisible,
      handleBindChat,
      frameUrl
    } = useSecurityModule()

    onMounted(() => {
      queryUserAuth()
    })

    return {
      authInfo,
      passwordVisible,
      handleSetPassword,
      passwordForm,
      passwordInfo,
      handleCommitPassword,
      phoneVisible,
      phoneForm,
      phoneInfo,
      hanleChangePhone,
      handleSendMsgCode,
      handleSendNewMsgCode,
      handleConfirmChangePhone,
      handleBindEmail,
      emailVisible,
      emailInfo,
      handleSendNewEmailCode,
      handleSendEmailCode,
      handleConfirmChangeEmail,
      handleRealNameAuth,
      isShowSubView,
      bindChatVisible,
      handleBindChat,
      frameUrl
    }
  }
})
