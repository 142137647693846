
import { defineComponent } from 'vue'
import LoginRegisterBg from '@/components/Login-register-bg/LoginRegisterBg.vue'
import { useSubUserLogin } from './sub-user-login'

export default defineComponent({
  components: {
    LoginRegisterBg
  },
  setup () {
    const {
      imgCodeInfo,
      formInfo,
      handleRefreshImgCode,
      handleRegister,
      loginRuleFlag
    } = useSubUserLogin()

    return {
      imgCodeInfo,
      formInfo,
      handleRefreshImgCode,
      handleRegister,
      loginRuleFlag
    }
  }
})
