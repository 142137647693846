import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95f61a6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-box" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "one" }
const _hoisted_6 = {
  key: 0,
  class: "two"
}
const _hoisted_7 = { class: "price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    centered: "",
    title: _ctx.title,
    open: _ctx.visible,
    onCancel: _ctx.handleCanel,
    onOk: _ctx.handleConfirm,
    width: 746
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.title === _ctx.$t('netDisk.cacheDisk'))
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('netDisk.taskTips')), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('netDisk.netDiskTips')), 1))
      ]),
      _createVNode(_component_a_form, {
        class: "expansion-form",
        "label-col": {span: 6},
        "wrapper-col": {span: 16},
        model: _ctx.expansionForm,
        ref: "expansionFormRef"
      }, {
        default: _withCtx(() => [
          (_ctx.mode === 'cache')
            ? (_openBlock(), _createBlock(_component_a_form_item, {
                key: 0,
                label: _ctx.$t('netDisk.expandedSize')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.defaultDataSize) + "GB", 1)
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('netDisk.expandSize'),
            name: "size",
            rules: [{required: true, message: '请输入扩容大小'}]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_number, {
                "addon-after": "GB",
                value: _ctx.expansionForm.size,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expansionForm.size) = $event)),
                min: 0
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('netDisk.expandTime')
          }, {
            default: _withCtx(() => [
              (_ctx.mode !== 'cache')
                ? (_openBlock(), _createBlock(_component_a_select, {
                    key: 0,
                    value: _ctx.expansionForm.month,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expansionForm.month) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(11, (item) => {
                        return _createVNode(_component_a_select_option, {
                          key: item,
                          value: item
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item) + _toDisplayString(_ctx.$t('netDisk.month')), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"])
                      }), 64))
                    ]),
                    _: 1
                  }, 8, ["value"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('netDisk.asal')), 1))
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('netDisk.price'),
            class: "price-form-item"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, "￥" + _toDisplayString(_ctx.computedDiscountPrice) + "/GB/" + _toDisplayString(_ctx.mode !== 'cache' ? `${_ctx.$t('netDisk.month')}` : `${_ctx.$t('netDisk.hour')}`), 1),
              _createElementVNode("s", null, "￥" + _toDisplayString(_ctx.priceMonth) + "/GB/" + _toDisplayString(_ctx.mode !== 'cache' ? `${_ctx.$t('netDisk.month')}` : `${_ctx.$t('netDisk.hour')}`), 1),
              (_ctx.computedDiscount)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.computedDiscount) + _toDisplayString(_ctx.$t('netDisk.discount')), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["label"]),
          (_ctx.mode !== 'cache')
            ? (_openBlock(), _createBlock(_component_a_form_item, {
                key: 1,
                label: _ctx.$t('netDisk.endTime')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.expirationTime), 1)
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('netDisk.totalPrice')
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_7, "￥" + _toDisplayString(_ctx.totalPrice), 1)
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _: 1
  }, 8, ["title", "open", "onCancel", "onOk"]))
}