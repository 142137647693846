import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fbb8a2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sider-menu" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "tips-title"
}
const _hoisted_5 = {
  key: 1,
  class: "tips-title"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "tips-title"
}
const _hoisted_9 = {
  key: 1,
  class: "tips-title"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "tips-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_sub_menu = _resolveComponent("sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "logo",
      style: _normalizeStyle(_ctx.logoPadding)
    }, [
      _createVNode(_component_svg_icon, {
        "icon-class": _ctx.menu.meta.icon + _ctx.firstTitleIcon
      }, null, 8, ["icon-class"]),
      _withDirectives(_createElementVNode("span", null, [
        (_ctx.$i18n.locale === 'zh-CN')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.menu.meta.menuTitle), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.menu.meta.englishTitle), 1))
      ], 512), [
        [_vShow, !_ctx.collapsed]
      ])
    ], 4),
    _createVNode(_component_a_menu, {
      mode: "inline",
      selectedKeys: _ctx.selectedKeys,
      "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedKeys) = $event)),
      onClick: _ctx.handleMenuClick
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.children, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.path
          }, [
            (item.children && item.children.length === 0 && !item.hidden)
              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: item.path,
                  class: _normalizeClass(_ctx.collapsed ? 'is-icon' : 'not-icon')
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_svg_icon, {
                      "icon-class": item.meta.icon
                    }, null, 8, ["icon-class"])
                  ]),
                  title: _withCtx(() => [
                    (_ctx.$i18n.locale === 'zh-CN')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.meta.menuTitle), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.meta.englishTitle), 1))
                  ]),
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("span", null, [
                      (_ctx.$i18n.locale === 'zh-CN')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.meta.menuTitle), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.meta.englishTitle), 1))
                    ], 512), [
                      [_vShow, !_ctx.collapsed]
                    ])
                  ]),
                  _: 2
                }, 1032, ["class"]))
              : (item.children && item.children.length === 1 && !item.hidden)
                ? (_openBlock(), _createBlock(_component_a_menu_item, {
                    key: item.path,
                    class: _normalizeClass(_ctx.collapsed ? 'is-icon' : 'not-icon')
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_svg_icon, {
                        "icon-class": item.meta.icon
                      }, null, 8, ["icon-class"])
                    ]),
                    title: _withCtx(() => [
                      (_ctx.$i18n.locale === 'zh-CN')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.meta.menuTitle), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(item.meta.englishTitle), 1))
                    ]),
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("span", null, [
                        (_ctx.$i18n.locale === 'zh-CN')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(item.meta.menuTitle), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(item.meta.englishTitle), 1))
                      ], 512), [
                        [_vShow, !_ctx.collapsed]
                      ])
                    ]),
                    _: 2
                  }, 1032, ["class"]))
                : (item.children && item.children.length > 1 && item.children.find((e)=> e.hidden))
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: item.path,
                      class: _normalizeClass(_ctx.collapsed ? 'is-icon' : 'not-icon')
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_svg_icon, {
                          "icon-class": item.meta.icon
                        }, null, 8, ["icon-class"])
                      ]),
                      title: _withCtx(() => [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(item.meta.menuTitle), 1)
                      ]),
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("span", null, _toDisplayString(item.meta.menuTitle), 513), [
                          [_vShow, !_ctx.collapsed]
                        ])
                      ]),
                      _: 2
                    }, 1032, ["class"]))
                  : (_openBlock(), _createBlock(_component_sub_menu, {
                      key: 3,
                      "menu-info": item,
                      collapsed: _ctx.collapsed
                    }, null, 8, ["menu-info", "collapsed"]))
          ], 64))
        }), 128))
      ]),
      _: 1
    }, 8, ["selectedKeys", "onClick"])
  ]))
}