import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7c32876"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container student-container" }
const _hoisted_2 = { class: "form-box" }
const _hoisted_3 = {
  key: 0,
  class: "upload-box"
}
const _hoisted_4 = { class: "upload" }
const _hoisted_5 = {
  key: 1,
  class: "img-box"
}
const _hoisted_6 = { class: "model-box" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { style: {"color":"#3273f9"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_st_button = _resolveComponent("st-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_form, {
        ref: "form",
        "label-col": {
          span: 6
        },
        "wrapper-col": {
          span: 18
        },
        model: _ctx.formInfo
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('userCenter.realName'),
                    name: "realName",
                    rules: [{required: true, validator: _ctx.checkCharacters}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.formInfo.realName,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInfo.realName) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('userCenter.documentType'),
                    name: "certType",
                    rules: [{required: true, message: '请选择证件类型'}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _ctx.formInfo.certType,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formInfo.certType) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select_option, { value: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode("身份证")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 2 }, {
                            default: _withCtx(() => [
                              _createTextVNode("学生证")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('userCenter.identificationNumber'),
                    name: "certNumber",
                    rules: [{required: true, validator: _ctx.checkCreditCode}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.formInfo.certNumber,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formInfo.certNumber) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('userCenter.education'),
                    name: "education",
                    rules: [{required: true, validator: _ctx.checkCharacters}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.formInfo.education,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formInfo.education) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('userCenter.schoolLocation'),
                    name: "schoolLocation",
                    rules: [{required: true, validator: _ctx.checkCharacters}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.formInfo.schoolLocation,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formInfo.schoolLocation) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('userCenter.school'),
                    name: "schoolName",
                    rules: [{required: true, validator: _ctx.checkCharacters}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.formInfo.schoolName,
                        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formInfo.schoolName) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('userCenter.college'),
                    name: "college",
                    rules: [{required: true, validator: _ctx.checkCharacters}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.formInfo.college,
                        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formInfo.college) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('userCenter.major'),
                    name: "major",
                    rules: [{required: true, validator: _ctx.checkCharacters}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.formInfo.major,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formInfo.major) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('userCenter.schoolTime'),
                    "label-col": {span: 3},
                    "wrapper-col": {span: 21},
                    name: "rangeTime",
                    rules: [{required: true, message: _ctx.$t('userCenter.schoolTimeErrMsg')}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_range_picker, {
                        style: {"width":"100%"},
                        value: _ctx.formInfo.rangeTime,
                        "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formInfo.rangeTime) = $event)),
                        onChange: _ctx.handleChangeTime
                      }, null, 8, ["value", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["label", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('userCenter.studentCard'),
                    "label-col": {span: 3},
                    "wrapper-col": {span: 21}
                  }, {
                    default: _withCtx(() => [
                      (!_ctx.formInfo.studentPicture)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_a_upload, {
                              "custom-request": _ctx.handleUpload,
                              "before-upload": _ctx.beforeUpload,
                              "file-list": _ctx.fileList
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_4, [
                                  _createVNode(_component_svg_icon, { "icon-class": "ic_jia" }),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('userCenter.studentCard')), 1)
                                ])
                              ]),
                              _: 1
                            }, 8, ["custom-request", "before-upload", "file-list"]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t('userCenter.studentTips')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                              _createVNode(_component_delete_outlined, {
                                onClick: _cache[9] || (_cache[9] = () => _ctx.formInfo.studentPicture = '')
                              })
                            ]),
                            _createElementVNode("img", {
                              src: '/api/user' + _ctx.formInfo.studentPicture
                            }, null, 8, _hoisted_7)
                          ]))
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    "label-col": {span: 3},
                    "wrapper-col": {span: 19, offset: 3}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_checkbox, {
                        checked: _ctx.isChecked,
                        "onUpdate:checked": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isChecked) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('userCenter.companyAgree')), 1)
                        ]),
                        _: 1
                      }, 8, ["checked"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    "label-col": {span: 3},
                    "wrapper-col": {span: 19, offset: 3}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_st_button, {
                        type: "primary",
                        onClick: _ctx.handleSubmit
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('userCenter.submitapplication')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ]))
}