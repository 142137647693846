import http from '@/utils/http'
import type { UserCenter, SecuritySet } from '@/interface/userCenter'
interface Response {
  [propName: string]: any
}

// 查询用户基本信息
const queryUserApi = (): Promise<Response> => {
  return http.request({
    method: 'get',
    url: '/user/user/info/findUser'
  })
}

// 查询用户头像
const queryUserPortraitApi = (): Promise<Response> => {
  return http.request({
    method: 'get',
    url: '/user/user/info/findUserPortrait'
  })
}

// 修改用户基本信息
const setUserApi = (data: UserCenter.AccountInfoFace): Promise<Response> => {
  return http.request({
    method: 'post',
    url: '/user/user/info/setUser',
    data: data
  })
}

// 设置用户头像
const setUserPortraitApi = (data: any) => {
  return http({
    method: 'post',
    url: '/user/user/info/setUserPortrait',
    data: data
  })
}

// <----------------安全设置--------------->
// 获取权限
const userAuthApi = () => {
  return http.request({
    method: 'post',
    url: '/user/user/info/userAuth'
  })
}
// 登录状态下修改密码
const userPasswordApi = (data: SecuritySet.PasswordFace) => {
  return http({
    method: 'post',
    url: '/user/user/info/userPassword',
    data: data
  })
}
// 用户修改手机号(发送旧手机验证码)
const sendPhoneMsgApi = () => {
  return http({
    method: 'post',
    url: '/user/user/info/sendPhoneMsg'
  })
}
// 用户修改手机号(发送新手机验证码)
const sendNewPhoneMsgApi = (phone: string) => {
  return http.request({
    method: 'post',
    url: `/user/user/info/sendNewPhoneMsg/${phone}`
  })
}
// 用户修改手机号(验证新手机号验证码并修改手机号)
const checkNewPhoneMsgApi = (data: SecuritySet.PhoneFace) => {
  return http.request({
    method: 'post',
    url: '/user/user/info/checkNewPhoneMsg',
    data: data
  })
}
// 用户修改邮箱①(发送旧邮箱验证码)
const sendOldEmailMsgApi = () => {
  return http({
    method: 'post',
    url: '/user/user/info/sendOldEmailMsg'
  })
}
// 发送新邮箱验证码
const sendNewEmailMsgApi = (email: string) => {
  return http.request({
    method: 'post',
    url: `/user/user/info/sendEmailMsg/${email}/`
  })
}
// 验证新邮箱验证码并修改邮箱
const checkEmailMsgApi = (data: SecuritySet.EmailFace) => {
  return http.request({
    method: 'post',
    url: '/user/user/info/checkEmailMsg',
    data: data
  })
}

// 查询用户实名认证信息
const queryNameAuthApi = () => {
  return http({
    method: 'post',
    url: '/user/nameAuth/queryNameAuth'
  })
}

// 企业认证
const compAuthApi = (data: SecuritySet.EnterpriseFormInfoFace) => {
  return http({
    method: 'post',
    url: '/user/user/auth/compAuth',
    data: data
  })
}

// 学生认证
const studentAuthApi = (data: SecuritySet.StudentFormInfoFace) => {
  return http({
    method: 'post',
    url: '/user/user/auth/studentAuth',
    data: data
  })
}

// 用户实名认证申请信息
const saveNameAuthApi = (data: SecuritySet.NameFormInfoFace) => {
  return http.request({
    method: 'post',
    url: '/user/nameAuth/saveNameAuth',
    data: data
  })
}

// 查询邀请信息
const queryInviteInfoApi = () => {
  return http({
    method: 'get',
    url: '/user/user/inviteLink'
  })
}

// 用户绑定微信
const queryUserQrCodeApi = () => {
  return http({
    method: 'get',
    url: '/user/user/auth/qrcode'
  })
}

// 轮询查询是否扫码
const queryUserScanCodeApi = (data: string) => {
  return http({
    method: 'post',
    url: `/user/user/auth/qrcodeQuery/${data}`
  })
}

// 解绑微信
const unbindChatApi = () => {
  return http({
    method: 'post',
    url: '/user/user/unBindWechat'
  })
}

export {
  queryUserApi,
  queryUserPortraitApi,
  setUserApi,
  setUserPortraitApi,
  userAuthApi,
  userPasswordApi,
  sendPhoneMsgApi,
  sendNewPhoneMsgApi,
  checkNewPhoneMsgApi,
  sendOldEmailMsgApi,
  sendNewEmailMsgApi,
  checkEmailMsgApi,
  queryNameAuthApi,
  compAuthApi,
  studentAuthApi,
  saveNameAuthApi,
  queryInviteInfoApi,
  queryUserQrCodeApi,
  queryUserScanCodeApi,
  unbindChatApi
}
