import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c749c95"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "inner-container real-name-container"
}
const _hoisted_2 = { class: "persion" }
const _hoisted_3 = { class: "common-ul" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "enterprise" }
const _hoisted_7 = { class: "common-ul" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "student" }
const _hoisted_11 = { class: "common-ul" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.isShowSubView)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_collapse, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_collapse_panel, {
                header: _ctx.$t('userCenter.personalCertification')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("ul", _hoisted_3, [
                    _createElementVNode("li", null, [
                      (_ctx.name.status === 0 || _ctx.name.status === -1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                            _createVNode(_component_router_link, { to: "/user-center/security-setting/real-name-auth/name-certification" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('userCenter.personalIdentityAuthentication')), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('userCenter.personalIdentityAuthentication')), 1))
                    ]),
                    _createElementVNode("li", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('userCenter.detail')) + ": ", 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.name.info), 1)
                    ]),
                    _createElementVNode("li", null, _toDisplayString(_ctx.$t('userCenter.authStatus')) + ": " + _toDisplayString(_ctx.persionStatus), 1)
                  ])
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_a_collapse, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_collapse_panel, {
                header: _ctx.$t('userCenter.enterpriseCertification')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("ul", _hoisted_7, [
                    _createElementVNode("li", null, [
                      (_ctx.comp.status === 0 || _ctx.comp.status === -1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                            _createVNode(_component_router_link, { to: "/user-center/security-setting/real-name-auth/enterprise-certification" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('userCenter.enterpriseIDAuthentication')), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('userCenter.enterpriseIDAuthentication')), 1))
                    ]),
                    _createElementVNode("li", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('userCenter.detail')) + ": ", 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.comp.info), 1)
                    ]),
                    _createElementVNode("li", null, _toDisplayString(_ctx.$t('userCenter.authStatus')) + ": " + _toDisplayString(_ctx.compStatus), 1)
                  ])
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_a_collapse, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_collapse_panel, {
                header: _ctx.$t('userCenter.studentCertification')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("ul", _hoisted_11, [
                    _createElementVNode("li", null, [
                      (_ctx.student.status === 0 || _ctx.student.status === -1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                            _createVNode(_component_router_link, { to: "/user-center/security-setting/real-name-auth/student-certification" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('userCenter.studentIDAuthentication')), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('userCenter.studentIDAuthentication')), 1))
                    ]),
                    _createElementVNode("li", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('userCenter.detail')) + ": ", 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.student.info), 1)
                    ]),
                    _createElementVNode("li", null, _toDisplayString(_ctx.$t('userCenter.authStatus')) + ": " + _toDisplayString(_ctx.studentStatus), 1)
                  ])
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          })
        ])
      ]))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}