import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74803e94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-image" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "operate-column"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "primary-span" }
const _hoisted_6 = { class: "error-span" }
const _hoisted_7 = {
  key: 0,
  class: "filter-box-y"
}
const _hoisted_8 = { class: "btn-group" }
const _hoisted_9 = {
  class: "table-box",
  style: {"margin-top":"16px"}
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_condition_search = _resolveComponent("condition-search")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_ellipsis_outlined = _resolveComponent("ellipsis-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_st_button = _resolveComponent("st-button")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_config_columns = _resolveComponent("config-columns")!
  const _component_st_pagination = _resolveComponent("st-pagination")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_condition_search, {
      "current-condition": _ctx.currentCondition,
      "condition-list": _ctx.conditionList,
      "filter-options": _ctx.filterOptions,
      onChangeCondition: _ctx.handleChangeCondition,
      onChange: _ctx.handleSelectChange,
      onDel: _ctx.handleDelOption,
      onClear: _ctx.handleClear,
      onQuery: _ctx.handleQuery
    }, null, 8, ["current-condition", "condition-list", "filter-options", "onChangeCondition", "onChange", "onDel", "onClear", "onQuery"]),
    _createElementVNode("div", {
      class: _normalizeClass(["table-box", _ctx.filterOptions.length > 0 ? 'table-box-filter' : ''])
    }, [
      _createVNode(_component_a_table, {
        size: "small",
        bordered: "",
        pagination: false,
        columns: (_ctx.filterColumns.length === 0 && !_ctx.isAllHidden) ? _ctx.columns : _ctx.filterColumns,
        "data-source": _ctx.dataSource,
        scroll: { x: 1800, y: _ctx.scrollHeight - 39 }
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.dataIndex === 'status')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (record.status == 0)
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 0,
                      color: "processing"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('image.creating')), 1)
                      ]),
                      _: 1
                    }))
                  : (record.status == 1)
                    ? (_openBlock(), _createBlock(_component_a_tag, {
                        key: 1,
                        color: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('image.success')), 1)
                        ]),
                        _: 1
                      }))
                    : (record.status == 2)
                      ? (_openBlock(), _createBlock(_component_a_tag, {
                          key: 2,
                          color: "error"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('image.error')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'share')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (record.share == 0)
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 0,
                      color: "processing"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('image.sharing')), 1)
                      ]),
                      _: 1
                    }))
                  : (record.share == 1)
                    ? (_openBlock(), _createBlock(_component_a_tag, {
                        key: 1,
                        color: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('image.otherShare')), 1)
                        ]),
                        _: 1
                      }))
                    : (record.share == 2)
                      ? (_openBlock(), _createBlock(_component_a_tag, {
                          key: 2,
                          color: "error"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('image.noShare')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'users')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (record.users)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(record.users.length), 1))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'lockStatus')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                (record.lockStatus == 0)
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 0,
                      color: "processing"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('image.unlock')), 1)
                      ]),
                      _: 1
                    }))
                  : (record.lockStatus == 1)
                    ? (_openBlock(), _createBlock(_component_a_tag, {
                        key: 1,
                        color: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('image.lock')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'operate')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                (record.share !== 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("span", {
                        class: "primary-span",
                        onClick: ($event: any) => (_ctx.handleShare(record, 0))
                      }, _toDisplayString(_ctx.$t('image.share')), 9, _hoisted_4),
                      _createVNode(_component_a_dropdown, null, {
                        overlay: _withCtx(() => [
                          _createVNode(_component_a_menu, { onClick: _ctx.handleChoostTools }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_component_a_menu_item, {
                                key: JSON.stringify(record) + ';subShare'
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('image.subUserShare')), 1)
                                ]),
                                _: 2
                              }, 1024)),
                              (_openBlock(), _createBlock(_component_a_menu_item, {
                                key: JSON.stringify({ id: record.id, share: record.share }) + ';del'
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('del.del')), 1)
                                ]),
                                _: 2
                              }, 1024))
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_ellipsis_outlined)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_svg_icon, {
            "icon-class": filtered ? 'ic_shaixuanz' : 'ic_shaixuan',
            class: "filter-icon"
          }, null, 8, ["icon-class"])
        ]),
        customFilterDropdown: _withCtx(({
            column,
            clearFilters
          }) => [
          (column.dataIndex === 'status')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("h1", null, _toDisplayString(_ctx.$t('image.state')), 1),
                _createVNode(_component_a_divider),
                _createVNode(_component_a_radio_group, {
                  value: column.radioValue,
                  "onUpdate:value": ($event: any) => ((column.radioValue) = $event),
                  onChange: ($event: any) => (_ctx.handleChangeStatus(column))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterList(column), (item) => {
                      return (_openBlock(), _createBlock(_component_a_radio, {
                        key: item.value,
                        value: item.value + ',' + item.label
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["value", "onUpdate:value", "onChange"]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_st_button, {
                    type: "default",
                    onClick: () => clearFilters({
                  closeDropdown: true
                })
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('del.cancel')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_st_button, {
                    type: "primary",
                    onClick: () => _ctx.handleconfirm(column, clearFilters),
                    disabled: !column.radioValue
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('del.ok')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "disabled"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["columns", "data-source", "scroll"]),
      _createVNode(_component_config_columns, {
        columns: _ctx.columns,
        onChangeAll: _ctx.handleChangeAll,
        onConfirm: _ctx.handleSetColumns
      }, null, 8, ["columns", "onChangeAll", "onConfirm"])
    ], 2),
    _createVNode(_component_st_pagination, {
      total: _ctx.total,
      onChange: _ctx.handleChangePage
    }, null, 8, ["total", "onChange"]),
    _createVNode(_component_a_modal, {
      open: _ctx.visible,
      "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
      width: 796,
      title: _ctx.title,
      centered: "",
      class: "share-modal",
      onOk: _ctx.handleConfirmShare
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('image.imageName')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.imageInfo.imageName,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.imageInfo.imageName) = $event)),
                          disabled: ""
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('image.columns.address')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.imageInfo.filePath,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.imageInfo.filePath) = $event)),
                          valueModifiers: { trim: true },
                          disabled: ""
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_st_button, {
          type: "primary",
          onClick: _ctx.handlePushUsers,
          disabled: _ctx.setBtnDisabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('image.addAuth')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_a_form, {
            ref: "shareFormRef",
            model: _ctx.users
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_table, {
                bordered: "",
                size: "small",
                columns: _ctx.shareColumns,
                "data-source": _ctx.users,
                pagination: false,
                scroll: { y: 240 }
              }, {
                bodyCell: _withCtx(({column, record, index}) => [
                  (column.dataIndex === 'order')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(index + 1), 1)
                      ], 64))
                    : (column.dataIndex === 'userId')
                      ? (_openBlock(), _createBlock(_component_a_form_item, {
                          key: 1,
                          name: [index, 'userId'],
                          rules: { validator: _ctx.checkUserId, trigger: 'blur' },
                          class: "ant-form-item-bottom"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: record.userId,
                              "onUpdate:value": ($event: any) => ((record.userId) = $event)
                            }, null, 8, ["value", "onUpdate:value"])
                          ]),
                          _: 2
                        }, 1032, ["name", "rules"]))
                      : (column.dataIndex === 'flag')
                        ? (_openBlock(), _createBlock(_component_a_radio_group, {
                            key: 2,
                            value: record.flag,
                            "onUpdate:value": ($event: any) => ((record.flag) = $event)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_radio, { value: 0 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('netDisk.randw')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_radio, { value: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('netDisk.ro')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1032, ["value", "onUpdate:value"]))
                        : (column.dataIndex === 'remark')
                          ? (_openBlock(), _createBlock(_component_a_input, {
                              key: 3,
                              value: record.remark,
                              "onUpdate:value": ($event: any) => ((record.remark) = $event)
                            }, null, 8, ["value", "onUpdate:value"]))
                          : (_openBlock(), _createElementBlock("span", {
                              key: 4,
                              class: "error-span",
                              onClick: ($event: any) => (_ctx.handleDelUser(index))
                            }, _toDisplayString(_ctx.$t('del.del')), 9, _hoisted_10))
                ]),
                _: 1
              }, 8, ["columns", "data-source"])
            ]),
            _: 1
          }, 8, ["model"])
        ])
      ]),
      _: 1
    }, 8, ["open", "title", "onOk"])
  ]))
}