
import { defineComponent, ref, reactive, watch, onMounted, computed } from 'vue'
import http from '@/utils/http'
export default defineComponent({
  name: 'DynamicSearch',
  emits: ['searchForm', 'clearFilterTag'],
  props: {
    dynamicForm: {
      type: Array,
      required: true
    },
    filters: {
      type: [Array, Object]
    }
  },
  setup (props, { emit }) {
    const searchKey = ref(null)
    const searchForm: any = reactive({})
    const formList: any = ref([])
    const timeRange = ref([])
    const tagsList: any = ref([])
    const multipleSelectList: any = ref([])
    const clearFilterText = computed(() => {
      if (tagsList.value.length > 0) {
        return areAllPropertiesEmpty(tagsList.value, 'value')
      } else {
        return true
      }
    })
    const handleFilter = (val: any) => {
      if (!val.filters || !Array.isArray(val.dynamicForm)) {
        console.error('Invalid input format')
        return // 提前返回，处理异常输入情况
      }
      var list: any[] = []
      // 判断属性值是对象还是数组；若为数组需要查找相关下拉菜单对应中文名进行拼接到tag标签内显示
      val.dynamicForm.forEach((v: any) => {
        for (const i in val.filters) {
          if (val.filters[i]) {
            searchForm[i] = val.filters[i]
            var tagArr = []
            if (Array.isArray(val.filters[i])) {
              // 多选下拉菜单类型（数组拼接）
              // 若为数组
              let result = ''
              if (val.filters[i].length > 0) {
                if (v.name === i) {
                  result = handleArrayFilter(val.filters[i], v) // 查找对应下拉菜单对应中文字段
                  tagArr.push({
                    label: v.label,
                    value: result,
                    timestamp: new Date().getTime(),
                    key: v.name
                  })
                }
              } else {
                // 若属性值为空，则赋值null处理.
                tagArr.push({
                  label: v.label,
                  value: null,
                  timestamp: new Date().getTime(),
                  key: v.name
                })
              }
            } else {
              // 若属性值为对象
              if (v.name === i) {
                var keyname = ''
                var strArr: any[] = []
                if (v.options) {
                  // 若属性值含有options字段进行遍历取值拼接(单选下拉菜单类型)
                  v.options.map((item: any) => {
                    if (item[v.optionKey] === val.filters[i]) {
                      strArr.push(item[v.optionLabel])
                      keyname = strArr.join(',')
                      tagArr.push({
                        label: v.label,
                        value: keyname,
                        timestamp: new Date().getTime(),
                        key: v.name
                      })
                    }
                  })
                } else {
                  // 直接赋值(输入框类型、当前日期选择器)
                  keyname = val.filters[i]
                  tagArr.push({
                    label: v.label,
                    value: keyname,
                    timestamp: new Date().getTime(),
                    key: v.name
                  })
                }
              } else if (
                v.type === 'dateRange' &&
                val.filters.startTime &&
                val.filters.endTime
              ) {
                // 日期范围选择器,确保值存在，防止传入空串标签
                tagArr.push({
                  label: v.label,
                  value: val.filters.startTime + '-' + val.filters.endTime,
                  timestamp: new Date().getTime(),
                  key: v.name,
                  type: 'dateRange'
                })
              }
            }
            // 获取已选中标签属性数组，若存在则进行赋值
            if (tagArr.length > 0) {
              tagArr.forEach((v) => {
                if (v.value) {
                  list.push(v)
                }
              })
            }
          }
        }
      })
      // 获取用户最新时间输入的值
      tagsList.value = deduplicate(list, 'label')
    }
    // 遍历数组下拉菜单获取中文值进行拼接为字符串显示
    const handleArrayFilter = (filterItem: any, formItem: any) => {
      const strArr: any = []
      filterItem.forEach((filterOption: any) => {
        formItem.options.forEach((option: any) => {
          if (option[formItem.optionKey] === filterOption) {
            strArr.push(option[formItem.optionLabel])
          }
        })
      })
      return strArr.join(',')
    }
    onMounted(() => {
      getList()
    })
    const getList = () => {
      // 遍历属性为select需分别判断是枚举类型或API类型
      var selectEnumtArr: any = [] // 枚举项item数组
      var EnumParamsArr: any[] = [] // 枚举项接口参数
      props.dynamicForm.forEach((item: any) => {
        if (item.type === 'select') {
          if (item.optionType === 'Api') {
            http
              .request({
                method: item.optionMethod,
                url: item.optionApi
              })
              .then((res) => {
                item.options = res.data
              })
          } else if (item.optionType === 'Enum') {
            selectEnumtArr.push(item) // 枚举项
            EnumParamsArr.push(item.optionParams) // 枚举接口参数
          }
        }
        if (item.isShow) {
          formList.value.push(item) // 进行动态显示隐藏；若存在主表格表头筛选菜单，而搜索表单无需显示的情况
        }
      })
      // 涉及枚举类型的接口先获取所有枚举参数值，以一次性数组方式调取所有接口下拉菜单数据，避免多次调取
      if (EnumParamsArr.length > 0) {
        http
          .request({
            method: 'post',
            url: '/user/dict/getDict',
            data: EnumParamsArr
          })
          .then((res) => {
            if (res) {
              selectEnumtArr.forEach((s: any) => {
                s.options = res.data[s.optionParams]
              })
              replaceValuesWithMatchingKeys(props.dynamicForm, selectEnumtArr, 'name') // 更新表单下拉菜单options值
            }
          })
      }
      if (formList.value.length > 0) {
        if (history.state.name && history.state.value && history.state.option) {
          formList.value.forEach((item:any) => {
            if (item.name === history.state.name) {
              searchKey.value = item.label
              searchForm[item.name] = [Number(history.state.value)]
              handleSelect([Number(history.state.value)], JSON.parse((history.state.option) as any), item.name)
              handleSelectMultipleSearch([Number(history.state.value)], item.name)
            }
          })
        } else {
          searchKey.value = formList.value[0].label // 搜索表单默认取第一个字段进行显示
        }
      }
    }
    function replaceValuesWithMatchingKeys (arr1: any, arr2: any, key: any) {
      const map = new Map(arr2.map((item: any) => [item[key], item]))
      arr1.forEach((item: any) => {
        const value = map.get(item[key])
        if (value) {
          Object.assign(item, value)
        }
      })
    }
    // 日期范围选择器change事件(默认startTime,endTime)
    const timeRangeChange = () => {
      if (timeRange.value) {
        searchForm.startTime = timeRange.value[0]
        searchForm.endTime = timeRange.value[1]
        tagsList.value.push({
          label: searchKey.value,
          value: searchForm.startTime + '-' + searchForm.endTime,
          timestamp: new Date().getTime(),
          key: 'timeRange',
          type: 'dateRange'
        })
      } else {
        searchForm.startTime = ''
        searchForm.endTime = ''
        tagsList.value.push({
          label: searchKey.value,
          value: '',
          timestamp: new Date().getTime(),
          key: 'timeRange',
          type: 'dateRange'
        })
      }
      handleTag()
    }
    // 单选下拉菜单搜索按钮事件
    const handleSelectSingleSearch = (value: any, key: any) => {
      var searchLabel = ''
      props.dynamicForm.forEach((item: any) => {
        if (item.name === key) {
          item.options.map((s: any) => {
            if (s[item.optionKey] === value) {
              searchLabel = s[item.optionLabel]
            }
          })
        }
      })
      tagsList.value.push({
        label: searchKey.value,
        value: searchLabel,
        timestamp: new Date().getTime(),
        key: key
      })
      handleTag()
    }
    // 多选下拉菜单搜索按钮事件
    const handleSelectMultipleSearch = async (value: any, key: any) => {
      if (multipleSelectList.value.length > 0) {
        var strArr: any[] = []
        props.dynamicForm.forEach((item: any) => {
          if (item.name === key) {
            item.options.map((item2: any) => {
              value.map((s: any) => {
                if (item2[item.optionKey] === s) {
                  strArr.push(item2[item.optionLabel])
                }
              })
            })
          }
        })
        tagsList.value.push({
          label: searchKey.value,
          value: strArr.join(','),
          timestamp: new Date().getTime(),
          key: key
        })
        await handleTag()
      } else {
        tagsList.value.push({
          label: searchKey.value,
          value: null,
          timestamp: new Date().getTime(),
          key: key
        })
        handleTag()
      }
    }
    // 下拉菜单选择事件-判断单选下拉或多选下拉菜单，处理相关值
    const handleSelect = (value: any, option: any, key: any) => {
      if (value && option) {
        if (isArray(value) && isArray(option)) {
          // 多选菜单
          multipleSelectList.value = [
            {
              value: value,
              option: option,
              key: key
            }
          ]
        } else {
          // 单选菜单
          tagsList.value.push({
            label: searchKey.value,
            value: option.key,
            timestamp: new Date().getTime(),
            key: key
          })
          handleTag()
        }
      } else {
        tagsList.value.push({
          label: searchKey.value,
          value: null,
          timestamp: new Date().getTime(),
          key: key
        })
        handleTag()
      }
    }
    // 输入事件搜索按钮
    const handleInput = (value: string, key: string) => {
      tagsList.value.push({
        label: searchKey.value,
        value: value,
        timestamp: new Date().getTime(),
        key: key
      })
      handleTag()
    }
    // 日期选择器事件搜索按钮
    const handleChange = (value: any, key: any): any => {
      tagsList.value.push({
        label: searchKey.value,
        value: value,
        timestamp: new Date().getTime(),
        key: key
      })
      handleTag()
    }
    // 删除当前tag标签
    const handleClose = (value: any) => {
      tagsList.value.map((v: any, index: number) => {
        if (v.key === value.key) {
          tagsList.value.splice(index, 1)
          searchForm[v.key] = null
        }
      })
      if (value.type === 'dateRange') {
        timeRange.value = []
        searchForm.startTime = ''
        searchForm.endTime = ''
      }
      emit('clearFilterTag', value) // 触发父组件事件-传递最新值
    }
    // 删除全部tag标签
    const clearAllTags = () => {
      tagsList.value = []
      emit('clearFilterTag', {}) // 触发父组件事件-传递最新值
      for (var i in searchForm) {
        if (i !== 'pageReq') {
          searchForm[i] = null
        }
      }
    }
    // 表头label下拉菜单change事件，每次切换需清空当前筛选条件
    const handleClearSearchKey = (value: any, option: any) => {
      if (option.key === 'timeRange') {
        // 若为日期范围选择器
        searchForm.startTime = ''
        searchForm.endTime = ''
        timeRange.value = []
      } else {
        props.dynamicForm.map((s: any) => {
          if (s.name === option.key) {
            // 若为下拉菜单
            if (s.type === 'select' && s.mode === 'multiple') {
              // 若为多选菜单，需删除属性值。(避免传空值到ant多选下拉菜单组件，显示空白tag)
              delete searchForm[option.key]
            } else {
              searchForm[option.key] = null
            }
          }
        })
      }
    }
    // 过滤重复标签，取用户最新时间内选择的数据
    const handleTag = async () => {
      tagsList.value = deduplicate(tagsList.value, 'label')
      await handleSearch(searchForm)
    }
    const handleSearch = async (searchForm: any) => {
      emit('searchForm', searchForm)
    }
    // 校验tag标签数组列表值是否为空
    function areAllPropertiesEmpty (arr: any, prop: any) {
      return arr.every(function (obj: any) {
        return obj[prop] === null || obj[prop] === undefined || obj[prop] === ''
      })
    }
    function isArray (value: any) {
      return Array.isArray(value)
    }
    // 数组去重-获取用户输入最新时间的数据
    function deduplicate (arr: any, t = '') {
      const uniqueItems = arr.reduce((accumulator: any, current: any) => {
        const existingItem = accumulator.find((item: any) => item[t] === current[t])
        if (existingItem) {
          // 如果已存在t，则比较时间戳并替换旧对象
          if (new Date(current.timestamp) > new Date(existingItem.timestamp)) {
            const index = accumulator.indexOf(existingItem)
            accumulator[index] = current
          }
        } else {
          // 如果不存在id，则添加到结果数组
          accumulator.push(current)
        }
        return accumulator
      }, [])
      return uniqueItems
    }
    watch(
      props,
      (val) => {
        handleFilter(val)
      },
      { immediate: true, deep: true }
    )
    return {
      searchForm,
      formList,
      timeRange,
      timeRangeChange,
      handleSearch,
      searchKey,
      handleChange,
      tagsList,
      handleClose,
      handleSelect,
      handleInput,
      clearAllTags,
      handleClearSearchKey,
      clearFilterText,
      handleSelectMultipleSearch,
      handleSelectSingleSearch
    }
  }
})
