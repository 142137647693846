import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-518c9cc3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "s-image" }
const _hoisted_2 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _directive_preventReClick = _resolveDirective("preventReClick")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    open: _ctx.changeImageDialogVisible,
    "onUpdate:open": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.changeImageDialogVisible) = $event)),
    title: _ctx.$t('myTask.imageChange'),
    centered: "",
    onOk: _ctx.handleChangeImage,
    maskClosable: false,
    footer: null,
    width: "700px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        labelCol: { span: 0 },
        ref: "imageFormRef",
        model: _ctx.imageForm,
        rules: _ctx.props.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { justify: "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('myTask.primaryImage'),
                    style: {"margin-bottom":"14px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.primaryImage), 1)
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, { justify: "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  (_ctx.changeImageVisible)
                    ? (_openBlock(), _createBlock(_component_a_form_item, {
                        key: 0,
                        label: _ctx.$t('costCenter.platImage'),
                        name: "image"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_cascader, {
                            value: _ctx.imageForm.image,
                            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.imageForm.image) = $event)),
                            options: _ctx.imageOptions,
                            "change-on-select": "",
                            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getImage(_ctx.imageForm.image)))
                          }, null, 8, ["value", "options"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (!_ctx.changeImageVisible)
                    ? (_openBlock(), _createBlock(_component_a_form_item, {
                        key: 1,
                        label: _ctx.$t('costCenter.myImage'),
                        name: "image"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            options: _ctx.imageOptions,
                            value: _ctx.imageForm.image,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.imageForm.image) = $event)),
                            "field-names": { label: 'value', value: 'label' },
                            onChange: _ctx.getMainImage
                          }, null, 8, ["options", "value", "onChange"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, {
                span: 4,
                class: "change-mirror"
              }, {
                default: _withCtx(() => [
                  (_ctx.changeImageVisible)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.changeImage && _ctx.changeImage(...args)))
                      }, _toDisplayString(_ctx.$t("costCenter.changeMyImage")), 1))
                    : _createCommentVNode("", true),
                  (!_ctx.changeImageVisible)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.changeImage && _ctx.changeImage(...args)))
                      }, _toDisplayString(_ctx.$t("costCenter.changePlatImage")), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "btn-m-cancel",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeImageDialogVisible = false))
        }, _toDisplayString(_ctx.$t("image.cancel")), 1),
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: "btn-m-primary",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleConfirmChangeImage && _ctx.handleConfirmChangeImage(...args)))
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t("image.confirm")), 1)
        ])), [
          [_directive_preventReClick, 3000]
        ])
      ])
    ]),
    _: 1
  }, 8, ["open", "title", "onOk"]))
}