
import { defineComponent } from 'vue'
import { useNetDiskDetail } from './net-disk-detail'
import ConditionSearch from '@/components/condition/Condition'
import StPagination from '@/components/st-pagination/StPagination.vue'
import ConfigColumns from '@/components/config-columns/ConfigColumns.vue'
import { EllipsisOutlined } from '@ant-design/icons-vue'
import ExpansionModal from '@/components/expansion-modal/ExpansionModal.vue'

export default defineComponent({
  components: {
    ConditionSearch,
    StPagination,
    ConfigColumns,
    EllipsisOutlined,
    ExpansionModal
  },
  setup () {
    const {
      currentCondition,
      conditionList,
      filterOptions,
      handleChangeCondition,
      handleSelectChange,
      handleQuery,
      total,
      handleChangePage,
      columns,
      dataSource,
      handleChangeAll,
      isAllHidden,
      handleSetColumns,
      filterColumns,
      scrollHeight,
      handleChoostTools,
      handleDel,
      handleQueryFolderInfo,
      handleClear,
      handleDelOption,
      handleAddFolder,
      visible,
      addForm,
      form,
      handleConfirmFolder,
      checkFolderName,
      useSize,
      totalSize,
      proportion,
      handleExpansion,
      expansionPrice,
      title,
      expansionVisible,
      handleConfirmExpansion,
      bucketId,
      handleDownload,
      handleUpload,
      handleBackFolder,
      isTopDir,
      isTopDirObj
    } = useNetDiskDetail()

    return {
      currentCondition,
      conditionList,
      filterOptions,
      handleChangeCondition,
      handleSelectChange,
      handleQuery,
      total,
      handleChangePage,
      columns,
      dataSource,
      handleChangeAll,
      isAllHidden,
      handleSetColumns,
      filterColumns,
      scrollHeight,
      handleChoostTools,
      handleDel,
      handleQueryFolderInfo,
      handleClear,
      handleDelOption,
      handleAddFolder,
      visible,
      addForm,
      form,
      handleConfirmFolder,
      checkFolderName,
      useSize,
      totalSize,
      proportion,
      handleExpansion,
      expansionPrice,
      title,
      expansionVisible,
      handleConfirmExpansion,
      bucketId,
      handleDownload,
      handleUpload,
      handleBackFolder,
      isTopDir,
      isTopDirObj
    }
  }
})
