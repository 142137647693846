
import { PropType, defineComponent } from 'vue'
import {
  CardFace
} from '@/interface/netDisk'

export default defineComponent({
  emits: ['tab'],
  props: {
    bucketType: {
      type: Number,
      required: true
    },
    cardList: {
      type: Array as PropType<CardFace[]>,
      required: true
    }
  },
  setup (_, { emit }) {
    const handleTabs = (bucketType: number) => {
      emit('tab', bucketType)
    }
    return {
      handleTabs
    }
  }
})
