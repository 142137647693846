
import { defineComponent } from 'vue'
import WorkOrderInfo from './components/WorkOrderInfo.vue'
import WorkOrderChat from './components/WorkOrderChat.vue'
import { useWorkOrderDetail } from './work-order-detail'

export default defineComponent({
  components: {
    WorkOrderInfo,
    WorkOrderChat
  },
  setup () {
    const {
      workOrderInfo,
      queryDetailInfo
    } = useWorkOrderDetail()
    return {
      workOrderInfo,
      queryDetailInfo
    }
  }
})
