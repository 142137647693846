import { reactive, ref } from 'vue'
import type { SecuritySet } from '@/interface/userCenter'
import { PictureValidator, CharactersValidator, CreditCodeValidator } from '@/interface/validator'
import { FormInstance, message, Upload } from 'ant-design-vue'
import type { UploadProps } from 'ant-design-vue'
import { uploadPictureApi } from '@/api/upload'
import type { Rule } from 'ant-design-vue/es/form'
import { compAuthApi } from '@/api/user-center'
import { useI18n } from 'vue-i18n'

export const useEnterprise = () => {
  const checkPictureSuffix = new PictureValidator()
  const compNameValidator = new CharactersValidator()
  const creditCodeValidator = new CreditCodeValidator()
  const { t } = useI18n()

  const checkCompanyName = (_rule: Rule, value: string) => {
    if (value === '') {
      return Promise.reject(new Error(t('userCenter.companyErrMsg')))
    } else {
      const flag = compNameValidator.isAcceptable(value)
      if (flag) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error(t('userCenter.companyTypeErrMsg')))
      }
    }
  }

  const checkCreditCode = (_rule: Rule, value: string) => {
    if (value === '') {
      return Promise.reject(new Error(t('userCenter.creditCodeErrMsg')))
    } else {
      const flag = creditCodeValidator.isAcceptable(value)
      if (flag) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error(t('userCenter.creditTypeCodeErrMsg')))
      }
    }
  }

  const isChecked = ref(false)
  const form = ref<FormInstance>()
  const formInfo: SecuritySet.EnterpriseFormInfoFace = reactive({
    compName: '',
    creditCode: '',
    businessPicture: ''
  })

  const fileList = ref<UploadProps['fileList']>([])

  const handleUpload = (params: {file: File}) => {
    fileList.value = []
    const { file } = params
    const formData = new FormData()
    formData.append('file', file)
    uploadPictureApi(formData).then(res => {
      formInfo.businessPicture = res.data
    })
  }

  const beforeUpload: UploadProps['beforeUpload'] = (file: File) => {
    if (!checkPictureSuffix.isAcceptable(file.name)) {
      message.error(t('userCenter.imgType'))
      return Upload.LIST_IGNORE
    }
  }

  const rules: Record<string, Rule[]> = reactive({
    compName: [
      {
        required: true,
        validator: checkCompanyName
      }
    ],
    creditCode: [
      {
        required: true,
        validator: checkCreditCode
      }
    ]
  })

  const handleSubmit = () => {
    if (form.value) {
      form.value.validate().then(() => {
        if (!formInfo.businessPicture) {
          message.error(t('userCenter.uploadyinye'))
        } else if (!isChecked.value) {
          message.error(t('userCenter.agreeReal'))
        } else {
          compAuthApi(formInfo).then(() => {
            message.success(t('userCenter.okCompany'), 0.5)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }

  return {
    formInfo,
    handleUpload,
    beforeUpload,
    fileList,
    isChecked,
    handleSubmit,
    form,
    rules
  }
}
