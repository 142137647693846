import { reactive, ref } from 'vue'
import type { SecuritySet } from '@/interface/userCenter'
import type { FormInstance, UploadProps } from 'ant-design-vue'
import { message, Upload } from 'ant-design-vue'
import { uploadPictureApi } from '@/api/upload'
import { PictureValidator, IDCardValidator, CharactersValidator } from '@/interface/validator'
import { Rule } from 'ant-design-vue/es/form'
import { saveNameAuthApi } from '@/api/user-center'
import { useI18n } from 'vue-i18n'

export const useName = () => {
  const checkPictureSuffix = new PictureValidator()
  const idCardValidator = new IDCardValidator()
  const nameValidator = new CharactersValidator()
  const { t } = useI18n()

  const checkIdCard = async (_rule: Rule, value: string) => {
    if (value === '') {
      return Promise.reject(new Error(t('userCenter.idCardErrMsg')))
    } else {
      const flag = idCardValidator.isAcceptable(value)
      if (flag) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error(t('userCenter.idCardTypeErrMsg')))
      }
    }
  }

  const checkName = (_rule: Rule, value: string) => {
    if (value === '') {
      return Promise.reject(new Error(t('userCenter.nameErrMsg')))
    } else {
      const flag = nameValidator.isAcceptable(value)
      if (flag) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error(t('userCenter.nameErrTypeMsg')))
      }
    }
  }

  const form = ref<FormInstance>()
  const formInfo: SecuritySet.NameFormInfoFace = reactive({
    name: '',
    certCode: '',
    frontUrl: '',
    backUrl: ''
  })

  const fileListFront = ref<UploadProps['fileList']>([])

  const beforeUpload: UploadProps['beforeUpload'] = (file: File) => {
    if (!checkPictureSuffix.isAcceptable(file.name)) {
      message.error('仅支持上传jpg、png、jpeg图片格式')
      return Upload.LIST_IGNORE
    }
  }

  const handleUploadFront = (params: {file: File}) => {
    fileListFront.value = []
    const { file } = params
    const formData = new FormData()
    formData.append('file', file)
    uploadPictureApi(formData).then(res => {
      formInfo.frontUrl = res.data
    })
  }

  const fileListEnd = ref<UploadProps['fileList']>([])
  const handleUploadEnd = (params: {file: File}) => {
    fileListEnd.value = []
    const { file } = params
    const formData = new FormData()
    formData.append('file', file)
    uploadPictureApi(formData).then(res => {
      formInfo.backUrl = res.data
    })
  }

  const handleSubmit = () => {
    if (form.value) {
      form.value.validate().then(() => {
        if (!formInfo.frontUrl) {
          message.error(t('userCenter.idCardEmptyFront'))
        } else if (!formInfo.backUrl) {
          message.error(t('userCenter.idCardEmptyEnd'))
        } else {
          saveNameAuthApi(formInfo).then(() => {
            message.success(t('userCenter.ok'), 0.5)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }

  return {
    formInfo,
    handleUploadFront,
    handleUploadEnd,
    fileListEnd,
    fileListFront,
    beforeUpload,
    checkIdCard,
    form,
    checkName,
    handleSubmit
  }
}
