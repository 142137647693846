
import { defineComponent, computed, PropType, ref } from 'vue'
import { useRoute } from 'vue-router'
import { permissionStore } from '@/store/permission'
import touxiang from '@/assets/img/ic_touxiang.png'
import { UploadProps } from 'ant-design-vue'
import { uploadPictureApi } from '@/api/upload'
import { replayWorkOrderApi } from '@/api/work-order'

export default defineComponent({
  emits: ['query'],
  props: {
    createTime: {
      type: String,
      required: true,
      default: ''
    },
    workExplain: {
      type: String,
      required: true,
      default: ''
    },
    filePath: {
      type: String,
      required: true,
      default: ''
    },
    vos: {
      type: Array as PropType<{[propName: string]: string}[]>,
      required: true,
      default: () => []
    }
  },
  setup (_, { emit }) {
    const route = useRoute()
    const permission = permissionStore()
    // 用户头像
    const userProfilePicture = computed<string>(() => {
      return permission.$state.accountInfo.portrait ? `/api/user/${permission.$state.accountInfo.portrait}` : touxiang
    })

    const contentDetail = ref<string>('')
    const fileList = ref<UploadProps['fileList']>([])
    const filePath = ref<string>('')
    const customRequest = (params: {file: File}) => {
      const formData = new FormData()
      formData.append('file', params.file)
      uploadPictureApi(formData).then(res => {
        fileList.value = [{
          ...params.file,
          uid: new Date().getTime().toString()
        }]
        filePath.value = res.data
        const req = {
          workExplain: contentDetail.value,
          workId: route.query.id as string,
          filePath: filePath.value
        }
        replayWorkOrderApi(req).then(() => {
          fileList.value = []
          contentDetail.value = ''
          emit('query')
        })
      })
    }

    const handleEnter = () => {
      const params = {
        workExplain: contentDetail.value,
        workId: route.query.id as string,
        filePath: filePath.value
      }
      replayWorkOrderApi(params).then(() => {
        fileList.value = []
        contentDetail.value = ''
        emit('query')
      })
    }

    const handleDownText = (path: string) => {
      window.open(`/api/user${path}`, '_blank')
    }

    return {
      userProfilePicture,
      contentDetail,
      customRequest,
      handleEnter,
      fileList,
      handleDownText
    }
  }
})
