import http from '@/utils/http'
import { StationMessageSpace } from '@/interface/stationMessageface'

const stationMessageApi = (data: StationMessageSpace.PagingParamsFace) => {
  return http({
    method: 'post',
    data: data,
    url: '/user/notice/userNoticePage'
  })
}

const delStationMessageApi = (data: string) => {
  return http({
    method: 'post',
    url: `/user/notice/removeNotice/${data}`
  })
}

const markStationMessageApi = (data: string) => {
  return http({
    method: 'post',
    url: `/user/notice/read/${data}`
  })
}

const markAllStationMessageApi = () => {
  return http({
    method: 'post',
    url: '/user/notice/readAll'
  })
}

export {
  stationMessageApi,
  delStationMessageApi,
  markStationMessageApi,
  markAllStationMessageApi
}
