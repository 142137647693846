import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-768afe21"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container name-container" }
const _hoisted_2 = { class: "form-box" }
const _hoisted_3 = { class: "upload-container" }
const _hoisted_4 = {
  key: 0,
  class: "upload-box"
}
const _hoisted_5 = { class: "upload" }
const _hoisted_6 = {
  key: 1,
  class: "img-box"
}
const _hoisted_7 = { class: "model-box" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 2,
  class: "upload-box"
}
const _hoisted_10 = { class: "upload" }
const _hoisted_11 = {
  key: 3,
  class: "img-box"
}
const _hoisted_12 = { class: "model-box" }
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_form, {
        ref: "form",
        "label-col": {span: 3},
        "wrapper-col": {span: 14},
        model: _ctx.formInfo
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.name'),
            name: "name",
            rules: [
            {
              required: true,
              trigger: 'blur',
              validator: _ctx.checkName
            }
          ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formInfo.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInfo.name) = $event)),
                min: 2,
                max: 4
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label", "rules"]),
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.identificationNumber'),
            name: "certCode",
            rules: [
            {
              required: true,
              trigger: 'blur',
              validator: _ctx.checkIdCard
            }
          ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formInfo.certCode,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formInfo.certCode) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label", "rules"]),
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('userCenter.idCard')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (!_ctx.formInfo.frontUrl)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_a_upload, {
                        "custom-request": _ctx.handleUploadFront,
                        "before-upload": _ctx.beforeUpload,
                        "file-list": _ctx.fileListFront
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_svg_icon, { "icon-class": "ic_jia" }),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('userCenter.frontofIDcard')), 1)
                          ])
                        ]),
                        _: 1
                      }, 8, ["custom-request", "before-upload", "file-list"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_delete_outlined, {
                          onClick: _cache[2] || (_cache[2] = () => _ctx.formInfo.frontUrl = '')
                        })
                      ]),
                      _createElementVNode("img", {
                        src: '/api/user' + _ctx.formInfo.frontUrl
                      }, null, 8, _hoisted_8)
                    ])),
                (!_ctx.formInfo.backUrl)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_a_upload, {
                        "custom-request": _ctx.handleUploadEnd,
                        "before-upload": _ctx.beforeUpload,
                        "file-list": _ctx.fileListEnd
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_svg_icon, { "icon-class": "ic_jia" }),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('userCenter.onthebackoftheIDcard')), 1)
                          ])
                        ]),
                        _: 1
                      }, 8, ["custom-request", "before-upload", "file-list"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_delete_outlined, {
                          onClick: _cache[3] || (_cache[3] = () => _ctx.formInfo.backUrl = '')
                        })
                      ]),
                      _createElementVNode("img", {
                        src: '/api/user' + _ctx.formInfo.backUrl
                      }, null, 8, _hoisted_13)
                    ]))
              ])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, { "wrapper-col": {span: 9, offset: 3} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _ctx.handleSubmit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('userCenter.submitapplication')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ]))
}