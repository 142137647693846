import { computed, reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {
  userAuthApi,
  userPasswordApi,
  sendPhoneMsgApi,
  sendNewPhoneMsgApi,
  checkNewPhoneMsgApi,
  sendOldEmailMsgApi,
  sendNewEmailMsgApi,
  checkEmailMsgApi,
  queryUserQrCodeApi,
  queryUserScanCodeApi,
  unbindChatApi
} from '@/api/user-center'
import { SecuritySet } from '@/interface/userCenter'
import { FormInstance, Modal, message } from 'ant-design-vue'
import { encrtpt } from '@/utils/jsencrypt'
import { getToken } from '@/utils/token'

const useSecurityModule = () => {
  const router = useRouter()
  const route = useRoute()
  const authInfo: SecuritySet.AuthInfoFace = reactive({
    authFlag: false,
    emailFlag: false,
    openFlag: false,
    passWordFlag: false,
    phone: '',
    phoneFlag: false
  })

  const queryUserAuth = async () => {
    const res = await userAuthApi()
    Object.assign(authInfo, res.data)
  }

  // 修改密码
  const passwordVisible = ref<boolean>(false)
  const passwordForm = ref<FormInstance>()
  const passwordInfo: SecuritySet.PasswordFace = reactive({
    oldPassword: '',
    newPassword: ''
  })
  const handleSetPassword = (): void => {
    passwordVisible.value = true
  }
  const handleCommitPassword = ():void => {
    const validator = passwordForm.value?.validate()
    if (validator) {
      validator.then(() => {
        userPasswordApi({
          newPassword: encrtpt(passwordInfo.newPassword) as string,
          oldPassword: encrtpt(passwordInfo.oldPassword) as string,
          token: getToken() as string
        }).then(() => {
          message.success('修改成功')
          passwordVisible.value = false
        })
      }).catch(err => {
        console.log(err)
      })
    }
  }

  // 更换手机
  const phoneVisible = ref<boolean>(false)
  const phoneForm = ref<FormInstance>()
  const phoneInfo: SecuritySet.PhoneFace = reactive({
    oldCode: '',
    newPhone: '',
    code: ''
  })
  const hanleChangePhone = ():void => {
    phoneVisible.value = true
  }

  const handleSendMsgCode = () => {
    sendPhoneMsgApi().then(() => {
      message.success('已将验证码发送至手机，请注意查收')
    })
  }

  const handleSendNewMsgCode = () => {
    sendNewPhoneMsgApi(phoneInfo.newPhone).then(() => {
      message.success('已将验证码发送至手机，请注意查收')
    })
  }

  const handleConfirmChangePhone = () => {
    const params = {
      code: phoneInfo.code,
      newPhone: phoneInfo.newPhone
    }
    checkNewPhoneMsgApi(params).then(() => {
      message.success('更换手机号成功')
      phoneVisible.value = false
    })
  }

  // 邮箱
  const emailVisible = ref<boolean>(false)
  const emailInfo: SecuritySet.EmailFace = reactive({
    email: '',
    code: '',
    oldCode: ''
  })
  const handleBindEmail = () => {
    emailVisible.value = true
  }
  const handleSendEmailCode = () => {
    sendOldEmailMsgApi().then(() => {
      message.success('已将验证码发送至邮箱，请注意查收')
    })
  }
  const handleSendNewEmailCode = () => {
    sendNewEmailMsgApi(emailInfo.email).then(() => {
      message.success('已将验证码发送至邮箱，请注意查收')
    })
  }
  const handleConfirmChangeEmail = () => {
    const params: SecuritySet.EmailFace = {
      code: emailInfo.code,
      email: emailInfo.email
    }
    if (authInfo.emailFlag) {
      params.oldCode = emailInfo.oldCode
    }
    checkEmailMsgApi(params).then(() => {
      message.success('绑定邮箱成功')
      emailVisible.value = false
    })
  }

  // 微信绑定
  const bindChatVisible = ref<boolean>(false)
  const timer = ref()
  const frameUrl = ref<string>('')
  const handleWxShare = (data: string) => {
    timer.value = setInterval(() => {
      scanCode(data)
    }, 1000)
  }
  const getQrCode = () => {
    queryUserQrCodeApi().then(res => {
      frameUrl.value = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + res.data.base64Img
      handleWxShare(res.data.sceneCode)
    })
  }

  const scanCode = async (data: string) => {
    const res = await queryUserScanCodeApi(data)
    if (res.data) {
      clearInterval(timer.value)
      message.success('绑定成功')
      bindChatVisible.value = false
      queryUserAuth()
    }
  }

  const handleBindChat = () => {
    if (authInfo.openFlag) {
      // 已绑定
      console.log(authInfo.openFlag)
      Modal.confirm({
        title: '系统提示',
        content: '是否确认解绑当前微信?',
        centered: true,
        onOk: () => {
          unbindChatApi().then(() => {
            message.success('解绑成功')
            queryUserAuth()
          })
        }
      })
    } else {
      bindChatVisible.value = true
      getQrCode()
    }
  }

  // 实名认证
  const handleRealNameAuth = () => {
    router.push({
      path: '/user-center/security-setting/real-name-auth'
    })
  }

  const isShowSubView = computed<boolean>(() => {
    if (route.path === '/user-center/security-setting') {
      return true
    } else {
      return false
    }
  })

  return {
    authInfo,
    queryUserAuth,
    handleSetPassword,
    passwordVisible,
    passwordForm,
    passwordInfo,
    handleCommitPassword,
    phoneVisible,
    phoneForm,
    phoneInfo,
    hanleChangePhone,
    handleSendMsgCode,
    handleSendNewMsgCode,
    handleConfirmChangePhone,
    handleBindEmail,
    emailVisible,
    emailInfo,
    handleSendEmailCode,
    handleSendNewEmailCode,
    handleConfirmChangeEmail,
    handleRealNameAuth,
    isShowSubView,
    bindChatVisible,
    handleBindChat,
    frameUrl
  }
}

export {
  useSecurityModule
}
