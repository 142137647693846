import http from '@/utils/http'

interface Response {
  [propName: string]: any;
}
const getSshPublicKeyList = (): Promise<Response> => {
  return http({
    method: 'post',
    url: '/airestserver/sshPublicKey/list'
  })
}
const addSshPublicKey = (data:any): Promise<Response> => {
  return http({
    method: 'post',
    url: '/airestserver/sshPublicKey/add',
    data: data
  })
}
const updateSshPublicKey = (id:string, data:any): Promise<Response> => {
  return http({
    method: 'post',
    url: `/airestserver/sshPublicKey/update/${id}`,
    data: data
  })
}
const deleteSshPublicKey = (id:string): Promise<Response> => {
  return http({
    method: 'post',
    url: `/airestserver/sshPublicKey/delete/${id}`
  })
}
export {
  getSshPublicKeyList,
  deleteSshPublicKey,
  addSshPublicKey,
  updateSshPublicKey
}
