import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, renderList as _renderList, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c2c635b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "inner-container work-order-container"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 4,
  class: "operate-column"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "filter-box-y"
}
const _hoisted_7 = { class: "btn-group" }
const _hoisted_8 = {
  key: 1,
  class: "filter-box-y"
}
const _hoisted_9 = { class: "btn-group" }
const _hoisted_10 = {
  class: "btn-primary",
  type: "button"
}
const _hoisted_11 = {
  key: 1,
  class: "show-upload-box"
}
const _hoisted_12 = {
  key: 0,
  class: "img-box"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "model-box" }
const _hoisted_15 = {
  key: 1,
  class: "other-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_st_button = _resolveComponent("st-button")!
  const _component_dynamic_condition_search = _resolveComponent("dynamic-condition-search")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_st_pagination = _resolveComponent("st-pagination")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_router_view = _resolveComponent("router-view")!

  return (!_ctx.showDetail)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_dynamic_condition_search, {
          "condition-list": _ctx.conditionList,
          "current-condition": _ctx.currentCondition,
          "filter-options": _ctx.filterOptions,
          onChangeCondition: _ctx.handleChangeCondition,
          onChange: _ctx.handleSelectChange,
          onQuery: _ctx.handleQuery,
          onClear: _ctx.handleClear,
          onDel: _ctx.handleDelOption
        }, {
          btn: _withCtx(() => [
            _createVNode(_component_st_button, {
              type: "primary",
              onClick: _ctx.handleAddWorkOrder
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_svg_icon, { "icon-class": "ic_btn_jia" })
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.$t('workOrder.add')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["condition-list", "current-condition", "filter-options", "onChangeCondition", "onChange", "onQuery", "onClear", "onDel"]),
        _createElementVNode("div", {
          class: _normalizeClass(["table-box", _ctx.filterOptions.length > 0 ? 'table-box-other' : ''])
        }, [
          _createVNode(_component_a_table, {
            size: "small",
            bordered: "",
            "data-source": _ctx.dataSource,
            columns: _ctx.columns,
            pagination: false
          }, {
            bodyCell: _withCtx(({ column, record }) => [
              (column.dataIndex === 'workCode')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "primary-span",
                    onClick: () => _ctx.handleLinkDetail(record.id)
                  }, _toDisplayString(record.workCode), 9, _hoisted_2))
                : _createCommentVNode("", true),
              (column.dataIndex === 'status')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (record.status === 0)
                      ? (_openBlock(), _createBlock(_component_a_tag, {
                          key: 0,
                          color: "processing"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('workOrder.submit')), 1)
                          ]),
                          _: 1
                        }))
                      : (record.status === 1)
                        ? (_openBlock(), _createBlock(_component_a_tag, {
                            key: 1,
                            color: "success"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('workOrder.over')), 1)
                            ]),
                            _: 1
                          }))
                        : (record.status === -1)
                          ? (_openBlock(), _createBlock(_component_a_tag, {
                              key: 2,
                              color: "error"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('workOrder.revoked')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                  ], 64))
                : (column.dataIndex === 'workGrade')
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 2,
                      color: record.workGrade ? 'error' : 'processing'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(record.workGrade ? _ctx.$t('workOrder.danger') : _ctx.$t('workOrder.normal')), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"]))
                  : (column.dataIndex === 'workType')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                        (record.workType === 0)
                          ? (_openBlock(), _createBlock(_component_a_tag, {
                              key: 0,
                              color: "processing"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('workOrder.task')), 1)
                              ]),
                              _: 1
                            }))
                          : (record.workType === 1)
                            ? (_openBlock(), _createBlock(_component_a_tag, {
                                key: 1,
                                color: "success"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('workOrder.storage')), 1)
                                ]),
                                _: 1
                              }))
                            : (record.workType === 2)
                              ? (_openBlock(), _createBlock(_component_a_tag, {
                                  key: 2,
                                  color: "warning"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('workOrder.image')), 1)
                                  ]),
                                  _: 1
                                }))
                              : (record.workType === 3)
                                ? (_openBlock(), _createBlock(_component_a_tag, {
                                    key: 3,
                                    color: "error"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('workOrder.charging')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_a_tag, {
                                    key: 4,
                                    color: "processing"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('workOrder.user')), 1)
                                    ]),
                                    _: 1
                                  }))
                      ], 64))
                    : (column.dataIndex === 'operate')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          (record.status === -1)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: "primary-span",
                                onClick: () => _ctx.handleModifyWorkOrder(record.id)
                              }, _toDisplayString(_ctx.$t('workOrder.edit')), 9, _hoisted_4))
                            : _createCommentVNode("", true),
                          (record.status !== -1)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: "error-span",
                                onClick: () => _ctx.handleRevoke(record.id)
                              }, _toDisplayString(_ctx.$t('workOrder.revoke')), 9, _hoisted_5))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
            ]),
            customFilterIcon: _withCtx(({ filtered }) => [
              _createVNode(_component_svg_icon, {
                "icon-class": filtered ? 'ic_shaixuanz' : 'ic_shaixuan',
                class: "filter-icon"
              }, null, 8, ["icon-class"])
            ]),
            customFilterDropdown: _withCtx(({ column, clearFilters }) => [
              (column.dataIndex === 'workType')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('workOrder.type')), 1),
                    _createVNode(_component_a_divider),
                    _createVNode(_component_a_radio_group, {
                      value: column.radioValue,
                      "onUpdate:value": ($event: any) => ((column.radioValue) = $event),
                      onChange: ($event: any) => (_ctx.handleChangeStatus(column))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterList(column), (item) => {
                          return (_openBlock(), _createBlock(_component_a_radio, {
                            key: item.value,
                            value: item.value + ',' + item.label
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value", "onChange"]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_st_button, {
                        type: "default",
                        onClick: () => clearFilters({
                  closeDropdown: true
                })
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('del.cancel')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_st_button, {
                        type: "primary",
                        onClick: () => _ctx.handleconfirmFilter(column, clearFilters),
                        disabled: !column.radioValue
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('del.ok')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (column.dataIndex === 'workGrade')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('workOrder.level')), 1),
                    _createVNode(_component_a_divider),
                    _createVNode(_component_a_radio_group, {
                      value: column.radioValue,
                      "onUpdate:value": ($event: any) => ((column.radioValue) = $event),
                      onChange: ($event: any) => (_ctx.handleChangeStatus(column))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterList(column), (item) => {
                          return (_openBlock(), _createBlock(_component_a_radio, {
                            key: item.value,
                            value: item.value + ',' + item.label
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value", "onChange"]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_st_button, {
                        type: "default",
                        onClick: () => clearFilters({
                  closeDropdown: true
                })
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('del.cancel')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_st_button, {
                        type: "primary",
                        onClick: () => _ctx.handleconfirmFilter(column, clearFilters),
                        disabled: !column.radioValue
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('del.ok')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source", "columns"])
        ], 2),
        _createVNode(_component_st_pagination, {
          onChange: _ctx.hanleChangePage,
          total: _ctx.total
        }, null, 8, ["onChange", "total"]),
        _createVNode(_component_a_modal, {
          width: 746,
          centered: "",
          title: _ctx.title,
          open: _ctx.visible,
          "onUpdate:open": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.visible) = $event)),
          onOk: _ctx.handleConfirm
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              "label-col": {style: { width: '126px' }},
              ref: "form",
              model: _ctx.addForm
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('workOrder.name'),
                          name: "workName",
                          rules: [
                {
                  required: true,
                  message: _ctx.$t('workOrder.nameInput')
                }
              ]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.addForm.workName,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addForm.workName) = $event)),
                              maxlength: 33,
                              style: {"width":"220px"}
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label", "rules"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('workOrder.type'),
                          name: "workType",
                          rules: [
                {
                  required: true,
                  message: _ctx.$t('workOrder.typeInput')
                }
              ]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.addForm.workType,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addForm.workType) = $event)),
                              style: {"width":"220px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select_option, { value: 0 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('workOrder.task')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_select_option, { value: 1 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('workOrder.storage')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_select_option, { value: 2 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('workOrder.image')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_select_option, { value: 3 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('workOrder.charging')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_select_option, { value: 4 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('workOrder.user')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label", "rules"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('workOrder.level'),
                          name: "workGrade",
                          rules: [
                {
                  required: true,
                  message: _ctx.$t('workOrder.levelInput')
                }
              ]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.addForm.workGrade,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addForm.workGrade) = $event)),
                              style: {"width":"220px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select_option, { value: 0 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('workOrder.normal')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_select_option, { value: 1 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('workOrder.danger')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label", "rules"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('workOrder.detail'),
                          name: "workExplain",
                          rules: [
                {
                  required: true,
                  message: _ctx.$t('workOrder.detailInput')
                }
              ]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.addForm.workExplain,
                              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addForm.workExplain) = $event)),
                              maxlength: 300,
                              style: {"width":"220px","height":"32px"}
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label", "rules"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('workOrder.upload'),
                          name: "filePath",
                          rules: [
                {
                  required: true,
                  message: _ctx.$t('workOrder.uploadInput')
                }
              ]
                        }, {
                          default: _withCtx(() => [
                            (!_ctx.addForm.filePath)
                              ? (_openBlock(), _createBlock(_component_a_upload, {
                                  key: 0,
                                  "file-list": _ctx.fileList,
                                  customRequest: _ctx.customRequest
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("button", _hoisted_10, _toDisplayString(_ctx.$t('workOrder.upload')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["file-list", "customRequest"]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  (_ctx.allowUploadList.find(e => _ctx.addForm.filePath.includes(e)))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                        _createElementVNode("img", {
                                          src: '/api/user' + _ctx.addForm.filePath,
                                          alt: "",
                                          class: "banner-image"
                                        }, null, 8, _hoisted_13),
                                        _createElementVNode("div", _hoisted_14, [
                                          _createVNode(_component_delete_outlined, {
                                            onClick: _cache[4] || (_cache[4] = () => _ctx.addForm.filePath = '')
                                          })
                                        ])
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.addForm.filePath.substring(_ctx.addForm.filePath.lastIndexOf('/') + 1)), 1),
                                        _createVNode(_component_delete_outlined, {
                                          onClick: _cache[5] || (_cache[5] = () => _ctx.addForm.filePath = ''),
                                          style: {"margin-left":"16px"}
                                        })
                                      ]))
                                ]))
                          ]),
                          _: 1
                        }, 8, ["label", "rules"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["title", "open", "onOk"]),
        _createVNode(_component_a_modal, {
          open: _ctx.revokeVisible,
          "onUpdate:open": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.revokeVisible) = $event)),
          title: _ctx.$t('workOrder.recall'),
          centered: "",
          onOk: _ctx.handleConfirmReVoke,
          width: 746
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              "label-col": {
          style: {
            width: '126px',
            marginLeft: '158px'
          }
        },
              model: _ctx.recallForm,
              ref: "formRe"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _ctx.$t('workOrder.recall') + _ctx.$t('workOrder.reason'),
                  name: "revokeReason",
                  rules: [
            {
              required: true,
              message: _ctx.$t('workOrder.reasonInput')
            }
          ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _ctx.recallForm.revokeReason,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.recallForm.revokeReason) = $event)),
                      style: {"width":"220px","height":"32px"}
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"])
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["open", "title", "onOk"])
      ]))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}