import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "s-button",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.btnClick && _ctx.btnClick(...args)), ["stop"])),
    disabled: _ctx.disabled,
    type: "button"
  }, [
    _createElementVNode("i", _hoisted_2, [
      _renderSlot(_ctx.$slots, "icon")
    ]),
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 8, _hoisted_1))
}