import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48080e78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container detail-container" }
const _hoisted_2 = { class: "btn-group" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "left-content" }
const _hoisted_5 = { class: "table-box" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "operate-column"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "error-span" }
const _hoisted_11 = {
  key: 1,
  class: "operate-column"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "right-content" }
const _hoisted_14 = { class: "upload-files" }
const _hoisted_15 = { class: "progress-box" }
const _hoisted_16 = { class: "header-title" }
const _hoisted_17 = { style: {"color":"#3273f9"} }
const _hoisted_18 = { class: "use-total" }
const _hoisted_19 = { class: "use-ul" }
const _hoisted_20 = { class: "total-ul" }
const _hoisted_21 = { class: "btn-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_st_button = _resolveComponent("st-button")!
  const _component_condition_search = _resolveComponent("condition-search")!
  const _component_ellipsis_outlined = _resolveComponent("ellipsis-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_config_columns = _resolveComponent("config-columns")!
  const _component_st_pagination = _resolveComponent("st-pagination")!
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_expansion_modal = _resolveComponent("expansion-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_condition_search, {
      "current-condition": _ctx.currentCondition,
      "condition-list": _ctx.conditionList,
      "filter-options": _ctx.filterOptions,
      onChangeCondition: _ctx.handleChangeCondition,
      onChange: _ctx.handleSelectChange,
      onQuery: _ctx.handleQuery,
      onDel: _ctx.handleDelOption,
      onClear: _ctx.handleClear
    }, {
      btn: _withCtx(() => [
        _createVNode(_component_st_button, {
          type: "primary",
          onClick: _ctx.handleAddFolder
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_svg_icon, { "icon-class": "ic_btn_jia" })
          ]),
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(_ctx.$t('netDisk.addFolder')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["current-condition", "condition-list", "filter-options", "onChangeCondition", "onChange", "onQuery", "onDel", "onClear"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_st_button, {
        type: "primary",
        onClick: _ctx.handleBackFolder,
        disabled:  (_ctx.$route.query.type === '2' || _ctx.$route.query.type === '4') ? _ctx.isTopDir : _ctx.isTopDirObj
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('netDisk.returnPath')), 1)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_a_table, {
            "data-source": _ctx.dataSource,
            columns: (_ctx.filterColumns.length === 0 && !_ctx.isAllHidden) ? _ctx.columns : _ctx.filterColumns,
            bordered: "",
            pagination: false,
            size: "small",
            "row-key": "fileName",
            scroll: { y: _ctx.scrollHeight - 39 }
          }, {
            bodyCell: _withCtx(({column, record}) => [
              (column.dataIndex === 'fileName')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (record.isDir)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "primary-span",
                          onClick: () => _ctx.handleQueryFolderInfo(record)
                        }, _toDisplayString(record.fileName), 9, _hoisted_6))
                      : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(record.fileName), 1))
                  ], 64))
                : (column.dataIndex === 'operate')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (!record.isDir)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("span", {
                              class: "primary-span",
                              onClick: ($event: any) => (_ctx.handleDownload(record))
                            }, _toDisplayString(_ctx.$t('netDisk.download')), 9, _hoisted_9),
                            _createVNode(_component_a_dropdown, null, {
                              overlay: _withCtx(() => [
                                _createVNode(_component_a_menu, { onClick: _ctx.handleChoostTools }, {
                                  default: _withCtx(() => [
                                    (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: JSON.stringify(record) + ';del'
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('netDisk.del')), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_ellipsis_outlined)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("span", {
                              class: "error-span",
                              onClick: ($event: any) => (_ctx.handleDel(record))
                            }, _toDisplayString(_ctx.$t('netDisk.del')), 9, _hoisted_12)
                          ]))
                    ], 64))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source", "columns", "scroll"]),
          _createVNode(_component_config_columns, {
            columns: _ctx.columns,
            onChangeAll: _ctx.handleChangeAll,
            onConfirm: _ctx.handleSetColumns
          }, null, 8, ["columns", "onChangeAll", "onConfirm"])
        ]),
        _createVNode(_component_st_pagination, {
          total: _ctx.total,
          onChange: _ctx.handleChangePage
        }, null, 8, ["total", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("span", {
            class: "primary-span",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleUpload && _ctx.handleUpload(...args)))
          }, _toDisplayString(_ctx.$t('netDisk.fileUpload')), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$route.query.type === '2' ? _ctx.$t('netDisk.sharedStorage') : _ctx.$route.query.type === '4' ? _ctx.$t('netDisk.objectStorage') : _ctx.$t('netDisk.objectStorage')), 1),
          _createVNode(_component_a_progress, {
            type: "circle",
            "stroke-color": {
              '0%': '#608DE9',
              '100%': '#017AE3',
            },
            strokeWidth: 8,
            percent: parseInt(_ctx.proportion),
            size: 220
          }, {
            format: _withCtx((percent) => [
              _createElementVNode("span", _hoisted_17, _toDisplayString(percent) + "%", 1)
            ]),
            _: 1
          }, 8, ["percent"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("ul", _hoisted_19, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('netDisk.used')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.useSize) + "GB", 1)
          ]),
          _createElementVNode("ul", _hoisted_20, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('netDisk.totalSpace')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.totalSize) + "GB", 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          (_ctx.expansionPrice.flag)
            ? (_openBlock(), _createBlock(_component_st_button, {
                key: 0,
                type: "default",
                onClick: _ctx.handleExpansion
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('netDisk.expansion')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_component_a_modal, {
      open: _ctx.visible,
      "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
      title: _ctx.$t('netDisk.addFolder'),
      centered: "",
      onOk: _ctx.handleConfirmFolder,
      width: 746
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.addForm,
          ref: "form",
          "label-col": {
          style: {
            width: '126px',
            marginLeft: '158px'
          }
        }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('netDisk.fileName'),
              name: "fileName",
              rules: [
            {
              required: true,
              trigger: 'change',
              validator: _ctx.checkFolderName
            }
          ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.addForm.fileName,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addForm.fileName) = $event)),
                  style: {"width":"220px"}
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label", "rules"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["open", "title", "onOk"]),
    _createVNode(_component_expansion_modal, _mergeProps({
      visible: _ctx.expansionVisible,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.expansionVisible) = $event))
    }, _ctx.expansionPrice, {
      mode: "data",
      "bucket-id": _ctx.bucketId,
      onExpansionDiskFun: _ctx.handleConfirmExpansion,
      title: _ctx.title
    }), null, 16, ["visible", "bucket-id", "onExpansionDiskFun", "title"])
  ]))
}