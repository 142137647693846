
import { defineComponent, onMounted, ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import CardTab from './components/CardTab.vue'
import ConditionSearch from '@/components/condition/Condition'
import StPagination from '@/components/st-pagination/StPagination.vue'
import { EllipsisOutlined } from '@ant-design/icons-vue'
import ConfigColumns from '@/components/config-columns/ConfigColumns.vue'
import ExpansionModal from '@/components/expansion-modal/ExpansionModal.vue'
import {
  precentInfoModule,
  useTableModule,
  createVolumnModule,
  shareModule,
  expansionModule
} from './net-disk'

export default defineComponent({
  components: {
    CardTab,
    ConditionSearch,
    StPagination,
    EllipsisOutlined,
    ConfigColumns,
    ExpansionModal
  },
  setup () {
    const route = useRoute()

    // 判断是否已创建存储卷
    const computedIsCreateVolumn = computed<boolean>(() => {
      const chooseBucket = cardList.value.filter(item => item.bucketType === conditionParams.value.bucketType)
      return chooseBucket[0].isCreatedVolumn
    })

    const {
      cardList,
      queryPrecentInfo,
      loading
    } = precentInfoModule()

    const {
      conditionParams,
      currentCondition,
      conditionList,
      conditionListCache,
      queryNetDiskInfo,
      bucketType,
      dataSource,
      columns,
      total,
      handleQuery,
      handleSelectChange,
      filterOptions,
      handleDelOption,
      handleClear,
      handleChangeAll,
      handleSetColumns,
      filterColumns,
      isAllHidden,
      handleChangePage,
      flagCache,
      scrollHeight,
      handleTabs,
      handleDetail
    } = useTableModule(cardList)

    const detailVisible = ref(false)

    // 初始化存储卷
    const {
      createColumnParams,
      regionList,
      ipList,
      categoryList,
      handleChangeRegion,
      handleChangeCategory,
      createVolumnVisible,
      handleCreateVolumn,
      formColumn,
      handleConfirmCreate
    } = createVolumnModule(cardList, queryNetDiskInfo, bucketType)

    // 监听新建存储卷弹窗关闭时，清空数据
    watch(createVolumnVisible, () => {
      if (!createVolumnVisible.value) {
        createColumnParams.regionId = ''
        createColumnParams.chooseIp = ''
        createColumnParams.categoryId = ''
      }
    })

    // 存储桶分享
    const {
      visible,
      shareFormRef,
      shareColumns,
      users,
      handleShare,
      handlePushUsers,
      handleDelUser,
      checkUserId,
      handleConfirm,
      storageInfo,
      shareTitle,
      handleChoostTools
    } = shareModule(queryNetDiskInfo)

    // 网盘扩容
    const {
      expansionVisible,
      handleExpansion,
      title,
      expansionPrice,
      handleConfirmExpansion,
      bucketId,
      okBtnState
    } = expansionModule(queryNetDiskInfo)

    onMounted(() => {
      if (!route.path.includes('detail')) {
        detailVisible.value = false
        queryPrecentInfo(queryNetDiskInfo, bucketType)
      } else {
        detailVisible.value = true
      }
    })

    watch(() => route.path, () => {
      if (route.path === '/storage-center/net-disk') {
        queryPrecentInfo(queryNetDiskInfo, bucketType)
        detailVisible.value = false
      } else {
        detailVisible.value = true
      }
    })

    const setBtnDisabled = ref(false)

    watch(users, () => {
      const list = users.value.filter(item => item.userId === '')
      if (list.length > 0) {
        setBtnDisabled.value = true
      } else {
        setBtnDisabled.value = false
      }
    }, { deep: true })

    return {
      cardList,
      conditionParams,
      currentCondition,
      conditionList,
      bucketType,
      dataSource,
      columns,
      total,
      handleQuery,
      handleSelectChange,
      filterOptions,
      handleDelOption,
      handleClear,
      handleChangeAll,
      handleSetColumns,
      filterColumns,
      isAllHidden,
      handleChangePage,
      flagCache,
      scrollHeight,
      handleTabs,
      computedIsCreateVolumn,
      createColumnParams,
      regionList,
      ipList,
      categoryList,
      handleChangeRegion,
      handleChangeCategory,
      createVolumnVisible,
      handleCreateVolumn,
      formColumn,
      handleConfirmCreate,
      visible,
      shareFormRef,
      shareColumns,
      users,
      handleShare,
      handlePushUsers,
      handleDelUser,
      checkUserId,
      handleConfirm,
      storageInfo,
      shareTitle,
      setBtnDisabled,
      handleChoostTools,
      expansionVisible,
      handleExpansion,
      title,
      expansionPrice,
      handleConfirmExpansion,
      bucketId,
      okBtnState,
      handleDetail,
      detailVisible,
      loading,
      conditionListCache
    }
  }
})
