import { querySystemImageFace, queryMyImageFace, shareMyImageFace } from '@/interface/imageCenter'
import http from '@/utils/http'

const querySystemImageApi = (data: querySystemImageFace) => {
  return http({
    method: 'post',
    url: '/image/imageManage/queryImages',
    data: data
  })
}

const queryMyImageApi = (data: queryMyImageFace) => {
  return http({
    method: 'post',
    url: '/image/image/queryUserImages',
    data: data
  })
}

const shareMyImageApi = (data: shareMyImageFace) => {
  return http({
    method: 'post',
    url: '/image/image/imageRelationUser',
    data: data
  })
}

const delMyImageApi = (data: string) => {
  return http({
    method: 'post',
    url: `/image/image/removeImage/${data}`
  })
}

export {
  querySystemImageApi,
  queryMyImageApi,
  shareMyImageApi,
  delMyImageApi
}
