import { ref, reactive } from 'vue'
import http from '@/utils/http'
import { message } from 'ant-design-vue'
export const useExpansion = () => {
  const expansionFormRef = ref(null)
  const expansionPrice = reactive({
    discountMonth: 0,
    priceMonth: 0,
    flag: false,
    dataSize: 0,
    defaultDataSize: 0,
    dilatationDataSize: 0
  })

  // 查询单价
  const queryBucketMoneyApi = (id: string) => {
    http.request({
      method: 'get',
      url: `/storage/bucket/queryBucketMoney/${id}`
    }).then(res => {
      expansionPrice.priceMonth = res.data.priceMonth === null ? 0 : res.data.priceMonth
      expansionPrice.discountMonth = res.data.discountMonth === null ? 0 : res.data.discountMonth
      expansionPrice.flag = res.data.flag
      expansionPrice.dataSize = res.data.dataSize
      expansionPrice.defaultDataSize = res.data.defaultDataSize
      expansionPrice.dilatationDataSize = res.data.dilatationDataSize
    })
  }

  const queryBucketMoneyApiByTaskId = (id: string) => {
    return new Promise((resolve, reject) => {
      http.request({
        method: 'get',
        url: `/airestserver/task/queryExpansionCachePrice/${id}`
      }).then(res => {
        expansionPrice.priceMonth = res.data.priceMonth === null ? 0 : res.data.priceMonth
        expansionPrice.discountMonth = res.data.discountMonth === null ? 0 : res.data.discountMonth
        expansionPrice.flag = res.data.flag
        expansionPrice.dataSize = res.data.dataSize
        expansionPrice.defaultDataSize = res.data.defaultDataSize
        expansionPrice.dilatationDataSize = res.data.dilatationDataSize
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  // 网盘扩容
  const handleExpansionDiskApi = (data: any, url: any, fn: any) => {
    return new Promise((resolve, reject) => {
      http.request({
        method: 'post',
        url: url,
        data: data,
        cancelToken: fn()
      }).then(() => {
        message.success('网盘扩容成功', 0.5).then(() => {
          resolve(1)
        })
      }).catch(err => {
        reject(err)
      })
    })
  }

  return {
    expansionFormRef,
    expansionPrice,
    queryBucketMoneyApi,
    handleExpansionDiskApi,
    queryBucketMoneyApiByTaskId
  }
}
