
import { defineComponent, onMounted, reactive } from 'vue'
import { queryInviteInfoApi } from '@/api/user-center'
import { InviteSpace } from '@/interface/userCenter'
import { message } from 'ant-design-vue'

export default defineComponent({
  setup () {
    const info: InviteSpace.InviteInfoFace = reactive({
      money: 0,
      count: 0,
      url: ''
    })
    const handleCopy = () => {
      const input = document.createElement('input')
      input.value = info.url
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      message.success('已复制')
    }

    const queryInviteInfo = () => {
      queryInviteInfoApi().then(res => {
        Object.assign(info, res.data)
      })
    }

    onMounted(() => {
      queryInviteInfo()
    })

    return {
      handleCopy,
      info
    }
  }
})
