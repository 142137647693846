
import { defineComponent, ref, computed } from 'vue'
import SystemImage from '../system-image/SystemImage.vue'
import MyImage from '../my-image/MyImage.vue'
export default defineComponent({
  components: {
    SystemImage,
    MyImage
  },
  setup () {
    const activeKey = ref<number>(1)
    const currentComponent = computed<string>(() => {
      if (activeKey.value === 1) {
        return 'SystemImage'
      } else {
        return 'MyImage'
      }
    })
    return {
      activeKey,
      currentComponent
    }
  }
})
