import {
  QueryParamsFace,
  QueryIpListparamsFace,
  ShareColumnFace,
  QueryNetDiskContentFace,
  GetObjChunkFileParam
} from '@/interface/netDisk'
import http from '@/utils/http'

const queryPrecentInfoApi = () => {
  return http({
    method: 'get',
    url: '/storage/bucket/queryProportion'
  })
}

const queryNetDiskInfoApi = (data: QueryParamsFace) => {
  return http({
    method: 'post',
    url: '/storage/bucket/queryUserBucketList',
    data: data
  })
}

const queryRegionListApi = () => {
  return http({
    method: 'post',
    url: '/maintenance/regionManage/getRegionName'
  })
}

const queryIpListApi = (data: QueryIpListparamsFace) => {
  return http({
    method: 'post',
    url: '/maintenance/storageDevice/getRegionDeviceDict',
    data: data
  })
}

const queryCategoryListApi = (data: QueryIpListparamsFace) => {
  return http({
    method: 'post',
    url: '/maintenance/storageDevice/getRegionDeviceDict',
    data: data
  })
}

const initBucketApi = (id: string) => {
  return http({
    method: 'post',
    url: `/storage/bucket/initBucket/${id}`
  })
}

const shareBucketApi = (data: ShareColumnFace) => {
  return http({
    method: 'post',
    url: '/storage/bucket/bucketRelationUser',
    data: data
  })
}

// 共享存储&分布式存储查询文件夹
const queryNetDiskContentApi = (data: QueryNetDiskContentFace) => {
  return http({
    method: 'post',
    url: '/storage/nfsOperation/fileAndDirManager',
    data: data
  })
}

// 对象存储查询
const queryNetDiskContentByObjApi = (data: QueryNetDiskContentFace) => {
  return http({
    method: 'post',
    url: '/storage/objOperation/listObject',
    data: data
  })
}

// 删除共享存储和分布式存储
const delNetDiskContentApi = (data: { bucketId: string; fileName: string; }) => {
  return http({
    method: 'post',
    url: '/storage/nfsOperation/deleteFileOrDir',
    data: data
  })
}

// 删除对象存储
const removeObjNameApi = (data: { bucketId: string; fileName: string; }) => {
  return http({
    method: 'post',
    url: '/storage/objOperation/removeObjName',
    data: data
  })
}

// 共享存储&分布式存储添加文件夹
const createDirApi = (data: { bucketId: string; fileName: string; }) => {
  return http({
    method: 'post',
    url: '/storage/nfsOperation/createDir',
    data: data
  })
}

// 对象存储添加文件夹
const createObjApi = (data: { bucketDir: string; bucketId: string; }) => {
  return http({
    method: 'post',
    url: '/storage/objOperation/createObj',
    data: data
  })
}

// 查询存储桶信息
const queryUsageProportionApi = (id: string) => {
  return http({
    method: 'get',
    url: `/storage/bucket/queryBucketProportion/${id}`
  })
}

// 网盘扩容
const expansionNetDiskApi = (data: any, fn: any) => {
  return http({
    method: 'post',
    url: '/storage/bucket/bucketDilatation',
    data: data,
    cancelToken: fn()
  })
}

// 获取对象存储上传文件参数
const getObjChunkFileParamsApi = (data: GetObjChunkFileParam) => {
  return http({
    method: 'post',
    url: '/storage/objOperation/fileChunkUrl',
    data: data
  })
}

export {
  queryPrecentInfoApi,
  queryNetDiskInfoApi,
  queryRegionListApi,
  queryIpListApi,
  queryCategoryListApi,
  initBucketApi,
  shareBucketApi,
  queryNetDiskContentApi,
  delNetDiskContentApi,
  createDirApi,
  queryUsageProportionApi,
  queryNetDiskContentByObjApi,
  createObjApi,
  removeObjNameApi,
  expansionNetDiskApi,
  getObjChunkFileParamsApi
}
