import { queryRecordByTaskId } from '@/api/myTask'
import { operateLogDialogVisible, operateDataSource, operateColumns } from '../myTaskModule'
const handleOperateLog = (item:any) => {
  queryRecordByTaskId(item.id).then((res) => {
    operateDataSource.value = res.data
    operateLogDialogVisible.value = true
  })
}
export {
  handleOperateLog,
  operateDataSource,
  operateLogDialogVisible,
  operateColumns
}
