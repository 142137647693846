import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc7f1596"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumb-nav" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "active-title"
}
const _hoisted_4 = {
  key: 1,
  class: "active-title"
}
const _hoisted_5 = {
  key: 0,
  class: "title"
}
const _hoisted_6 = {
  key: 1,
  class: "title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_breadcrumb, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbList, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.path
          }, [
            (index !== _ctx.breadcrumbList.length - 1)
              ? (_openBlock(), _createBlock(_component_a_breadcrumb_item, {
                  key: 0,
                  style: {"color":"#3273f9","cursor":"pointer"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      onClick: ($event: any) => (_ctx.handleBreadLink(item.path))
                    }, [
                      (_ctx.$i18n.locale === 'zh-CN')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.meta.menuTitle), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.meta.englishTitle), 1))
                    ], 8, _hoisted_2)
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createBlock(_component_a_breadcrumb_item, { key: 1 }, {
                  default: _withCtx(() => [
                    (_ctx.$i18n.locale === 'zh-CN')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.meta.menuTitle), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.meta.englishTitle), 1))
                  ]),
                  _: 2
                }, 1024))
          ], 64))
        }), 128))
      ]),
      _: 1
    })
  ]))
}