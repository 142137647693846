import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18c0c608"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-container invoice-set-container" }
const _hoisted_2 = { class: "todo" }
const _hoisted_3 = { class: "invoice-set" }
const _hoisted_4 = { class: "form-box" }
const _hoisted_5 = { class: "title-group" }
const _hoisted_6 = { class: "title-label" }
const _hoisted_7 = { class: "form-box" }
const _hoisted_8 = { class: "title-group" }
const _hoisted_9 = { class: "title-label" }
const _hoisted_10 = { class: "btn-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_st_button = _resolveComponent("st-button")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form = _resolveComponent("a-form")!
  const _directive_preventReClick = _resolveDirective("preventReClick")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_st_button, {
        type: "primary",
        onClick: _ctx.handleBack
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_svg_icon, { "icon-class": "ic_fanhui" })
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("costCenter.fanhui")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_form, {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      ref: "invoiceRef",
      model: _ctx.invoiceInfo,
      rules: _ctx.rules,
      class: "inlineForm"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_a_divider, {
                class: "title-line",
                type: "vertical"
              }),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("costCenter.invoiceInformation")), 1)
            ]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('costCenter.invoiceTitle'),
              name: "invoiceTitle"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.invoiceTitle,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.invoiceTitle) = $event)),
                  valueModifiers: { trim: true }
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('costCenter.invoiceType'),
              name: "invoiceType"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.invoiceType,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.invoiceType) = $event)),
                  onChange: _ctx.handleChangeMode
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, { value: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("costCenter.VATordinaryinvoice")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, { value: 2 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("costCenter.VATspecialinvoice")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value", "onChange"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('costCenter.billingMethod')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.invoiceMode,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.invoiceMode) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, {
                      value: 1,
                      disabled: _ctx.invoiceType == 2
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("costCenter.electronicinvoice")), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_a_radio, { value: 2 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("costCenter.postInvoice")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('costCenter.taxpayerNumber'),
              name: "taxpayerNumber"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.taxpayerNumber,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.taxpayerNumber) = $event)),
                  valueModifiers: { trim: true }
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('costCenter.bankName'),
              name: "bankName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.bankName,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.bankName) = $event)),
                  valueModifiers: { trim: true }
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('costCenter.bankAccount'),
              name: "bankAccount"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.bankAccount,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.bankAccount) = $event)),
                  valueModifiers: { trim: true }
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('costCenter.companyAddress'),
              name: "companyAddress"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.companyAddress,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.companyAddress) = $event)),
                  valueModifiers: { trim: true }
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('costCenter.companyPhone'),
              name: "companyPhone"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.companyPhone,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.companyPhone) = $event)),
                  valueModifiers: { trim: true }
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_a_divider, {
                class: "title-line",
                type: "vertical"
              }),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("costCenter.personalInformation")), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('costCenter.consignee'),
                name: "consignee"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.consignee,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.consignee) = $event)),
                    valueModifiers: { trim: true }
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('costCenter.invoicePhone'),
                name: "consigneePhone"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.consigneePhone,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.consigneePhone) = $event)),
                    valueModifiers: { trim: true }
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('costCenter.consigneeAddress'),
                name: "consigneeAddress"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.consigneeAddress,
                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.consigneeAddress) = $event)),
                    valueModifiers: { trim: true }
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('costCenter.consigneeEmail'),
                name: "consigneeEmail"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.consigneeEmail,
                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.consigneeEmail) = $event)),
                    valueModifiers: { trim: true }
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('costCenter.command'),
                name: "remark"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.remark,
                    "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.remark) = $event)),
                    valueModifiers: { trim: true }
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["label"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _withDirectives((_openBlock(), _createElementBlock("button", {
            class: "btn-primary",
            onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t("costCenter.confirm")), 1)
          ])), [
            [_directive_preventReClick, 3000]
          ])
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}