
import { defineComponent, onMounted } from 'vue'
import LoginRegisterBg from '@/components/Login-register-bg/LoginRegisterBg.vue'
import { useFindPassword } from './findPasswordModule'
export default defineComponent({
  components: {
    LoginRegisterBg
  },
  setup () {
    const {
      findPassword,
      second,
      queryCodeInfo,
      imgCodeInfo,
      handleRegister,
      handleSendCode,
      disabled,
      handleRefreshImgCode,
      checkPassword,
      validatorPhone
    } = useFindPassword()

    onMounted(() => {
      queryCodeInfo()
    })

    return {
      findPassword,
      second,
      queryCodeInfo,
      imgCodeInfo,
      handleRegister,
      handleSendCode,
      disabled,
      handleRefreshImgCode,
      checkPassword,
      validatorPhone
    }
  }
})
