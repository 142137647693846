import http from '@/utils/http'
import { useCancelAjax } from '@/utils/cancel-ajax'
import {
  SearchTaskFormParamsFace, BillingChangeFormParamsFace, ImageParamsFace, ImagePacakageParamsFace,
  TaskUpgradeParamsFace, SelectProductPriceParamsFace, TaskRenewParamsFace, IncreaseBandParamsFace,
  NoteParamStateFace, ImageChangeParamStateFace
} from '@/interface/myTaskInterface'
const { createCancelList } = useCancelAjax()
interface Response {
  [propName: string]: any;
}
// 查询实例
const getTaskPage = (data:SearchTaskFormParamsFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/airestserver/task/taskPage',
    data: data
  })
}
const getIsCost = (): Promise<Response> => {
  return http({
    method: 'get',
    url: '/user/configCost/getIsCost'
  })
}
const balanceCoupon = (data:any): Promise<Response> => {
  return http({
    method: 'post',
    url: '/user/user/account/balanceCoupon',
    data: data
  })
}
const queryReleaseTime = (): Promise<Response> => {
  return http({
    method: 'get',
    url: '/airestserver/config/queryReleaseTime'
  })
}
// 关闭容器
const killContainer = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/killContainer/${taskId}`
  })
}
// 无卡模式容器
const notGpuModel = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/notGpuModel/${taskId}`
  })
}
// 计费变更
const billingChanges = (data:BillingChangeFormParamsFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/airestserver/task/billingChanges',
    data: data
  })
}
// 重置系统
const resetContainer = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/resetContainer/${taskId}`
  })
}
// 镜像下拉框数据
const queryImageDict = (data:ImageParamsFace): Promise<Response> => {
  return http({
    method: 'post',
    url: '/image/image/queryImageDict',
    data: data
  })
}
// 打包容器
const packagingContainer = (data:ImagePacakageParamsFace) => {
  return http.request({
    method: 'post',
    url: '/airestserver/task/packagingContainer',
    data: data
  })
}
// 查询缓存盘扩容单价
const queryBucketMoney = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/queryExpansionCachePrice/${taskId}`
  })
}
// 实例操作记录查询
const queryRecordByTaskId = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/operationRecord/queryRecordByTaskId/${taskId}`
  })
}
// 修改实例状态为已关机
const modTaskStatus = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/modTaskStatus/${taskId}`
  })
}
// 查询当前计费变更价格
const selectProductPriceById = (data:SelectProductPriceParamsFace): Promise<Response> => {
  return http({
    method: 'get',
    url: `/maintenance/productInfo/selectProductPriceById/${data.specificationId}/${data.userId}`,
    params: {
      userSubId: data.userSubId
    }
  })
}
// 实例升配
const taskUpgrade = (data:TaskUpgradeParamsFace) => {
  return http.request({
    method: 'post',
    url: '/airestserver/task/taskUpgrade',
    data: data
  })
}
// 实例续租
const taskRenewal = (data:TaskRenewParamsFace) => {
  return http.request({
    method: 'post',
    url: '/airestserver/task/taskRenewal',
    data: data
  })
}
// 查询带宽价格
const queryBandwidthPrice = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/productPrice/queryBandwidthPrice/${taskId}`
  })
}
// 增加带宽
const increaseBandwidth = (data:IncreaseBandParamsFace) => {
  return http.request({
    method: 'post',
    url: '/airestserver/task/increaseBandwidth',
    data: data
  })
}
// 重启容器
const restartContainer = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/restartContainer/${taskId}`
  })
}
// 删除实例
const deleteTask = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/delete/${taskId}`
  })
}
// 启动容器
const startContainer = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/startContainer/${taskId}`
  })
}
// 生成自定义端口
const generateCustomPort = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/generateCustomPort/${taskId}`
  })
}
// 创建代理隧道
const generateClientTunnel = (taskId:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/generateClientTunnel/${taskId}`
  })
}
// 修改实例备注信息
const updateNote = (taskId:string, data:NoteParamStateFace): Promise<Response> => {
  return http({
    method: 'get',
    url: `/airestserver/task/updateNote/${taskId}`,
    params: data
  })
}
// 查询区域下资源规格信息
const listAvailableSpecification = (area:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/maintenance/resourceSpecification/listAvailableSpecification/${area}`
  })
}
// 数据集
const dataSetInfo = (): Promise<Response> => {
  return http({
    method: 'get',
    url: '/dataprocess/dataSet/info'
  })
}
const publishInfo = (version:string): Promise<Response> => {
  return http({
    method: 'get',
    url: `/dataprocess/dataSet/publishInfo/${version}`
  })
}
const createTaskMultiMachine = (data:any) => {
  return http({
    method: 'post',
    url: '/airestserver/task/createTaskMultiMachine',
    cancelToken: createCancelList(),
    data: data
  })
}
// 即将释放任务查询
const queryWillReleaseTask = () => {
  return http({
    method: 'post',
    url: '/airestserver/task/queryWillReleaseTask'
  })
}
// 7日内到期资源任务查询
const queryWillExpireTask = () => {
  return http({
    method: 'post',
    url: '/airestserver/task/queryWillExpireTask'
  })
}
// 查询用户信息认证信息
const userAuth = () => {
  return http({
    method: 'post',
    url: '/user/user/info/userAuth'
  })
}
// 查询当前用户的通知信息(默认展示十条)
const userNotice = () => {
  return http({
    method: 'post',
    url: '/user/notice/userNotice'
  })
}
// 获取作业运行概况
const getTaskStatusVO = (regionId:string) => {
  return http({
    method: 'get',
    url: '/airestserver/task/getTaskStatusVO',
    params: {
      regionId: regionId
    }
  })
}
// 查询所有存储桶信息首页展示
const queryAllBucket = () => {
  return http({
    method: 'get',
    url: '/storage/bucket/queryAllBucket'
  })
}
// 查询所有镜像信息首页展示
const imageHomeUser = () => {
  return http({
    method: 'get',
    url: '/image/image/imageHomeUser'
  })
}
// 更换镜像
const changeTaskImage = (data:ImageChangeParamStateFace) => {
  return http({
    method: 'post',
    url: '/airestserver/task/changeImage',
    data: data
  })
}
export {
  getTaskPage,
  getIsCost,
  balanceCoupon,
  queryReleaseTime,
  killContainer,
  notGpuModel,
  billingChanges,
  resetContainer,
  queryImageDict,
  packagingContainer,
  queryBucketMoney,
  queryRecordByTaskId,
  modTaskStatus,
  taskUpgrade,
  selectProductPriceById,
  taskRenewal,
  increaseBandwidth,
  queryBandwidthPrice,
  restartContainer,
  deleteTask,
  startContainer,
  generateCustomPort,
  generateClientTunnel,
  updateNote,
  listAvailableSpecification,
  dataSetInfo,
  publishInfo,
  createTaskMultiMachine,
  queryWillReleaseTask,
  queryWillExpireTask,
  userAuth,
  userNotice,
  getTaskStatusVO,
  queryAllBucket,
  imageHomeUser,
  changeTaskImage
}
