
import { defineComponent, ref, computed, onMounted, reactive, nextTick, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  searchForm, filterArrar, total, tableHeight, selectedIds, onSelectChange,
  selectRowData, spinning, dialogVisible, formRef, form, orderType, checkDetail
} from './ordermanagement'
import { useRouter, useRoute } from 'vue-router'
import { getOrderList, exportOrder, getClaim, getOrderDetail } from '@/api/order'
import { SearchFormParamsFace } from '@/interface/orderInterface'
import fileDownload from 'js-file-download'
import { message } from 'ant-design-vue'
import ConfigColumns from '@/components/config-columns/ConfigColumns.vue'// 筛选表格列数据组件
import StAlert from '@/components/st-alert/StAlert.vue'// 简介栏
import DynamicSearch from '@/components/dynamic-search/DynamicSearch.vue'// 搜索表单组件
export default defineComponent({
  components: {
    ConfigColumns,
    StAlert,
    DynamicSearch
  },
  setup () {
    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    const dataSource = ref([])
    const rules = {
      claimAmount: [
        {
          message: t('costCenter.rules.claimAmount'),
          required: true
        }
      ]
    }
    const titleLabel = computed(() => {
      if (orderType.value === 2) {
        return t('costCenter.volumeId')
      } else {
        return t('costCenter.InstanceId')
      }
    })
    onMounted(() => {
      handleChange(searchForm.productType)
    })
    watchEffect(() => {
      if (route.path === '/cost-center/order-management') {
        checkDetail.value = true
      } else if (route.query.number) {
        checkDetail.value = false
      }
    })
    const handleChange = (data: number) => {
      searchForm.productType = data
      if (orderType.value === 2) {
        var storageColums: any = [
          {
            title: t('costCenter.volumeId'),
            dataIndex: 'taskId',
            key: 'taskId',
            scopedSlots: { customRender: 'taskId' },
            ellipsis: true,
            width: 110
          },
          {
            title: t('costCenter.storageVolumeName'),
            dataIndex: 'taskName',
            key: 'taskName',
            resizable: true,
            width: 150,
            scopedSlots: { customRender: 'taskName' },
            ellipsis: true
          }
        ]
        const resultArr = filterArrar(storageColums, columns.value)
        columns.value = resultArr
      } else {
        var taskColums: any = [
          {
            title: t('costCenter.InstanceId'),
            dataIndex: 'taskId',
            key: 'taskId',
            scopedSlots: { customRender: 'taskId' },
            ellipsis: true
          },
          {
            title: t('costCenter.taskName'),
            dataIndex: 'taskName',
            key: 'taskName',
            scopedSlots: { customRender: 'taskName' },
            ellipsis: true,
            resizable: true,
            width: 150
          }
        ]
        const resultArr = filterArrar(taskColums, columns.value)
        if (orderType.value === 3) {
          columns.value = resultArr.filter((item: any) => {
            return item.key !== 'nodeIp'
          })
        } else {
          columns.value = resultArr
        }
      }
      getList()
    }
    const getList = () => {
      searchForm.productType = orderType.value
      getOrderList(searchForm as SearchFormParamsFace).then((res: any) => {
        dataSource.value = res.data.rows
        total.value = res.data.total
      })
    }
    const handleExportExcel = () => {
      spinning.value = true
      var arr: any = []
      selectRowData.value.forEach((v: any) => {
        arr.push(v.id)
      })
      exportOrder(arr).then((res: any) => {
        fileDownload(res.data, '订单信息' + '.xlsx')
        spinning.value = false
      })
    }
    const handleCompensation = (data: any) => {
      selectRowData.value = []
      selectRowData.value.push(data)
      dialogVisible.value = true
    }
    const handleConfirmCompensation = () => {
      formRef.value.validateFields().then(() => {
        form.ownerId = selectRowData.value[0].ownerId
        form.orderId = selectRowData.value[0].id
        getClaim(form).then((res: any) => {
          if (res !== undefined) {
            message.success(t('costCenter.submitSuccess'))
            dialogVisible.value = false
            selectedIds.value = []
            selectRowData.value = []
            formRef.value.resetFields()
            getList()
          }
        })
      })
    }
    const handleDetail = (data: any) => {
      getOrderDetail(data.id).then((res) => {
        if (res.data) {
          var paramsData = res.data
          paramsData.tagType = orderType.value
          localStorage.setItem('orderDetail', JSON.stringify(paramsData))
          nextTick(() => {
            checkDetail.value = false
            router.push({
              name: 'OrderDetail',
              path: 'order-detail'
            } as any)
          })
        }
      })
    }
    const handleSearch = () => {
      searchForm.pageReq.pageNum = 1
      getList()
    }
    const handlePageSize = (current: number, size: number) => {
      searchForm.pageReq.pageNum = current
      searchForm.pageReq.pageSize = size
      getList()
    }
    function handleResizeColumn (w: any, col: any) {
      col.width = w
    }
    // 筛选表单参数及方法
    const filtered: any = reactive({})
    const columns = ref([
      {
        title: t('costCenter.number'),
        dataIndex: 'number',
        key: 'number',
        scopedSlots: { customRender: 'number' },
        ellipsis: true,
        isShow: true,
        disabled: true
      },
      {
        title: t('costCenter.InstanceId'),
        dataIndex: 'taskId',
        key: 'taskId',
        scopedSlots: { customRender: 'taskId' },
        ellipsis: true,
        resizable: true,
        width: 110,
        isShow: true
      },
      {
        title: t('costCenter.taskName'),
        dataIndex: 'taskName',
        key: 'taskName',
        scopedSlots: { customRender: 'taskName' },
        ellipsis: true,
        resizable: true,
        width: 150,
        isShow: true
      },
      {
        title: t('costCenter.billingType'),
        dataIndex: 'costType',
        key: 'costType',
        scopedSlots: { customRender: 'costType' },
        ellipsis: true,
        width: 100,
        align: 'center',
        isShow: true,
        filteredValue: filtered.costTypeList || null,
        filters: [],
        state: {
          indeterminate: true,
          checkAll: false,
          checkedList: []
        },
        customFilterDropdown: true,
        filterDropdownOpen: false,
        filterMultiple: true,
        searchIndex: 'costTypeList'
      },
      {
        title: t('costCenter.productType'),
        dataIndex: 'productType',
        key: 'productType',
        scopedSlots: { customRender: 'productType' },
        ellipsis: true,
        width: 100,
        resizable: true,
        isShow: true
      },
      {
        title: t('costCenter.orderType'),
        dataIndex: 'orderType',
        key: 'orderType',
        scopedSlots: { customRender: 'orderType' },
        ellipsis: true,
        width: 80,
        resizable: true,
        isShow: true
      },
      {
        title: t('costCenter.orderStatus'),
        dataIndex: 'status',
        key: 'status',
        scopedSlots: { customRender: 'status' },
        ellipsis: true,
        width: 100,
        align: 'center',
        isShow: true,
        filteredValue: filtered.statusList || null,
        filters: [],
        state: {
          indeterminate: true,
          checkAll: false,
          checkedList: []
        },
        customFilterDropdown: true,
        filterDropdownOpen: false,
        filterMultiple: true,
        searchIndex: 'statusList'
      },
      {
        title: t('costCenter.orderPrice'),
        dataIndex: 'orderPrice',
        key: 'orderPrice',
        scopedSlots: { customRender: 'orderPrice' },
        ellipsis: true,
        width: 100,
        isShow: true
      },
      {
        title: t('costCenter.nodeIp'),
        dataIndex: 'nodeIp',
        key: 'nodeIp',
        scopedSlots: { customRender: 'nodeIp' },
        ellipsis: true,
        isShow: true
      },
      {
        title: t('costCenter.createTime'),
        dataIndex: 'createTime',
        key: 'createTime',
        scopedSlots: { customRender: 'createTime' },
        ellipsis: true,
        isShow: true
      },
      {
        title: t('myTask.columns.operation'),
        dataIndex: 'operation',
        key: 'operation',
        ellipsis: true,
        align: 'center',
        width: 110,
        isShow: true,
        disabled: true,
        fixed: 'right'
      }
    ])
    const isAllHidden = ref(false)
    const filterColumns: any = ref([])
    const filtersKeywords: any = ref([])
    const handleChangeAll = (params: any) => {
      columns.value.forEach((item: any) => {
        if (!item.disabled) {
          item.isShow = params
        }
      })
    }
    const handleSetColumns = () => {
      filterColumns.value = []
      const allDispear = columns.value.filter((item: any) => item.isShow)
      if (allDispear.length > 0) {
        isAllHidden.value = false
        columns.value.forEach((item: any) => {
          if (item.isShow) {
            filterColumns.value.push(item)
          }
        })
      } else {
        isAllHidden.value = true
        filterColumns.value = []
      }
    }

    const dynamicForm = ref([
      {
        label: t('costCenter.number'),
        name: 'number',
        type: 'input',
        span: 6,
        isShow: true
      },
      {
        label: orderType.value !== 2 ? t('costCenter.taskName') : t('costCenter.storageVolumeName'),
        name: 'taskName',
        type: 'input',
        span: 6,
        isShow: true
      },
      {
        label: orderType.value !== 2 ? t('myTask.taskId') : t('netDisk.volumnId'),
        name: 'taskId',
        type: 'input',
        span: 6,
        isShow: true
      },
      {
        label: t('costCenter.status'),
        name: 'statusList',
        type: 'select',
        span: 6,
        optionType: 'Enum',
        optionParams: 'CostOrderStatusEnum',
        options: [],
        optionKey: 'value',
        optionLabel: 'name',
        isShow: true,
        mode: 'multiple'
      },
      {
        label: t('costCenter.billingType'),
        name: 'costTypeList',
        type: 'select',
        span: 6,
        optionType: 'Enum',
        optionParams: 'CostTypeEnum',
        options: [],
        optionKey: 'value',
        optionLabel: 'name',
        isShow: orderType.value !== 3,
        mode: 'multiple'
      }
    ])
    const handleFilterOptions = () => {
      dynamicForm.value.map((v: any) => {
        columns.value.map((s: any) => {
          if (v.type === 'select' && v.name === s.searchIndex) {
            s.filters = []
            v.options.map((item: any) => {
              const obj = {
                label: item[v.optionLabel],
                value: item[v.optionKey]
              }
              s.filters.push(obj)
            })
            s.optionLabel = v.optionLabel
            s.optionKey = v.optionKey
          }
        })
      })
    }
    const handleFilterSingleChange = (e: any, column: any) => {
      (searchForm as any)[column.searchIndex] = e.target.value
      searchForm.pageReq.pageNum = 1
      filtersKeywords.value = searchForm
      handleSearch()
    }
    const onCheckAllChangeFilter = (e: any, column: any) => {
      var arr: any[] = []
      column.filters.map((v: any) => {
        arr.push(v.value)
      })
      Object.assign(column.state, {
        checkedList: e.target.checked ? arr : [],
        indeterminate: false
      })
      columns.value.map((v: any) => {
        if (v.searchIndex === column.searchIndex) {
          v.state = column.state
        }
      })
    }
    const handleFilterVisible = (column: any) => {
      columns.value.map((v: any) => {
        if (v.searchIndex === column.searchIndex && column.filterMultiple) {
          v.filterDropdownOpen = !v.filterDropdownOpen
        }
      })
      handleFilterOptions()
    }
    const handleCheckedFilterChange = (e: any) => {
      var checkedLen = e.length
      columns.value.map((item: any) => {
        if (item.state) {
          item.state.indeterminate = !!checkedLen && checkedLen < item.filters.length
          item.state.checkAll = checkedLen === item.filters.length
        }
      })
    }
    const handleFilterMultipleChange = (checkedList: any, column: any) => {
      (searchForm as any)[column.searchIndex] = checkedList
      searchForm.pageReq.pageNum = 1
      filtered[column.searchIndex] = checkedList
      filtersKeywords.value[column.searchIndex] = filtered[column.searchIndex]
      filtersKeywords.value = searchForm // 双向赋值父子组件联动
      handleCancelFilters(column)
    }
    const handleCancelFilters = (column: any) => {
      columns.value.map((v: any) => {
        if (v.searchIndex === column.searchIndex) {
          v.filterDropdownOpen = !v.filterDropdownOpen
        }
      })
      handleSearch()
    }
    const handleSearchFilters = (params: any) => {
      selectedIds.value = []
      selectRowData.value = ''
      if (Object.keys(params).length === 0) {
        handleClearFilterTag(params)
      } else {
        for (var i in searchForm) {
          if (i !== 'pageReq') {
            (searchForm as any)[i] = params[i]
            filtered[i] = params[i]
          }
        }
        filtersKeywords.value = searchForm
        searchForm.pageReq.pageNum = 1
        getList()
      }
    }
    const handleClearFilterTag = (params: any) => {
      selectedIds.value = []
      selectRowData.value = ''
      if (Object.keys(params).length === 0) {
        dynamicForm.value.map((s) => {
          for (var i in searchForm) {
            if (i !== 'pageReq' && s.name === i) {
              if (s.type === 'select' && s.mode === 'multiple') {
                (searchForm as any)[s.name] = []
                filtered[s.name] = []
              } else {
                (searchForm as any)[s.name] = null
                filtered[s.name] = null
              }
            }
            if (i === 'startTime' || i === 'endTime') {
              (searchForm as any)[i] = null
              filtered[i] = null
            }
          }
        })
      } else {
        // 清除主列表筛选表头多选表单勾选值
        columns.value.map((v: any) => {
          if (v.searchIndex === params.key && v.state) {
            if (v.state.checkedList.length > 0) {
              v.state.checkedList = []
              v.state.indeterminate = false
            }
          }
        })
        dynamicForm.value.map((s: any) => {
          for (const i in searchForm) {
            if (params.type === 'dateRange' && (i === 'startTime' || i === 'endTime')) {
              (searchForm as any)[i] = null
              filtered[i] = null
            } else if (i !== 'pageReq' && params.key === i && s.name === params.key) {
              if (s.type === 'select' && s.mode === 'multiple') {
                (searchForm as any)[s.name] = []
                filtered[s.name] = []
              } else {
                (searchForm as any)[s.name] = null
                filtered[s.name] = null
              }
            }
          }
        })
      }
      filtersKeywords.value = searchForm
      searchForm.pageReq.pageNum = 1
      getList()
    }
    return {
      formRef,
      form,
      rules,
      columns,
      titleLabel,
      handleResizeColumn,
      handlePageSize,
      handleSearch,
      tableHeight,
      dataSource,
      orderType,
      onSelectChange,
      selectedIds,
      selectRowData,
      handleChange,
      handleExportExcel,
      handleDetail,
      dialogVisible,
      handleCompensation,
      handleConfirmCompensation,
      total,
      searchForm,
      handleChangeAll,
      handleSetColumns,
      filterColumns,
      isAllHidden,
      dynamicForm,
      filtered,
      handleFilterSingleChange,
      onCheckAllChangeFilter,
      handleCancelFilters,
      handleFilterVisible,
      handleFilterMultipleChange,
      handleClearFilterTag,
      handleCheckedFilterChange,
      handleSearchFilters,
      filtersKeywords,
      spinning,
      checkDetail
    }
  }
})
