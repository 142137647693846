
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import bgImage from '@/assets/img/bg_green.png'
import sgImage from '@/assets/img/bg_blue.png'
import TaskTableList from './components/TaskTableList.vue'
import { userAuth, userNotice, getTaskStatusVO, queryAllBucket, imageHomeUser } from '@/api/myTask'
import { getRegionName, balanceCoupon } from '@/api/market'
import { permissionStore } from '@/store/permission'
import touxiang from '@/assets/img/ic_touxiang.png'
export default defineComponent({
  components: {
    TaskTableList
  },
  setup () {
    const router = useRouter()
    const permission = permissionStore()
    const activeKey = ref(1)
    const useLevel = ref(permission.$state.accountInfo.gradeName)
    const textStyle = {
      'font-size': '18px',
      fill: '#fff',
      'font-weight': 'bold'
    }
    const textPositions = {
      xPosition: '20',
      yPosition: '21'
    }
    const weChatFlag = ref(false)
    const noticeMsgList = ref([])
    const formState = reactive({
      regionId: ''
    })
    const taskList = reactive({
      totalTaskCount: 0,
      runningTaskCount: 0,
      closedTaskCount: 0,
      releaseTaskCount: 0,
      expireTaskCount: 0
    })
    const priceList = reactive({
      balance: 0,
      couponCount: 0
    })
    const storageMsgList = reactive({
      bucketCount: 0,
      dataCount: 0,
      dataReleaseCount: 0,
      sumSize: 0,
      useSize: 0
    })
    const storagePercent = ref(0)
    const imageMsgList = reactive({
      systemCount: 0,
      userCount: 0,
      shareCount: 0,
      othersShareCount: 0
    })
    const nodePlaceOptions: any = ref([])
    const currentComponent = computed<string>(() => {
      return 'TaskTableList'
    })
    // 用户头像
    const userProfilePicture = computed<string>(() => {
      return permission.$state.accountInfo.portrait ? `/api/user${permission.$state.accountInfo.portrait}` : touxiang
    })
    onMounted(() => {
      getUserAuth()
      getNoticeMsg()
      getNodePlace()
      getPriceMsg()
      getStorageMsg()
      getImageMsg()
    })
    const handleTabs = async (item: any): Promise<void> => {
      formState.regionId = item.id
      getTaskList()
    }
    const getUserAuth = () => {
      userAuth().then((res:any) => {
        weChatFlag.value = res.data.openFlag
      })
    }
    const getTaskList = () => {
      getTaskStatusVO(formState.regionId).then((res:any) => {
        Object.assign(taskList, res.data)
      })
    }
    const getPriceMsg = () => {
      balanceCoupon({
        productType: 1
      }).then((res) => {
        if (res.data) {
          Object.assign(priceList, res.data)
          if (res.data.couponListVOList) {
            priceList.couponCount = res.data.couponListVOList.length
          }
        }
      })
    }
    const getNodePlace = () => {
      getRegionName().then((res:any) => {
        handleTabs(res.data[0])
        const newListArr = Array.from({ length: Math.ceil(res.data.length / 6) }, (_, index) =>
          res.data.slice(index * 6, index * 6 + 6)
        )
        nodePlaceOptions.value = newListArr
        getTaskList()
      })
    }
    const getNoticeMsg = () => {
      userNotice().then((res:any) => {
        noticeMsgList.value = res.data
      })
    }
    function getStorageMsg () {
      queryAllBucket().then((res:any) => {
        if (res.data) {
          Object.assign(storageMsgList, res.data)
          storagePercent.value = Math.ceil((storageMsgList.useSize / storageMsgList.sumSize) * 100)
        }
      })
    }
    const getImageMsg = () => {
      imageHomeUser().then((res:any) => {
        if (res.data) {
          Object.assign(imageMsgList, res.data)
        }
      })
    }
    const jumpToTask = (data: number) => {
      if (data === 1) {
        router.push({
          path: '/ai-center/my-task'
        })
      } else if (data === 2) {
        router.push({
          name: 'MyTask',
          state: {
            name: 'statusS',
            value: 4,
            option: JSON.stringify([
              { key: '运行中', value: 4, disabled: false }
            ])
          }
        } as any)
      } else if (data === 3) {
        router.push({
          name: 'MyTask',
          state: {
            name: 'statusS',
            value: 6,
            option: JSON.stringify([
              { key: '已关机', value: 6, disabled: false }
            ])
          }
        } as any)
      }
    }
    const handleJump = (data: any) => {
      router.push({
        path: data
      })
    }
    function handleCopy (data: any) {
      var save = function (e: any) {
        e.clipboardData.setData('text/plain', data)
        e.preventDefault() // 阻止默认行为
      }
      document.addEventListener('copy', save)
      document.execCommand('copy')
      document.removeEventListener('copy', save)
      message.success('复制成功')
    }
    const handleClose = () => {
      weChatFlag.value = true
    }
    return {
      nodePlaceOptions,
      formState,
      handleTabs,
      bgImage,
      sgImage,
      activeKey,
      currentComponent,
      useLevel,
      textStyle,
      textPositions,
      weChatFlag,
      noticeMsgList,
      taskList,
      jumpToTask,
      permission,
      priceList,
      handleJump,
      handleCopy,
      storageMsgList,
      storagePercent,
      handleClose,
      imageMsgList,
      userProfilePicture
    }
  }
})
