
import { defineComponent } from 'vue'
import { handleFeeChange, changeFee, changeFeeParams, changeFeeForm, changeTaskBtnState, computedOrderPrice, computedTotalPrice, computedDiscountPirce, handleConfirmChangeFee } from '../js/tableFeeChange'// 计费变更
export default defineComponent({
  setup () {
    return {
      handleFeeChange, changeFee, changeFeeParams, changeFeeForm, changeTaskBtnState, computedOrderPrice, computedTotalPrice, computedDiscountPirce, handleConfirmChangeFee
    }
  }
})
