export const toFixedNum = (num:any, fix:any) => {
  if (num.toString().includes('.')) {
    const numSuf = num.toString().split('.')[1]
    if (numSuf.length > 2) {
      const arr = []
      for (let i = 0; i < fix; i++) {
        arr.push(0)
      }
      const fixNum = parseInt('1' + arr.join(''))

      return Math.ceil(num * fixNum) / fixNum
    } else {
      return num
    }
  } else {
    return num
  }
}
