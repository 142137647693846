import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "system-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_condition_search = _resolveComponent("condition-search")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_config_columns = _resolveComponent("config-columns")!
  const _component_st_pagination = _resolveComponent("st-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_condition_search, {
      "current-condition": _ctx.currentCondition,
      "filter-options": _ctx.filterOptions,
      "condition-list": _ctx.conditionList,
      onChangeCondition: _ctx.handleChangeCondition,
      onChange: _ctx.handleSelectChange,
      onQuery: _ctx.handleQuery,
      onDel: _ctx.handleDelOption,
      onClear: _ctx.handleClear
    }, null, 8, ["current-condition", "filter-options", "condition-list", "onChangeCondition", "onChange", "onQuery", "onDel", "onClear"]),
    _createElementVNode("div", {
      class: _normalizeClass(["table-box", _ctx.filterOptions.length > 0 ? 'table-box-filter' : ''])
    }, [
      _createVNode(_component_a_table, {
        size: "small",
        bordered: "",
        pagination: false,
        "data-source": _ctx.dataSource,
        columns: (_ctx.filterColumns.length === 0 && !_ctx.isAllHidden) ? _ctx.columns : _ctx.filterColumns,
        scroll: { x: 1500, y: _ctx.tableHeight - 39 },
        "row-key": "id"
      }, {
        bodyCell: _withCtx(({ column, record, index }) => [
          (column.dataIndex === 'id')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString((_ctx.pageParams.pageNum - 1) * _ctx.pageParams.pageSize + index + 1), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'status')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (record.status == 0)
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 0,
                      color: "processing"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('image.creating')), 1)
                      ]),
                      _: 1
                    }))
                  : (record.status == 1)
                    ? (_openBlock(), _createBlock(_component_a_tag, {
                        key: 1,
                        color: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('image.success')), 1)
                        ]),
                        _: 1
                      }))
                    : (record.status == 2)
                      ? (_openBlock(), _createBlock(_component_a_tag, {
                          key: 2,
                          color: "error"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('image.error')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'lockStatus')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (record.lockStatus == 0)
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 0,
                      color: "processing"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('image.unlock')), 1)
                      ]),
                      _: 1
                    }))
                  : (record.lockStatus == 1)
                    ? (_openBlock(), _createBlock(_component_a_tag, {
                        key: 1,
                        color: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('image.lock')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'structure')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                (record.structure == 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, "x84_64"))
                  : (record.structure == 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, "arm"))
                    : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["data-source", "columns", "scroll"]),
      _createVNode(_component_config_columns, {
        columns: _ctx.columns,
        onChangeAll: _ctx.handleChangeAll,
        onConfirm: _ctx.handleSetColumns
      }, null, 8, ["columns", "onChangeAll", "onConfirm"])
    ], 2),
    _createVNode(_component_st_pagination, {
      total: _ctx.total,
      onChange: _ctx.handleChangePage
    }, null, 8, ["total", "onChange"])
  ]))
}