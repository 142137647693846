import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-98d506c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-tabs" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "total-used" }
const _hoisted_7 = { class: "used" }
const _hoisted_8 = { class: "number" }
const _hoisted_9 = { class: "total" }
const _hoisted_10 = { class: "number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardList, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item.bucketType
      }, [
        (item.isShow)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["card-item", _ctx.bucketType === item.bucketType ? 'active' : '']),
              onClick: () => _ctx.handleTabs(item.bucketType)
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: "echarts-box",
                  id: item.echartsName
                }, null, 8, _hoisted_5),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("ul", _hoisted_7, [
                    _createElementVNode("li", {
                      class: _normalizeClass(item.markClass)
                    }, _toDisplayString(_ctx.$t('netDisk.used')) + "：", 3),
                    _createElementVNode("li", _hoisted_8, _toDisplayString(item.usedGpu) + "GB", 1)
                  ]),
                  _createElementVNode("ul", _hoisted_9, [
                    _createElementVNode("li", null, _toDisplayString(_ctx.$t('netDisk.totalSpace')) + "：", 1),
                    _createElementVNode("li", _hoisted_10, _toDisplayString(item.totalGpu) + "GB", 1)
                  ])
                ])
              ])
            ], 10, _hoisted_2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}