
import { defineComponent, watch, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { getToken } from '@/utils/token'
import { useRouter } from 'vue-router'
// 初始化参数定义类型公共文件及公共方法
import { costFlag, balance } from '../../ai-center/my-task/myTaskModule'
import { queryWillReleaseTask, queryWillExpireTask, getIsCost, balanceCoupon } from '@/api/myTask'
// 关机、无卡模式、重置系统、状态变更、实例重启、实例释放基本方法
import {
  stopLoading, handleStop, stopDialogVisible, stopTips, stopContainer, handleNoCardModel, handleResetContainer,
  handleChangeStatus, handleTaskReset, handleTaskRelease, handleStart
} from '../../ai-center/my-task/js/tableOperation'
// 更换镜像
import { handleChangeImage } from '../../ai-center/my-task/js/tableChangeImage'
// 关机界面
import tableStopContaner from '../../ai-center/my-task/component/tableStopContaner.vue'
// 计费变更、实例升配、实例续租界面及方法
import { handleFeeChange, handleTaskEscalate, handleTaskRelet } from '../../ai-center/my-task/js/tableFeeChange'
import tableChangeFee from '../../ai-center/my-task/component/tableChangeFee.vue'// 计费变更
import tableTaskEscalate from '../../ai-center/my-task/component/tableTaskEscalate.vue'// 实例升配
import tableTaskRelet from '../../ai-center/my-task/component/tableTaskRelet.vue'// 实例续租
// 镜像打包界面及方法
import { handlePackageImage, packageVisible } from '../../ai-center/my-task/js/tablePackageImage'
import tablePackageImage from '../../ai-center/my-task/component/tablePackageImage.vue'
// 网盘扩容界面及方法
import tableExpansionModal from '../../ai-center/my-task/component/tableExpansionModal.vue'
import {
  handleExpansion, expansionVisible, bucketId, expansionPrice, onBtnState,
  handleConfirmExpansion, title
} from '../../ai-center/my-task/js/tableExpansion'
// 操作记录
import tableOperateLog from '../../ai-center/my-task/component/tableOperateLog.vue'
import { handleOperateLog } from '../../ai-center/my-task/js/tableOperateLog'
// 带宽扩容
import tableExpandNet from '../../ai-center/my-task/component/tableExpandNet.vue'
import { handleExpandNet } from '../../ai-center/my-task/js/tableExpandNet'

import STag from '@/components/s-tag/STag.vue'// 状态标签
export default defineComponent({
  props: {
    type: {
      type: Number,
      required: true
    }
  },
  components: {
    STag,
    tableStopContaner,
    tablePackageImage,
    tableChangeFee,
    tableExpansionModal,
    tableOperateLog,
    tableTaskEscalate,
    tableTaskRelet,
    tableExpandNet
  },
  setup (props) {
    const router = useRouter()
    const { t } = useI18n()
    const columns = ref([
      {
        title: t('myTask.columns.taskId'),
        dataIndex: 'taskName',
        key: 'taskName',
        ellipsis: true,
        resizable: true,
        width: 100,
        align: 'center'
      },
      {
        title: t('myTask.columns.status'),
        dataIndex: 'status',
        key: 'status',
        scopedSlots: { customRender: 'status' },
        align: 'center',
        width: 150
      },
      {
        title: t('myTask.columns.hostStatus'),
        dataIndex: 'hostStatus',
        key: 'hostStatus',
        scopedSlots: { customRender: 'hostStatus' },
        ellipsis: true,
        align: 'center',
        width: 100
      },
      {
        title: t('myTask.columns.gpuModel'),
        dataIndex: 'gpuModel',
        key: 'gpuModel',
        scopedSlots: { customRender: 'gpuModel' },
        resizable: true,
        ellipsis: true,
        width: 150
        // align: "center",
      },
      {
        title: t('myTask.columns.costType'),
        dataIndex: 'costType',
        key: 'costType',
        resizable: true,
        ellipsis: true,
        align: 'center',
        width: 110
      },
      {
        title: t('myTask.columns.releaseTime'),
        dataIndex: 'releaseTime',
        key: 'releaseTime',
        resizable: true,
        ellipsis: true,
        align: 'center',
        width: 120,
        isShow: true,
        // 指定排序规则，日期空值处理为特定值
        sorter: (a: any, b: any) => {
          if (a.releaseTime === null) {
            return -1 // 假设 NULL 日期应该排在最前面
          } else if (b.releaseTime === null) {
            return 1 // 假设 NULL 日期应该排在最后面
          } else {
            const aTime = new Date(a.releaseTime).getTime()
            const bTime = new Date(b.releaseTime).getTime()
            return aTime - bTime // 正常日期排序
          }
        }
      },
      {
        title: t('myTask.columns.operation'),
        dataIndex: 'operation',
        key: 'operation',
        ellipsis: true,
        align: 'center',
        width: 110,
        isShow: true,
        disabled: true,
        fixed: 'right'
      }
    ])
    const dataSource = ref([])
    const allData = ref([])
    const total = ref(0)
    const currentPage = ref(1)
    const rules = {
      imageName: [{ required: true, trigger: 'change', validator: checkName }],
      description: [{ required: false, trigger: 'change', validator: checkDescription }],
      image: [{ required: true, trigger: 'change', message: '请选择' }]
    }
    function checkName (rules: any, value: any) {
      if (value === '' || value == null || value === undefined) {
        return Promise.reject(t('myTask.rules.imageName'))
      } else {
        const reg = /^[a-z0-9][a-z0-9]{3,9}$/
        if (!reg.test(value)) {
          return Promise.reject(t('myTask.rules.imageNameRule'))
        } else {
          return Promise.resolve()
        }
      }
    }
    function checkDescription (rules: any, value: any) {
      if (value === '' || value == null || value === undefined) {
        return Promise.resolve()
      } else {
        const reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{2,50}$/
        if (!reg.test(value)) {
          return Promise.reject(t('myTask.rules.description'))
        } else {
          return Promise.resolve()
        }
      }
    }
    watch(() => props.type, (newVal) => {
      if (newVal === 1) {
        getReleaseTask()
      } else {
        getExpireTask()
      }
    })
    onMounted(() => {
      if (props.type === 1) {
        getReleaseTask()
      } else {
        getExpireTask()
      }
      handleChargingMoney()
      handleBalance()
    })
    const getReleaseTask = () => {
      queryWillReleaseTask().then(res => {
        allData.value = res.data
        computeTableList()
      })
    }
    const getExpireTask = () => {
      queryWillExpireTask().then(res => {
        allData.value = res.data
        computeTableList()
      })
    }
    const computeTableList = () => {
      dataSource.value = allData.value.slice((currentPage.value - 1) * 5, currentPage.value * 5)
      total.value = Math.ceil(allData.value.length / 5)
    }
    const handleChargingMoney = () => {
      // 当有token才可调用判断是否停用计费服务接口
      if (getToken()) {
        getIsCost().then((res) => {
          costFlag.value = res.data
        })
      }
    }
    // 调取业务查询菜单接口（当前用户余额）
    const handleBalance = () => {
      balanceCoupon({
        productType: 1
      }).then((res) => {
        balance.value = res.data.balance
      })
    }
    // 跳转指定网址
    const handleJump = (data: string) => {
      window.open(data, '_blank')
    }
    const handleMenuClick = ({ item, key }: any) => {
      if (key === 1) {
        handleNoCardModel(item.item)
      } else if (key === 2) {
        handleFeeChange(item.item)
      } else if (key === 3) {
        handleResetContainer(item.item)
      } else if (key === 4) {
        handleChangeImage(item.item)
      } else if (key === 5) {
        handlePackageImage(item.item)
      } else if (key === 6) {
        handleExpansion(item.item)
      } else if (key === 8) {
        handleOperateLog(item.item)
      } else if (key === 9) {
        router.push({
          path: '/ai-center/my-task/work-monitoring',
          query: {
            id: item.item.id
          }
        })
      } else if (key === 10) {
        handleChangeStatus(item.item)
      } else if (key === 11) {
        handleTaskEscalate(item.item)
      } else if (key === 12) {
        handleTaskRelet(item.item)
      } else if (key === 13) {
        handleExpandNet(item.item.id)
      } else if (key === 14) {
        handleTaskReset(item.item)
      } else if (key === 15) {
        handleTaskRelease(item.item)
      }
    }
    function handleResizeColumn (w: any, col: any) {
      col.width = w
    }
    const handleFrontPage = () => {
      currentPage.value = currentPage.value - 1
      computeTableList()
    }
    const handleAfterPage = () => {
      currentPage.value = currentPage.value + 1
      computeTableList()
    }
    return {
      total,
      dataSource,
      handleResizeColumn,
      columns,
      rules,
      stopLoading,
      handleStop,
      stopDialogVisible,
      stopTips,
      stopContainer,
      handleNoCardModel,
      handleResetContainer,
      handleChangeStatus,
      handleTaskReset,
      handleTaskRelease,
      expansionVisible,
      bucketId,
      expansionPrice,
      onBtnState,
      handleConfirmExpansion,
      title,
      handleStart,
      handleJump,
      handleMenuClick,
      packageVisible,
      balance,
      handleFrontPage,
      handleAfterPage,
      currentPage
    }
  }
})
