
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import BigNumber from 'bignumber.js'
import { toFixedNum } from '@/utils/toFixedNum'
import {
  areaList,
  selectedArea,
  tableData,
  runningMode,
  cpuNumber,
  currentIndex,
  formInfoList,
  handleSubmit,
  handleTabs,
  cascaderRef,
  imageOptions,
  handleChangeImgMode,
  myImgList,
  handleChange,
  specificationInfo,
  handleAmountChange,
  handelImageChange,
  dataSetOptions,
  dataSetLoadData,
  handleChangeRunningMode,
  resourceSpecList,
  gpuManageSerialNoList,
  handleChangeGpuNum,
  handleChangeSplitAmount,
  resourceSpecListTableData,
  singleGpuSegAmount,
  isOpen,
  queryAreaList,
  queryDataSetVersion,
  querySpecification
} from './addTaskModule'
export default defineComponent({
  setup () {
    const { t } = useI18n()
    const columns = ref([
      {
        title: t('addTask.name'),
        dataIndex: 'specificationName'
      },
      {
        title: t('addTask.node'),
        dataIndex: 'ip'
      },
      {
        title: t('addTask.availableQuantity'),
        dataIndex: 'availableAmount'
      },
      {
        title: t('addTask.price'),
        dataIndex: 'discountHour',
        customRender: ({ record }:any) => {
          return `${toFixedNum(BigNumber(record.discountHour).multipliedBy(BigNumber(record.priceHour)).toNumber(), 2)}元/小时`
        }
      }
    ])
    watch(selectedArea, () => {
      querySpecification()
    })
    onMounted(() => {
      queryAreaList()
      queryDataSetVersion()
    })
    return {
      areaList,
      selectedArea,
      columns,
      tableData,
      runningMode,
      cpuNumber,
      currentIndex,
      formInfoList,
      handleSubmit,
      handleTabs,
      cascaderRef,
      imageOptions,
      handleChangeImgMode,
      myImgList,
      handleChange,
      specificationInfo,
      toFixedNum,
      handleAmountChange,
      handelImageChange,
      dataSetOptions,
      dataSetLoadData,
      handleChangeRunningMode,
      resourceSpecList,
      gpuManageSerialNoList,
      handleChangeGpuNum,
      handleChangeSplitAmount,
      resourceSpecListTableData,
      singleGpuSegAmount,
      isOpen,
      queryAreaList,
      queryDataSetVersion

    }
  }
})
