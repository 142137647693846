
import { defineComponent } from 'vue'
export default defineComponent({
  emits: ['change'],
  props: {
    total: {
      type: Number,
      default: 0
    }
  },
  setup (_, { emit }) {
    const handlePaginationChange = (pageNum: number, pageSize: number) => {
      emit('change', {
        pageNum,
        pageSize
      })
    }
    return {
      handlePaginationChange
    }
  }
})
