
import { defineComponent, ref, onMounted, watch } from 'vue'
import ConditionSearch from '@/components/condition/Condition'
import StPagination from '@/components/st-pagination/StPagination.vue'
import ConfigColumns from '@/components/config-columns/ConfigColumns.vue'
import { EllipsisOutlined } from '@ant-design/icons-vue'
import { shareModule } from './share'
import { delModule } from './del'
import { queryModule } from './query'

export default defineComponent({
  components: {
    ConditionSearch,
    StPagination,
    ConfigColumns,
    EllipsisOutlined
  },
  setup () {
    const {
      currentCondition,
      filterOptions,
      conditionList,
      handleChangeCondition,
      handleSelectChange,
      handleDelOption,
      handleClear,
      handleQuery,
      total,
      dataSource,
      columns,
      scrollHeight,
      handleChangePage,
      filterColumns,
      isAllHidden,
      handleChangeAll,
      handleSetColumns,
      filterList,
      handleChangeStatus,
      handleconfirm,
      queryMyImage
    } = queryModule()

    const {
      visible,
      shareFormRef,
      shareColumns,
      users,
      handleShare,
      handlePushUsers,
      handleDelUser,
      checkUserId,
      handleConfirmShare,
      imageInfo,
      title
    } = shareModule(queryMyImage)

    const {
      handleDel
    } = delModule()

    const handleChoostTools = (params: { key: string }) => {
      const [data, type] = params.key.split(';')
      if (type === 'subShare') {
        handleShare(JSON.parse(data), 1)
      } else if (type === 'del') {
        handleDel(JSON.parse(data), queryMyImage)
      }
    }

    const setBtnDisabled = ref(false)

    watch(users, () => {
      const list = users.value.filter(item => item.userId === '')
      if (list.length > 0) {
        setBtnDisabled.value = true
      } else {
        setBtnDisabled.value = false
      }
    }, { deep: true })

    onMounted(() => {
      queryMyImage()
    })

    return {
      currentCondition,
      filterOptions,
      conditionList,
      handleChangeCondition,
      total,
      dataSource,
      handleChangePage,
      handleDelOption,
      handleClear,
      handleSelectChange,
      handleQuery,
      columns,
      scrollHeight,
      filterColumns,
      isAllHidden,
      handleChangeAll,
      handleSetColumns,
      filterList,
      handleChangeStatus,
      handleconfirm,
      visible,
      shareColumns,
      users,
      checkUserId,
      handleShare,
      setBtnDisabled,
      handlePushUsers,
      handleDelUser,
      shareFormRef,
      handleConfirmShare,
      imageInfo,
      handleChoostTools,
      title
    }
  }
})
