import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    open: _ctx.operateLogDialogVisible,
    "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.operateLogDialogVisible) = $event)),
    title: _ctx.$t('myTask.operateLog'),
    centered: "",
    width: "746px",
    footer: null,
    maskClosable: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        dataSource: _ctx.operateDataSource,
        columns: _ctx.operateColumns,
        bordered: "",
        pagination: false,
        size: "small",
        scroll: { y: 350 }
      }, null, 8, ["dataSource", "columns"])
    ]),
    _: 1
  }, 8, ["open", "title"]))
}