import Bus from '@/components/global-uploader/utils/bus'
import { getToken } from '@/utils/token'
import { useRoute } from 'vue-router'
import { onMounted } from 'vue'
import { message } from 'ant-design-vue'
export const uploadFileModule = (path: any, bucketDir: any, queryNetDisk: () => void) => {
  const route = useRoute()
  const handleUpload = () => {
    Bus.emit('openUploader', {
      params: {
        type: route.query.type,
        bucketId: route.query.id,
        queryFullPathObj: bucketDir.value,
        fullPath: path.value ? path.value : ''
      },
      options: {
        target: route.query.type === '1' ? '/api/storage/objOperation/uploadSlicingFile' : '/api/storage/nfsOperation/uploadSlicingFile',
        headers: { 'Front-Token': getToken() },
        maxChunkRetries: 10,
        singleFile: route.query.type === '1'
      }
    })
  }
  onMounted(() => {
    Bus.on('fileSuccess', () => {
      // console.log("文件上传成功");
      message.success('文件上传成功')
      queryNetDisk()
    })
  })
  return {
    handleUpload
  }
}
