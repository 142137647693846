
import { defineComponent } from 'vue'
import ConditionSearch from '@/components/condition/Condition'
import { useDomainName } from './domain-name'
import StPagination from '@/components/st-pagination/StPagination.vue'
import { EllipsisOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    ConditionSearch,
    StPagination,
    EllipsisOutlined
  },
  setup () {
    const {
      currentCondition,
      conditionList,
      filterOptions,
      total,
      dataSource,
      columns,
      handleQuery,
      handleSelectChange,
      handleDelOption,
      handleClear,
      handleChangePage,
      visible,
      title,
      form,
      handleSubmit,
      addForm,
      checkReamlName,
      handleAdd,
      handleModify,
      handleChoostTools
    } = useDomainName()

    return {
      currentCondition,
      conditionList,
      filterOptions,
      total,
      dataSource,
      columns,
      handleQuery,
      handleSelectChange,
      handleDelOption,
      handleClear,
      handleChangePage,
      visible,
      title,
      form,
      handleSubmit,
      addForm,
      checkReamlName,
      handleAdd,
      handleModify,
      handleChoostTools
    }
  }
})
