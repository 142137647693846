
import { defineComponent, ref, watch, onMounted } from 'vue'
import { weChatPay } from '@/api/recharge'
import { balanceCoupon } from '@/api/market'
import QRCode from 'qrcodejs2'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  setup () {
    const { t } = useI18n()
    const router = useRouter()
    const currentIndex: any = ref(0)
    const payPrice = ref()
    const currentTime:any = ref()
    const seconds:any = ref(601)
    const mainSpinning = ref(false)
    const payIndex = ref(0)
    const currentBalace = ref()
    const chargeMoney = ref()
    const qrcodes = ref(null)
    const timer = ref()
    const payOutTradeNo = ref()
    const payStatus = ref()
    const socket = ref()
    const ip = window.location.host
    const protocol = window.location.protocol
    const showOtherVisible = ref(false) // 用户自定义输入充值金额
    const showChargeVisible = ref(false) // 进入充值提示界面
    const showPaySuccess = ref(false) // 进入充值成功提示界面
    const showPayFail = ref(false) // 进入充值失败提示界面

    watch(
      () => currentTime.value,
      (val) => {
        if (val === '0') {
          var msg = ''
          msg = t('costCenter.rechargeOverTimeTips')
          message.warning(msg, 2.5).then(() => {
            reset()
          })
        }
      }
    )
    onMounted(() => {
      getCurrentAccount()
    })
    // 用户选择充值余额
    const handleChoose = (data:number) => {
      currentIndex.value = data
      if (currentIndex.value === 4) {
        showOtherVisible.value = true
      } else {
        showOtherVisible.value = false
      }
    }
    // 支付方式：微信/支付宝
    const handlePay = (data:number) => {
      payIndex.value = data
    }
    // 充值
    const handleCharge = async () => {
      await getWechatCode()
    }
    // 处理输入框小数点两位问题
    function clearNoNumTwo (obj:any) {
      const reg = /^\d+(\.\d{1,2})?$/
      return reg.test(obj)
    }

    const getWechatCode = async () => {
      if (currentIndex.value === 0) {
        payPrice.value = 50
      } else if (currentIndex.value === 1) {
        payPrice.value = 100
      } else if (currentIndex.value === 2) {
        payPrice.value = 500
      } else if (currentIndex.value === 3) {
        payPrice.value = 1000
      } else if (currentIndex.value === 4) {
        if (
          chargeMoney.value !== undefined &&
          chargeMoney.value !== null &&
          chargeMoney.value !== ''
        ) {
          if (chargeMoney.value > '50000') {
            message.warning(t('costCenter.rechargeTipsOne'))
            return
          }
          if (!clearNoNumTwo(chargeMoney.value)) {
            message.warning(t('costCenter.rechargeTipsTwo'))
            return
          }
          payPrice.value = chargeMoney.value
        } else {
          message.warning(t('costCenter.rechargeTipsThree'))
          return
        }
      }
      // 获取微信二维码
      await weChatPay(payPrice.value).then((res:any) => {
        if (res) {
          payOutTradeNo.value = res.data.outTradeNo
          showChargeVisible.value = true
          getAsyncData(res.data.codeUrl)
          mainSpinning.value = true
        }
      })
    }
    const getAsyncData = async (codeUrl:string) => {
      Time() // 开始倒计时
      setTimeout(() => {
        (document.getElementById('qrcode') as any).innerHTML = ''
        creatQrCode(codeUrl) // 渲染二维码
        getPayState() // socket连接，获取当前用户支付状态
        mainSpinning.value = false
      }, 1000)
    }
    const creatQrCode = async (code:any) => {
      qrcodes.value = new QRCode('qrcode', {
        width: 200, // 二维码宽度
        height: 200, // 二维码高度
        text: code, // 二维码内容，这里就是后端返回的字符串
        render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      })
    }
    // socket连接，获取当前用户支付状态
    const getPayState = async () => {
      await connectWebSocket(payOutTradeNo.value)
    }
    // 获取当前支付状态
    const getCurrentState = () => {
      if (payStatus.value === '1') {
        // 充值状态：若为1 成功
        close()
        showPaySuccess.value = true
      } else if (payStatus.value === '7') {
        // 7：充值失败
        close()
        showPayFail.value = true
        showPaySuccess.value = false
      } else if (payStatus.value === '3') {
        // 未支付
        close()
        var msg = t('costCenter.rechargeUnpaidTips')
        message.warning(msg, 2.5).then(() => {
          reset()
        })
      } else if (payStatus.value === '4') {
        // 交易关闭-跳转充值记录界面
        close()
        router.push({ path: '/cost-center/recharge-record' })
      }
    }
    // socket连接
    const connectWebSocket = async (TradeNo:string) => {
      let wsPrefix = ''
      if (protocol === 'http:') {
        wsPrefix = 'ws'
      } else {
        wsPrefix = 'wss'
      }
      const ws = `${wsPrefix}://${ip}/ws/cost/pay/notify/${TradeNo}`
      // const ws = `ws://192.168.207.37:8888/pay/notify/${TradeNo}`;
      await init(ws)
    }
    async function init (url:string) {
      //   实例化socket
      socket.value = new WebSocket(url)
      // 监听socket连接
      socket.value.onopen = open
      // 监听socket错误信息
      // socket.value.onerror = error
      // 监听socket消息
      socket.value.onmessage = getMessage
      socket.value.onsend = send
      // socket.value.onpaste = paste
    }
    function close ():void {
      socket.value.close()
    }
    function getMessage (msg:any) {
      var data = JSON.parse(msg.data)
      payStatus.value = data
      if (data === '1') {
        // 充值状态：若为1 成功
        close()
        getCurrentAccount()
        showPaySuccess.value = true
      } else if (data === '7') {
        // 7：充值失败
        close()
        showPayFail.value = true
        showPaySuccess.value = false
      } else if (data === '4') {
        // 交易关闭-跳转充值记录界面
        close()
        router.push({ path: '/cost-center/recharge-record' })
      }
    }
    function send (order:any) {
      socket.value.send(order)
    }
    function countDown () {
      // let d: any = parseInt(seconds.value / (24 * 60 * 60) as any) as number
      // d = d < 10 ? '0' + d : d
      // let h:any = parseInt((seconds.value / (60 * 60)) % 24 as any)
      // h = h < 10 ? '0' + h : h
      let m:any = parseInt((seconds.value / 60) % 60 as any)
      m = m < 10 ? '0' + m : m
      let s:any = parseInt(seconds.value % 60 as any)
      s = s < 10 ? '0' + s : s
      currentTime.value = m + '分' + s + '秒'
    }
    // 定时器每过1秒参数减1
    function Time () {
      timer.value = setInterval(() => {
        if (seconds.value >= 1) {
          seconds.value -= 1
          countDown()
        }
      }, 1000)
    }
    const handleDetail = () => {
      // router.push({ path: "/control/cost_center/order_management" });
    }
    // 获取当前余额
    const getCurrentAccount = () => {
      var data = {
        productType: 1
      }
      balanceCoupon(data).then((res:any) => {
        currentBalace.value = res.data.balance
      })
    }
    const reset = () => {
      showPayFail.value = false
      showPaySuccess.value = false
      showChargeVisible.value = false
      // 重置倒计时十分钟
      clearInterval(timer.value)
      seconds.value = 601
      // 关闭socket链接
      close();
      // 清空qrcode
      (document.getElementById('qrcode') as any).innerHTML = ''
    }
    return {
      handleChoose,
      currentIndex,
      showOtherVisible,
      payIndex,
      handlePay,
      handleCharge,
      showChargeVisible,
      showPaySuccess,
      showPayFail,
      handleDetail,
      reset,
      chargeMoney,
      payPrice,
      currentTime,
      getCurrentState,
      mainSpinning,
      currentBalace,
      clearNoNumTwo
    }
  }
})
