import { queryMyImageApi } from '@/api/image-center'
import { DynamicConditionSpace } from '@/interface/condition'
import { queryMyImageFace } from '@/interface/imageCenter'
import { TableColumnTypeConfig } from '@/interface/table'
import { useCondition } from '@/utils/condition-operate'
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const queryModule = () => {
  const { t } = useI18n()
  const {
    initConditionList,
    inputOrselectChange,
    initColumnsFiltered,
    setColumnsFiltered,
    setFilerOptions,
    clearFilterOptionsList
  } = useCondition()
  const currentCondition = ref<string>('imageName')

  const filterOptions = ref<DynamicConditionSpace.FilterConditionFace[]>([])

  const conditionList = ref<DynamicConditionSpace.ConditionFace[]>([
    {
      name: t('image.imageName'),
      key: 'imageName',
      value: '',
      type: 'AInput',
      placeholder: t('image.inputPlaceholder')
    },
    {
      name: t('image.columns.state'),
      key: 'status',
      value: undefined,
      type: 'ASelect',
      placeholder: t('image.selectPlaceholder'),
      filtered: true,
      label: '',
      options: [
        {
          value: 0,
          label: t('image.creating')
        },
        {
          value: 1,
          label: t('image.success')
        },
        {
          value: 2,
          label: t('image.error')
        }
      ]
    }
  ])

  const handleChangeCondition = (condition: string) => {
    currentCondition.value = condition
    const list = initConditionList(conditionList, condition)
    conditionList.value = list
  }

  const handleSelectChange = (params: DynamicConditionSpace.ConditionFace) => {
    const arr = inputOrselectChange(conditionList, params)
    conditionList.value = arr
  }

  const handleDelOption = (params: {index: number, key: string, filtered: boolean | undefined}) => {
    filterOptions.value.splice(params.index, 1)
    clearFilterOptionsList(params.index)
    const arr = initConditionList(conditionList, params.key)
    conditionList.value = arr

    const columnsArr = [...initColumnsFiltered(
      columns,
      {
        key: params.key,
        filtered: params.filtered
      }
    )]
    columns.value = columnsArr

    queryMyImage()
  }

  const handleClear = () => {
    filterOptions.value = []
    const arr = initConditionList(conditionList)
    conditionList.value = arr

    const columnsList = initColumnsFiltered(columns)
    columns.value = columnsList

    queryMyImage()
  }

  const handleQuery = () => {
    const selectConditionParams = conditionList.value.filter(item => item.key === currentCondition.value)[0]
    if (selectConditionParams.value === '' || selectConditionParams.value === undefined || (selectConditionParams.value as []).length === 0) {
      if (filterOptions.value.length !== 0) {
        const index = filterOptions.value.findIndex(item => item.key === selectConditionParams.key)
        filterOptions.value.splice(index, 1)
        clearFilterOptionsList(index)
      }
      queryMyImage()
    } else {
      columns.value = setColumnsFiltered(columns, { key: selectConditionParams.key, value: selectConditionParams.value as number, label: selectConditionParams.label as string })
      filterOptions.value = [...setFilerOptions(filterOptions, selectConditionParams)]
      queryMyImage()
    }
  }

  const pageParams: { pageNum: number; pageSize: number } = reactive({
    pageNum: 1,
    pageSize: 10
  })

  const conditionParams = computed<queryMyImageFace>(() => {
    const map = new Map()
    const searchForm: any = {
      ...pageParams
    }
    conditionList.value.forEach(item => {
      map.set(item.key, item.value)
    })
    for (const [k, v] of map) {
      searchForm[k] = v
    }
    return searchForm
  })

  const total = ref<number>(0)

  const dataSource = ref<any[]>()

  const columns = ref<TableColumnTypeConfig[]>([
    {
      title: t('image.columns.imageName'),
      dataIndex: 'imageName',
      isShow: true,
      disabled: true,
      fixed: 'left',
      width: 150
    },
    {
      title: t('image.columns.address'),
      dataIndex: 'filePath',
      isShow: true,
      disabled: false,
      width: 400
    },
    {
      title: t('image.columns.size'),
      dataIndex: 'size',
      isShow: true,
      disabled: false,
      width: 100
    },
    {
      title: t('image.columns.state'),
      dataIndex: 'status',
      isShow: true,
      disabled: false,
      align: 'center',
      width: 100,
      customFilterDropdown: true,
      radioValue: undefined,
      filtered: false,
      filterDropdownOpen: false
    },
    {
      title: t('image.columns.lockState'),
      dataIndex: 'lockStatus',
      isShow: true,
      disabled: false,
      align: 'center',
      width: 100
    },
    {
      title: t('image.columns.shareState'),
      dataIndex: 'share',
      isShow: true,
      disabled: false,
      align: 'center',
      width: 100
    },
    {
      title: t('image.columns.shareNum'),
      dataIndex: 'count',
      isShow: true,
      disabled: false,
      width: 100
    },
    {
      title: t('image.columns.subUser'),
      dataIndex: 'userSubName',
      isShow: true,
      disabled: false
    },
    {
      title: t('image.columns.describe'),
      dataIndex: 'description',
      isShow: true,
      disabled: false
    },
    {
      title: t('image.columns.updateTime'),
      dataIndex: 'createTime',
      isShow: true,
      disabled: false,
      width: 150
    },
    {
      title: t('image.columns.operate'),
      dataIndex: 'operate',
      isShow: true,
      disabled: true,
      fixed: 'right',
      align: 'center'
    }
  ])

  const scrollHeight = ref<number>(0)

  const setScrollHeight = () => {
    const height = document.querySelector('.table-box')?.clientHeight
    scrollHeight.value = height as number
  }

  const queryMyImage = async () => {
    const res = await queryMyImageApi(conditionParams.value)
    dataSource.value = res.data.rows
    total.value = res.data.total
    setScrollHeight()
  }

  const handleChangePage = (params: { pageNum: number; pageSize: number }) => {
    pageParams.pageNum = params.pageNum
    pageParams.pageSize = params.pageSize
    queryMyImage()
  }

  const filterColumns = ref<TableColumnTypeConfig[]>([])

  const isAllHidden = ref<boolean>(false)

  const handleChangeAll = (params: boolean) => {
    columns.value.forEach(item => {
      if (!item.disabled) {
        item.isShow = params
      }
    })
  }

  const handleSetColumns = () => {
    filterColumns.value = []
    const allDispear: any[] = []
    columns.value.forEach(item => {
      if (item.isShow) {
        allDispear.push(item)
      }
    })
    if (allDispear.length > 0) {
      isAllHidden.value = false
      filterColumns.value = allDispear
    } else {
      isAllHidden.value = true
      filterColumns.value = []
    }
  }

  const filterList = (params: TableColumnTypeConfig) => {
    const list = conditionList.value.filter(item => item.key === params.dataIndex)[0].options
    return list
  }

  const handleChangeStatus = (column: any) => {
    column.filtered = true
  }

  const handleconfirm = (params: any, clearFilters: any) => {
    filterOptions.value = [...setFilerOptions(filterOptions, { ...params, filtered: true, type: 'ASelect' })]
    conditionList.value.forEach(item => {
      if (item.key === params.dataIndex) {
        const value = params.radioValue.split(',')[0]
        item.value = parseInt(value)
      }
    })
    queryMyImage()
    clearFilters({
      closeDropdown: true
    })
  }

  return {
    currentCondition,
    filterOptions,
    conditionList,
    handleChangeCondition,
    handleSelectChange,
    handleDelOption,
    handleClear,
    handleQuery,
    total,
    dataSource,
    columns,
    scrollHeight,
    handleChangePage,
    filterColumns,
    isAllHidden,
    handleChangeAll,
    handleSetColumns,
    filterList,
    handleChangeStatus,
    handleconfirm,
    queryMyImage
  }
}
