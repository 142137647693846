import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "work-order-info" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('workOrder.orderInfo')), 1),
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, [
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('workOrder.productName')) + "：", 1),
          _createElementVNode("b", null, _toDisplayString(_ctx.workName), 1)
        ]),
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('workOrder.number')) + "：", 1),
          _createElementVNode("b", null, _toDisplayString(_ctx.workCode), 1)
        ])
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('workOrder.problemType')) + "： ", 1),
          (_ctx.workType === 0)
            ? (_openBlock(), _createElementBlock("b", _hoisted_2, _toDisplayString(_ctx.$t('workOrder.task')), 1))
            : (_ctx.workType === 1)
              ? (_openBlock(), _createElementBlock("b", _hoisted_3, _toDisplayString(_ctx.$t('workOrder.storage')), 1))
              : (_ctx.workType === 2)
                ? (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(_ctx.$t('workOrder.image')), 1))
                : (_ctx.workType === 3)
                  ? (_openBlock(), _createElementBlock("b", _hoisted_5, _toDisplayString(_ctx.$t('workOrder.charging')), 1))
                  : (_ctx.workType === 4)
                    ? (_openBlock(), _createElementBlock("b", _hoisted_6, _toDisplayString(_ctx.$t('workOrder.user')), 1))
                    : _createCommentVNode("", true)
        ]),
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('workOrder.status')) + "： ", 1),
          (_ctx.status === 0)
            ? (_openBlock(), _createElementBlock("b", _hoisted_7, _toDisplayString(_ctx.$t('workOrder.submit')), 1))
            : _createCommentVNode("", true),
          (_ctx.status === 1)
            ? (_openBlock(), _createElementBlock("b", _hoisted_8, _toDisplayString(_ctx.$t('workOrder.over')), 1))
            : _createCommentVNode("", true),
          (_ctx.status === -1)
            ? (_openBlock(), _createElementBlock("b", _hoisted_9, _toDisplayString(_ctx.$t('workOrder.revoked')), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('workOrder.createTime')) + "：", 1),
          _createElementVNode("b", null, _toDisplayString(_ctx.createTime), 1)
        ])
      ])
    ])
  ]))
}